import styled from 'styled-components/macro';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

export interface ErrorProp {
  error?: boolean;
}

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
  width: 100%;
`;

export const Label = styled.label<ErrorProp>`
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing.XS / 8}rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  color: ${({ theme, error }) => (error ? theme.palette.error : theme.palette.label)};
  line-height: 19px;
`;

interface FieldProps {
  isMultiline?: boolean;
}

export const Field = styled.span<FieldProps>`
  display: flex;
  align-items: ${({ isMultiline }) => (isMultiline ? 'flex-start' : 'center')};
`;

export const Icon = styled.img<FieldProps>`
  position: absolute;
  margin-left: 15px;
  margin-top: ${({ isMultiline, theme }) => (isMultiline ? `${theme.spacing.XXS / 2}rem` : 0)};
`;

interface TextInputProps extends ErrorProp {
  hasIcon?: boolean;
}

export const TextInputField = styled.input<TextInputProps>`
  height: ${({ theme }) => theme.spacing.S * 0.85}rem;
  border-radius: 4px;
  width: 100%;
  border: ${({ theme, error }) => (error ? theme.borderStyles.error : theme.borderStyles.primary)};
  color: ${({ theme, error }) => (error ? theme.palette.error : theme.palette.label)};
  padding-left: ${({ theme, hasIcon }) => (hasIcon ? `${theme.spacing.S * 1.25}rem` : `${theme.spacing.XXS}rem`)};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  outline: none;
`;

export const MultilineTextInputField = styled.textarea<ErrorProp>`
  height: ${({ theme }) => theme.spacing.M * 1.2}rem;
  border-radius: 4px;
  width: 100%;
  border: ${({ theme, error }) => (error ? theme.borderStyles.error : theme.borderStyles.primary)};
  color: ${({ theme, error }) => (error ? theme.palette.error : theme.palette.label)};
  padding-left: ${({ theme }) => theme.spacing.S * 1.25}rem;
  padding-top: ${({ theme }) => theme.spacing.XXS / 2}rem;
  padding-bottom: ${({ theme }) => theme.spacing.XXS / 2}rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  outline: none;
  resize: none;
`;

export const Error = styled.p`
  margin-top: 5px;
  color: ${({ theme }) => theme.palette.error};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  align-self: flex-end;
`;
