import styled from 'styled-components/macro';

// -------------- Main Area Header (to be used inside the ContentWrapper)
export const Header = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: calc(${({ theme }) => theme.spacing.XXL}px * 1);
  padding-top: ${({ theme }) => theme.spacing.M}px;
  height: fit-content;
  
  button:last-child {
    margin-left: 1rem;
  }
`;

export interface HeaderProps {
  legend?: boolean;
}

export const HeaderText = styled.div<HeaderProps>`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: calc(${({ theme }) => theme.fontScale.L}rem * 0.85);
  color: ${({ theme }) => theme.palette.header};
  width: fit-content;
  letter-spacing: 0.03rem;
  height: fit-content;
  margin: 0.5rem ${({ legend }) => (legend ? '0.5rem' : 'auto')} 0.5rem 0.5rem;
`;

export interface BackButtonProps {
  hasBackButton?: boolean;
}

export const BackButton = styled.img<BackButtonProps>`
  margin: 0 0 auto 0;
  cursor: pointer;
  height: 0.75rem;
  width: auto;
  padding: 12px;
  display: ${({ hasBackButton }) => (hasBackButton ? 'block' : 'none')};
`;

export const TableKey = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.subheader};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  margin: 0.7rem auto 2px 30px;
`;

export const InactiveFlag = styled.div`
  background: ${({ theme }) => theme.palette.error};
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.spacing.S}px;
  margin-left: ${({ theme }) => theme.spacing.L}px;
  display: block;
`;

export const InactiveFlagKey = styled(InactiveFlag)`
  margin: auto 10px auto auto;  
  width: 0.75rem;
  height: 0.75rem;
`;
