import styled from 'styled-components/macro';

import { LoginBackground } from '../../assets/Images';

export const BackgroundImage = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background-image: url(${LoginBackground});
  background-size: cover;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
  border-radius: 40px;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  height: 200px;
  width: 280px;
`;
