export default {
  Loading: {
    loadingText: 'Loading...',
  },
  Home: {
    login: 'Go to Admin Site',
  },
  Login: {
    heading: 'Admin Login',
    emailLabel: 'Email ID',
    passwordLabel: 'Password',
    show: 'Show',
    buttonText: 'Login',
    loadingButtonText: 'Logging in...',
    forgotPassword: 'Forgot Password?',
    emailDoesNotExist: "*Email id doesn't exist.",
    wrongPassword: '*Invalid password.',
    defaultError: '*Error logging in.',
  },
  ForgotPassword: {
    headerText: 'Forgot Password',
    bodyText: 'Please enter your registered Email ID.\nYou will receive a password reset link on your email id.',
    emailLabel: 'Email ID',
    emailInvalidError: '*Invalid Email given.',
    emailDoesNotExistError: '*Email id doesn\'t exist.',
    forgotPasswordText: 'Submit',
    passwordResetText: 'Submitting...',
  },
  PageHeadings: {
    dashboard: 'Dashboard',
    users: 'Users',
    charities: 'Charities',
    events: 'Events',
    statistics: 'Statistics',
    complaints: 'Complaints',
    settings: 'Settings',
    loggedInAs: 'Logged in as: ',
  },
  Dashboard: {
    pageTitle: 'Dashboard',
    totalUsers: 'Total Users',
    totalEvents: 'Total Events',
    totalDonations: 'Total Donations',
    totalRevenue: 'Total Smylee Revenue',
    newUsersHeading: 'New Users Added this Week',
    activityThisWeek: {
      heading: 'Activity This Week',
      newUsers: 'New Users',
      eventsCreated: 'Events Created',
      eventsHeld: 'Events Held',
      eventsCancelled: 'Events Cancelled',
      donations: 'Donations',
    },
    viewAll: 'View All',
    donationsHeading: 'Donations This Week',
    newUsersTableHeading: 'New Users',
    tableHeadings: {
      createdDate: 'Created Date',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      events: 'Events',
      donations: 'Donation Total',
      rating: 'Rating',
      action: 'Action',
      viewProfile: 'View Profile',
    },
  },
  Users: {
    pageTitle: 'Users',
    allUsers: 'All Users',
    activeUsers: 'Active Users',
    inactiveUsers: 'Inactive Users',
    tableHeadings: {
      createdDate: 'Created Date',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      events: 'Events',
      donations: 'Donation Total',
      rating: 'Rating',
      action: 'Action',
    },
    viewAll: 'View All',
    viewProfile: 'View Profile',
    filter: 'Filter',
    export: 'Download CSV',
    noData: 'No records found',
    download: 'Download',
  },
  UserProfile: {
    pageHeading: 'User Profile',
    stats: 'Stats',
    rating: 'Rating',
    events: 'Events',
    donations: 'Donation Total',
    deactivateUser: 'Deactivate User',
    activateUser: 'Activate User',
    delete: 'Delete user and all of their data',
    eventsTable: {
      heading: 'Events',
      eventID: 'Event ID',
      eventTitle: 'Event Title',
      eventType: 'Event Type',
      location: 'Location',
      startDate: 'Event Date',
      endDate: 'End Date',
      startTime: 'Start Time',
      endTime: 'End Time',
      donation: 'Donation',
      status: 'Status',
    },
    eventStatus: {
      pledgeTaken: 'Pledge Taken',
      donatedAnyway: 'Donated Anyway',
      eventCancelled: 'Event Cancelled',
      cancelledAttendance: 'Cancelled Attendance',
      waiting: 'Waiting for Event',
      didntProceed: "Didn't Proceed",
      noDonation: 'No Donation',
      proceeded: 'Proceeded',
    },
  },
  Charities: {
    pageTitle: 'Charities',
    allCharities: 'All Charities',
    activeCharities: 'Active Charities',
    inactiveCharities: 'Inactive Charities',
    tableHeadings: {
      charityName: 'Charity Name',
      category: 'Category',
      nominated: 'Nominated',
      donations: 'Donation Total',
      action: 'Action',
    },
    viewAll: 'View All',
    viewProfile: 'View Profile',
    addNew: 'Add',
    export: 'Export',
  },
  CharityProfile: {
    pageHeading: 'Charity Profile',
    stats: 'Stats',
    nominated: 'Nominated',
    donations: 'Donation Total',
    deactivateCharity: 'Deactivate Charity',
    activateCharity: 'Activate Charity',
    editCharity: 'Edit Charity Details',
    getStripeLink: 'Get Stripe Login Link',
    stripeLinkTitle: 'Stripe Login Link',
    stripeLinkMessage: 'Provide this single-use link to the charity to log in to Stripe.  Users must enter an SMS authentication code to view their dashboard.',
    stripeLinkErrorTitle: 'Error',
    eventsTable: {
      heading: 'Events',
      eventID: 'Event ID',
      eventTitle: 'Event Title',
      eventType: 'Event Type',
      location: 'Location',
      eventDate: 'Event Date',
      endDate: 'End Date',
      startTime: 'Start Time',
      endTime: 'End Time',
      donation: 'Donation',
      status: 'Status',
      export: 'Export',
    },
    eventStatus: {
      eventCancelled: 'Event Cancelled',
      waiting: 'Waiting for Event',
      didntProceed: "Didn't Proceed",
      proceeded: 'Proceeded',
    },
  },
  CharityModal: {
    addLogo: 'Add Logo',
    logoError: 'Must provide the charity logo in a square format',
    heading: 'Add Charity',
    headingEdit: 'Edit Charity',
    subheading: 'Provide the information below to add a charity to the platform',
    businessDetails: 'Charity Business Details',
    nameLabel: 'Charity Name',
    officialNameLabel: 'Charity Official Name (for invoicing)',
    nameError: 'Must provide the charity name',
    abn: 'ABN Number',
    abnError: 'Must provide the charities ABN number',
    stripeIDLabel: 'Stripe Account ID',
    stripeIDError: 'Must provide a valid stripe account ID',
    addr1: 'Address Line 1',
    addr2: 'Address Line 2',
    city: 'City',
    state: 'State',
    postcode: 'Postcode',
    country: 'Country',
    signature: 'Add Signature',
    addrError: 'Must provide the charity address',
    charityContact: 'Charity Contact',
    contactName: 'Contact Name',
    phoneNumber: 'Phone Number',
    website: 'Website',
    subscribeLink: 'Subscribe Link',
    charityNumber: 'Charity Number',
    websiteError: 'Must provide the charity website',
    buttonText: 'Add Charity',
    saveButtonText: 'Save Charity',
    optional: '(optional)',
    promoted: 'Promoted',
    editPromoted: 'Edit Promoted Details',
  },
  PriorityCharityModal: {
    subheading: 'Promoted Charity Details',
    addLogo: 'Add Logo',
    horizontalLogoError: 'Must provide the charity logo in a horizontal format',
    description: 'Description',
    descriptionError: 'Must provide the charity description',
    backgroundImage: 'Background Image',
    backgroundImageError: 'Must provide the charity background image',
    cause1: 'Cause 1',
    cause2: 'Cause 2',
    cause3: 'Cause 3',
    cause: 'Cause',
    link: 'Link',
    image: 'Image',
    upload: 'Upload',
    causeError: 'Must provide 3 charity cause titles, links and images',
    save: 'Save',
  },
  ExportCharity: {
    heading: 'Export By',
    startDate: 'Start Date',
    endDate: 'End Date',
    charity: 'Charity',
    subLabelCharity: 'Please select a charity, if none are chosen a report will be generated for all.',
    download: 'Download',
    createReport: 'Create Report',
    ready: 'Your report is ready to download',
    noData: 'No records found',
  },
  Events: {
    pageTitle: 'Events',
    allEvents: 'All Events',
    upcomingEvents: 'Upcoming Events',
    previousEvents: 'Previous Events',
    tableHeadings: {
      eventId: 'Event ID',
      fullEventId: 'Full Event ID',
      created: 'Date Created',
      country: 'Country',
      user: {
        givenName: 'First Name',
        surname: 'Last Name',
        email: 'Email',
      },
      guests: 'Guests',
      eventTitle: 'Event Title',
      eventType: 'Event Type',
      location: 'Location',
      eventStartDate: 'Event Start Date',
      eventEndDate: 'Event End Date',
      startTime: 'Start Time',
      endTime: 'End Time',
      charity: 'Charity',
      pledge: 'Pledge',
      donation: 'Donation',
      status: 'Status',
      host: {
        givenName: 'Host First Name',
        surname: 'Host Last Name',
        email: 'Host Email',
        location: 'Host Location',
        dateCreated: 'Date Profile Created',
      },
      guest: {
        givenName: 'Guest First Name',
        surname: 'Guest Last Name',
        email: 'Guest Email',
        location: 'Guest Location',
        rsvpStatus: 'Guest RSVP Status',
        inviteType: 'Guest Invite Type',
      },
      eventDateCreated: 'Date of event creation',
      profileDateCreated: 'Date Profile Created',
      totalGuestsInvited: 'Total Number of Guests Invited',
      checkIn: 'Check-in',
      locationTrackerUsed: 'Location Tracker Used',
    },
    viewAll: 'View All',
    filter: 'Filter',
    export: 'Download CSV',
    eventStatus: {
      eventCancelled: 'Event Cancelled',
      waiting: 'Waiting for Event',
      didntProceed: "Didn't Proceed",
      proceeded: 'Proceeded',
    },
  },
  Statistics: {
    pageTitle: 'Statistics',
    topEventType: 'Top Event Types',
    donationTypes: 'Donation Types',
    topEventLocations: 'Top Event Locations (Suburb)',
    donationAmount: 'Donation Amount',
    donationFreq: 'Donation Frequency',
    donationByState: 'Donation By State',
    topCharities: 'Top Charities by Revenue',
    donationsByAge: 'Donations By Age',
    thisYear: 'This Year',
    thisMonth: 'This Month',
    thisWeek: 'This Week',
    noResults: 'No Results Available',
    donateAnyway: 'Donate Anyway',
  },
  Complaints: {
    pageTitle: 'Complaints',
    unread: 'Unread',
    resolved: 'Resolved',
    seeAll: 'See All',
    hide: 'Hide',
    reportedBy: 'Reported By',
    reason: 'Reason',
    placeholder: 'Please type your reply...',
    sendMessage: 'Send message to %',
    markResolved: 'Mark Resolved',
    suspendUser: 'Suspend User',
    reasons: {
      abusive: 'Abusive',
      offensive: 'Offensive',
      privacy: 'Privacy',
      threatening: 'Threatening',
      trolling: 'Trolling',
      discrimination: 'Discrimination',
      behaviour: 'Behaviour against the law',
      violatedTOS: 'Violated Smylee Terms of Service',
      inappropriate: 'Inappropriate language or imagery',
    },
    successHeading: 'Success!',
    successSubheading: 'Your email has been sent.',
  },
  Settings: {
    pageTitle: 'Settings',
    logOut: {
      loggedInAs: 'Logged in as ',
      logoutButton: 'Log Out',
    },
    addAdmin: {
      heading: 'Add a New Admin',
      firstNameLabel: 'First Name',
      firstNameError: 'Must enter first name.',
      lastNameLabel: 'Last Name',
      lastNameError: 'Must enter last name.',
      emailLabel: 'Email',
      emailError: 'Must enter email.',
      passwordLabel: 'Create A Password',
      show: 'Show',
      passwordError: 'Must enter temporary password.',
      addImageButtonText: 'Add Image',
      submitButtonText: 'Add new admin',
      successMessage: 'You have successfully been logged in to your new user account',
    },
    changePassword: {
      heading: 'Change Password',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      confirm: 'Confirm New Password',
      save: 'Save New Password',
      show: 'Show',
    },
    SuccessModal: {
      heading: 'Success!',
      subheading: 'The changes have been saved',
      buttonText: 'Got it!',
    },
    FailureModal: {
      heading: 'Error!',
      subheading: 'The changes have not been saved due to an error. Please try again',
      buttonText: 'Okay',
    },
    changeDetails: {
      heading: 'Change Your Details',
      firstNameLabel: 'First Name',
      lastNameLabel: 'Last Name',
      emailLabel: 'Email',
      changeImage: 'Change Image',
      submit: 'Save Changes',
      confirmPasswordHeading: 'Confirm Password',
      confirmPasswordSubheading: 'Before changing senstive information, you must login again to proceed. Please enter your password',
      confirmPasswordLabel: 'Confirm Password',
      confirmPasswordButton: 'Confirm',
    },
    admins: {
      heading: 'Admins',
    },
  },
  SearchBar: {
    searchPlaceholder: 'Search',
  },
  EventsFilterModal: {
    heading: 'Filter By',
    dateFrom: 'Date From',
    dateTo: 'Date To',
    donationRange: 'Donation Range',
    lowerAmount: 'Lower Range',
    upperRange: 'Upper Range',
    apply: 'Apply',
    clear: 'Clear Filters',
  },
};
