import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import typePolicies from './graphql/typePolicies';
import { getAuthUser } from './Authentication';
import { logError, log } from './Analytics';

export type Client = ApolloClient<NormalizedCacheObject>;

export function apolloInit(): Client {
  // ------------------------ Cache ------------------------ //
  const cache = new InMemoryCache({ typePolicies });

  // ---------------------- Http Link ---------------------- //
  if (!process.env.REACT_APP_GRAPHQL_URL) throw new Error('Must define REACT_APP_GRAPHQL_URL env variable');
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });

  // ----------------- Authentication Link ----------------- //
  const authLink = setContext(async (_, { headers }) => {
    const authUser = getAuthUser();
    if (!authUser) return headers;

    const token = await authUser.getIdToken();
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer: ${token}` : '',
      },
    };
  });

  // ---------------------- Error Link --------------------- //
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    let errorMessage = '';

    if (graphQLErrors) {
      errorMessage += '** GRAPHQL ERROR **\n';
      graphQLErrors.forEach(({ message, locations }) => {
        errorMessage += `[GraphQL error]: Message: ${JSON.stringify(message)}, Location: ${JSON.stringify(locations)}\n`;
      });
    }

    if (networkError) {
      errorMessage += '\n** NETWORK ERROR **\n';
      errorMessage += JSON.stringify(networkError);
    }

    if (errorMessage) logError(errorMessage);
  });

  // ---------------------- Log Link --------------------- //
  const SHOW_NETWORK_LOGS = false;
  const logLink = new ApolloLink((operation, forward) => {
    if (SHOW_NETWORK_LOGS) log(operation);
    return forward(operation);
  });

  return new ApolloClient({
    cache,
    link: ApolloLink.from([logLink, errorLink, authLink, httpLink]),
  });
}

export default {
  apolloInit,
};
