import React, { useState, useRef, ChangeEvent } from 'react';

import {
  SectionHeading, FormWrapper, InputContainer, HiddenFileInput,
  NameInputWrapper, Gap, ProfilePicture, ProfilePicturePlaceholder, ImageContainer,
} from '../Styles';
import {
  Form, InputWrapper, Label, Field, TextInputField, Button, Modal,
} from '../../../../components';

import useStrings from '../../../../hooks/useStrings';
import { useGetAdmin, useEditAdmin } from '../../../../lib/graphql/queries';
import { getUserId, updateUserEmail } from '../../../../lib/Authentication';
import { logError } from '../../../../lib/Analytics';
import { uploadMedia } from '../../../../lib/Media';

const ChangeDetails: React.FC = () => {
  const [{ Settings: strings }] = useStrings();

  const hiddenFileInputRef = useRef<HTMLInputElement>(null);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmPasswordModal, setShowConfirmPasswordModal] = useState(false);

  const id = getUserId();

  const { data: adminData } = useGetAdmin({
    variables: { input: { id } },
    skip: !id,
  });
  const admin = adminData?.admin.admin;

  const [firstName, setFirstName] = useState(admin?.firstName || '');
  const [surname, setSurname] = useState(admin?.surname || '');
  const [email, setEmail] = useState(admin?.email || '');
  const [password, setPassword] = useState('');
  const [profilePhoto, setProfilePhoto] = useState(admin?.profilePhoto);

  const [editAdmin, { loading }] = useEditAdmin();

  const handleInitialSubmit = async () => {
    if (!firstName || !surname || !email) return;
    try {
      if (email !== admin?.email) {
        setShowConfirmPasswordModal(true);
        return;
      }

      await editAdmin({
        variables: {
          input: {
            email, firstName, id, surname, profilePhoto,
          },
        },
      });
      setShowSuccessModal(true);
    } catch (err) {
      setShowErrorModal(true);
      logError(err);
    }
  };

  const handlePasswordConfirmSubmit = async () => {
    try {
      if (!password) return;
      await updateUserEmail(email, password);

      await editAdmin({
        variables: {
          input: {
            email, firstName, id, surname, profilePhoto: undefined,
          },
        },
      });
      setShowConfirmPasswordModal(false);
      setShowSuccessModal(true);
    } catch (err) {
      setShowErrorModal(true);
      logError(err);
    }
  };

  const handleClick = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref && ref.current) {
      ref.current.click();
    }
  };

  const handleChangeImage = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event?.target?.files?.length > 0) {
      const imageFile = event.target.files[0];
      const imageURL = await uploadMedia(imageFile);

      if (imageURL) setProfilePhoto(imageURL);
    }
  };

  const buttonDisabled = loading || firstName === '' || surname === '' || email === ''
    || (firstName === admin?.firstName && surname === admin?.surname
        && email === admin?.email && profilePhoto === admin.profilePhoto);

  return (
    <Form onSubmit={handleInitialSubmit}>
      {showErrorModal && (
        <Modal
          closeModal={() => setShowErrorModal(false)}
          header={strings.FailureModal.heading}
          subHeader={strings.FailureModal.subheading}
          hasSubHeader
          rounded={false}
        >
          <Button
            onClick={() => setShowErrorModal(false)}
            buttonText={strings.FailureModal.buttonText}
            variant="modal"
          />
        </Modal>
      )}
      {showSuccessModal && (
        <Modal
          closeModal={() => setShowSuccessModal(false)}
          header={strings.SuccessModal.heading}
          subHeader={strings.SuccessModal.subheading}
          hasSubHeader
          rounded={false}
        >
          <Button
            onClick={() => setShowSuccessModal(false)}
            buttonText={strings.SuccessModal.buttonText}
            variant="modal"
          />
        </Modal>
      )}
      {showConfirmPasswordModal && (
        <Modal
          closeModal={() => setShowConfirmPasswordModal(false)}
          header={strings.changeDetails.confirmPasswordHeading}
          subHeader={strings.changeDetails.confirmPasswordSubheading}
          hasSubHeader
          rounded={false}
        >
          <InputWrapper>
            <Label>{strings.changeDetails.confirmPasswordLabel}</Label>
            <Field>
              <TextInputField
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Field>
          </InputWrapper>
          <Button
            disabled={password === ''}
            onClick={handlePasswordConfirmSubmit}
            buttonText={strings.changeDetails.confirmPasswordButton}
            variant="modal"
          />
        </Modal>
      )}
      <SectionHeading>{strings.changeDetails.heading}</SectionHeading>
      <FormWrapper>
        <InputContainer>
          <NameInputWrapper>
            <InputWrapper>
              <Label>{strings.changeDetails.firstNameLabel}</Label>
              <Field>
                <TextInputField
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Field>
            </InputWrapper>
            <Gap />
            <InputWrapper>
              <Label>{strings.changeDetails.lastNameLabel}</Label>
              <Field>
                <TextInputField
                  type="text"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </Field>
            </InputWrapper>
          </NameInputWrapper>
          <InputWrapper>
            <Label>{strings.changeDetails.emailLabel}</Label>
            <Field>
              <TextInputField
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Field>
          </InputWrapper>
        </InputContainer>
        <ImageContainer>
          {profilePhoto
            ? <ProfilePicture src={profilePhoto} /> : <ProfilePicturePlaceholder />}
          <HiddenFileInput
            ref={hiddenFileInputRef}
            multiple={false}
            accept="image/*"
            type="file"
            onChange={handleChangeImage}
          />
          <Button buttonText={strings.changeDetails.changeImage} variant="outlined" onClick={() => handleClick(hiddenFileInputRef)} />
        </ImageContainer>
      </FormWrapper>
      <Button
        buttonText={strings.changeDetails.submit}
        variant="modal"
        onClick={handleInitialSubmit}
        disabled={buttonDisabled}
      />
    </Form>
  );
};

export default ChangeDetails;
