import { graphql } from '../generated';
import { createUseLazyQuery, createUseMutation, createUseQuery } from './helpers';

export const ORGANIZATION_DATA_FRAGMENT = graphql(`
fragment OrganizationData on Organization {
  id
  name
  description
  iconLogo
  logo
  backgroundImage
  assetPairOne {
    website
    title
    image
  }
  assetPairTwo {
    website
    title
    image
  }
  assetPairThree {
    website
    title
    image
  }
  website
  eventsLink
  subscribeLink
  totalDonated
  disabled
  totalEvents
  addrLineOne
  addrLineTwo
  abn
  priority
  city
  state
  postcode
  country
  contactName
  phoneNumber
  signatureImage
  charityNumber
  currency
  officialName
}
`);

export const ORGANIZATION_WITH_PAYMENT_DATA_FRAGMENT = graphql(`
fragment OrganizationQueryViewData on OrganizationQueryView {
  id
  name
  description
  iconLogo
  logo
  backgroundImage
  assetPairOne {
    website
    title
    image
  }
  assetPairTwo {
    website
    title
    image
  }
  assetPairThree {
    website
    title
    image
  }
  website
  eventsLink
  subscribeLink
  totalDonated
  disabled
  totalEvents
  addrLineOne
  addrLineTwo
  abn
  priority
  city
  state
  postcode
  country
  contactName
  phoneNumber
  signatureImage
  charityNumber
  currency
  officialName
  paymentDetailsID
  stripeAccountID
}
`);

export const GET_ORGANIZATIONS = graphql(`
  query GetOrganizationsIgnoringDisabled($adminID: ID!) {
    organizationsIgnoringDisabled(adminID: $adminID) {
      organizations {
        ...OrganizationQueryViewData
      }
    }
  }
`);
export const useGetOrganizationsIgnoringDisabled = createUseQuery(GET_ORGANIZATIONS);

export const GET_ORGANIZATION = graphql(`
  query GetOrganizationIgnoringDisabled($adminID: ID!, $organizationID: ID!) {
    organizationIgnoringDisabled(adminID: $adminID, organizationID: $organizationID) {
      organization {
        ...OrganizationQueryViewData
      }
    }
  }
`);
export const useGetOrganizationIgnoringDisabled = createUseQuery(GET_ORGANIZATION);

export const GET_ORGANIZATION_STRIPE_LINK = graphql(`
query GetOrganizationStripeLink($organizationID: String!) {
  stripeLoginLink(organizationID: $organizationID) {
    link
  }
}
`);
export const useOrganizationStripeLinkLazy = createUseLazyQuery(GET_ORGANIZATION_STRIPE_LINK, { fetchPolicy: 'network-only' });

export const CREATE_ORGANIZATION = graphql(`
  mutation CreateOrganization($input: newOrganizationInput!) {
    newOrganization(input: $input) {
      organization {
        ...OrganizationData
      }
    }
  }
`);
export const useCreateOrganization = createUseMutation(CREATE_ORGANIZATION);

export const EDIT_ORGANIZATION = graphql(`
  mutation EditOrganization($input: editOrganizationInput!) {
    editOrganization(input: $input) {
      ...OrganizationQueryViewData
    }
  }
`);
export const useEditOrganization = createUseMutation(EDIT_ORGANIZATION);
