import styled from 'styled-components/macro';

export const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 20px;
  width: 24rem;
  height: 38px;
`;

export const SearchInput = styled.input`
  height: ${({ theme }) => theme.spacing.S * 0.85}rem;
  border-radius: 25px;
  width: 100%;
  border: ${({ theme }) => theme.borderStyles.primary};
  color: ${({ theme }) => theme.palette.label};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  padding-left: ${({ theme }) => theme.spacing.S * 1.1}rem;
  outline: none;
`;

export const Icon = styled.img`
  margin-left: 8px;
  height: 1.4rem;
  margin-right: ${({ theme }) => theme.spacing.XS}px;
  padding: 12px;
  position: absolute;
  cursor: pointer;
`;
