import React from 'react';
import { BackArrow } from '../../assets/Icons';

import {
  BackButtonProps, HeaderProps,
  Header,
  BackButton,
  HeaderText,
  TableKey,
  InactiveFlagKey,
} from './Styles';

const ContentHeader: React.FC<ContentHeaderProps> = ({
  contentTitle,
  clickFunction,
  hasBackButton = false,
  children,
  legendText,
}) => (
  <Header>
    <BackButton
      src={BackArrow}
      onClick={clickFunction}
      hasBackButton={hasBackButton}
    />
    <HeaderText legend={!!legendText}>{contentTitle}</HeaderText>
    {!!legendText && (
      <TableKey>
        <InactiveFlagKey />
        {legendText}
      </TableKey>
    )}
    {children}
  </Header>
);

export interface ContentHeaderProps extends BackButtonProps, HeaderProps {
  contentTitle?: string;
  clickFunction?: () => void;
  hasBackButton?: boolean;
  legendText?: string;
  children?: JSX.Element | JSX.Element[];
}

export default ContentHeader;
