import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PillContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.grey};
  border-radius: 25px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const SelectedTitle = styled.p`
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  margin-right: 0.4rem;
  color: ${({ theme }) => theme.palette.title};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  white-space: nowrap;
`;

export const DropdownTitle = styled(SelectedTitle)`
  margin: 0.8rem 0;
  cursor: pointer;
`;

export const DownArrow = styled.img`
  fill: ${({ theme }) => theme.palette.title};
`;

export const Dropdown = styled.div`
  position: absolute;
  border-radius: 15px;
  border: ${({ theme }) => theme.borderStyles.secondary};
  margin-top: ${({ theme }) => theme.spacing.XS}rem;
  padding: 0rem 1.8rem 0rem 1rem;
  background-color: ${({ theme }) => theme.palette.white};
  z-index: 1;
`;
