import React from 'react';

import StyledScrollableTable from './Styles';

const ScrollableTable: React.FC = ({ children }) => (
  <StyledScrollableTable>
    {children}
  </StyledScrollableTable>
);

export default ScrollableTable;
