import { graphql } from '../generated';
import { createUseQuery } from './helpers';

export const PAYMENT_DATA_FRAGMENT = graphql(`
fragment PaymentData on Payment {
  id
  userPaymentDetails {
    id
    user { ...UserData }
  }
  organizationPaymentDetails {
    id
    organization { ...OrganizationData }
  }
  stripeChargeID
  receiptNo
  type
  currency
  amount
  status
  lastTried
  eventID
  cardTransactionFee
  event { ...EventData }
}
`);

export const DONATION_HISTORY_PAYMENT_DATA_FRAGMENT = graphql(`
fragment DonationHistoryPaymentData on DonationHistoryPayment {
  id
  organizationPaymentDetails {
    id
    organization { ...OrganizationData }
  }
  type
  amount
  status
  lastTried
  eventID
}
`);

export const GET_PAYMENT_HISTORY_FOR_USER = graphql(`
  query GetPaymentHistory($input: userIDInput!) {
    donationHistory(input: $input) {
      totalDonated
      payments {
        ...DonationHistoryPaymentData
      }
    }
  }
`);
export const useGetPaymentHistory = createUseQuery(GET_PAYMENT_HISTORY_FOR_USER);
