import React from 'react';
import moment from 'moment';

import {
  HeadingContainer, GraphHeading,
  BarGraphContainer, LabelBarContainer, Label, BarContainer, Bar, Total,
} from '../Styles';

import useStrings from '../../../../hooks/useStrings';
import { getDashboardGraphDates, displayDonation } from '../../../../lib/Utils';
import {
  useGetUsersThisWeek, useGetEventsCreatedFromDate,
  useGetEventsHeldFromDate, useGetEventsCancelledFromDate,
  useGetDonationsThisWeek,
} from '../../../../lib/graphql/queries';

const mondayThisWeek = moment().startOf('isoWeek').valueOf();
const mondayLastWeek = moment().startOf('isoWeek').subtract(7, 'days').valueOf();

const ActivityThisWeek: React.FC<ActivityThisWeekProps> = () => {
  const [{ Dashboard: strings }] = useStrings();

  const graphDays = (thisWeek: boolean) => {
    const startingMonday = moment(thisWeek ? mondayThisWeek : mondayLastWeek).format();

    return getDashboardGraphDates(startingMonday);
  };

  const dataCounts = (thisWeek: boolean, dateDataArray: number[]) => (
    graphDays(thisWeek)
      .map((graphDay) => dateDataArray
        .filter((userCreatedDate) => moment(userCreatedDate).format('YYYY-MM-DD') === graphDay))
      .reduce((prev, curr) => (prev + curr.length), 0)
  );

  const { data: userSinceLastWeekData } = useGetUsersThisWeek({
    variables: { date: mondayLastWeek.toString() },
  });
  const usersSinceLastWeek = userSinceLastWeekData?.usersThisWeek.usersCreatedThisWeekDates || [];

  const { data: eventsCreatedData } = useGetEventsCreatedFromDate({
    variables: { date: mondayLastWeek.toString() },
  });
  const eventsCreatLastWeek = eventsCreatedData?.eventsCreatedFromDate.eventsDates || [];

  const { data: eventsHeldData } = useGetEventsHeldFromDate({
    variables: { date: mondayLastWeek.toString() },
  });
  const eventsHeldLastWeek = eventsHeldData?.eventsHeldFromDate.eventsDates || [];

  const { data: eventsCancelledData } = useGetEventsCancelledFromDate({
    variables: { date: mondayLastWeek.toString() },
  });
  const eventsCancelledLastWeek = eventsCancelledData?.eventsCancelledFromDate.eventsDates || [];

  const { data: donationsData } = useGetDonationsThisWeek({
    variables: { date: mondayLastWeek.toString() },
  });
  const donations = donationsData?.donationsThisWeek.donationsMadeThisWeek || [];

  const donationDataCounts = (thisWeek: boolean) => (
    graphDays(thisWeek)
      .map((graphDay) => donations
        .filter((donation) => moment(donation.lastTried).format('YYYY-MM-DD') === graphDay)
        .map((donation) => donation.amount)
        .reduce((prev, curr) => (prev + curr), 0))
      .reduce((prev, curr) => prev + curr)
  );

  const activityBarData = [
    {
      label: strings.activityThisWeek.newUsers,
      percentage: (
        (dataCounts(true, usersSinceLastWeek) / dataCounts(false, usersSinceLastWeek)) * 100),
      total: dataCounts(true, usersSinceLastWeek),
    },
    {
      label: strings.activityThisWeek.eventsCreated,
      percentage: (
        (dataCounts(true, eventsCreatLastWeek) / dataCounts(false, eventsCreatLastWeek)) * 100),
      total: dataCounts(true, eventsCreatLastWeek),
    },
    {
      label: strings.activityThisWeek.eventsHeld,
      percentage: (
        (dataCounts(true, eventsHeldLastWeek) / dataCounts(false, eventsHeldLastWeek)) * 100),
      total: dataCounts(true, eventsHeldLastWeek),
    },
    {
      label: strings.activityThisWeek.eventsCancelled,
      percentage:
        ((dataCounts(true, eventsCancelledLastWeek) / dataCounts(false, eventsCancelledLastWeek)
        ) * 100),
      total: dataCounts(true, eventsCancelledLastWeek),
    },
    {
      label: strings.activityThisWeek.donations,
      percentage: ((donationDataCounts(true) / donationDataCounts(false)) * 100),
      total: donationDataCounts(true),
    },
  ];

  const renderActivityBarCharts = () => (
    activityBarData.map((data) => (
      <BarGraphContainer key={data.label}>
        <LabelBarContainer>
          <Label>{data.label}</Label>
          <BarContainer>
            <Bar percentage={data.total === 0 ? 0 : data.percentage} />
          </BarContainer>
        </LabelBarContainer>
        <Total>
          {data.label === strings.activityThisWeek.donations
            ? displayDonation(data.total)
            : data.total.toLocaleString()}
        </Total>
      </BarGraphContainer>
    ))
  );

  return (
    <>
      <HeadingContainer>
        <GraphHeading>{strings.activityThisWeek.heading}</GraphHeading>
      </HeadingContainer>
      {renderActivityBarCharts()}
    </>
  );
};

interface ActivityThisWeekProps { }

export default ActivityThisWeek;
