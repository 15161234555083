import React from 'react';

import {
  Header,
  PageName,
  ProfileWrap,
  InfoText,
  ProfileImage,
} from './Styles';
import { PlaceholderProfile } from '../../../assets/Images';

import { getUserId } from '../../../lib/Authentication';
import { useGetAdmin } from '../../../lib/graphql/queries';
import useStrings from '../../../hooks/useStrings';

const PageHeader: React.FC<PageHeaderProps> = ({ pageTitle }) => {
  const [{ PageHeadings: strings }] = useStrings();

  const authID = getUserId();

  const { data: adminData } = useGetAdmin({
    variables: { input: { id: authID } },
    skip: !authID,
  });
  const admin = adminData?.admin.admin || null;

  return (
    <Header>
      <PageName>{pageTitle}</PageName>
      <ProfileWrap>
        <InfoText>{strings.loggedInAs}</InfoText>
        <InfoText>{`${admin?.firstName.charAt(0) || ''} ${admin?.surname || ''}`}</InfoText>
        <ProfileImage src={admin?.profilePhoto || PlaceholderProfile} />
      </ProfileWrap>
    </Header>
  );
};

export interface PageHeaderProps {
  pageTitle: string;
}

export default PageHeader;
