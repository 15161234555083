import styled from 'styled-components/macro';

const InactiveFlag = styled.div`
  background: ${({ theme }) => theme.palette.error};
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.spacing.S}px;
  margin-left: ${({ theme }) => theme.spacing.L}px;
  display: block;
`;

export default InactiveFlag;
