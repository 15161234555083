import styled from 'styled-components/macro';

export const PageShell = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 210px calc(100% - 210px);
  grid-template-rows: 80px calc(100% - 80px);
  grid-template-areas: 'logo header'
                        'sidebar main';
  position: fixed;
  overflow: scroll;
`;

export const PageContent = styled.div`
  background-color: ${({ theme }) => theme.palette.bgPrimary};
  align-items: stretch;
  align-content: stretch;
  display: flex;
  overflow: scroll;
  flex-direction: column;
  width: auto;
  height: 101vh;
  transition: all 0.4s 0.3s ease-in-out;
  grid-area: main;
  padding: 1.5rem 1rem;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent; /* make scrollbar transparent i.e. hide */
  }

  ${({ theme }) => theme.device.laptop} {
    padding-right: 2%;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.palette.white};
  margin-top: 5px;
`;

export const LogoImg = styled.img`
  width: 55%;
  height: auto;
  margin: auto;
`;
