import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

import { resetPassword } from '../../lib/Authentication';
import useStrings from '../../hooks/useStrings';
import { logError } from '../../lib/Analytics';

import {
  Button, Error, Field, Icon, InputWrapper, Label, TextInputField,
} from '../../components';
import { CloseEnvelope } from '../../assets/Icons';
import { Logo } from '../../assets/Images';
import {
  BackgroundImage, Wrapper, SmyleeLogo, Heading, BodyText, Form, Gap,
} from '../Login/Styles';

const ForgotPassword: React.FC = () => {
  const [{ ForgotPassword: strings }] = useStrings();

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setErrors,
  } = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async ({ email }: { email: string }) => {
      setLoading(true);

      try {
        await resetPassword(email);
        history.goBack();
      } catch (err) {
        setLoading(false);
        switch (err.code) {
          case 'auth/user-not-found':
            setErrors({ email: strings.emailDoesNotExistError });
            break;
          case 'auth/invalid-email':
            setErrors({ email: strings.emailInvalidError });
            break;
          default:
            logError(`Error - ${err.code}`);
            break;
        }
      }
    },
  });

  return (
    <BackgroundImage>
      <Wrapper>
        <SmyleeLogo src={Logo} />
        <Heading>{strings.headerText}</Heading>
        <BodyText>{strings.bodyText}</BodyText>
        <Form onSubmit={handleSubmit}>
          <InputWrapper>
            <Label error={!!errors.email} htmlFor="email">{strings.emailLabel}</Label>
            <Field>
              <Icon src={CloseEnvelope} />
              <TextInputField
                id="email"
                name="email"
                type="text"
                value={values.email}
                onChange={handleChange}
                error={!!errors.email}
                hasIcon
              />
            </Field>
            <Error>{errors.email}</Error>
          </InputWrapper>
          <Gap />
          <Button disabled={loading || !values.email} type="submit" variant="modal">
            {loading ? strings.passwordResetText : strings.forgotPasswordText}
          </Button>
          <Gap />
        </Form>
      </Wrapper>
    </BackgroundImage>
  );
};

export default ForgotPassword;
