import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import moment from 'moment';

import {
  ContentWrapper, ContentHeader, Table, PageLayout, Button, EventStatus, Modal,
} from '../../../../components';
import {
  ProfileWidgetWrapper,
  BackIcon,
  CharityLogo,
  CharityDetailContainer,
  PrimaryDetail,
  // TODO: implement
  // SecondaryDetail,
  StatsContainer,
  StatsHeading,
  StatsWrapper,
  StatColumn,
  StatValue,
  StatLabel,
  ButtonWrapper,
  StripeLinkMessage,
  StripeLink,
} from './Styles';
import { BackIcon as BackIconImage } from '../../../../assets/Icons';

import useStrings from '../../../../hooks/useStrings';
import { useGetOrganizationIgnoringDisabled, useGetEventsByOrganization, useEditOrganization } from '../../../../lib/graphql/queries';
import { Event, Guest } from '../../../../lib/types';
import { getUserId } from '../../../../lib/Authentication';
import CharityModal from '../CharityModal';
import {
  GET_ORGANIZATIONS,
  GET_ORGANIZATION,
  useOrganizationStripeLinkLazy,
} from '../../../../lib/graphql/queries/organization';
import {
  displayDonation, displayEventID,
  hasAnyGuestRSVPResponded, getTimeInLocationTimezone,
} from '../../../../lib/Utils';
import { EditOrganizationInput, OrganizationQueryView, OrganizationQueryViewDataFragment } from '../../../../lib/graphql/generated/graphql';
import { StrictInput } from '../../../../lib/types/helpers';

const CharityView: React.FC<CharityViewProps> = () => {
  const [{ CharityProfile: strings }] = useStrings();

  const [editingCharity, setEditingCharity] = useState<OrganizationQueryViewDataFragment | null>(null);

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { state }: {
    state: { previousPath: string; displayHeader?: string };
  } = useLocation();

  const adminID = getUserId();

  const { data: charityData, loading: isCharityLoading } = useGetOrganizationIgnoringDisabled({
    variables: { adminID, organizationID: id },
  });
  const charity = charityData?.organizationIgnoringDisabled.organization;

  const { data: eventData, loading: areEventsLoading } = useGetEventsByOrganization({
    variables: { adminID, organizationID: id },
  });
  const events = eventData?.eventsByOrganizationID || [];

  const displayMiniEventStatus = useMemo(() => (
    guests: Guest[], isCancelledEvent?: boolean, eventStartTime?: number,
  ) => {
    if (isCancelledEvent) {
      return <EventStatus variant="event_cancelled">{strings.eventStatus.eventCancelled}</EventStatus>;
    }
    if (moment(eventStartTime).isAfter(moment())) {
      return <EventStatus variant="waiting">{strings.eventStatus.waiting}</EventStatus>;
    }
    if (!hasAnyGuestRSVPResponded(guests)) {
      return <EventStatus variant="didnt_proceed">{strings.eventStatus.didntProceed}</EventStatus>;
    }
    if (hasAnyGuestRSVPResponded(guests)
      && moment(eventStartTime).isBefore(moment()) && !isCancelledEvent) {
      return <EventStatus variant="proceeded">{strings.eventStatus.proceeded}</EventStatus>;
    }

    return '';
  }, [strings]);

  const columns: Column<Partial<Event>>[] = useMemo(() => [
    {
      Header: strings.eventsTable.eventID,
      id: 'id',
      accessor: (row) => displayEventID(row.id || ''),
    },
    {
      Header: strings.eventsTable.eventTitle,
      id: 'title',
      accessor: (row) => (
        row.title && row.title?.length > 24
          ? `${row.title.slice(0, 24)}...`
          : row.title),
    },
    {
      Header: strings.eventsTable.eventType,
      id: 'type',
      accessor: (row) => row.type,
    },
    {
      Header: strings.eventsTable.location,
      id: 'locationName',
      accessor: (row) => (
        row.location?.name && row.location?.name.length > 24
          ? `${row.location?.name.slice(0, 24)}...`
          : row.location?.name),
    },
    {
      Header: strings.eventsTable.eventDate,
      id: 'startDate',
      accessor: (row) => row.date,
      Cell: ({ row }: Cell<Event>) => (
        <p>
          {getTimeInLocationTimezone(row.original.location.timezoneID || 'Australia/Melbourne', row.original.date).format('DD/MM/YYYY z')}
        </p>
      ),
    },
    {
      Header: strings.eventsTable.endDate,
      id: 'endDate',
      accessor: (row) => row.endDate,
      Cell: ({ row }: Cell<Event>) => (
        <p>
          {getTimeInLocationTimezone(row.original.location.timezoneID || 'Australia/Melbourne', row.original.endDate).format('DD/MM/YYYY z')}
        </p>
      ),
    },
    {
      Header: strings.eventsTable.startTime,
      id: 'startTime',
      accessor: (row) => moment(row.startTime).format('HH:mm'),
      Cell: ({ row }: Cell<Event>) => (
        <p>
          {getTimeInLocationTimezone(row.original.location.timezoneID || 'Australia/Melbourne', row.original.startTime).format('HH:mm z')}
        </p>
      ),
    },
    {
      Header: strings.eventsTable.endTime,
      id: 'endTime',
      accessor: (row) => moment(row.endTime).format('HH:mm'),
      Cell: ({ row }: Cell<Event>) => (
        <p>
          {getTimeInLocationTimezone(row.original.location.timezoneID || 'Australia/Melbourne', row.original.endTime).format('HH:mm z')}
        </p>
      ),
    },
    {
      Header: strings.eventsTable.donation,
      id: 'pledgeAmount',
      accessor: (row) => displayDonation(row.pledgeAmount || 0),
    },
    {
      Header: strings.eventsTable.status,
      id: 'eventStatus',
      accessor: (originalRow) => (
        displayMiniEventStatus(
          (originalRow.guests || []),
          originalRow.isCancelled,
          originalRow.startTime,
        )
      ),
    },
  ], [strings, displayMiniEventStatus]);

  const [editCharity, { loading: editCharityLoading }] = useEditOrganization({
    update(cache, { data: editedCharityData }) {
      const editedCharity = editedCharityData?.editOrganization;
      if (!editedCharity) {
        return;
      }

      const existingCharitiesData = cache.readQuery({
        query: GET_ORGANIZATIONS,
        variables: { adminID },
      });
      const allCharities = existingCharitiesData?.organizationsIgnoringDisabled.organizations || [];

      cache.writeQuery({
        query: GET_ORGANIZATIONS,
        data: {
          organizationsIgnoringDisabled: {
            organizations: allCharities.map((org) => (
              org.id === editedCharity.id
                ? { ...org, ...allCharities }
                : org
            )),
          },
        },
        variables: { adminID },
      });

      cache.writeQuery({
        query: GET_ORGANIZATION,
        variables: { adminID, organizationID: editedCharity.id },
        data: { organizationIgnoringDisabled: { organization: editedCharity } },
      });
    },
  });

  const handleToggleCharityStatus = useCallback(async () => {
    if (!charity) return;

    function getAsset(a: OrganizationQueryView['assetPairOne']) {
      if (!a) {
        return a;
      }
      const { __typename, ...asset } = a;
      return asset;
    }
    const input: StrictInput<EditOrganizationInput> = {
      abn: charity.abn,
      addrLineOne: charity.addrLineOne,
      addrLineTwo: charity.addrLineTwo,
      adminID,
      assetPairOne: getAsset(charity.assetPairOne),
      assetPairTwo: getAsset(charity.assetPairTwo),
      assetPairThree: getAsset(charity.assetPairThree),
      backgroundImage: charity.backgroundImage,
      charityNumber: charity.charityNumber,
      city: charity.city,
      contactName: charity.contactName,
      country: charity.country,
      currency: charity.currency,
      description: charity.description,
      disabled: !charity.disabled,
      eventsLink: charity.eventsLink,
      iconLogo: charity.iconLogo,
      logo: charity.logo,
      name: charity.name,
      officialName: charity.officialName,
      organizationID: charity.id,
      paymentDetailsID: charity.paymentDetailsID,
      phoneNumber: charity.phoneNumber,
      postcode: charity.postcode,
      signatureImage: charity.signatureImage,
      state: charity.state,
      stripeAccountID: charity.stripeAccountID,
      subscribeLink: charity.subscribeLink,
      website: charity.website,
    };

    // TODO: make separate query for toggle only
    await editCharity({
      variables: {
        input,
      },
    });
  }, [charity, adminID, editCharity]);

  const [stripeLoginLink, setStripeLoginLink] = useState<string | null>(null);
  const [stripeLoginLinkError, setStripeLoginLinkError] = useState<string | null>(null);
  const [getStripeLink, { loading: isStripLinkLoading }] = useOrganizationStripeLinkLazy({
    onCompleted: (response) => {
      setStripeLoginLink(response.stripeLoginLink.link);
    },
    onError: (error) => {
      setStripeLoginLinkError(error.message);
    },
  });

  const handleGetStripeLink = useCallback(() => {
    if (!charity) return;
    getStripeLink({ variables: { organizationID: charity.id } });
  }, [charity, getStripeLink]);
  const dismissStripeLinkModal = useCallback(() => {
    setStripeLoginLink(null);
    setStripeLoginLinkError(null);
  }, []);

  return (
    <PageLayout pageTitle={strings.pageHeading} loading={isCharityLoading || areEventsLoading}>
      {editingCharity && (
        <CharityModal
          closeModal={() => setEditingCharity(null)}
          initialData={editingCharity}
          isEdit
        />
      )}
      {stripeLoginLink !== null && (
        <Modal
          closeModal={dismissStripeLinkModal}
          header={strings.stripeLinkTitle}
          rounded={false}
        >
          <StripeLinkMessage>{strings.stripeLinkMessage}</StripeLinkMessage>
          <StripeLink to={{ pathname: stripeLoginLink }} target="_blank">{stripeLoginLink}</StripeLink>
        </Modal>
      )}
      {stripeLoginLinkError !== null && (
        <Modal
          closeModal={dismissStripeLinkModal}
          header={strings.stripeLinkErrorTitle}
          rounded={false}
        >
          <StripeLinkMessage>{stripeLoginLinkError}</StripeLinkMessage>
        </Modal>
      )}
      <ContentWrapper>
        <ProfileWidgetWrapper>
          <BackIcon
            src={BackIconImage}
            onClick={() => history.push(`${state.previousPath || '/admin/charities'}`, {
              displayHeader: state.displayHeader,
            })}
          />
          <CharityLogo src={charity?.iconLogo} />
          <CharityDetailContainer>
            <PrimaryDetail>{charity?.name}</PrimaryDetail>
            {/* TODO: implement */}
            {/* <SecondaryDetail>Children</SecondaryDetail> */}
          </CharityDetailContainer>
          <StatsContainer>
            <StatsHeading>{strings.stats}</StatsHeading>
            <StatsWrapper>
              <StatColumn>
                <StatValue>{charity?.totalEvents ? charity.totalEvents : '-'}</StatValue>
                <StatLabel>{strings.nominated}</StatLabel>
              </StatColumn>
              <StatColumn>
                <StatValue>{charity?.totalDonated ? displayDonation(charity.totalDonated) : '-'}</StatValue>
                <StatLabel>{strings.donations}</StatLabel>
              </StatColumn>
            </StatsWrapper>
          </StatsContainer>
          <ButtonWrapper>
            <Button
              buttonText={charity?.disabled ? strings.activateCharity : strings.deactivateCharity}
              variant="outlined"
              fit="stretch"
              onClick={handleToggleCharityStatus}
              disabled={isCharityLoading || editCharityLoading}
            />
            <Button
              buttonText={strings.editCharity}
              variant="outlined"
              fit="stretch"
              onClick={charity ? (() => setEditingCharity(charity)) : undefined}
              disabled={isCharityLoading}
            />
            <Button
              buttonText={strings.getStripeLink}
              variant="outlined"
              fit="stretch"
              onClick={handleGetStripeLink}
              disabled={isCharityLoading || isStripLinkLoading}
            />
          </ButtonWrapper>
        </ProfileWidgetWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <ContentHeader contentTitle={strings.eventsTable.heading} />
        <Table
          data={events}
          columns={columns}
          paginated
        />
      </ContentWrapper>
    </PageLayout>
  );
};

export interface CharityViewProps { }

export default CharityView;
