import { graphql } from '../generated';
import { createUseLazyQuery, createUseQuery } from './helpers';

export const GUEST_DATA_FRAGMENT = graphql(`
fragment GuestData on Guest {
  id
  user { ...UserData }
  rsvpd
  checkinExemption
  checkedIn
  entryCode
  userID
  mobileNumber
  email
  source
  name
  isCancelled
}
`);

export const LOCATION_DATA_FRAGMENT = graphql(`
fragment LocationData on Location {
  id
  latitude
  longitude
  name
  address
  timezoneID
  suburb
}
`);

export const EVENT_DATA_FRAGMENT = graphql(`
fragment EventData on Event {
  id
  createdDateTime
  title
  eventOrganizer { ...UserData }
  description
  location { ...LocationData }
  type
  date
  startTime
  endDate
  endTime
  rsvpDate
  checkInTime
  cost
  isHostCheckedIn
  organization { ...OrganizationData }
  pledgeAmount
  entryCode
  isCancelled
  guests { ...GuestData }
}
`);

export const GET_EVENTS_BY_ORGANIZATION = graphql(`
  query GetEventsByOrganization($organizationID: ID!, $adminID: ID!) {
    eventsByOrganizationID(organizationID: $organizationID, adminID: $adminID) {
      ...EventData
    }
  }
`);
export const useGetEventsByOrganization = createUseQuery(GET_EVENTS_BY_ORGANIZATION);

export const GET_PAGINATED_EVENTS = graphql(`
  query GetPaginatedEvents($adminID: String!, $pageNo: Int!, $pageSize: Int!, $tableFilter: String, $sortField: String, $sortOrder: SortOrder, $filters: FilterInput) {
    paginatedEvents(adminID: $adminID, pageNo: $pageNo, pageSize: $pageSize, tableFilter: $tableFilter, sortField: $sortField, sortOrder: $sortOrder, filters: $filters) {
      events {
        ...EventData
      }
      totalPages
    }
  }
`);
export const useGetPaginatedEvents = createUseLazyQuery(GET_PAGINATED_EVENTS);

export const GET_BASE_ADMIN_EVENTS = graphql(`
  query EventsForAdminBaseTable($adminID: String!) {
    eventsForAdminBaseTable(adminID: $adminID) {
      events {
        ...EventData
      }
    }
  }
`);
export const useGetEventsForAdminBaseTable = createUseQuery(GET_BASE_ADMIN_EVENTS);

export const GET_EVENTS_BY_USER = graphql(`
  query GetEventsByUser($input: userIDInput!) {
    eventsByUser(input: $input) {
      events { ...EventData }
    }
  }
`);
export const useGetEventsByUser = createUseQuery(GET_EVENTS_BY_USER);
