import React from 'react';

import {
  PageShell, PageContent,
  LogoContainer, LogoImg,
} from './Styles';

import { LoadingScreen } from '..';
import SideNav from './SideNav';
import PageHeader from './PageHeader';
import { Logo } from '../../assets/Images';

const PageLayout: React.FC<PageLayoutProp> = ({ pageTitle, loading, children }) => (
  <>
    {loading ? <LoadingScreen /> : (
      <PageShell>
        <LogoContainer>
          <LogoImg src={Logo} />
        </LogoContainer>
        <PageHeader pageTitle={pageTitle} />
        <SideNav />
        <PageContent>{children}</PageContent>
      </PageShell>
    )}
  </>
);

export interface PageLayoutProp {
  pageTitle: string;
  loading: boolean;
}

export default PageLayout;
