import React from 'react';
import {
  ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip,
} from 'recharts';

const PieChartGraph: React.FC<PieChartGraphProps> = ({ data }) => {
  const colours = ['#FD7B62', '#651236', '#21526C', '#F1AD6E', '#8D8D8D'];

  const legendText = (value: string) => <span style={{ lineHeight: '30px', color: '#3E3F42' }}>{value}</span>;

  return (
    <ResponsiveContainer width="100%" height="85%">
      <PieChart style={{ marginLeft: -20 }}>
        <Pie dataKey="value" data={data} innerRadius="38%" outerRadius="100%" stroke="">
          {data.map((entry, index) => (
            <Cell key={entry.name} fill={colours[index % colours.length]} />
          ))}
        </Pie>
        <Legend verticalAlign="middle" align="right" layout="vertical" iconType="circle" iconSize={6} formatter={legendText} wrapperStyle={{ width: '48%', marginRight: '-20px' }} />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

interface PieChartGraphProps {
  data: { name: string; value: number }[];
}

export default PieChartGraph;
