import React, { useState } from 'react';
import moment from 'moment';

import {
  Modal, InputWrapper, Label, Button, DatePicker,
} from '../../../../components';
import { ModalProps } from '../../../../components/Modal/Modal';
import {
  FormContainer, CharitySelect, SubLabel, DownloadLink,
} from './Styles';

import useStrings from '../../../../hooks/useStrings';
import { useGetOrganizationsIgnoringDisabled, useGetPaymentExports } from '../../../../lib/graphql/queries';
import { getUserId } from '../../../../lib/Authentication';
import { Organization, Payment } from '../../../../lib/types';
import { displayDonation } from '../../../../lib/Utils';
import { PRIORITY_CHARITY_CUT, NON_PRIORITY_CHARITY_CUT } from '../../../../lib/Constants';

interface PaymentWithAmountString extends Payment {
  donationAmount: string;
  cardTransactionFeeAmount: string;
  donationMonth: string;
  donationDate: string;
  donationYear: string;
  serviceFee: string;
  payableToSmylee: string;
}

const ExportCharityModal: React.FC<ExportCharityModalProps> = ({ closeModal }) => {
  const [{ ExportCharity: strings }] = useStrings();

  const [startDate, setStartDate] = useState<Date | Date[]>(new Date());
  const [endDate, setEndDate] = useState<Date | Date[]>(new Date());
  const [charity, setCharity] = useState<Organization['id'] | undefined>(undefined);
  const [exportData, setExportData] = useState<PaymentWithAmountString[]>([]);
  const [noData, setNoData] = useState(false);

  const {
    data: charityData, loading: charitiesLoading, refetch: refreshOrganizationData,
  } = useGetOrganizationsIgnoringDisabled({
    variables: { adminID: getUserId() },
  });
  const allCharities = charityData?.organizationsIgnoringDisabled.organizations || [];

  const [getPaymentExports, { loading: paymentsLoading }] = useGetPaymentExports({
    onCompleted: async (data) => {
      const dataToSet = data.allPayments.payments.map((payment) => ({
        ...payment,
        __typename: undefined,
        donationAmount: displayDonation(payment.amount || 0),
        cardTransactionFeeAmount: displayDonation(payment.cardTransactionFee || 0),
        donationMonth: moment(payment.lastTried).format('MMMM'),
        donationDate: moment(payment.lastTried).format('DD.M.YYYY'),
        donationYear: moment(payment.lastTried).format('YYYY'),
        serviceFee: payment.organizationPaymentDetails.organization.priority ? `${PRIORITY_CHARITY_CUT}%` : `${NON_PRIORITY_CHARITY_CUT}%`,
        payableToSmylee: payment.organizationPaymentDetails.organization.priority
          ? displayDonation((PRIORITY_CHARITY_CUT / 100) * (payment.amount || 0))
          : displayDonation((NON_PRIORITY_CHARITY_CUT / 100) * (payment.amount || 0)),
      }));

      const totalSmyleeCut = data.allPayments.payments.map((payment) => (
        payment.organizationPaymentDetails.organization.priority
          ? ((PRIORITY_CHARITY_CUT / 100) * (payment.amount || 0))
          : ((NON_PRIORITY_CHARITY_CUT / 100) * (payment.amount || 0))
      )).reduce((prev, curr) => (prev + curr), 0);

      const totalDonationAmount = data.allPayments.payments.map((payment) => (
        payment.amount || 0
      )).reduce((prev, curr) => (prev + curr), 0);

      const totals: PaymentWithAmountString = {
        id: '',
        donationDate: '',
        donationMonth: '',
        donationYear: '',
        organizationPaymentDetails: {
          id: '',
          organization: {
            abn: '',
            addrLineOne: '',
            addrLineTwo: '',
            city: '',
            state: '',
            postcode: '',
            country: '',
            contactName: '',
            phoneNumber: '',
            currency: '',
            disabled: false,
            iconLogo: '',
            id: '',
            name: '',
          },
        },
        type: '',
        cardTransactionFeeAmount: '',
        donationAmount: displayDonation(totalDonationAmount),
        payableToSmylee: displayDonation(totalSmyleeCut),
        serviceFee: '',
        amount: 0,
        // TODO: Find out if actually needed.  This is not in the definition for PaymentWithAmountString.
        // userPaymentDetails: {
        //   id: '',
        //   user: {
        //     createdDate: 0,
        //     email: '',
        //     givenName: '',
        //     id: '',
        //     mobileInformation: {
        //       areaCode: '',
        //       mobileNumber: '',
        //     },
        //     reliability: 0,
        //     surname: '',
        //   },
        // },
      };
      if (dataToSet.length !== 0) setExportData([...dataToSet, totals]);
      if (dataToSet.length === 0) setNoData(true);
      await refreshOrganizationData({ adminID: getUserId() });
    },
  });

  const headings = [
    { label: 'Transaction Reference Number (Stripe)', key: 'stripeChargeID' },
    { label: 'Month', key: 'donationMonth' },
    { label: 'Year', key: 'donationYear' },
    { label: 'Donation Date', key: 'donationDate' },
    { label: 'First Name', key: 'userPaymentDetails.user.givenName' },
    { label: 'Last Name', key: 'userPaymentDetails.user.surname' },
    { label: 'Location', key: 'event.location.suburb' },
    { label: 'Email', key: 'userPaymentDetails.user.email' },
    { label: 'Donation Category', key: 'type' },
    { label: 'Donation Amount', key: 'donationAmount' },
    { label: 'Currency', key: 'currency' },
    { label: 'Charity', key: 'organizationPaymentDetails.organization.name' },
    { label: 'Receipt Number', key: 'receiptNo' },
    { label: 'Service Fee', key: 'serviceFee' },
    { label: 'Payable to Smylee', key: 'payableToSmylee' },
  ];

  return (
    <Modal closeModal={closeModal} header={strings.heading}>
      <FormContainer>
        <InputWrapper>
          <Label>{strings.startDate}</Label>
          <DatePicker onChange={setStartDate} value={startDate} />
        </InputWrapper>
        <InputWrapper>
          <Label>{strings.endDate}</Label>
          <DatePicker onChange={setEndDate} value={endDate} />
        </InputWrapper>
        <InputWrapper>
          <Label>{strings.charity}</Label>
          <SubLabel>{strings.subLabelCharity}</SubLabel>
          <CharitySelect onChange={(e) => setCharity(e.target.value)}>
            <option label=" " />
            {allCharities.map((charityOption) => (
              <option
                key={charityOption.id}
                value={charityOption.id}
                label={charityOption.name}
              />
            ))}
          </CharitySelect>
        </InputWrapper>
      </FormContainer>
      {exportData.length === 0 ? (
        <>
          <Button
            buttonText={strings.createReport}
            variant="modal"
            onClick={() => getPaymentExports({
              variables: {
                adminID: getUserId(),
                input: {
                  organizationID: charity,
                  startDate: moment(startDate.toString()).format('DD-MM-YYYY'),
                  endDate: moment(endDate.toString()).format('DD-MM-YYYY'),
                },
              },
            })}
            disabled={charitiesLoading || paymentsLoading}
          />
          {noData && <SubLabel>{strings.noData}</SubLabel>}
        </>
      ) : (
        <DownloadLink
          onClick={closeModal}
          data={exportData}
          headers={headings}
          filename={`${allCharities.find((organi) => organi.id === charity)?.name || 'All-Charities'}_${moment(startDate.toString()).format('DD-MM-YYYY')}_${moment(endDate.toString()).format('DD-MM-YYYY')}`}
        >
          {strings.download}
        </DownloadLink>
      )}
    </Modal>
  );
};

export interface ExportCharityModalProps extends ModalProps { }

export default ExportCharityModal;
