export const SUBSET_SIZE = 5;

export const SENTRY_DSN = 'https://1746567087694b02af99a8db346265f7@o1241451.ingest.sentry.io/4504275144933376';

export const PRIORITY_CHARITY_CUT = 20; // percentage
export const NON_PRIORITY_CHARITY_CUT = 10; // percentage

export const AVAILABLE_CHARITY_COUNTRIES = [
  { currency: 'AUD', countryName: 'Australia' },
  { currency: 'NZD', countryName: 'New Zealand' },
  { currency: 'USD', countryName: 'United States' },
];
