import styled from 'styled-components/macro';
import DatePicker from 'react-date-picker/dist/entry.nostyle';

const StyledDatePicker = styled(DatePicker)`
  .react-date-picker {
    display: inline-flex;
    position: relative;
  }
  .react-date-picker,
  .react-date-picker *,
  .react-date-picker *:before,
  .react-date-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-date-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: ${({ theme }) => theme.borderStyles.primary};
    border-radius: 4px;
    height: ${({ theme }) => theme.spacing.S * 0.85}rem;
    width: 100%;
  }
  .react-date-picker__inputGroup {
    min-width: calc((4px * 3) +  0.54em * 8  +  0.217em * 2);
    flex-grow: 1;
    padding: 0 ${({ theme }) => theme.spacing.XL}px;
    box-sizing: content-box;
    font-size: ${({ theme }) => theme.fontScale.S}em;
  }
  .react-date-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  .react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
  .react-date-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-date-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-date-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px +  0.54em);
  }
  .react-date-picker__button {
    border: 0;
    background: none;
    padding: 4px 6px;
  }
  .react-date-picker__button:enabled {
    cursor: pointer;
  }
  .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
  .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: ${({ theme }) => theme.palette.primary};
    outline: none;
  }
  .react-date-picker__button:disabled .react-date-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-date-picker__button svg {
    display: inherit;
  }
  .react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: ${({ theme }) => theme.palette.white};
    padding: ${({ theme }) => theme.spacing.XXS}rem;
    border-radius: 4px;
    border: ${({ theme }) => theme.borderStyles.primary};
    font-family: ${({ theme }) => theme.fontFamily.body};
  }
  .react-calendar__navigation__label {
    font-family: ${({ theme }) => theme.fontFamily.body};
  }
  .react-calendar__tile {
    background-color: ${({ theme }) => theme.palette.white};
    border: ${({ theme }) => theme.borderStyles.primary};
    border-radius: 4px;
    font-family: ${({ theme }) => theme.fontFamily.body};
    padding: 4px 0;
  }
  .react-calendar__month-view__weekdays {
    padding: 9px 0;
  }
  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    color: ${({ theme }) => theme.palette.title};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }
  .react-date-picker__calendar--closed {
    display: none;
  }
  .react-date-picker__calendar .react-calendar {
    border-width: thin;
  }
`;

export default StyledDatePicker;
