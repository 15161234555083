import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

export interface NavProps {
  active?: boolean;
}

export const SideNavWrapper = styled.div`
  grid-area: sidebar;
  background-color: ${({ theme }) => theme.palette.navBackground};
  color: ${({ theme }) => theme.palette.white};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.XL}% 0;
  height: 100vh;
`;

export const SideNavIcon = styled.img`
  width: 1.2rem;
  height: 1rem;
  object-fit: contain;
  margin: auto 1.2rem auto 1.5rem;
`;

export const SideNavListItem = styled(NavLink) <NavProps>`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.palette.navText};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  height: 2.8rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  text-decoration: none;
  text-align: left;
  justify-content: flex-start;
  align-items: center;

  &:hover, &.active {
    background-color: ${({ theme }) => theme.palette.navBackgroundActive};
    color: ${({ theme }) => theme.palette.navTextActive};
  }
  
  &:hover ${SideNavIcon}, &.active ${SideNavIcon} {
    filter: brightness(150);
  }
`;

export const SideNavLink = styled.span`
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};;
  line-height: 19px;
  font-family: ${({ theme }) => theme.fontFamily.body};
  text-decoration: none;
  text-align: left;
`;
