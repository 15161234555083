import React from 'react';

import {
  Wrapper,
  SectionHeading,
  ListContainer,
  AdminCard,
  ListProfilePicture,
  ListProfilePlaceholder,
  NameContainer,
  Name,
  Email,
} from '../Styles';

import useStrings from '../../../../hooks/useStrings';
import { getUserId } from '../../../../lib/Authentication';
import { Admin } from '../../../../lib/types';
import { useGetAdmins } from '../../../../lib/graphql/queries';

const Admins: React.FC = () => {
  const [{ Settings: strings }] = useStrings();
  const authID = getUserId();

  const { data } = useGetAdmins({ variables: { input: { id: authID } } });
  const admins = data?.allAdmins.admins || [];

  const renderAdminCard = (admin: Admin) => {
    const adminFullName = `${admin.firstName} ${admin.surname}`;
    return (
      <AdminCard>
        {admin?.profilePhoto
          ? <ListProfilePicture src={admin.profilePhoto} /> : <ListProfilePlaceholder />}
        <NameContainer>
          <Name>{adminFullName}</Name>
          <Email>{admin.email}</Email>
        </NameContainer>
      </AdminCard>
    );
  };

  return (
    <Wrapper>
      <SectionHeading>{strings.admins.heading}</SectionHeading>
      <ListContainer>
        {admins.map((admin) => renderAdminCard(admin))}
      </ListContainer>
    </Wrapper>
  );
};

export default Admins;
