import React from 'react';
import {
  Switch, Route, Redirect, useRouteMatch,
} from 'react-router-dom';

import AuthRoute from '../components/AuthRoute';

import Dashboard from '../pages/Admin/Dashboard';
import Users from '../pages/Admin/Users';
import UserView from '../pages/Admin/Users/UserView';
import Charities from '../pages/Admin/Charities';
import CharityView from '../pages/Admin/Charities/CharityView';
import Events from '../pages/Admin/Events';
import Statistics from '../pages/Admin/Statistics';
import Complaints from '../pages/Admin/Complaints';
import Settings from '../pages/Admin/Settings';
import Login from '../pages/Login';

import useAuth from '../hooks/useAuth';

const Admin: React.FC = () => {
  const { path } = useRouteMatch();
  const isLoggedIn = useAuth();

  const basePath = '/admin';

  return (
    <Switch>
      <AuthRoute basePath={basePath} path={`${path}/dashboard`}>
        <Dashboard />
      </AuthRoute>
      <AuthRoute basePath={basePath} path={`${path}/users`}>
        <Users />
      </AuthRoute>
      <AuthRoute basePath={basePath} path={`${path}/user/:id`}>
        <UserView />
      </AuthRoute>
      <AuthRoute basePath={basePath} path={`${path}/charities`}>
        <Charities />
      </AuthRoute>
      <AuthRoute basePath={basePath} path={`${path}/charity/:id`}>
        <CharityView />
      </AuthRoute>
      <AuthRoute basePath={basePath} path={`${path}/events`}>
        <Events />
      </AuthRoute>
      <AuthRoute basePath={basePath} path={`${path}/statistics`}>
        <Statistics />
      </AuthRoute>
      <AuthRoute basePath={basePath} path={`${path}/complaints`}>
        <Complaints />
      </AuthRoute>
      <AuthRoute basePath={basePath} path={`${path}/settings`}>
        <Settings />
      </AuthRoute>
      <Route path="/">
        {isLoggedIn && <Redirect to={`${path}/dashboard`} />}
        <Login />
      </Route>
    </Switch>
  );
};

export default Admin;
