import { graphql } from '../generated';
import { createUseQuery } from './helpers';

export const GET_TOP_EVENT_TYPES = graphql(`
  query GetTopEventTypes($fromDate: Float!) {
    topEventTypes(fromDate: $fromDate) {
      types {
        type
        count
      }
    }
  }
`);
export const useGetTopEventTypes = createUseQuery(GET_TOP_EVENT_TYPES);

export const GET_TOP_DONATION_TYPES = graphql(`
  query GetTopDonationTypes($fromDate: Float!) {
    topDonationTypes(fromDate: $fromDate) {
      donationTypes {
        type
        count
      }
    }
  }
`);
export const useGetTopDonationTypes = createUseQuery(GET_TOP_DONATION_TYPES);

export const GET_TOP_ORG_DONATIONS = graphql(`
  query GetTopCharitiesByDonation($fromDate: Float!) {
    topCharitiesByDonationAmount(fromDate: $fromDate) {
      orgsTotalDonated {
        name
        donated
      }
    }
  }
`);
export const useGetTopCharitiesByDonation = createUseQuery(GET_TOP_ORG_DONATIONS);

export const GET_DONATION_VS_KEEP_PLEDGE_COUNT = graphql(`
  query GetDonateAnywayFrequencies($fromDate: Float!) {
    donateAnywayFrequencies(fromDate: $fromDate) {
      keptPledge
      donatedAnyway
    }
  }
`);
export const useGetDonateAnywayFrequencies = createUseQuery(GET_DONATION_VS_KEEP_PLEDGE_COUNT);

export const TOP_LOCATIONS_BY_STATE = graphql(`
  query GetTopLocationsByState($fromDate: Float!) {
    topLocationsByState(fromDate: $fromDate) {
      locations {
        state
        count
      }
    }
  }
`);
export const useGetTopLocationsByState = createUseQuery(TOP_LOCATIONS_BY_STATE);

export const TOP_LOCATIONS_BY_SURBURB = graphql(`
  query GetTopLocationsBySuburb($fromDate: Float!) {
    topLocationsBySuburb(fromDate: $fromDate) {
      locations {
        suburb
        count
      }
    }
  }
`);
export const useGetTopLocationsBySuburb = createUseQuery(TOP_LOCATIONS_BY_SURBURB);

export const DONATIONS_BY_MONTH = graphql(`
  query GetDonationsByMonth {
    donationsByMonth {
      donationsByMonth {
        Month
        Amount
      }
    }
  }
`);
export const useGetDonationsByMonth = createUseQuery(DONATIONS_BY_MONTH);

export const DONATIONS_BY_AGE = graphql(`
  query GetDonationsByAge($fromDate: Float!) {
    donationsByAge(fromDate: $fromDate ) {
      donationsByAgeBracket {
        AgeBracket
        TotalDonated
      }
    }
  }
`);
export const useGetDonationsByAge = createUseQuery(DONATIONS_BY_AGE);
