import React, { useState } from 'react';

import { SearchBarWrapper, SearchInput, Icon } from './Styles';
import { SearchIcon } from '../../assets/Icons';

import useStrings from '../../hooks/useStrings';

const SearchBar: React.FC<SearchBarProps> = ({ handleSearch, searchQuery }) => {
  const [strings] = useStrings();

  const [searchUserInput, setSearchUserInput] = useState(searchQuery || '');

  return (
    <SearchBarWrapper>
      <Icon src={SearchIcon} onClick={() => handleSearch(searchUserInput)} />
      <SearchInput
        id="userSearch"
        name="userSearch"
        type="text"
        value={searchUserInput}
        placeholder={strings.SearchBar.searchPlaceholder}
        onChange={(e) => setSearchUserInput(e.target.value)}
        onKeyDown={(e) => { if (e.key === 'Enter') handleSearch(searchUserInput); }}
      />
    </SearchBarWrapper>
  );
};

export interface SearchBarProps {
  handleSearch: (searchQuery: string) => void;
  searchQuery?: string;
}

export default SearchBar;
