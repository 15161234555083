import React, { ChangeEvent, useRef, useState } from 'react';

import {
  PersonFieldIcon,
  DollarFieldIcon,
  LocationFieldIcon,
  LinkFieldIcon,
  PhoneFieldIcon,
  Tick,
} from '../../../../assets/Icons';
import {
  Modal,
  InputWrapper,
  Label,
  Field,
  Icon,
  TextInputField,
  Button,
} from '../../../../components';
import {
  AddLogoButton,
  LogoImageContainer,
  LogoWrapper,
  Heading,
  Subtext,
  Subheading,
  InputRow,
  CityInputWrapper,
  StateInputWrapper,
  PostcodeInputWrapper,
  CheckBoxWrapper,
  PromotedTicked,
  PromotedUnticked,
  PromotedLabel,
  HiddenFileInput,
  EditPriorityDetails,
  CheckBoxRow,
  CountrySelect,
  CountryOption,
  SignatureInputWrapper,
  SignatureImage,
} from './Styles';
import PriorityCharityModal from '../PriorityCharityModal';

import { uploadMedia } from '../../../../lib/Media';
import useStrings from '../../../../hooks/useStrings';
import { ModalProps } from '../../../../components/Modal/Modal';
import { useCreateOrganization, useEditOrganization } from '../../../../lib/graphql/queries';
import {
  GET_ORGANIZATIONS,
  GET_ORGANIZATION,
} from '../../../../lib/graphql/queries/organization';
import { getUserId } from '../../../../lib/Authentication';
import { logError } from '../../../../lib/Analytics';
import { AVAILABLE_CHARITY_COUNTRIES } from '../../../../lib/Constants';
import {
  EditOrganizationInput, GetOrganizationsIgnoringDisabledQuery, NewOrganizationInput, OrganizationQueryViewDataFragment,
} from '../../../../lib/graphql/generated/graphql';
import { StrictInput } from '../../../../lib/types/helpers';

export type PromotedCharityDetails = {
  horizontalLogo?: string | null;
  description?: string | null;
  bgImage?: string | null;
  cause1?: {
    cause1Name?: string;
    cause1Link?: string;
    cause1Image?: string;
  };
  cause2?: {
    cause2Name?: string;
    cause2Link?: string;
    cause2Image?: string;
  };
  cause3?: {
    cause3Name?: string;
    cause3Link?: string;
    cause3Image?: string;
  };
};

const CharityModal: React.FC<CharityModalProps> = ({
  closeModal,
  isEdit = false,
  initialData,
}) => {
  const [{ CharityModal: strings }] = useStrings();
  const adminID = getUserId();

  const initialPriorityDetails: PromotedCharityDetails = {
    horizontalLogo: initialData?.logo,
    description: initialData?.description,
    bgImage: initialData?.backgroundImage,
    cause1: {
      cause1Name: initialData?.assetPairOne?.title,
      cause1Link: initialData?.assetPairOne?.website,
      cause1Image: initialData?.assetPairOne?.image,
    },
    cause2: {
      cause2Name: initialData?.assetPairTwo?.title,
      cause2Link: initialData?.assetPairTwo?.website,
      cause2Image: initialData?.assetPairTwo?.image,
    },
    cause3: {
      cause3Name: initialData?.assetPairThree?.title,
      cause3Link: initialData?.assetPairThree?.website,
      cause3Image: initialData?.assetPairThree?.image,
    },
  };

  const [iconLogo, setIconLogo] = useState(initialData?.iconLogo || '');
  const [charityName, setCharityName] = useState(initialData?.name || '');
  const [charityOfficialName, setCharityOfficialName] = useState(initialData?.officialName || '');
  const [abn, setAbn] = useState(initialData?.abn || '');
  const [stripeID, setStripeID] = useState(initialData?.stripeAccountID || '');
  const [addr1, setAddr1] = useState(initialData?.addrLineOne || '');
  const [addr2, setAddr2] = useState(initialData?.addrLineTwo || '');
  const [city, setCity] = useState(initialData?.city || '');
  const [state, setState] = useState(initialData?.state || '');
  const [postcode, setPostcode] = useState(initialData?.postcode || '');
  const [country, setCountry] = useState(
    initialData?.country || AVAILABLE_CHARITY_COUNTRIES[0].countryName,
  );
  const [signatureImage, setSignatureImage] = useState(initialData?.signatureImage || '');
  const [contactName, setContactName] = useState(initialData?.contactName || '');
  const [phoneNumber, setPhoneNumber] = useState(initialData?.phoneNumber || '');
  const [website, setWebsite] = useState(initialData?.website || '');
  const [subscribeLink, setSubscribeLink] = useState(initialData?.subscribeLink || '');
  const [charityNumber, setCharityNumber] = useState(initialData?.charityNumber || '');
  const [promotedDetails, setPromotedDetails] = useState<PromotedCharityDetails | null>(
    isEdit ? initialPriorityDetails : null,
  );

  const [isPriorityCharity, setIsPriorityCharity] = useState(
    !!initialData?.priority && !!promotedDetails,
  );
  const [showPromoModal, setShowPromoModal] = useState(false);

  const hiddenFileInputRefIconLogo = useRef<HTMLInputElement>(null);
  const hiddenSignatureInputRef = useRef<HTMLInputElement>(null);

  const [createCharity, { loading: createCharityLoading }] = useCreateOrganization({
    onCompleted: closeModal,
    update(cache, { data: newCharityData }) {
      if (!newCharityData) return;
      const { organization } = newCharityData.newOrganization;

      const existingCharityData = cache.readQuery({
        query: GET_ORGANIZATIONS,
        variables: { adminID },
      });
      const allCharities = existingCharityData?.organizationsIgnoringDisabled.organizations || [];

      cache.writeQuery({
        query: GET_ORGANIZATIONS,
        data: {
          organizationsIgnoringDisabled: {
            organizations: [...allCharities, organization],
          },
        } as GetOrganizationsIgnoringDisabledQuery, // TODO: Fix type issues
        variables: { adminID },
      });
    },
  });

  const [editCharity, { loading: editCharityLoading }] = useEditOrganization({
    onCompleted: closeModal,
    update(cache, { data: editedCharityData }) {
      const editedCharity = editedCharityData?.editOrganization;
      if (!editedCharity) {
        return;
      }

      const existingCharitiesData = cache.readQuery({
        query: GET_ORGANIZATIONS,
        variables: { adminID },
      });
      const allCharities = existingCharitiesData?.organizationsIgnoringDisabled.organizations || [];

      cache.writeQuery({
        query: GET_ORGANIZATIONS,
        data: {
          organizationsIgnoringDisabled: {
            organizations: allCharities.map((charity) => (
              charity.id === editedCharity.id
                ? { ...charity, ...allCharities }
                : charity
            )),
          },
        },
        variables: { adminID },
      });

      cache.writeQuery({
        query: GET_ORGANIZATION,
        variables: { adminID, organizationID: editedCharity.id },
        data: { organizationIgnoringDisabled: { organization: editedCharity } },
      });
    },
  });

  const handleSubmitCharity = async () => {
    try {
      const charityInput = {
        name: charityName,
        officialName: charityOfficialName,
        abn,
        addrLineOne: addr1,
        addrLineTwo: addr2,
        iconLogo,
        website,
        city,
        state,
        postcode,
        country,
        contactName,
        phoneNumber,
        adminID,
        signatureImage,
        subscribeLink,
        charityNumber,
        currency: AVAILABLE_CHARITY_COUNTRIES.find((availCountry) => availCountry.countryName === country)?.currency || '',

        // Ignored by server when omitted
        assetPairOne: undefined,
        assetPairTwo: undefined,
        assetPairThree: undefined,
        backgroundImage: undefined,
        description: undefined,
        logo: undefined,
        eventsLink: undefined,
        paymentDetailsID: undefined,
        stripeAccountID: undefined,
      };

      const priorityCharityInput = {
        ...charityInput,
        assetPairOne: {
          image: promotedDetails?.cause1?.cause1Image || '',
          title: promotedDetails?.cause1?.cause1Name || '',
          website: promotedDetails?.cause1?.cause1Link || '',
        },
        assetPairTwo: {
          image: promotedDetails?.cause2?.cause2Image || '',
          title: promotedDetails?.cause2?.cause2Name || '',
          website: promotedDetails?.cause2?.cause2Link || '',
        },
        assetPairThree: {
          image: promotedDetails?.cause3?.cause3Image || '',
          title: promotedDetails?.cause3?.cause3Name || '',
          website: promotedDetails?.cause3?.cause3Link || '',
        },
        backgroundImage: promotedDetails?.bgImage,
        logo: promotedDetails?.horizontalLogo,
        description: promotedDetails?.description,
      };

      // EDITING
      if (isEdit && initialData?.id) {
        if (isPriorityCharity) {
          const editPriorityCharityInput: StrictInput<EditOrganizationInput> = {
            ...priorityCharityInput,
            organizationID: initialData.id,
            disabled: !!initialData?.disabled,
          };
          await editCharity({ variables: { input: editPriorityCharityInput } });
        } else {
          const editCharityInput: StrictInput<EditOrganizationInput> = {
            ...charityInput,
            organizationID: initialData.id,
            disabled: !!initialData?.disabled,
          };
          await editCharity({ variables: { input: editCharityInput } });
        }
        return;
      }

      // CREATING
      if (isPriorityCharity) {
        const input: StrictInput<NewOrganizationInput> = {
          ...priorityCharityInput,
          stripeAccountID: stripeID,
        };
        await createCharity({ variables: { input } });
      } else {
        const input: StrictInput<NewOrganizationInput> = {
          ...charityInput,
          stripeAccountID: stripeID,
        };
        await createCharity({ variables: { input } });
      }
    } catch (err) {
      logError(err);
    }
  };

  const handleClick = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref && ref.current) {
      ref.current.click();
    }
  };

  const handleChangeImage = async (event: ChangeEvent<HTMLInputElement>, imageType: 'icon' | 'signature') => {
    if (event?.target?.files && event?.target?.files?.length > 0) {
      const imageFile = event.target.files[0];
      const imageURL = await uploadMedia(imageFile);

      if (imageURL && imageType === 'icon') setIconLogo(imageURL);
      if (imageURL && imageType === 'signature') setSignatureImage(imageURL);
    }
  };

  const createButtonDisabled = !iconLogo || !charityName || !abn || !addr1 || !city
    || !state || !postcode || !country || !contactName || !website || !stripeID
    || (isPriorityCharity && !promotedDetails);

  return (
    <Modal closeModal={closeModal} rounded>
      {showPromoModal && (
        <PriorityCharityModal
          isEdit={isEdit}
          hideModal={() => setShowPromoModal(false)}
          changeIsPromoted={() => setIsPriorityCharity(false)}
          promotedDetails={promotedDetails}
          changePromotedDetails={(details) => setPromotedDetails(details)}
        />
      )}
      <LogoWrapper>
        <LogoImageContainer src={iconLogo || undefined} />
        <HiddenFileInput
          ref={hiddenFileInputRefIconLogo}
          multiple={false}
          accept="image/*"
          type="file"
          onChange={(e) => handleChangeImage(e, 'icon')}
        />
        <AddLogoButton onClick={() => handleClick(hiddenFileInputRefIconLogo)}>
          {strings.addLogo}
        </AddLogoButton>
      </LogoWrapper>

      <Heading>{isEdit ? strings.headingEdit : strings.heading}</Heading>
      <Subtext>{strings.subheading}</Subtext>

      <Subheading>{strings.businessDetails}</Subheading>

      <InputWrapper>
        <Label>{strings.nameLabel}</Label>
        <Field>
          <Icon src={PersonFieldIcon} />
          <TextInputField
            id="name"
            name="name"
            type="text"
            value={charityName}
            onChange={(e) => setCharityName(e.target.value)}
            hasIcon
          />
        </Field>
      </InputWrapper>

      <InputWrapper>
        <Label>{strings.officialNameLabel}</Label>
        <Field>
          <Icon src={PersonFieldIcon} />
          <TextInputField
            id="officialName"
            name="officialName"
            type="text"
            value={charityOfficialName}
            onChange={(e) => setCharityOfficialName(e.target.value)}
            hasIcon
          />
        </Field>
      </InputWrapper>

      <InputWrapper>
        <Label>{strings.abn}</Label>
        <Field>
          <Icon src={DollarFieldIcon} />
          <TextInputField
            id="abn"
            name="abn"
            type="text"
            value={abn}
            onChange={(e) => setAbn(e.target.value)}
            hasIcon
          />
        </Field>
      </InputWrapper>

      <InputWrapper>
        <Label>{strings.stripeIDLabel}</Label>
        <Field>
          <TextInputField
            id="stripeID"
            name="stripeID"
            type="text"
            value={stripeID}
            onChange={(e) => setStripeID(e.target.value)}
            disabled={isEdit}
          />
        </Field>
      </InputWrapper>

      <InputWrapper>
        <Label>{strings.charityNumber}</Label>
        <Field>
          <TextInputField
            id="charityNumber"
            name="charityNumber"
            type="text"
            value={charityNumber}
            onChange={(e) => setCharityNumber(e.target.value)}
          />
        </Field>
      </InputWrapper>

      <InputWrapper>
        <Label>{strings.addr1}</Label>
        <Field>
          <Icon src={LocationFieldIcon} />
          <TextInputField
            id="addrLineOne"
            name="addrLineOne"
            type="text"
            value={addr1}
            onChange={(e) => setAddr1(e.target.value)}
            hasIcon
          />
        </Field>
      </InputWrapper>

      <InputWrapper>
        <Label>{strings.addr2}</Label>
        <Field>
          <Icon src={LocationFieldIcon} />
          <TextInputField
            id="addrLineTwo"
            name="addrLineTwo"
            type="text"
            value={addr2}
            onChange={(e) => setAddr2(e.target.value)}
            hasIcon
          />
        </Field>
      </InputWrapper>

      <InputRow>
        <CityInputWrapper>
          <Label>{strings.city}</Label>
          <Field>
            <TextInputField
              id="city"
              name="city"
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Field>
        </CityInputWrapper>

        <StateInputWrapper>
          <Label>{strings.state}</Label>
          <Field>
            <TextInputField
              id="state"
              name="state"
              type="text"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </Field>
        </StateInputWrapper>

        <PostcodeInputWrapper>
          <Label>{strings.postcode}</Label>
          <Field>
            <TextInputField
              id="postcode"
              name="postcode"
              type="text"
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
            />
          </Field>
        </PostcodeInputWrapper>
      </InputRow>

      <InputWrapper>
        <Label>{strings.country}</Label>
        <Field>
          <CountrySelect onChange={(e) => setCountry(e.target.value)} id="country" name="country" value={country}>
            {AVAILABLE_CHARITY_COUNTRIES.map((availCountry) => (
              <CountryOption value={availCountry.countryName} label={availCountry.countryName} />
            ))}
          </CountrySelect>
        </Field>
      </InputWrapper>

      <SignatureInputWrapper>
        <SignatureImage src={signatureImage || undefined} hasImage={!!signatureImage} />
        <HiddenFileInput
          ref={hiddenSignatureInputRef}
          multiple={false}
          accept="image/*"
          type="file"
          onChange={(e) => handleChangeImage(e, 'signature')}
        />
        <AddLogoButton onClick={() => handleClick(hiddenSignatureInputRef)}>
          {strings.signature}
        </AddLogoButton>
      </SignatureInputWrapper>

      <Subheading>{strings.charityContact}</Subheading>

      <InputWrapper>
        <Label>{strings.contactName}</Label>
        <Field>
          <Icon src={PersonFieldIcon} />
          <TextInputField
            id="contactName"
            name="contactName"
            type="text"
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
            hasIcon
          />
        </Field>
      </InputWrapper>

      <InputWrapper>
        <Label>{strings.phoneNumber}</Label>
        <Field>
          <Icon src={PhoneFieldIcon} />
          <TextInputField
            id="phoneNumber"
            name="phoneNumber"
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            hasIcon
          />
        </Field>
      </InputWrapper>

      <InputWrapper>
        <Label>{strings.website}</Label>
        <Field>
          <Icon src={LinkFieldIcon} />
          <TextInputField
            id="website"
            name="website"
            type="text"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            hasIcon
          />
        </Field>
      </InputWrapper>

      <InputWrapper>
        <Label>{strings.subscribeLink}</Label>
        <Field>
          <Icon src={LinkFieldIcon} />
          <TextInputField
            id="subscribeLink"
            name="subscribeLink"
            type="text"
            value={subscribeLink}
            onChange={(e) => setSubscribeLink(e.target.value)}
            hasIcon
          />
        </Field>
      </InputWrapper>

      <CheckBoxRow>
        <CheckBoxWrapper>
          {isPriorityCharity
            ? (
              <PromotedTicked
                onClick={() => {
                  setIsPriorityCharity(false);
                  setShowPromoModal(false);
                }}
                src={Tick}
              />
            ) : (
              <PromotedUnticked onClick={() => {
                setShowPromoModal(true);
                setIsPriorityCharity(true);
              }}
              />
            )}
          <PromotedLabel>{strings.promoted}</PromotedLabel>
        </CheckBoxWrapper>
        {(isPriorityCharity && (isEdit || !!promotedDetails)) && (
        <EditPriorityDetails
          onClick={() => {
            setShowPromoModal(true);
            setIsPriorityCharity(true);
          }}
        >
          {strings.editPromoted}
        </EditPriorityDetails>
        )}
      </CheckBoxRow>

      <Button
        buttonText={isEdit ? strings.saveButtonText : strings.buttonText}
        disabled={createCharityLoading || editCharityLoading || createButtonDisabled}
        type="submit"
        variant="modal"
        width="110%"
        onClick={handleSubmitCharity}
      />
    </Modal>
  );
};

export interface CharityModalProps extends ModalProps {
  initialData?: Partial<OrganizationQueryViewDataFragment>;
  isEdit?: boolean;
}

export default CharityModal;
