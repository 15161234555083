import React, { useState, useMemo } from 'react';
import moment from 'moment';

import { PageLayout } from '../../../components';
import ComplaintView from './ComplaintView';
import {
  ComplaintsHeading,
  ComplaintList,
  ListHeadingContainer,
  ListHeading,
  PageContent,
  ComplaintViewContainer,
  ComplaintListContainer,
  ListItem,
  Date,
  Details,
  Name,
  Reason,
  DetailsContainer,
  SeeAllButton,
  DateMonth,
  DateNumber,
} from './Styles';

import useStrings from '../../../hooks/useStrings';
import { useGetAllReportedContent } from '../../../lib/graphql/queries';
import { ReportedContent } from '../../../lib/types';
import { ReportedReason } from '../../../lib/graphql/generated/graphql';

function compareComplaints(a: ReportedContent, b: ReportedContent): number {
  return moment(b.dateCreated).diff(moment(a.dateCreated));
}

export const displayReportReason = (
  reason: ReportedReason | null | undefined,
  strings: Record<string, string>,
): string => {
  switch (reason) {
    case ReportedReason.Abusive:
      return strings.abusive;
    case ReportedReason.Offensive:
      return strings.offensive;
    case ReportedReason.Privacy:
      return strings.privacy;
    case ReportedReason.Threatening:
      return strings.threatening;
    case ReportedReason.Trolling:
      return strings.trolling;
    case ReportedReason.Discrimination:
      return strings.discrimination;
    case ReportedReason.BehaviourAgainstTheLaw:
      return strings.behaviour;
    case ReportedReason.ViolatedTos:
      return strings.violatedTOS;
    case ReportedReason.InappropriateLanguageOrImagery:
      return strings.inappropriate;
    default:
      return '';
  }
};

const Complaints: React.FC = () => {
  const [{ Complaints: strings }] = useStrings();
  const { data: complaintData, loading } = useGetAllReportedContent();

  const [seeAllUnread, setSeeAllUnread] = useState(false);
  const [seeAllResolved, setSeeAllResolved] = useState(false);
  const [complaintViewing, setComplaintViewing] = useState<ReportedContent | null>(null);

  const allReportedContent = useMemo(() => (
    complaintData?.allReportedContent.reportedContents || []
  ), [complaintData]);

  const unread = useMemo(() => (allReportedContent
    .filter(({ isResolved }) => isResolved === false)
    .sort(compareComplaints)
  ), [allReportedContent]);

  const resolved = useMemo(() => (allReportedContent
    .filter(({ isResolved }) => isResolved === true)
    .sort(compareComplaints)
  ), [allReportedContent]);

  const unreadListCount = seeAllUnread ? undefined : 3;
  const resolvedListCount = seeAllResolved ? undefined : 3;

  const renderComplaint = (complaint: ReportedContent, i: number) => {
    const reported = `${complaint.reported.givenName} ${complaint.reported.surname}`;
    const reportedBy = `${strings.reportedBy} ${complaint.reporter.givenName} ${complaint.reporter.surname}`;
    const reason = complaint.reportedReason;
    const dateMonth = moment(complaint.dateCreated).format('MMM');
    const dateDay = moment(complaint.dateCreated).format('DD');

    return (
      <ListItem
        role="button"
        onClick={() => setComplaintViewing(complaint)}
        onKeyDown={() => setComplaintViewing(complaint)}
        key={complaint.id}
        tabIndex={i}
        selected={complaintViewing?.id === complaint.id}
      >
        <DetailsContainer>
          <Date>
            <DateMonth>{dateMonth}</DateMonth>
            <DateNumber>{dateDay}</DateNumber>
          </Date>
          <div>
            <Name>{reported}</Name>
            <Details>{reportedBy}</Details>
          </div>
        </DetailsContainer>
        <Reason>{displayReportReason(reason, strings.reasons)}</Reason>
      </ListItem>
    );
  };

  return (
    <PageLayout pageTitle={strings.pageTitle} loading={loading}>
      <ComplaintsHeading>{strings.pageTitle}</ComplaintsHeading>
      <PageContent>
        <ComplaintListContainer>
          <ComplaintList>
            <ListHeadingContainer>
              <ListHeading>{strings.unread}</ListHeading>
              {!seeAllUnread
                ? (
                  <SeeAllButton type="button" onClick={() => setSeeAllUnread(true)}>
                    {strings.seeAll}
                    {' '}
                    &rarr;
                  </SeeAllButton>
                ) : (
                  <SeeAllButton type="button" onClick={() => setSeeAllUnread(false)}>
                    {strings.hide}
                    {' '}
                    &larr;
                  </SeeAllButton>
                )}
            </ListHeadingContainer>
            {unread?.map((complaint, i) => renderComplaint(complaint, i)).slice(0, unreadListCount)}
          </ComplaintList>
          <ComplaintList>
            <ListHeadingContainer>
              <ListHeading>{strings.resolved}</ListHeading>
              {!seeAllResolved
                ? (
                  <SeeAllButton type="button" onClick={() => setSeeAllResolved(true)}>
                    {strings.seeAll}
                    {' '}
                    &rarr;
                  </SeeAllButton>
                ) : (
                  <SeeAllButton type="button" onClick={() => setSeeAllResolved(false)}>
                    {strings.hide}
                    {' '}
                    &larr;
                  </SeeAllButton>
                )}
            </ListHeadingContainer>
            {resolved?.map((complaint, i) => renderComplaint(complaint, i))
              .slice(0, resolvedListCount)}
          </ComplaintList>
        </ComplaintListContainer>
        <ComplaintViewContainer>
          {complaintViewing && (
            <ComplaintView
              complaint={complaintViewing}
              onMarkedResolved={() => setComplaintViewing(
                allReportedContent.find((complaint) => (
                  complaint.id === complaintViewing?.id
                )) || null,
              )}
            />
          )}
        </ComplaintViewContainer>
      </PageContent>
    </PageLayout>
  );
};

export default Complaints;
