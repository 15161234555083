import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { Column } from 'react-table';
import moment from 'moment';

import {
  PageLayout, ContentWrapper, ContentHeader, ViewAllLink, Table, Button, EventStatus,
  ScrollableTable,
} from '../../../components';
import EventsFilterModal from './EventsFilterModal';

import { sortByType, TableProps } from '../../../components/Table/Table';
import useStrings from '../../../hooks/useStrings';
import { Event, Guest } from '../../../lib/types';
import {
  displayEventID, displayDonation, hasAnyGuestRSVPResponded,
} from '../../../lib/Utils';
import { useGetPaginatedEvents, useGetEventsForAdminBaseTable } from '../../../lib/graphql/queries';
import { getUserId } from '../../../lib/Authentication';
import { SUBSET_SIZE } from '../../../lib/Constants';
import { SortOrder } from '../../../lib/graphql/generated/graphql';
import { ContentHeaderProps } from '../../../components/ContentHeader/ContentHeader';
import { DownloadLink } from '../Users/Styles';
import {
  getCountryStr,
  getEventTime,
  getGuestFirstName,
  getGuestSurname,
  getGuestEmail,
  getGuestRsvpStatus,
  formatEventTime,
} from './EventUtils';

type EventsTableProps = TableProps & ContentHeaderProps;

function EventsTable(props: EventsTableProps) {
  const { children } = props;
  return (
    <ContentWrapper>
      <ContentHeader {...props}>
        {children}
      </ContentHeader>
      <ScrollableTable>
        <Table {...props} />
      </ScrollableTable>
    </ContentWrapper>
  );
}

interface Filters {
  dateFrom?: number;
  dateTo?: number;
  lowerDonation?: number;
  upperDonation?: number;
}

interface GuestsByEventCSVExportProps {
  tableFilter: string;
  filters: Filters | undefined;
}

function GuestsByEventCSVExport(props: GuestsByEventCSVExportProps) {
  const [{ Events: strings }] = useStrings();

  const [getPaginatedEvents, { data: paginatedEventsData }] = useGetPaginatedEvents();

  const { tableFilter, filters } = props;

  // Get the full list of data to be exported.
  useEffect(() => {
    getPaginatedEvents({
      variables: {
        adminID: getUserId(),
        tableFilter,
        pageNo: 0,
        pageSize: 0,
        filters,
      },
    });
  }, [tableFilter, filters, getPaginatedEvents]);

  // Transform the event data into the desired CSV Format.
  // IE: One row per guest (event information may be duplicated).
  const headers = useMemo(() => [
    strings.tableHeadings.eventId,
    strings.tableHeadings.host.givenName,
    strings.tableHeadings.host.surname,
    strings.tableHeadings.host.email,
    strings.tableHeadings.host.location,
    strings.tableHeadings.host.dateCreated,
    strings.tableHeadings.eventType,
    strings.tableHeadings.eventDateCreated,
    strings.tableHeadings.eventStartDate,
    strings.tableHeadings.eventEndDate,
    strings.tableHeadings.location,
    strings.tableHeadings.totalGuestsInvited,
    strings.tableHeadings.guest.givenName,
    strings.tableHeadings.guest.surname,
    strings.tableHeadings.guest.email,
    strings.tableHeadings.guest.location,
    strings.tableHeadings.guest.rsvpStatus,
    strings.tableHeadings.guest.inviteType,
    strings.tableHeadings.charity,
    strings.tableHeadings.pledge,
    strings.tableHeadings.checkIn,
    strings.tableHeadings.fullEventId,
  ], [strings]);

  const data = useMemo(() => {
    const allData: string[][] = [];

    const events = paginatedEventsData?.paginatedEvents.events || [];
    events.forEach((event) => {
      const displayEventId = displayEventID(event.id);
      const hostCountry = getCountryStr(event.eventOrganizer);
      const hostProfileCreatedDate = event.eventOrganizer.createdDate != null ? moment(event.eventOrganizer.createdDate).format('DD/MM/YY') : '';
      const eventCreated = formatEventTime(event.createdDateTime, event.location.timezoneID);
      const eventStartDate = formatEventTime(event.date, event.location.timezoneID);
      const eventEndDate = formatEventTime(event.endDate, event.location.timezoneID);
      const numGuests = (event.guests?.length || 0).toString();
      const eventPledgeAmount = displayDonation(event.pledgeAmount || 0);

      // For each event, append each guest as a separate row.
      if (event.guests != null && event.guests.length > 0) {
        event.guests.forEach((guest) => {
          const rowData = [
            displayEventId,
            event.eventOrganizer.givenName,
            event.eventOrganizer.surname,
            event.eventOrganizer.email,
            hostCountry,
            hostProfileCreatedDate,
            event.type || '',
            eventCreated,
            eventStartDate,
            eventEndDate,
            event.location?.name || '',
            numGuests,
            getGuestFirstName(guest),
            getGuestSurname(guest),
            getGuestEmail(guest),
            getCountryStr(guest.user),
            getGuestRsvpStatus(guest),
            guest.source || '',
            event.organization.name,
            eventPledgeAmount,
            guest.checkedIn.toString(),
            event.id,
          ];
          allData.push(rowData);
        });
      } else {
        // This event has no guests - log the event details but leave guest information empty
        const rowData = [
          displayEventId,
          event.eventOrganizer.givenName,
          event.eventOrganizer.surname,
          event.eventOrganizer.email,
          hostCountry,
          hostProfileCreatedDate,
          event.type || '',
          eventCreated,
          eventStartDate,
          eventEndDate,
          event.location?.name || '',
          numGuests,
          '',
          '',
          '',
          '',
          '',
          '',
          event.organization.name,
          eventPledgeAmount,
          '',
          event.id,
        ];
        allData.push(rowData);
      }
    });

    return allData;
  }, [paginatedEventsData?.paginatedEvents.events]);

  return (
    <DownloadLink data={data} headers={headers} filename="EventGuestData.csv">{strings.export}</DownloadLink>
  );
}

const Events: React.FC = () => {
  const [{ Events: strings }] = useStrings();

  const [viewingAll, setViewingAll] = useState(false);
  const [displayHeader, setDisplayHeader] = useState('');
  const [tableFilter, setTableFilter] = useState('');

  const [showEventsFilterModal, setShowEventsFilterModal] = useState(false);
  const [filters, setFilters] = useState<Filters | undefined>(undefined);

  const [maxPage, setMaxPage] = useState(1);

  const { data: baseTableData } = useGetEventsForAdminBaseTable({
    variables: { adminID: getUserId() },
  });
  const baseEvents = baseTableData?.eventsForAdminBaseTable.events || [];

  const [getPaginatedEvents, { data: paginatedEventsData }] = useGetPaginatedEvents();
  const events = paginatedEventsData?.paginatedEvents.events || [];

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy }: {
      pageIndex: number; pageSize: number; sortBy: sortByType;
    }) => {
      getPaginatedEvents({
        variables: {
          adminID: getUserId(),
          tableFilter,
          pageNo: pageIndex,
          pageSize,
          sortField: (sortBy.length > 0 ? sortBy[0].id : undefined),
          // eslint-disable-next-line no-nested-ternary
          sortOrder: (sortBy.length > 0 ? (sortBy[0].desc ? SortOrder.Desc : SortOrder.Asc) : undefined),
          filters,
        },
      });
    },
    [getPaginatedEvents, filters, tableFilter],
  );

  useEffect(() => {
    if (paginatedEventsData) setMaxPage(paginatedEventsData.paginatedEvents.totalPages);
  }, [paginatedEventsData]);

  const onViewAll = (set: string) => {
    switch (set) {
      case 'all':
        setDisplayHeader(strings.allEvents);
        setTableFilter('all');
        break;
      case 'upcoming':
        setDisplayHeader(strings.upcomingEvents);
        setTableFilter('upcoming');
        break;
      case 'previous':
        setDisplayHeader(strings.previousEvents);
        setTableFilter('previous');
        break;
      default:
        break;
    }
    setViewingAll(true);
  };

  const displayMiniEventStatus = useMemo(() => (
    guests: Guest[], isCancelledEvent?: boolean, eventStartTime?: number,
  ) => {
    if (isCancelledEvent) {
      return <EventStatus variant="event_cancelled">{strings.eventStatus.eventCancelled}</EventStatus>;
    }
    if (moment(eventStartTime).isAfter(moment())) {
      return <EventStatus variant="waiting">{strings.eventStatus.waiting}</EventStatus>;
    }
    if (!hasAnyGuestRSVPResponded(guests)) {
      return <EventStatus variant="didnt_proceed">{strings.eventStatus.didntProceed}</EventStatus>;
    }
    if (hasAnyGuestRSVPResponded(guests)
      && moment(eventStartTime).isBefore(moment()) && !isCancelledEvent) {
      return <EventStatus variant="proceeded">{strings.eventStatus.proceeded}</EventStatus>;
    }

    return '';
  }, [strings]);

  const columns: Column<Partial<Event>>[] = [
    {
      Header: strings.tableHeadings.eventId,
      id: 'event.id',
      accessor: (originalRow) => displayEventID(originalRow.id || ''),
    },
    {
      Header: strings.tableHeadings.created,
      id: 'event.createdDateTime',
      accessor: (originalRow) => getEventTime(originalRow.createdDateTime, originalRow.location?.timezoneID)?.format('Do MMMM YYYY') || '',
    },
    {
      Header: strings.tableHeadings.country,
      id: 'eventOrganizer.mobileInformation',
      accessor: (originalRow) => getCountryStr(originalRow.eventOrganizer),
    },
    {
      Header: strings.tableHeadings.user.givenName,
      id: 'eventOrganizer.givenName',
      accessor: (originalRow) => originalRow.eventOrganizer?.givenName,
    },
    {
      Header: strings.tableHeadings.user.surname,
      id: 'eventOrganizer.surname',
      accessor: (originalRow) => originalRow.eventOrganizer?.surname,
    },
    {
      Header: strings.tableHeadings.user.email,
      id: 'eventOrganizer.email',
      accessor: (originalRow) => originalRow.eventOrganizer?.email,
    },
    {
      Header: strings.tableHeadings.eventTitle,
      id: 'event.title',
      accessor: (originalRow) => (
        originalRow.title && originalRow.title?.length > 24
          ? `${originalRow.title.slice(0, 24)}...`
          : originalRow.title),
    },
    {
      Header: strings.tableHeadings.eventType,
      id: 'event.type',
      accessor: (row) => row.type,
    },
    {
      Header: strings.tableHeadings.location,
      id: 'location.name',
      accessor: (originalRow) => (
        originalRow.location?.name && originalRow.location?.name.length > 24
          ? `${originalRow.location?.name.slice(0, 24)}...`
          : originalRow.location?.name),
    },
    {
      Header: strings.tableHeadings.eventStartDate,
      id: 'event.date',
      accessor: (originalRow) => getEventTime(originalRow.date, originalRow.location?.timezoneID)?.format('DD/MM/YYYY z') || '',
    },
    {
      Header: strings.tableHeadings.eventEndDate,
      id: 'event.endDate',
      accessor: (originalRow) => getEventTime(originalRow.endDate, originalRow.location?.timezoneID)?.format('DD/MM/YYYY z') || '',
    },
    {
      Header: strings.tableHeadings.startTime,
      id: 'event.startTime',
      accessor: (originalRow) => getEventTime(originalRow.startTime, originalRow.location?.timezoneID)?.format('HH:mm z') || '',
      disableSortBy: true,
    },
    {
      Header: strings.tableHeadings.endTime,
      id: 'event.endTime',
      accessor: (originalRow) => getEventTime(originalRow.endTime, originalRow.location?.timezoneID)?.format('HH:mm z') || '',
      disableSortBy: true,
    },
    {
      Header: strings.tableHeadings.charity,
      id: 'organization.name',
      accessor: (originalRow) => (
        originalRow.organization?.name && originalRow.organization?.name.length > 24
          ? originalRow.organization?.name.slice(0, 24)
          : originalRow.organization?.name),
    },
    {
      Header: strings.tableHeadings.donation,
      id: 'event.pledgeAmount',
      accessor: (originalRow) => displayDonation(originalRow.pledgeAmount || 0),
    },
    {
      Header: strings.tableHeadings.status,
      id: 'status',
      accessor: (originalRow) => (
        displayMiniEventStatus(
          (originalRow.guests || []),
          originalRow.isCancelled,
          originalRow.startTime,
        )
      ),
      disableSortBy: true,
    },
  ];

  const handleApplyFilter = (queries: {
    dateFrom?: number; dateTo?: number; lowerDonation?: number; upperDonation?: number;
  }) => {
    setFilters(queries);
    setShowEventsFilterModal(false);
  };

  const handleClearFilter = () => {
    setFilters(undefined);
    setShowEventsFilterModal(false);
  };

  return (
    <PageLayout loading={false} pageTitle={strings.pageTitle}>
      {showEventsFilterModal && (
        <EventsFilterModal
          onClose={() => setShowEventsFilterModal(false)}
          onClearFilters={handleClearFilter}
          onFilter={handleApplyFilter}
        />
      )}
      {viewingAll ? (
        <EventsTable
          contentTitle={displayHeader}
          hasBackButton
          clickFunction={() => {
            setViewingAll(false);
            setDisplayHeader('');
            setTableFilter('');
            handleClearFilter();
          }}
          data={events}
          paginated
          columns={columns}
          fetchData={fetchData}
          pageCount={maxPage}
          manualPagination
          manualSortBy
        >
          <GuestsByEventCSVExport tableFilter={tableFilter} filters={filters} />

          <Button
            buttonText={strings.filter}
            onClick={() => setShowEventsFilterModal(true)}
            variant="primary"
            alignRight
            alignBottom
          />
        </EventsTable>
      ) : (
        <>
          <EventsTable
            contentTitle={strings.allEvents}
            columns={columns}
            data={baseEvents.slice(0, SUBSET_SIZE)}
            fetchData={fetchData}
          >
            <ViewAllLink to="#" onClick={() => onViewAll('all')}>{strings.viewAll}</ViewAllLink>
          </EventsTable>
          <EventsTable
            contentTitle={strings.upcomingEvents}
            columns={columns}
            data={baseEvents
              .filter((event) => moment(event.date).isAfter(moment()))
              .slice(0, SUBSET_SIZE)}
            fetchData={fetchData}
          >
            <ViewAllLink to="#" onClick={() => onViewAll('upcoming')}>{strings.viewAll}</ViewAllLink>
          </EventsTable>

          <EventsTable
            contentTitle={strings.previousEvents}
            columns={columns}
            data={baseEvents
              .filter((event) => moment(event.date).isBefore(moment()))
              .slice(0, SUBSET_SIZE)}
            fetchData={fetchData}
          >
            <ViewAllLink to="#" onClick={() => onViewAll('previous')}>{strings.viewAll}</ViewAllLink>
          </EventsTable>
        </>
      )}
    </PageLayout>
  );
};

export default Events;
