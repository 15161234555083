import React, { useMemo } from 'react';

import {
  Container, PillContainer, SelectedTitle, DropdownTitle, DownArrow, Dropdown,
} from './Styles';
import { SortArrowDown } from '../../../../assets/Icons';

import useStrings from '../../../../hooks/useStrings';

const DateFilterDropdown: React.FC<DateFilterDropdownProps> = ({
  onToggleDropdown, isDropdownShown, onClickFilter, filterSelected,
}) => {
  const [{ Statistics: strings }] = useStrings();

  const shownFilter = useMemo(() => {
    let displayed = '';
    switch (filterSelected) {
      case 'week':
        displayed = strings.thisWeek;
        break;
      case 'month':
        displayed = strings.thisMonth;
        break;
      case 'year':
        displayed = strings.thisYear;
        break;
      default:
        displayed = strings.thisWeek;
    }
    return displayed;
  }, [filterSelected, strings]);

  return (
    <Container onClick={onToggleDropdown}>
      <PillContainer>
        <SelectedTitle>{shownFilter}</SelectedTitle>
        <DownArrow src={SortArrowDown} />
      </PillContainer>
      {isDropdownShown && (
        <Dropdown>
          <DropdownTitle onClick={() => onClickFilter('year')}>{strings.thisYear}</DropdownTitle>
          <DropdownTitle onClick={() => onClickFilter('month')}>{strings.thisMonth}</DropdownTitle>
          <DropdownTitle onClick={() => onClickFilter('week')}>{strings.thisWeek}</DropdownTitle>
        </Dropdown>
      )}
    </Container>
  );
};

interface DateFilterDropdownProps {
  onToggleDropdown: () => void;
  isDropdownShown: boolean;
  onClickFilter: (filter: 'year' | 'month' | 'week') => void;
  filterSelected: 'year' | 'month' | 'week';
}

export default DateFilterDropdown;
