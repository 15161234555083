import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { LoginBackground } from '../../assets/Images';

export const BackgroundImage = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background-image: url(${LoginBackground});
  background-size: cover;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  background-color: ${({ theme }) => theme.palette.bgSecondary};
  border-radius: 40px;
  align-items: center;
`;

export const SmyleeLogo = styled.img`
  margin-top: ${({ theme }) => theme.spacing.M}rem;
  margin-bottom: ${({ theme }) => theme.spacing.XS}rem;
  height: 79px;
  width: 217px;
`;

export const Heading = styled.h1`
  margin-bottom: ${({ theme }) => theme.spacing.XS * 0.85}rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.palette.header};
  text-transform: uppercase;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const ShowPasswordText = styled.p`
  position: absolute;
  right: 35%;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  color: ${({ theme }) => theme.palette.label};
  opacity: 0.5;
  line-height: 16px;
  &:hover {
    cursor: pointer;
  }
`;

export const ForgotPasswordLink = styled(Link)`
  text-decoration: none;
  margin-top: ${({ theme }) => theme.spacing.XS}rem;
  margin-bottom: ${({ theme }) => theme.spacing.M}rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.palette.subheader};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

// FOR USE IN THE FORGOT PASSWORD PAGE
export const BodyText = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing.XS * 0.85}rem;
  margin-top: ${({ theme }) => theme.spacing.XS * 0.85}rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.palette.body};
  padding-left: ${({ theme }) => theme.spacing.S}rem;
  padding-right: ${({ theme }) => theme.spacing.S}rem;
  text-align: center;
  line-height: 25px;
  white-space:pre-wrap;
`;

export const Gap = styled.div`
  height: 30px;
`;
