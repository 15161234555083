import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const LoadingSpinner = styled.div`
  border: 16px solid ${({ theme }) => theme.palette.bgPrimary};
  border-top: 16px solid ${({ theme }) => theme.palette.primary};
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 1em;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% { 
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingText = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontScale.L}rem;
`;
