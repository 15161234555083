/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import firebase from 'firebase';
import 'firebase/analytics';
import FirebaseConfig from './firebase-config.json';
import { SENTRY_DSN } from './Constants';

export const IS_PROD = process.env.NODE_ENV === 'production';

if (IS_PROD && process.env.REACT_APP_SENTRY_ENV) {
  if (!firebase.apps.length) firebase.initializeApp(FirebaseConfig);
  firebase.analytics();
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2, // Need to play around with this value
    ignoreErrors: [
      'ResizeObserver loop limit exceeded', // ignored due to animation performance on dashboard in different browsers, spamming Sentry
    ],
  });
}

/** Error Catching Component */
export const ErrorBoundry = Sentry.ErrorBoundary;

/** Error Monitoring */
export const log = (...messages: unknown[]): void => messages.forEach((message) => {
  if (!IS_PROD) console.log(message);
  Sentry.addBreadcrumb({ message: JSON.stringify(message) });
});

export const logEvent = (...messages: string[]): void => messages.forEach((message) => {
  if (!IS_PROD) console.log(message);
  Sentry.captureEvent({ message });
});

export const logError = (error: Error | string): void => {
  if (!IS_PROD) console.warn(error);
  Sentry.captureException(typeof error === 'string' ? new Error(error) : error);
};

export const logUserId = (id: string): void => {
  firebase.analytics().setUserId(id);
  Sentry.setUser({ id });
};

export const logUserData = (data: { [key: string]: string }): void => {
  const { id, ...userData } = data;
  if (id) logUserId(id);
  firebase.analytics().setUserProperties(userData);
  Sentry.setTags(userData);
};

export const setPage = (pageName: string): void => {
  firebase.analytics().setCurrentScreen(pageName);
  Sentry.setTag('page', pageName);
};
