import React, { FC } from 'react';
import useStrings from '../../hooks/useStrings';
import { Wrapper, LoadingSpinner, LoadingText } from './Styles';

const LoadingScreen: FC = () => {
  const [{ Loading: strings }] = useStrings();

  return (
    <Wrapper>
      <LoadingSpinner />
      <LoadingText>{strings.loadingText}</LoadingText>
    </Wrapper>
  );
};

export default LoadingScreen;
