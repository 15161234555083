import React from 'react';

import StyledContentWrapper from './Styles';

const ContentWrapper: React.FC = ({ children }) => (
  <StyledContentWrapper>
    {children}
  </StyledContentWrapper>
);

export default ContentWrapper;
