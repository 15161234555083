import React, { useState } from 'react';

import {
  Form, InputWrapper, Label, Field, TextInputField, Button, Modal,
} from '../../../../components';
import {
  SectionHeading, FormWrapper,
  ShowPasswordContainer, ShowPasswordText,
  InputContainer, NameInputWrapper, Gap,
} from '../Styles';

import useStrings from '../../../../hooks/useStrings';
import { changePassword as firebaseChangePassword } from '../../../../lib/Authentication';
import { logError } from '../../../../lib/Analytics';

const ChangePassword: React.FC<ChangePasswordProps> = () => {
  const [{ Settings: strings }] = useStrings();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');

  const [isPasswordError, setIsPasswordError] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setShowErrorModal(true);
      setIsPasswordError(true);
      return;
    }
    try {
      await firebaseChangePassword(oldPassword, newPassword);
      setShowSuccessModal(true);
    } catch (err) {
      setShowErrorModal(true);
      logError(err);
    }
    setNewPassword('');
    setConfirmPassword('');
    setOldPassword('');
  };

  const saveDisabled = !newPassword || !oldPassword || !confirmPassword;

  return (
    <Form onSubmit={handleSubmit}>
      {showErrorModal && (
        <Modal
          closeModal={() => setShowErrorModal(false)}
          header={strings.FailureModal.heading}
          subHeader={strings.FailureModal.subheading}
          hasSubHeader
          rounded={false}
        >
          <Button
            onClick={() => setShowErrorModal(false)}
            buttonText={strings.FailureModal.buttonText}
            variant="modal"
          />
        </Modal>
      )}
      {showSuccessModal && (
        <Modal
          closeModal={() => setShowSuccessModal(false)}
          header={strings.SuccessModal.heading}
          subHeader={strings.SuccessModal.subheading}
          hasSubHeader
          rounded={false}
        >
          <Button
            onClick={() => setShowSuccessModal(false)}
            buttonText={strings.SuccessModal.buttonText}
            variant="modal"
          />
        </Modal>
      )}
      <SectionHeading>{strings.changePassword.heading}</SectionHeading>
      <FormWrapper>
        <InputContainer fullWidth>
          <InputWrapper>
            <Label error={isPasswordError} htmlFor="oldPassword">{strings.changePassword.oldPassword}</Label>
            <Field>
              <TextInputField
                id="oldPassword"
                name="oldPassword"
                type={showOldPassword ? 'text' : 'password'}
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                  if (e.target.value === '') {
                    setIsPasswordError(true);
                  } else {
                    setIsPasswordError(false);
                  }
                }}
                error={isPasswordError}
              />
              <ShowPasswordContainer>
                <ShowPasswordText onClick={() => setShowOldPassword(!showOldPassword)}>
                  {strings.changePassword.show}
                </ShowPasswordText>
              </ShowPasswordContainer>
            </Field>
          </InputWrapper>
          <NameInputWrapper>
            <InputWrapper>
              <Label error={isPasswordError} htmlFor="newPassword">{strings.changePassword.newPassword}</Label>
              <Field>
                <TextInputField
                  id="newPassword"
                  name="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    if (e.target.value === '') {
                      setIsPasswordError(true);
                    } else {
                      setIsPasswordError(false);
                    }
                  }}
                  error={isPasswordError}
                />
                <ShowPasswordContainer>
                  <ShowPasswordText onClick={() => setShowNewPassword(!showNewPassword)}>
                    {strings.changePassword.show}
                  </ShowPasswordText>
                </ShowPasswordContainer>
              </Field>
            </InputWrapper>
            {/* I don't know why I keep doing this.. love the old <br> */}
            <Gap />
            <InputWrapper>
              <Label error={isPasswordError} htmlFor="confirmPassword">{strings.changePassword.confirm}</Label>
              <Field>
                <TextInputField
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    if (e.target.value === '') {
                      setIsPasswordError(true);
                    } else {
                      setIsPasswordError(false);
                    }
                  }}
                  error={isPasswordError}
                />
                <ShowPasswordContainer>
                  <ShowPasswordText onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {strings.changePassword.show}
                  </ShowPasswordText>
                </ShowPasswordContainer>
              </Field>
            </InputWrapper>
          </NameInputWrapper>
        </InputContainer>
      </FormWrapper>
      <Button
        buttonText={strings.changePassword.save}
        variant="modal"
        onClick={handleSubmit}
        disabled={saveDisabled}
      />
    </Form>
  );
};

interface ChangePasswordProps { }

export default ChangePassword;
