import React from 'react';
import {
  Wrapper,
  Container,
  Header,
  SubHeader,
  StyledCloseIcon,
  SubHeaderProps,
  ModalStyleProps,
} from './Styles';
import { CloseIcon } from '../../assets/Icons';

const Modal: React.FC<ModalProps> = ({
  children,
  closeModal,
  header,
  subHeader,
  rounded = true,
  showCloseButton = true,
}) => (
  <Wrapper>
    <Container rounded={rounded}>
      {showCloseButton && <StyledCloseIcon onClick={closeModal} src={CloseIcon} />}
      <Header hasHeader={!!header}>{header}</Header>
      <SubHeader hasSubHeader={!!subHeader}>{subHeader}</SubHeader>
      {children}
    </Container>
  </Wrapper>
);

export interface ModalProps extends SubHeaderProps, ModalStyleProps {
  closeModal: () => void;
  header?: string;
  subHeader?: string;
  rounded?: boolean;
  showCloseButton?: boolean;
}

export default Modal;
