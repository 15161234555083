/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AddGuestInput = {
  eventID: Scalars['String'];
  guests: Array<NewGuestInput>;
  userID: Scalars['String'];
};

export type AddPlusOneInput = {
  eventID: Scalars['String'];
  guestID: Scalars['String'];
};

export type Admin = {
  __typename?: 'Admin';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  profilePhoto?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
};

export type AdminInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  profilePhoto?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
};

export type AdminResponse = {
  __typename?: 'AdminResponse';
  admin?: Maybe<Admin>;
  admins?: Maybe<Array<Admin>>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AgeBracketDonation = {
  __typename?: 'AgeBracketDonation';
  AgeBracket: Scalars['String'];
  TotalDonated: Scalars['Int'];
};

export type AllPaymentsInput = {
  endDate?: InputMaybe<Scalars['String']>;
  organizationID?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type Asset = {
  __typename?: 'Asset';
  image: Scalars['String'];
  title: Scalars['String'];
  website: Scalars['String'];
};

export type Attachment = {
  __typename?: 'Attachment';
  documentType?: Maybe<Scalars['String']>;
  event: Event;
  id: Scalars['String'];
  title: Scalars['String'];
  uploadDate?: Maybe<Scalars['Float']>;
  uploader: User;
  uri: Scalars['String'];
};

export type AttachmentResponse = {
  __typename?: 'AttachmentResponse';
  attachment?: Maybe<Attachment>;
  attachments?: Maybe<Array<Attachment>>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type BlockUserInput = {
  blocked?: InputMaybe<Scalars['String']>;
  blocker?: InputMaybe<Scalars['String']>;
  contactID?: InputMaybe<Scalars['String']>;
};

export type BlockedUser = {
  __typename?: 'BlockedUser';
  blocked: User;
  blocker: User;
  id: Scalars['String'];
};

export type BlockedUserInput = {
  blocker?: InputMaybe<Scalars['String']>;
};

export type BlockedUserResponse = {
  __typename?: 'BlockedUserResponse';
  blockedUser?: Maybe<BlockedUser>;
  blockedUsers?: Maybe<Array<BlockedUser>>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type BulkEventTypeInput = {
  userID: Scalars['String'];
};

export type BulkEventTypeResponse = {
  __typename?: 'BulkEventTypeResponse';
  eventTypes: Array<EventType>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CancelAttendanceInput = {
  eventID: Scalars['String'];
  userID: Scalars['String'];
};

export type CancelEventInput = {
  id: Scalars['String'];
};

export type CancelEventResponse = {
  __typename?: 'CancelEventResponse';
  event?: Maybe<Event>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CancelIntentInput = {
  eventID: Scalars['String'];
  userID: Scalars['String'];
};

export type ChargeInput = {
  amount: Scalars['Int'];
  cardTransactionFee: Scalars['Int'];
  currency?: InputMaybe<Scalars['String']>;
  eventID: Scalars['String'];
  organizationID: Scalars['String'];
  status: Scalars['String'];
  type: Scalars['String'];
  userID: Scalars['String'];
};

export type ChatInfoResponse = {
  __typename?: 'ChatInfoResponse';
  apartOfThread?: Maybe<Array<ChatThread>>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
  threadsCreated?: Maybe<Array<ChatThread>>;
};

export type ChatThread = {
  __typename?: 'ChatThread';
  apartOfThread: Array<User>;
  createdBy: User;
  directMessageExists: Scalars['Boolean'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type ChatThreadIdInput = {
  id: Scalars['String'];
  userID?: InputMaybe<Scalars['String']>;
};

export type ChatThreadIdResponse = {
  __typename?: 'ChatThreadIDResponse';
  chatThreadID?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ChatThreadResponse = {
  __typename?: 'ChatThreadResponse';
  chatThread?: Maybe<ChatThread>;
  chatThreads?: Maybe<Array<ChatThread>>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Contact = {
  __typename?: 'Contact';
  contact: User;
  createdBy: User;
  email: Scalars['String'];
  emails?: Maybe<Array<Scalars['String']>>;
  givenName: Scalars['String'];
  id: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  reciprocal: Scalars['Boolean'];
  surname: Scalars['String'];
};

export type ContactInfoResponse = {
  __typename?: 'ContactInfoResponse';
  createdBy?: Maybe<Array<Contact>>;
  groups?: Maybe<Array<Group>>;
  message: Scalars['String'];
  reciprocalInvites?: Maybe<Array<Contact>>;
  success: Scalars['Boolean'];
};

export type ContactProfileInput = {
  contactUserID: Scalars['String'];
  userID: Scalars['String'];
};

export type ContactResponse = {
  __typename?: 'ContactResponse';
  contact?: Maybe<Contact>;
  contacts?: Maybe<Array<Contact>>;
  futureUsersNumbers?: Maybe<Array<MobileInformation>>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CountEventsResponse = {
  __typename?: 'CountEventsResponse';
  totalEvents: Scalars['Int'];
};

export type CountUsersResponse = {
  __typename?: 'CountUsersResponse';
  totalUsers?: Maybe<Scalars['Int']>;
};

export type DeleteCardInput = {
  cardID?: InputMaybe<Scalars['String']>;
  userID?: InputMaybe<Scalars['String']>;
};

export type DeleteContactResponse = {
  __typename?: 'DeleteContactResponse';
  id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DeleteGroupInput = {
  id: Scalars['String'];
};

export type DeleteGroupResponse = {
  __typename?: 'DeleteGroupResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DeleteGuestInput = {
  id: Scalars['String'];
  userID: Scalars['String'];
};

export type DeleteNoteInput = {
  id: Scalars['String'];
  userID: Scalars['String'];
};

export type DeleteNoteResponse = {
  __typename?: 'DeleteNoteResponse';
  id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DeletedLocationResponsePayload = {
  __typename?: 'DeletedLocationResponsePayload';
  id?: Maybe<Scalars['String']>;
};

export type DidNotProceedEventInput = {
  eventID: Scalars['ID'];
  userID: Scalars['ID'];
};

export type DietaryRequirement = {
  __typename?: 'DietaryRequirement';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type DietaryRequirementInput = {
  title?: InputMaybe<Scalars['String']>;
  userID: Scalars['String'];
};

export type DietaryRequirementResponse = {
  __typename?: 'DietaryRequirementResponse';
  dietaryRequirement?: Maybe<DietaryRequirement>;
  dietaryRequirements?: Maybe<Array<DietaryRequirement>>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DirectMessageExistsResponse = {
  __typename?: 'DirectMessageExistsResponse';
  chatThreadID?: Maybe<Scalars['String']>;
  creatorID?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DonateAnywayFreqResponse = {
  __typename?: 'DonateAnywayFreqResponse';
  donatedAnyway: Scalars['Int'];
  keptPledge: Scalars['Int'];
};

export type DonationHistoryPayment = {
  __typename?: 'DonationHistoryPayment';
  amount: Scalars['Float'];
  eventDate: Scalars['Float'];
  eventID?: Maybe<Scalars['String']>;
  eventTitle: Scalars['String'];
  id: Scalars['String'];
  lastTried?: Maybe<Scalars['Float']>;
  organizationPaymentDetails: OrganizationPaymentDetail;
  status?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type DonationHistoryResponse = {
  __typename?: 'DonationHistoryResponse';
  message?: Maybe<Scalars['String']>;
  payments: Array<DonationHistoryPayment>;
  success: Scalars['Boolean'];
  totalDonated: Scalars['Float'];
  user?: Maybe<User>;
};

export type DonationsByAgeResponse = {
  __typename?: 'DonationsByAgeResponse';
  donationsByAgeBracket?: Maybe<Array<AgeBracketDonation>>;
};

export type DonationsByMonthResponse = {
  __typename?: 'DonationsByMonthResponse';
  donationsByMonth: Array<MonthlyDonations>;
};

export type EditChatThreadInput = {
  chatThreadID: Scalars['ID'];
  icon?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userID: Scalars['ID'];
};

export type EmailInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type EmailReplyInput = {
  message: Scalars['String'];
  recipientID: Scalars['ID'];
};

export type Event = {
  __typename?: 'Event';
  attachments?: Maybe<Array<Attachment>>;
  checkInTime: Scalars['Float'];
  cost?: Maybe<Scalars['Float']>;
  createdDateTime?: Maybe<Scalars['Float']>;
  date: Scalars['Float'];
  description: Scalars['String'];
  didNotProceed: Scalars['Boolean'];
  endDate: Scalars['Float'];
  endTime: Scalars['Float'];
  entryCode?: Maybe<Scalars['String']>;
  eventOrganizer: User;
  guests: Array<Guest>;
  id: Scalars['String'];
  isCancelled: Scalars['Boolean'];
  isHostCheckedIn: Scalars['Boolean'];
  location: Location;
  notes?: Maybe<Array<Note>>;
  organization: Organization;
  pledgeAmount: Scalars['Float'];
  rsvpDate: Scalars['Float'];
  safetyOfficer?: Maybe<User>;
  showHost: Scalars['Boolean'];
  startTime: Scalars['Float'];
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type EventIdResponse = {
  __typename?: 'EventIDResponse';
  eventID?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type EventResponsePayload = {
  __typename?: 'EventResponsePayload';
  event?: Maybe<Event>;
  guest?: Maybe<Guest>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type EventType = {
  __typename?: 'EventType';
  id: Scalars['String'];
  type: Scalars['String'];
};

export type EventTypeInput = {
  type: Scalars['String'];
  userID: Scalars['String'];
};

export type EventTypeResponse = {
  __typename?: 'EventTypeResponse';
  eventType?: Maybe<EventType>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type EventsAdminBaseResponse = {
  __typename?: 'EventsAdminBaseResponse';
  events?: Maybe<Array<Event>>;
};

export type EventsDashboardResponse = {
  __typename?: 'EventsDashboardResponse';
  eventsDates: Array<Scalars['Float']>;
};

export type EventsHomeScreenBeforeGuestUserIdInput = {
  email?: InputMaybe<Scalars['String']>;
  emails: Array<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  userID: Scalars['String'];
};

export type ExistingIdentifier = {
  createdBy: Scalars['String'];
  userContact: Scalars['String'];
};

export type FilterInput = {
  dateFrom?: InputMaybe<Scalars['Float']>;
  dateTo?: InputMaybe<Scalars['Float']>;
  lowerDonation?: InputMaybe<Scalars['Float']>;
  upperDonation?: InputMaybe<Scalars['Float']>;
};

export type GenericResponse = {
  __typename?: 'GenericResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Group = {
  __typename?: 'Group';
  contacts: Array<Contact>;
  id: Scalars['String'];
  name: Scalars['String'];
  user: User;
};

export type GroupIdInput = {
  id: Scalars['String'];
};

export type GroupResponse = {
  __typename?: 'GroupResponse';
  group?: Maybe<Group>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Guest = {
  __typename?: 'Guest';
  checkedIn: Scalars['Boolean'];
  checkinExemption?: Maybe<Scalars['Float']>;
  checkinExemptionDate?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  entryCode?: Maybe<Scalars['String']>;
  event?: Maybe<Event>;
  id: Scalars['String'];
  invitingUser?: Maybe<User>;
  invitingUserID?: Maybe<Scalars['String']>;
  isCancelled: Scalars['Boolean'];
  items?: Maybe<Array<Item>>;
  location?: Maybe<Location>;
  mobileNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  plusOneGiven: Scalars['Boolean'];
  plusOneUsed?: Maybe<Scalars['Boolean']>;
  reminderCount?: Maybe<Scalars['Int']>;
  rsvpd?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userID?: Maybe<Scalars['String']>;
};

export type GuestArrivedInput = {
  eventID?: InputMaybe<Scalars['ID']>;
  eventTitle?: InputMaybe<Scalars['String']>;
  locationTimezoneID?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  userIDsAndNames?: InputMaybe<Array<UserIDsAndNames>>;
};

export type GuestCancelledAttendanceInput = {
  eventTitle?: InputMaybe<Scalars['String']>;
  guestName?: InputMaybe<Scalars['String']>;
  locationTimezoneID?: InputMaybe<Scalars['String']>;
  userOnesignalPlayerIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type GuestCheckInInput = {
  checkedIn: Scalars['Boolean'];
  eventID: Scalars['String'];
  id: Scalars['String'];
  userID: Scalars['String'];
};

export type GuestResponse = {
  __typename?: 'GuestResponse';
  guest?: Maybe<Guest>;
  guests?: Maybe<Array<Guest>>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type GuestTaskInput = {
  eventID?: InputMaybe<Scalars['ID']>;
  eventName?: InputMaybe<Scalars['String']>;
  guestName?: InputMaybe<Scalars['String']>;
  hostName?: InputMaybe<Scalars['String']>;
  locationTimezoneID?: InputMaybe<Scalars['String']>;
  userPlayerID?: InputMaybe<Scalars['ID']>;
};

export type HostCheckInInput = {
  checkin: Scalars['Boolean'];
  eventID: Scalars['String'];
  userID: Scalars['String'];
};

export type Identifier = {
  objectID?: InputMaybe<Scalars['String']>;
  userID: Scalars['String'];
};

export type InstantRetryInput = {
  eventID: Scalars['ID'];
  userID: Scalars['ID'];
};

export type Item = {
  __typename?: 'Item';
  guest: Guest;
  id: Scalars['String'];
  title: Scalars['String'];
};

export type ItemResponse = {
  __typename?: 'ItemResponse';
  item?: Maybe<Item>;
  items?: Maybe<Array<Item>>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type LinkedInResponse = {
  __typename?: 'LinkedInResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
  user?: Maybe<LinkedInUser>;
};

export type LinkedInUser = {
  __typename?: 'LinkedInUser';
  email: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['String'];
  surname: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  name: Scalars['String'];
  postCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  timezoneID?: Maybe<Scalars['String']>;
};

export type LocationResponsePayload = {
  __typename?: 'LocationResponsePayload';
  location?: Maybe<Location>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type LongLatInput = {
  latitude: Scalars['String'];
  longitude: Scalars['String'];
};

export type MarkResolvedInput = {
  id: Scalars['ID'];
};

export type MinimumRelationsUser = {
  country?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Float']>;
  dietaryRequirements?: Maybe<Array<Scalars['String']>>;
  email: Scalars['String'];
  emails?: Maybe<Array<Scalars['String']>>;
  givenName: Scalars['String'];
  id: Scalars['String'];
  lockedOut: Scalars['Boolean'];
  marketingEmails: Scalars['Boolean'];
  mobileInformation: MobileInformation;
  onesignalPlayerID?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  reliability?: Maybe<Scalars['Float']>;
  surname: Scalars['String'];
};

export type MinimumRelationsUserResponse = {
  __typename?: 'MinimumRelationsUserResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  user?: Maybe<MinimumRelationsUser>;
};

export type MobileInformation = {
  __typename?: 'MobileInformation';
  areaCode?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
};

export type MobileInformationInput = {
  areaCode: Scalars['String'];
  mobileNumber: Scalars['String'];
};

export type ModifyUserInput = {
  increase: Scalars['Boolean'];
};

export type MonthlyDonations = {
  __typename?: 'MonthlyDonations';
  Amount: Scalars['String'];
  Month: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addContactToGroup: GroupResponse;
  addNewGuest: EventResponsePayload;
  assignPlusOne: GuestResponse;
  blockUser: BlockedUserResponse;
  bulkNewEventType: BulkEventTypeResponse;
  cancelAttendance: GuestResponse;
  cancelEvent: CancelEventResponse;
  cancelEventWithNoPenalty: CancelEventResponse;
  cancelPaymentIntent: PaymentResponse;
  checkInToEvent: GuestResponse;
  checkinToGoal: PersonalGoalResponse;
  dealWithReciprocalInvite: ContactInfoResponse;
  deleteChatThread: ChatThreadIdResponse;
  deleteContact: DeleteContactResponse;
  deleteGoal: PersonalGoalDelete;
  deleteGroup: GroupResponse;
  deleteGuest: GuestResponse;
  deleteItem: ItemResponse;
  deleteLocation: DeletedLocationResponsePayload;
  deleteNote: DeleteNoteResponse;
  deleteOrganization: OrganizationQueryViewResponse;
  deleteStripeCard: PaymentDetailsResponse;
  deleteUser: UserResponse;
  didNotProceedEventKeepPledge: Scalars['Boolean'];
  donateAnyway: PaymentResponse;
  editAdminProfile: AdminResponse;
  editChatThread: ChatThreadResponse;
  editOrganization?: Maybe<OrganizationQueryView>;
  editUserOnesignalID: UserResponse;
  editUserProfile: UserResponse;
  favouriteCharity: UserResponse;
  giveGuestExemption: EventResponsePayload;
  guestArrivedNotification: NotificationResponse;
  guestCancelledAttendance: NotificationResponse;
  guestTaskNotification: NotificationResponse;
  hostCheckIn: EventResponsePayload;
  instantRetry: PaymentResponse;
  inviteByMobileNumber: GenericResponse;
  invitePlusOnePostRsvp: GuestResponse;
  inviteUser: GenericResponse;
  joinEvent: EventIdResponse;
  markAsResolved: ReportedContentResponse;
  modifyReliabilityScore: UserResponse;
  newAdmin: AdminResponse;
  newAgreement: PaymentResponse;
  newAttachment: AttachmentResponse;
  newContact: ContactResponse;
  newEvent: EventResponsePayload;
  newEventType: EventTypeResponse;
  newGoal: PersonalGoalResponse;
  newGroup: GroupResponse;
  newItem: ItemResponse;
  newLinkedInUser: LinkedInResponse;
  newLocation: LocationResponsePayload;
  newNote: NoteResponse;
  newNotification: NotificationResponse;
  newOrganization: OrganizationResponse;
  newOutstandingPayment: PaymentResponse;
  newStripeCardDetails: PaymentDetailsResponse;
  newStripeCustomer: PaymentDetailsResponse;
  newThread: ChatThreadResponse;
  newUser: UserResponse;
  oneHour: NotificationResponse;
  removeGuestFromEvent: EventResponsePayload;
  removeGuestsFromEvent: EventResponsePayload;
  removeUserOnesignalID: UserResponse;
  reportContent: ReportedContentBlockedUserResponse;
  reportMessage: ReportedMessageResponse;
  rsvpToEvent: GuestResponse;
  sendEmailReply: ReportedContentResponse;
  sendReceipt: PaymentResponse;
  sendReminderText: GenericResponse;
  twentyFourHour: NotificationResponse;
  unblockUser: UnblockedUserResponse;
  updateDietaryRequirements: UserResponse;
  updateEvent: EventResponsePayload;
  updateGoal: PersonalGoalResponse;
  updateThread: ChatThreadResponse;
  updateVouch: VouchResponse;
  uploadContacts: ContactResponse;
  vouchForUser: VouchResponse;
};

export type MutationAddContactToGroupArgs = {
  input: ContactAndGroupInput;
};

export type MutationAddNewGuestArgs = {
  input: AddGuestInput;
};

export type MutationAssignPlusOneArgs = {
  input: AddPlusOneInput;
};

export type MutationBlockUserArgs = {
  input: BlockUserInput;
};

export type MutationBulkNewEventTypeArgs = {
  input: Array<EventTypeInput>;
};

export type MutationCancelAttendanceArgs = {
  input: CancelAttendanceInput;
};

export type MutationCancelEventArgs = {
  input: CancelEventInput;
};

export type MutationCancelEventWithNoPenaltyArgs = {
  input: CancelEventInput;
};

export type MutationCancelPaymentIntentArgs = {
  input: CancelIntentInput;
};

export type MutationCheckInToEventArgs = {
  input: GuestCheckInInput;
};

export type MutationCheckinToGoalArgs = {
  input: CheckInInput;
};

export type MutationDealWithReciprocalInviteArgs = {
  input: ReciprocalInviteInput;
};

export type MutationDeleteChatThreadArgs = {
  input: ChatThreadIdInput;
};

export type MutationDeleteContactArgs = {
  input: ContactDeleteInput;
};

export type MutationDeleteGoalArgs = {
  input: GoalIdInput;
};

export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};

export type MutationDeleteGuestArgs = {
  input: DeleteGuestInput;
};

export type MutationDeleteItemArgs = {
  input: ItemIdInput;
};

export type MutationDeleteLocationArgs = {
  input: Identifier;
};

export type MutationDeleteNoteArgs = {
  input: DeleteNoteInput;
};

export type MutationDeleteOrganizationArgs = {
  input: Identifier;
};

export type MutationDeleteStripeCardArgs = {
  input: DeleteCardInput;
};

export type MutationDeleteUserArgs = {
  input: UserIdInput;
};

export type MutationDidNotProceedEventKeepPledgeArgs = {
  input: DidNotProceedEventInput;
};

export type MutationDonateAnywayArgs = {
  input: DonateAnywayInput;
};

export type MutationEditAdminProfileArgs = {
  input: AdminInput;
};

export type MutationEditChatThreadArgs = {
  input: EditChatThreadInput;
};

export type MutationEditOrganizationArgs = {
  input: EditOrganizationInput;
};

export type MutationEditUserOnesignalIdArgs = {
  input: OneSignalPlayerIdInput;
  userID: Scalars['ID'];
};

export type MutationEditUserProfileArgs = {
  input: EditUserInput;
};

export type MutationFavouriteCharityArgs = {
  input: FavouriteCharityInput;
};

export type MutationGiveGuestExemptionArgs = {
  input: Array<GuestExemptionTime>;
};

export type MutationGuestArrivedNotificationArgs = {
  input: GuestArrivedInput;
  userID: Scalars['ID'];
};

export type MutationGuestCancelledAttendanceArgs = {
  input: GuestCancelledAttendanceInput;
  userID: Scalars['ID'];
};

export type MutationGuestTaskNotificationArgs = {
  input: GuestTaskInput;
  userID: Scalars['ID'];
};

export type MutationHostCheckInArgs = {
  input: HostCheckInInput;
};

export type MutationInstantRetryArgs = {
  input: InstantRetryInput;
};

export type MutationInviteByMobileNumberArgs = {
  input?: InputMaybe<InviteByMobileNumberInput>;
};

export type MutationInvitePlusOnePostRsvpArgs = {
  input: InvitePlusOneInput;
};

export type MutationInviteUserArgs = {
  input: InviteByEmailInput;
};

export type MutationJoinEventArgs = {
  input: JoinEventInput;
};

export type MutationMarkAsResolvedArgs = {
  input: MarkResolvedInput;
};

export type MutationModifyReliabilityScoreArgs = {
  input: ModifyUserInput;
  userID: Scalars['ID'];
};

export type MutationNewAdminArgs = {
  input: AdminInput;
};

export type MutationNewAgreementArgs = {
  input: ChargeInput;
};

export type MutationNewAttachmentArgs = {
  input: NewAttachmentInput;
};

export type MutationNewContactArgs = {
  input: NewContactInput;
};

export type MutationNewEventArgs = {
  input: NewEventInput;
};

export type MutationNewEventTypeArgs = {
  input: EventTypeInput;
};

export type MutationNewGoalArgs = {
  input: NewGoalInput;
};

export type MutationNewGroupArgs = {
  input: NewGroupInput;
};

export type MutationNewItemArgs = {
  input: NewItemInput;
};

export type MutationNewLinkedInUserArgs = {
  input: NewLinkedInUserInput;
};

export type MutationNewLocationArgs = {
  input: NewLocationInput;
};

export type MutationNewNoteArgs = {
  input: NewNoteInput;
};

export type MutationNewNotificationArgs = {
  input: NotificationInput;
};

export type MutationNewOrganizationArgs = {
  input: NewOrganizationInput;
};

export type MutationNewOutstandingPaymentArgs = {
  input: OutstandingPaymentInput;
};

export type MutationNewStripeCardDetailsArgs = {
  input: NewStripeCardInput;
};

export type MutationNewStripeCustomerArgs = {
  input: UserIdInput;
};

export type MutationNewThreadArgs = {
  input: NewThreadInput;
};

export type MutationNewUserArgs = {
  input: NewUserInput;
};

export type MutationOneHourArgs = {
  input: TwentyFourHourInput;
  userID: Scalars['ID'];
};

export type MutationRemoveGuestFromEventArgs = {
  input: RemoveGuestInput;
};

export type MutationRemoveGuestsFromEventArgs = {
  input: RemoveGuestsInput;
};

export type MutationRemoveUserOnesignalIdArgs = {
  userID: Scalars['ID'];
};

export type MutationReportContentArgs = {
  input: ReportContentInput;
};

export type MutationReportMessageArgs = {
  input: ReportMessageInput;
};

export type MutationRsvpToEventArgs = {
  input: RsvpInput;
};

export type MutationSendEmailReplyArgs = {
  input: EmailReplyInput;
};

export type MutationSendReceiptArgs = {
  input: PastDonationEmailInput;
};

export type MutationSendReminderTextArgs = {
  input: SendReminderTextInput;
};

export type MutationTwentyFourHourArgs = {
  input: TwentyFourHourInput;
  userID: Scalars['ID'];
};

export type MutationUnblockUserArgs = {
  input: UnblockUserInput;
};

export type MutationUpdateDietaryRequirementsArgs = {
  input: DietaryRequirementsInput;
};

export type MutationUpdateEventArgs = {
  input: EventInput;
};

export type MutationUpdateGoalArgs = {
  input: UpdateGoalInput;
};

export type MutationUpdateThreadArgs = {
  input: NewThreadInput;
};

export type MutationUpdateVouchArgs = {
  input: VouchForUserInput;
};

export type MutationUploadContactsArgs = {
  input: Array<MobileInformationInput>;
};

export type MutationVouchForUserArgs = {
  input: VouchForUserInput;
};

export type NewGroupInput = {
  contactIDs: Array<Scalars['String']>;
  name: Scalars['String'];
  userID: Scalars['String'];
};

export type NewGuestInput = {
  checkinExemption?: InputMaybe<Scalars['Float']>;
  email: Scalars['String'];
  mobileNumber: Scalars['String'];
  name: Scalars['String'];
  plusOneGiven?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  userID: Scalars['String'];
};

export type NewStripeCardInput = {
  token: Scalars['String'];
  userID: Scalars['String'];
};

export type NewThreadInput = {
  apartOfThreadID: Array<Scalars['String']>;
  createdByID?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type NewUserDateInput = {
  startDate: Scalars['String'];
};

export type NewUsersResponse = {
  __typename?: 'NewUsersResponse';
  usersCreatedThisWeekDates?: Maybe<Array<Scalars['Float']>>;
};

export type Note = {
  __typename?: 'Note';
  content: Scalars['String'];
  event: Event;
  forWho: User;
  id: Scalars['String'];
  isReminder: Reminder;
  user: User;
};

export type NoteResponse = {
  __typename?: 'NoteResponse';
  message: Scalars['String'];
  note?: Maybe<Note>;
  notes?: Maybe<Array<Note>>;
  success: Scalars['Boolean'];
};

export type Notification = {
  __typename?: 'Notification';
  contents: Scalars['String'];
  externalUserIDS?: Maybe<Array<Scalars['String']>>;
  headings: Scalars['String'];
};

export type NotificationInput = {
  chatIcon?: InputMaybe<Scalars['String']>;
  chatName?: InputMaybe<Scalars['String']>;
  chatThreadID?: InputMaybe<Scalars['String']>;
  contents?: InputMaybe<Scalars['String']>;
  creatorID?: InputMaybe<Scalars['String']>;
  externalUserIDS?: InputMaybe<Array<Scalars['String']>>;
  headings?: InputMaybe<Scalars['String']>;
  isGroupChat?: InputMaybe<Scalars['Boolean']>;
  userID: Scalars['String'];
};

export type NotificationResponse = {
  __typename?: 'NotificationResponse';
  message: Scalars['String'];
  notification?: Maybe<Notification>;
  success: Scalars['Boolean'];
};

export type OneSignalHashResponse = {
  __typename?: 'OneSignalHashResponse';
  hash?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type OneSignalPlayerIdInput = {
  playerID: Scalars['ID'];
};

export type Organization = {
  __typename?: 'Organization';
  abn: Scalars['String'];
  addrLineOne: Scalars['String'];
  addrLineTwo: Scalars['String'];
  assetPairOne?: Maybe<Asset>;
  assetPairThree?: Maybe<Asset>;
  assetPairTwo?: Maybe<Asset>;
  backgroundImage?: Maybe<Scalars['String']>;
  charityNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  contactName: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  eventsLink?: Maybe<Scalars['String']>;
  iconLogo: Scalars['String'];
  id: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  officialName?: Maybe<Scalars['String']>;
  paymentDetails?: Maybe<OrganizationPaymentDetail>;
  phoneNumber: Scalars['String'];
  postcode: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  signatureImage?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  subscribeLink?: Maybe<Scalars['String']>;
  totalDonated?: Maybe<Scalars['Float']>;
  totalEvents?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['String']>;
};

export type OrganizationByCountryInput = {
  country?: InputMaybe<Scalars['String']>;
  userID: Scalars['ID'];
};

export type OrganizationCountriesResponse = {
  __typename?: 'OrganizationCountriesResponse';
  countries: Array<Scalars['String']>;
};

export type OrganizationIncomeReportResponse = {
  __typename?: 'OrganizationIncomeReportResponse';
  avgOfPayments?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  smyleeRevenue?: Maybe<Scalars['Float']>;
  sumOfPayments?: Maybe<Scalars['Float']>;
  totalCountOfPayments?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type OrganizationPaymentDetail = {
  __typename?: 'OrganizationPaymentDetail';
  id: Scalars['String'];
  organization: Organization;
  payments?: Maybe<Array<Payment>>;
  stripeID?: Maybe<Scalars['String']>;
};

export type OrganizationQueryView = {
  __typename?: 'OrganizationQueryView';
  abn: Scalars['String'];
  addrLineOne: Scalars['String'];
  addrLineTwo: Scalars['String'];
  assetPairOne?: Maybe<Asset>;
  assetPairThree?: Maybe<Asset>;
  assetPairTwo?: Maybe<Asset>;
  backgroundImage?: Maybe<Scalars['String']>;
  charityNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  contactName: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  eventsLink?: Maybe<Scalars['String']>;
  iconLogo: Scalars['String'];
  id: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  officialName?: Maybe<Scalars['String']>;
  paymentDetailsID?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postcode: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  signatureImage?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  stripeAccountID?: Maybe<Scalars['String']>;
  subscribeLink?: Maybe<Scalars['String']>;
  totalDonated?: Maybe<Scalars['Float']>;
  totalEvents?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['String']>;
};

export type OrganizationQueryViewResponse = {
  __typename?: 'OrganizationQueryViewResponse';
  message: Scalars['String'];
  organization?: Maybe<OrganizationQueryView>;
  success: Scalars['Boolean'];
};

export type OrganizationResponse = {
  __typename?: 'OrganizationResponse';
  id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  organization?: Maybe<Organization>;
  organizations?: Maybe<Array<Organization>>;
  reportResponse?: Maybe<Array<OrganizationIncomeReportResponse>>;
  success: Scalars['Boolean'];
};

export type OrganizationsQueryViewResponse = {
  __typename?: 'OrganizationsQueryViewResponse';
  message: Scalars['String'];
  organizations?: Maybe<Array<OrganizationQueryView>>;
  success: Scalars['Boolean'];
};

export type PaginatedEventsResponse = {
  __typename?: 'PaginatedEventsResponse';
  events?: Maybe<Array<Event>>;
  totalPages: Scalars['Int'];
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  createdDate?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  eventsUserHasBeenAGuestOf: Scalars['Int'];
  eventsUserHasCreated: Scalars['Int'];
  givenName: Scalars['String'];
  id: Scalars['String'];
  mobileInformation: MobileInformation;
  profilePhoto?: Maybe<Scalars['String']>;
  reliability: Scalars['Float'];
  surname: Scalars['String'];
  totalEvents: Scalars['Int'];
  userTotalDonated: Scalars['Int'];
};

export type PaginatedUsersResponse = {
  __typename?: 'PaginatedUsersResponse';
  totalPages: Scalars['Int'];
  users: Array<PaginatedUser>;
};

export type PastDonationEmailInput = {
  paymentID: Scalars['ID'];
  userID: Scalars['ID'];
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Float'];
  cardTransactionFee?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  event?: Maybe<Event>;
  eventID?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastTried?: Maybe<Scalars['Float']>;
  organizationPaymentDetails: OrganizationPaymentDetail;
  receiptNo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stripeChargeID?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  userPaymentDetails?: Maybe<PaymentDetails>;
};

export type PaymentCard = {
  __typename?: 'PaymentCard';
  brand: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  exp_month: Scalars['Int'];
  exp_year: Scalars['Int'];
  funding: Scalars['String'];
  id: Scalars['String'];
  last4: Scalars['String'];
};

export type PaymentDetails = {
  __typename?: 'PaymentDetails';
  id: Scalars['String'];
  payments?: Maybe<Array<Payment>>;
  stripeID?: Maybe<Scalars['String']>;
  user: User;
};

export type PaymentDetailsResponse = {
  __typename?: 'PaymentDetailsResponse';
  cardDetails?: Maybe<PaymentCard>;
  message: Scalars['String'];
  paymentDetails?: Maybe<PaymentDetails>;
  success: Scalars['Boolean'];
};

export type PaymentResponse = {
  __typename?: 'PaymentResponse';
  message?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
  success: Scalars['Boolean'];
  totalDonated?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
};

export type PaymentsResponse = {
  __typename?: 'PaymentsResponse';
  message?: Maybe<Scalars['String']>;
  payments: Array<Payment>;
  success: Scalars['Boolean'];
  totalDonated?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
};

export type PaymentsThisWeekResponse = {
  __typename?: 'PaymentsThisWeekResponse';
  donationsMadeThisWeek: Array<Payment>;
};

export type PersonalGoal = {
  __typename?: 'PersonalGoal';
  checkInTime: Scalars['Float'];
  checkedIn?: Maybe<Scalars['Boolean']>;
  date: Scalars['Float'];
  description: Scalars['String'];
  endDate: Scalars['Float'];
  endTime: Scalars['Float'];
  eventOrganizer: User;
  id: Scalars['String'];
  location: Location;
  organization: Organization;
  pledgeAmount: Scalars['Float'];
  startTime: Scalars['Float'];
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type PersonalGoalDelete = {
  __typename?: 'PersonalGoalDelete';
  id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type PersonalGoalResponse = {
  __typename?: 'PersonalGoalResponse';
  message: Scalars['String'];
  personalGoal?: Maybe<PersonalGoal>;
  success: Scalars['Boolean'];
};

export type PersonalGoalsResponse = {
  __typename?: 'PersonalGoalsResponse';
  message: Scalars['String'];
  personalGoal?: Maybe<PersonalGoal>;
  success: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  admin: AdminResponse;
  allAdmins: AdminResponse;
  allCards: StripePaymentCardResponse;
  allPayments: PaymentsResponse;
  allReportedContent: ReportedContentResponse;
  attachmentOnEvent: AttachmentResponse;
  attachmentsOnEvent: AttachmentResponse;
  attachmentsUploadedByUser?: Maybe<AttachmentResponse>;
  blockedUsers: BlockedUserResponse;
  chatThread: ChatThreadResponse;
  checkAppVersion: UserResponse;
  contact: ContactResponse;
  contactExists: ContactResponse;
  contactUserProfile: UserResponse;
  countTotalEvents: CountEventsResponse;
  countTotalUsers: CountUsersResponse;
  dietaryRequirement: DietaryRequirementResponse;
  dietaryRequirements: DietaryRequirementResponse;
  directMessageExists: DirectMessageExistsResponse;
  donateAnywayFrequencies: DonateAnywayFreqResponse;
  donationHistory: DonationHistoryResponse;
  donationsByAge: DonationsByAgeResponse;
  donationsByMonth: DonationsByMonthResponse;
  donationsThisWeek: PaymentsThisWeekResponse;
  event: EventResponsePayload;
  eventByTitle: Array<Event>;
  eventTypes: BulkEventTypeResponse;
  eventTypesByName: EventTypeResponse;
  events: Array<Event>;
  eventsByOrganizationID: Array<Event>;
  eventsByUser: UsersEventResponse;
  eventsCancelledFromDate: EventsDashboardResponse;
  eventsCreatedByUser: UsersEventResponse;
  eventsCreatedFromDate: EventsDashboardResponse;
  eventsForAdminBaseTable: EventsAdminBaseResponse;
  eventsHeldFromDate: EventsDashboardResponse;
  eventsHomeScreenBeforeUserIDOnGuest: UserResponse;
  eventsUserIsApartOf: UsersEventResponse;
  getUserByIdMinimumRelations: MinimumRelationsUserResponse;
  getUserChatInformation: ChatInfoResponse;
  getUserContactAndGroups: ContactInfoResponse;
  getUserVouchInformation: VouchInfoResponse;
  goal: PersonalGoalResponse;
  goals: PersonalGoalsResponse;
  group?: Maybe<GroupResponse>;
  guest?: Maybe<Guest>;
  guests: Array<Guest>;
  guestsRsvpd: Array<Guest>;
  item: Array<Item>;
  itemByGuest: Array<Item>;
  location?: Maybe<Location>;
  locationByLongLat: LocationResponsePayload;
  locations: Array<Location>;
  locationsByName: Array<Location>;
  note: Note;
  notificationHash: OneSignalHashResponse;
  organization: OrganizationResponse;
  organizationCountries: OrganizationCountriesResponse;
  organizationIgnoringDisabled: OrganizationQueryViewResponse;
  organizations: OrganizationResponse;
  organizationsIgnoringDisabled: OrganizationsQueryViewResponse;
  organizationsIncomeReport: Array<OrganizationIncomeReportResponse>;
  paginatedEvents: PaginatedEventsResponse;
  paginatedUsers: PaginatedUsersResponse;
  reportedContent: ReportedContentResponse;
  reportedMessage: ReportedMessageResponse;
  reportedMessageByMessage: ReportedMessageResponse;
  reportedMessageByUser: ReportedMessageResponse;
  reportedMessages: ReportedMessageResponse;
  searchContacts?: Maybe<Array<Contact>>;
  smyleeRevenue: TotalDonatedResponse;
  stripeCharge: PaymentResponse;
  stripeLoginLink: StripeLoginLinkResponse;
  topCharitiesByDonationAmount: TopOrgDonationResponse;
  topDonationTypes: TopDonationsResponse;
  topEventTypes: TopEventsResponse;
  topLocationsByState: TopLocationStateResponse;
  topLocationsBySuburb: TopLocationSuburbResponse;
  totalDonatedSmyleeWide: TotalDonatedResponse;
  unpaidPayments: PaymentsResponse;
  userByEmail: UserResponse;
  userByMobileNumber: UserResponse;
  userByName: Array<User>;
  userDownloadHubspot: Array<UserDownloadForHubspot>;
  userForAdmin: UserForAdminResponse;
  users?: Maybe<Array<User>>;
  usersThisWeek: NewUsersResponse;
  verifyEmail: Scalars['Boolean'];
  verifyPhone: Scalars['Boolean'];
  vouchesForUser: VouchResponse;
  vouchesIHaveDone: VouchResponse;
  vouchesOnUserByUser: VouchResponse;
  weMetAtAll: WeMetAtResponse;
};

export type QueryAdminArgs = {
  input: AdminIdInput;
};

export type QueryAllAdminsArgs = {
  input: AdminIdInput;
};

export type QueryAllCardsArgs = {
  input: UserIdInput;
};

export type QueryAllPaymentsArgs = {
  adminID: Scalars['ID'];
  input: AllPaymentsInput;
};

export type QueryAttachmentOnEventArgs = {
  input: AttachmentOnEventInput;
};

export type QueryAttachmentsOnEventArgs = {
  input: AttachmentsOnEventInput;
};

export type QueryAttachmentsUploadedByUserArgs = {
  input: AttachmentsUploadedByUserInput;
};

export type QueryBlockedUsersArgs = {
  input: BlockedUserInput;
};

export type QueryChatThreadArgs = {
  input: ChatThreadIdInput;
};

export type QueryContactArgs = {
  input: ContactIdInput;
};

export type QueryContactExistsArgs = {
  input: ExistingIdentifier;
};

export type QueryContactUserProfileArgs = {
  input: ContactProfileInput;
};

export type QueryDietaryRequirementArgs = {
  input: DietaryRequirementInput;
};

export type QueryDietaryRequirementsArgs = {
  input: UserIdInput;
};

export type QueryDirectMessageExistsArgs = {
  input: UserIdInput;
};

export type QueryDonateAnywayFrequenciesArgs = {
  fromDate: Scalars['Float'];
};

export type QueryDonationHistoryArgs = {
  input: UserIdInput;
};

export type QueryDonationsByAgeArgs = {
  fromDate: Scalars['Float'];
};

export type QueryDonationsThisWeekArgs = {
  date: Scalars['String'];
};

export type QueryEventArgs = {
  input: EventIdInput;
};

export type QueryEventByTitleArgs = {
  input: EventTitleInput;
};

export type QueryEventTypesArgs = {
  input: BulkEventTypeInput;
};

export type QueryEventTypesByNameArgs = {
  input: EventTypeInput;
};

export type QueryEventsByOrganizationIdArgs = {
  adminID?: InputMaybe<Scalars['ID']>;
  organizationID?: InputMaybe<Scalars['ID']>;
};

export type QueryEventsByUserArgs = {
  input: UserIdInput;
};

export type QueryEventsCancelledFromDateArgs = {
  date: Scalars['String'];
};

export type QueryEventsCreatedByUserArgs = {
  input: UserIdInput;
};

export type QueryEventsCreatedFromDateArgs = {
  date: Scalars['String'];
};

export type QueryEventsForAdminBaseTableArgs = {
  adminID: Scalars['String'];
};

export type QueryEventsHeldFromDateArgs = {
  date: Scalars['String'];
};

export type QueryEventsHomeScreenBeforeUserIdOnGuestArgs = {
  input: EventsHomeScreenBeforeGuestUserIdInput;
};

export type QueryEventsUserIsApartOfArgs = {
  input: UserIdInput;
};

export type QueryGetUserByIdMinimumRelationsArgs = {
  input: UserIdInput;
};

export type QueryGetUserChatInformationArgs = {
  input: UserIdInput;
};

export type QueryGetUserContactAndGroupsArgs = {
  input: GetUserContactAndGroupsInput;
};

export type QueryGetUserVouchInformationArgs = {
  input: UserIdInput;
};

export type QueryGoalArgs = {
  input: GoalIdInput;
};

export type QueryGoalsArgs = {
  input: GoalsIdInput;
};

export type QueryGroupArgs = {
  input: GroupIdInput;
};

export type QueryGuestArgs = {
  input: GuestIdInput;
};

export type QueryGuestsRsvpdArgs = {
  input: GuestsCondition;
};

export type QueryItemArgs = {
  input: ItemIdInput;
};

export type QueryItemByGuestArgs = {
  input: ItemByGuestInput;
};

export type QueryLocationArgs = {
  input: LocationIdInput;
};

export type QueryLocationByLongLatArgs = {
  input: LongLatInput;
};

export type QueryLocationsByNameArgs = {
  input: LocationNameInput;
};

export type QueryNoteArgs = {
  input: NoteIdInput;
};

export type QueryNotificationHashArgs = {
  input: UserIdInput;
};

export type QueryOrganizationArgs = {
  input: OrganizationIdInput;
};

export type QueryOrganizationCountriesArgs = {
  userID: Scalars['ID'];
};

export type QueryOrganizationIgnoringDisabledArgs = {
  adminID: Scalars['ID'];
  organizationID: Scalars['ID'];
};

export type QueryOrganizationsArgs = {
  input: OrganizationByCountryInput;
};

export type QueryOrganizationsIgnoringDisabledArgs = {
  adminID: Scalars['ID'];
};

export type QueryOrganizationsIncomeReportArgs = {
  adminID: Scalars['ID'];
  input: OrganizationIncomeReportInput;
};

export type QueryPaginatedEventsArgs = {
  adminID: Scalars['String'];
  filters?: InputMaybe<FilterInput>;
  pageNo: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  tableFilter?: InputMaybe<Scalars['String']>;
};

export type QueryPaginatedUsersArgs = {
  adminID: Scalars['String'];
  filter?: InputMaybe<Scalars['String']>;
  pageNo: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchQuery?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QueryReportedContentArgs = {
  input: ReportContentInput;
};

export type QueryReportedMessageArgs = {
  input: MessageInput;
};

export type QueryReportedMessageByMessageArgs = {
  input: MessageInput;
};

export type QueryReportedMessageByUserArgs = {
  input: ReportMessageByUserInput;
};

export type QuerySearchContactsArgs = {
  input: ContactSearch;
};

export type QuerySmyleeRevenueArgs = {
  adminID: Scalars['ID'];
};

export type QueryStripeChargeArgs = {
  input: StripeChargeIdInput;
};

export type QueryStripeLoginLinkArgs = {
  organizationID: Scalars['String'];
};

export type QueryTopCharitiesByDonationAmountArgs = {
  fromDate: Scalars['Float'];
};

export type QueryTopDonationTypesArgs = {
  fromDate: Scalars['Float'];
};

export type QueryTopEventTypesArgs = {
  fromDate: Scalars['Float'];
};

export type QueryTopLocationsByStateArgs = {
  fromDate: Scalars['Float'];
};

export type QueryTopLocationsBySuburbArgs = {
  fromDate: Scalars['Float'];
};

export type QueryUnpaidPaymentsArgs = {
  input: UserIdInput;
};

export type QueryUserByEmailArgs = {
  input: UserEmailInput;
};

export type QueryUserByMobileNumberArgs = {
  input: UserMobileNumberInput;
};

export type QueryUserByNameArgs = {
  input: UserNameInput;
};

export type QueryUserDownloadHubspotArgs = {
  adminID: Scalars['ID'];
};

export type QueryUserForAdminArgs = {
  input: UserForAdminInput;
};

export type QueryUsersThisWeekArgs = {
  date: Scalars['String'];
};

export type QueryVerifyEmailArgs = {
  input: Scalars['String'];
};

export type QueryVerifyPhoneArgs = {
  input: MobileInformationInput;
};

export type QueryVouchesForUserArgs = {
  input: UserIdInput;
};

export type QueryVouchesIHaveDoneArgs = {
  input: UserIdInput;
};

export type QueryVouchesOnUserByUserArgs = {
  input: VouchesOnUserInput;
};

export type ReciprocalInviteInput = {
  accept: Scalars['Boolean'];
  contactID: Scalars['ID'];
  userID: Scalars['ID'];
};

export type RemoveGuestInput = {
  eventID: Scalars['ID'];
  eventOrganizerID: Scalars['ID'];
  guestIDs: Array<Scalars['ID']>;
};

export type RemoveGuestsInput = {
  eventID: Scalars['String'];
  guestsID: Array<Scalars['String']>;
  userID: Scalars['String'];
};

export type ReportContentInput = {
  contactID: Scalars['String'];
  content?: InputMaybe<Scalars['String']>;
  reportedReason: ReportedReason;
  reportedUserID: Scalars['String'];
  reportingUserID: Scalars['String'];
};

export type Reported = {
  __typename?: 'Reported';
  content?: Maybe<Scalars['String']>;
  dateCreated: Scalars['Float'];
  id: Scalars['String'];
  isResolved: Scalars['Boolean'];
  reported: User;
  reportedReason?: Maybe<ReportedReason>;
  reporter: User;
};

export type ReportedContentBlockedUserResponse = {
  __typename?: 'ReportedContentBlockedUserResponse';
  blockedUser?: Maybe<BlockedUser>;
  message: Scalars['String'];
  reportedContent?: Maybe<Reported>;
  success: Scalars['Boolean'];
};

export type ReportedContentResponse = {
  __typename?: 'ReportedContentResponse';
  message: Scalars['String'];
  reportedContent?: Maybe<Reported>;
  reportedContents?: Maybe<Array<Reported>>;
  success: Scalars['Boolean'];
};

export type ReportedMessage = {
  __typename?: 'ReportedMessage';
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  reporter?: Maybe<User>;
};

export type ReportedMessageResponse = {
  __typename?: 'ReportedMessageResponse';
  message: Scalars['String'];
  reportedMessage?: Maybe<ReportedMessage>;
  reportedMessages?: Maybe<Array<ReportedMessage>>;
  success: Scalars['Boolean'];
};

export enum ReportedReason {
  Abusive = 'abusive',
  BehaviourAgainstTheLaw = 'behaviour_against_the_law',
  Discrimination = 'discrimination',
  InappropriateLanguageOrImagery = 'inappropriate_language_or_imagery',
  Offensive = 'offensive',
  Privacy = 'privacy',
  Threatening = 'threatening',
  Trolling = 'trolling',
  ViolatedTos = 'violated_tos',
}

export type SendReminderTextInput = {
  guestIDs: Array<Scalars['ID']>;
  userID: Scalars['String'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StripeLoginLinkResponse = {
  __typename?: 'StripeLoginLinkResponse';
  link: Scalars['String'];
};

export type StripePaymentCardResponse = {
  __typename?: 'StripePaymentCardResponse';
  message: Scalars['String'];
  stripeCards: Array<PaymentCard>;
  success: Scalars['Boolean'];
};

export type TopDonationsCount = {
  __typename?: 'TopDonationsCount';
  count: Scalars['Int'];
  type: Scalars['String'];
};

export type TopDonationsResponse = {
  __typename?: 'TopDonationsResponse';
  donationTypes: Array<TopDonationsCount>;
};

export type TopEventsCount = {
  __typename?: 'TopEventsCount';
  count: Scalars['Int'];
  type: Scalars['String'];
};

export type TopEventsResponse = {
  __typename?: 'TopEventsResponse';
  types: Array<TopEventsCount>;
};

export type TopLocationState = {
  __typename?: 'TopLocationState';
  count: Scalars['Int'];
  state: Scalars['String'];
};

export type TopLocationStateResponse = {
  __typename?: 'TopLocationStateResponse';
  locations?: Maybe<Array<TopLocationState>>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type TopLocationSuburb = {
  __typename?: 'TopLocationSuburb';
  count: Scalars['Int'];
  suburb: Scalars['String'];
};

export type TopLocationSuburbResponse = {
  __typename?: 'TopLocationSuburbResponse';
  locations?: Maybe<Array<TopLocationSuburb>>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type TopOrgDonationCount = {
  __typename?: 'TopOrgDonationCount';
  donated: Scalars['Int'];
  name: Scalars['String'];
};

export type TopOrgDonationResponse = {
  __typename?: 'TopOrgDonationResponse';
  orgsTotalDonated?: Maybe<Array<TopOrgDonationCount>>;
};

export type TotalDonatedResponse = {
  __typename?: 'TotalDonatedResponse';
  message: Scalars['String'];
  smyleeRevenue?: Maybe<Scalars['Float']>;
  smyleeWideDonations?: Maybe<Scalars['Float']>;
  success: Scalars['Boolean'];
};

export type TwentyFourHourInput = {
  eventName?: InputMaybe<Scalars['String']>;
  locationTimezoneID?: InputMaybe<Scalars['String']>;
  userOnesignalPlayerIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type UnblockUserInput = {
  blocked?: InputMaybe<Scalars['String']>;
  blocker?: InputMaybe<Scalars['String']>;
  contactID?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UnblockedUserResponse = {
  __typename?: 'UnblockedUserResponse';
  id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdateEventPayload = {
  __typename?: 'UpdateEventPayload';
  checkInTime: Scalars['Float'];
  cost?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Float']>;
  eventOrganizer?: Maybe<User>;
  guests?: Maybe<Array<Guest>>;
  id: Scalars['String'];
  location?: Maybe<Location>;
  notes?: Maybe<Array<Note>>;
  organization?: Maybe<Organization>;
  pledgeAmount: Scalars['Float'];
  safetyOfficer?: Maybe<User>;
  title?: Maybe<Scalars['String']>;
};

export type User = MinimumRelationsUser & {
  __typename?: 'User';
  apartOfThread?: Maybe<Array<ChatThread>>;
  assingedNotes?: Maybe<Array<Note>>;
  attachments?: Maybe<Array<Attachment>>;
  blocked?: Maybe<Array<BlockedUser>>;
  contacts: Array<Contact>;
  country?: Maybe<Scalars['String']>;
  createdBy: Array<Contact>;
  createdDate?: Maybe<Scalars['Float']>;
  dateOfBirth?: Maybe<Scalars['Float']>;
  deleted: Scalars['Boolean'];
  dietaryRequirements?: Maybe<Array<Scalars['String']>>;
  email: Scalars['String'];
  emails?: Maybe<Array<Scalars['String']>>;
  favouriteCharities?: Maybe<Array<Organization>>;
  givenName: Scalars['String'];
  groups?: Maybe<Array<Group>>;
  id: Scalars['String'];
  lockedOut: Scalars['Boolean'];
  marketingEmails: Scalars['Boolean'];
  mobileInformation: MobileInformation;
  notes?: Maybe<Array<Note>>;
  onesignalPlayerID?: Maybe<Scalars['String']>;
  paymentDetails?: Maybe<Array<PaymentDetails>>;
  plusOne?: Maybe<Array<Guest>>;
  profilePhoto?: Maybe<Scalars['String']>;
  reliability?: Maybe<Scalars['Float']>;
  surname: Scalars['String'];
  termsAndConditions: Scalars['Boolean'];
  threadsCreated?: Maybe<Array<ChatThread>>;
  vouchee: Array<Vouch>;
  voucher: Array<Vouch>;
};

export type UserDownloadForHubspot = {
  __typename?: 'UserDownloadForHubspot';
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  marketingEmails: Scalars['Boolean'];
  mobileInformation: MobileInformation;
  surname?: Maybe<Scalars['String']>;
};

export type UserForAdminInput = {
  adminID: Scalars['ID'];
  id: Scalars['ID'];
};

export type UserForAdminResponse = {
  __typename?: 'UserForAdminResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  user?: Maybe<PaginatedUser>;
};

export type UserIDsAndNames = {
  userName?: InputMaybe<Scalars['String']>;
  userPlayerID?: InputMaybe<Scalars['ID']>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  user?: Maybe<User>;
  users?: Maybe<Array<User>>;
};

export type UsersEventResponse = {
  __typename?: 'UsersEventResponse';
  cursor?: Maybe<Scalars['String']>;
  events?: Maybe<Array<Event>>;
  eventsCreatedByUser?: Maybe<Array<Event>>;
  eventsUserIsApartOf?: Maybe<Array<Event>>;
  guests?: Maybe<Array<Guest>>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Vouch = {
  __typename?: 'Vouch';
  id: Scalars['String'];
  vouchee: User;
  voucher: User;
  weMetAt: Scalars['String'];
};

export type VouchForUserInput = {
  vouchee: Scalars['String'];
  voucher: Scalars['String'];
  weMetAt: Scalars['String'];
};

export type VouchInfoResponse = {
  __typename?: 'VouchInfoResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  vouchee?: Maybe<Array<Vouch>>;
  voucher?: Maybe<Array<Vouch>>;
};

export type VouchResponse = {
  __typename?: 'VouchResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  vouch?: Maybe<Vouch>;
  vouches?: Maybe<Array<Vouch>>;
};

export type WeMetAt = {
  __typename?: 'WeMetAt';
  id: Scalars['String'];
  where: Scalars['String'];
};

export type WeMetAtResponse = {
  __typename?: 'WeMetAtResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  weMetAtArray?: Maybe<Array<WeMetAt>>;
};

export type AdminIdInput = {
  id: Scalars['String'];
};

export type AttachmentOnEventInput = {
  attachmentID?: InputMaybe<Scalars['String']>;
  eventID: Scalars['String'];
  id: Scalars['String'];
  uploaderID: Scalars['String'];
};

export type AttachmentsOnEventInput = {
  attachmentID?: InputMaybe<Scalars['String']>;
  eventID: Scalars['String'];
  userID: Scalars['String'];
};

export type AttachmentsUploadedByUserInput = {
  id: Scalars['String'];
  uploaderID: Scalars['String'];
};

export type CheckInInput = {
  checkIn: Scalars['Boolean'];
  id: Scalars['String'];
  userID: Scalars['String'];
};

export type ContactAndGroupInput = {
  contactIDs: Array<Scalars['String']>;
  groupID: Scalars['String'];
};

export type ContactDeleteInput = {
  contactID: Scalars['String'];
  createdByID: Scalars['String'];
};

export type ContactIdInput = {
  id: Scalars['String'];
};

export type ContactSearch = {
  email: Scalars['String'];
  mobileNumber: Scalars['String'];
};

export type DietaryRequirementsInput = {
  dietaryRequirements: Array<Scalars['String']>;
  id: Scalars['String'];
};

export type DonateAnywayInput = {
  didNotProceed?: InputMaybe<Scalars['Boolean']>;
  eventID: Scalars['String'];
  userID: Scalars['String'];
};

export type EditOrganizationInput = {
  abn: Scalars['String'];
  addrLineOne: Scalars['String'];
  addrLineTwo: Scalars['String'];
  adminID?: InputMaybe<Scalars['String']>;
  assetPairOne?: InputMaybe<OrganizationAssetInput>;
  assetPairThree?: InputMaybe<OrganizationAssetInput>;
  assetPairTwo?: InputMaybe<OrganizationAssetInput>;
  backgroundImage?: InputMaybe<Scalars['String']>;
  charityNumber?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  contactName: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  eventsLink?: InputMaybe<Scalars['String']>;
  iconLogo: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  officialName?: InputMaybe<Scalars['String']>;
  organizationID: Scalars['String'];
  paymentDetailsID?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postcode: Scalars['String'];
  signatureImage?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  stripeAccountID?: InputMaybe<Scalars['String']>;
  subscribeLink?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type EditUserInput = {
  country: Scalars['String'];
  dateOfBirth?: InputMaybe<Scalars['Float']>;
  email: Scalars['String'];
  emails: Array<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['String'];
  marketingEmails: Scalars['Boolean'];
  mobileInformation: MobileInformationInput;
  mobileNumber?: InputMaybe<Scalars['String']>;
  profilePhoto?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
};

export type EventIdInput = {
  id: Scalars['String'];
};

export type EventInput = {
  checkInTime: Scalars['Float'];
  cost?: InputMaybe<Scalars['Float']>;
  date: Scalars['Float'];
  description: Scalars['String'];
  endDate: Scalars['Float'];
  endTime: Scalars['Float'];
  eventOrganizerID?: InputMaybe<Scalars['String']>;
  guests: Array<Scalars['String']>;
  id: Scalars['String'];
  locationID: LocationIdInput;
  notes?: InputMaybe<Array<Scalars['String']>>;
  organization: OrganizationIdInput;
  pledgeAmount?: InputMaybe<Scalars['Float']>;
  rsvpDate: Scalars['Float'];
  safetyOfficer?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Float'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type EventTitleInput = {
  title: Scalars['String'];
};

export type FavouriteCharityInput = {
  id: Scalars['String'];
  organizationIDs: Array<Scalars['String']>;
};

export type GetUserContactAndGroupsInput = {
  id: Scalars['ID'];
  showReciprocalInvites?: InputMaybe<Scalars['Boolean']>;
};

export type GoalIdInput = {
  eventOrganizerID: Scalars['String'];
  id: Scalars['String'];
};

export type GoalsIdInput = {
  eventOrganizerID: Scalars['String'];
};

export type GuestExemptionTime = {
  guestID: Scalars['String'];
  time: Scalars['Float'];
};

export type GuestIdInput = {
  id: Scalars['String'];
};

export type GuestsCondition = {
  id: Scalars['String'];
  rsvpd: Scalars['Boolean'];
};

export type InviteByEmailInput = {
  email: Scalars['String'];
  givenName: Scalars['String'];
  userID: Scalars['String'];
};

export type InviteByMobileNumberInput = {
  givenName: Scalars['String'];
  mobileNumber: Scalars['String'];
  userID: Scalars['String'];
};

export type InvitePlusOneInput = {
  eventID: Scalars['String'];
  id: Scalars['String'];
  plusOne: NewGuestInput;
  userID: Scalars['String'];
};

export type ItemByGuestInput = {
  guestID: Scalars['String'];
};

export type ItemIdInput = {
  id: Scalars['String'];
};

export type JoinEventInput = {
  email?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  entryCode: Scalars['String'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  userID: Scalars['String'];
};

export type LocationIdInput = {
  id: Scalars['String'];
};

export type LocationNameInput = {
  name: Scalars['String'];
};

export type MessageInput = {
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
};

export type MobileNumberInput = {
  mobileNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type NewAttachmentInput = {
  documentType: Scalars['String'];
  eventID: Scalars['String'];
  title: Scalars['String'];
  uploadDate: Scalars['Float'];
  uri: Scalars['String'];
  userID: Scalars['String'];
};

export type NewContactInput = {
  contact: Scalars['String'];
  createdBy: Scalars['String'];
  email: Scalars['String'];
  emails?: InputMaybe<Array<Scalars['String']>>;
  givenName: Scalars['String'];
  mobileNumber: Scalars['String'];
  surname: Scalars['String'];
};

export type NewEventInput = {
  checkInTime: Scalars['Float'];
  cost?: InputMaybe<Scalars['Float']>;
  date: Scalars['Float'];
  description: Scalars['String'];
  endDate: Scalars['Float'];
  endTime: Scalars['Float'];
  eventOrganizerID?: InputMaybe<Scalars['String']>;
  guests: Array<NewGuestInput>;
  locationID?: InputMaybe<Scalars['String']>;
  organizationID: Scalars['String'];
  pledgeAmount: Scalars['Float'];
  rsvpDate: Scalars['Float'];
  startTime: Scalars['Float'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type NewGoalInput = {
  checkInTime: Scalars['Float'];
  date: Scalars['Float'];
  description: Scalars['String'];
  endDate: Scalars['Float'];
  endTime: Scalars['Float'];
  eventOrganizerID: Scalars['String'];
  locationID: Scalars['String'];
  organizationID: Scalars['String'];
  pledgeAmount: Scalars['Float'];
  startTime: Scalars['Float'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type NewItemInput = {
  guestID: Scalars['String'];
  title: Scalars['String'];
};

export type NewLinkedInUserInput = {
  accessToken: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Float']>;
  email?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  givenName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  mobileInformation?: InputMaybe<MobileInformationInput>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
};

export type NewLocationInput = {
  address: Scalars['String'];
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  name: Scalars['String'];
  postCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  suburb?: InputMaybe<Scalars['String']>;
  timezoneID?: InputMaybe<Scalars['String']>;
};

export type NewNoteInput = {
  content: Scalars['String'];
  eventID: Scalars['String'];
  forWhoID: Scalars['String'];
  isReminder: Reminder;
  userID: Scalars['String'];
};

export type NewOrganizationInput = {
  abn: Scalars['String'];
  addrLineOne: Scalars['String'];
  addrLineTwo: Scalars['String'];
  adminID?: InputMaybe<Scalars['String']>;
  assetPairOne?: InputMaybe<OrganizationAssetInput>;
  assetPairThree?: InputMaybe<OrganizationAssetInput>;
  assetPairTwo?: InputMaybe<OrganizationAssetInput>;
  backgroundImage?: InputMaybe<Scalars['String']>;
  charityNumber?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  contactName: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  eventsLink?: InputMaybe<Scalars['String']>;
  iconLogo: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  officialName?: InputMaybe<Scalars['String']>;
  paymentDetailsID?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postcode: Scalars['String'];
  signatureImage?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  stripeAccountID: Scalars['String'];
  subscribeLink?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type NewUserInput = {
  country: Scalars['String'];
  dateOfBirth?: InputMaybe<Scalars['Float']>;
  dietaryRequirements?: InputMaybe<Array<Scalars['String']>>;
  email: Scalars['String'];
  emails: Array<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['String'];
  linkedInId: Scalars['String'];
  marketingEmails: Scalars['Boolean'];
  mobileInformation?: InputMaybe<MobileInformationInput>;
  mobileNumber: Scalars['String'];
  onesignalPlayerID: Scalars['String'];
  profilePhoto?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
  termsAndConditions?: InputMaybe<Scalars['Boolean']>;
};

export type NoteIdInput = {
  id: Scalars['String'];
};

export type OrganizationAssetInput = {
  image: Scalars['String'];
  title: Scalars['String'];
  website: Scalars['String'];
};

export type OrganizationIdInput = {
  id: Scalars['String'];
};

export type OrganizationIncomeReportInput = {
  endDate: Scalars['Float'];
  organizationID: Scalars['ID'];
  startDate: Scalars['Float'];
};

export type OutstandingPaymentInput = {
  paymentID: Scalars['String'];
  userID: Scalars['String'];
};

export type PaymentIntentInput = {
  amount: Scalars['Float'];
  currency?: InputMaybe<Scalars['String']>;
  organizationID: Scalars['String'];
  userID: Scalars['String'];
};

export enum Reminder {
  Guest = 'guest',
  Host = 'host',
}

export type ReportMessageByUserInput = {
  reporterID: Scalars['String'];
};

export type ReportMessageInput = {
  message: Scalars['String'];
  reporterID: Scalars['String'];
};

export type RsvpInput = {
  eventID: Scalars['String'];
  id: Scalars['String'];
  plusOne?: InputMaybe<NewGuestInput>;
  rsvpd: Scalars['Boolean'];
  userID: Scalars['String'];
};

export type StripeChargeIdInput = {
  chargeID: Scalars['String'];
  userID: Scalars['String'];
};

export type UpdateGoalInput = {
  checkInTime: Scalars['Float'];
  date: Scalars['Float'];
  description: Scalars['String'];
  endDate: Scalars['Float'];
  endTime: Scalars['Float'];
  eventOrganizerID: Scalars['String'];
  id: Scalars['String'];
  locationID: Scalars['String'];
  organizationID: Scalars['String'];
  pledgeAmount: Scalars['Float'];
  startTime: Scalars['Float'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type UserEmailInput = {
  email: Scalars['String'];
  givenName?: InputMaybe<Scalars['String']>;
};

export type UserIdInput = {
  id: Scalars['String'];
};

export type UserMobileNumberInput = {
  areaCode?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
};

export type UserNameInput = {
  givenName?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
};

export type VouchesOnUserInput = {
  vouchee: Scalars['String'];
  voucher: Scalars['String'];
};

export type AdminDataFragment = {
  __typename?: 'Admin';
  id: string;
  firstName: string;
  surname: string;
  email: string;
  profilePhoto?: string | null;
};

export type GetAdminQueryVariables = Exact<{
  input: AdminIdInput;
}>;

export type GetAdminQuery = {
  __typename?: 'Query';
  admin: {
    __typename?: 'AdminResponse';
    admin?: {
      __typename?: 'Admin';
      id: string;
      firstName: string;
      surname: string;
      email: string;
      profilePhoto?: string | null;
    } | null;
  };
};

export type GetAdminsQueryVariables = Exact<{
  input: AdminIdInput;
}>;

export type GetAdminsQuery = {
  __typename?: 'Query';
  allAdmins: {
    __typename?: 'AdminResponse';
    admins?: Array<{
      __typename?: 'Admin';
      id: string;
      firstName: string;
      surname: string;
      email: string;
      profilePhoto?: string | null;
    }> | null;
  };
};

export type CreateAdminMutationVariables = Exact<{
  input: AdminInput;
}>;

export type CreateAdminMutation = {
  __typename?: 'Mutation';
  newAdmin: {
    __typename?: 'AdminResponse';
    admin?: {
      __typename?: 'Admin';
      id: string;
      firstName: string;
      surname: string;
      email: string;
      profilePhoto?: string | null;
    } | null;
  };
};

export type EditAdminMutationVariables = Exact<{
  input: AdminInput;
}>;

export type EditAdminMutation = {
  __typename?: 'Mutation';
  editAdminProfile: {
    __typename?: 'AdminResponse';
    success: boolean;
    admin?: {
      __typename?: 'Admin';
      id: string;
      firstName: string;
      surname: string;
      email: string;
      profilePhoto?: string | null;
    } | null;
  };
};

export type GetTotalUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetTotalUsersQuery = {
  __typename?: 'Query';
  countTotalUsers: { __typename?: 'CountUsersResponse'; totalUsers?: number | null };
};

export type GetTotalEventsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTotalEventsQuery = {
  __typename?: 'Query';
  countTotalEvents: { __typename?: 'CountEventsResponse'; totalEvents: number };
};

export type GetTotalDonationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTotalDonationsQuery = {
  __typename?: 'Query';
  totalDonatedSmyleeWide: { __typename?: 'TotalDonatedResponse'; smyleeWideDonations?: number | null };
};

export type GetTotalSmyleeRevenueQueryVariables = Exact<{
  adminID: Scalars['ID'];
}>;

export type GetTotalSmyleeRevenueQuery = {
  __typename?: 'Query';
  smyleeRevenue: { __typename?: 'TotalDonatedResponse'; smyleeRevenue?: number | null };
};

export type GetDonationsThisWeekQueryVariables = Exact<{
  date: Scalars['String'];
}>;

export type GetDonationsThisWeekQuery = {
  __typename?: 'Query';
  donationsThisWeek: {
    __typename?: 'PaymentsThisWeekResponse';
    donationsMadeThisWeek: Array<{ __typename?: 'Payment'; lastTried?: number | null; amount: number }>;
  };
};

export type GetUsersThisWeekQueryVariables = Exact<{
  date: Scalars['String'];
}>;

export type GetUsersThisWeekQuery = {
  __typename?: 'Query';
  usersThisWeek: { __typename?: 'NewUsersResponse'; usersCreatedThisWeekDates?: Array<number> | null };
};

export type GetEventsCreatedFromDateQueryVariables = Exact<{
  date: Scalars['String'];
}>;

export type GetEventsCreatedFromDateQuery = {
  __typename?: 'Query';
  eventsCreatedFromDate: { __typename?: 'EventsDashboardResponse'; eventsDates: Array<number> };
};

export type GetEventsHeldFromDateQueryVariables = Exact<{
  date: Scalars['String'];
}>;

export type GetEventsHeldFromDateQuery = {
  __typename?: 'Query';
  eventsHeldFromDate: { __typename?: 'EventsDashboardResponse'; eventsDates: Array<number> };
};

export type GetEventsCancelledFromDateQueryVariables = Exact<{
  date: Scalars['String'];
}>;

export type GetEventsCancelledFromDateQuery = {
  __typename?: 'Query';
  eventsCancelledFromDate: { __typename?: 'EventsDashboardResponse'; eventsDates: Array<number> };
};

export type GuestDataFragment = {
  __typename?: 'Guest';
  id: string;
  rsvpd?: boolean | null;
  checkinExemption?: number | null;
  checkedIn: boolean;
  entryCode?: string | null;
  userID?: string | null;
  mobileNumber?: string | null;
  email?: string | null;
  source?: string | null;
  name?: string | null;
  isCancelled: boolean;
  user?: {
    __typename?: 'User';
    id: string;
    givenName: string;
    surname: string;
    email: string;
    profilePhoto?: string | null;
    reliability?: number | null;
    createdDate?: number | null;
    mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
  } | null;
};

export type LocationDataFragment = {
  __typename?: 'Location';
  id: string;
  latitude: string;
  longitude: string;
  name: string;
  address?: string | null;
  timezoneID?: string | null;
  suburb?: string | null;
};

export type EventDataFragment = {
  __typename?: 'Event';
  id: string;
  createdDateTime?: number | null;
  title: string;
  description: string;
  type?: string | null;
  date: number;
  startTime: number;
  endDate: number;
  endTime: number;
  rsvpDate: number;
  checkInTime: number;
  cost?: number | null;
  isHostCheckedIn: boolean;
  pledgeAmount: number;
  entryCode?: string | null;
  isCancelled: boolean;
  eventOrganizer: {
    __typename?: 'User';
    id: string;
    givenName: string;
    surname: string;
    email: string;
    profilePhoto?: string | null;
    reliability?: number | null;
    createdDate?: number | null;
    mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
  };
  location: {
    __typename?: 'Location';
    id: string;
    latitude: string;
    longitude: string;
    name: string;
    address?: string | null;
    timezoneID?: string | null;
    suburb?: string | null;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    description?: string | null;
    iconLogo: string;
    logo?: string | null;
    backgroundImage?: string | null;
    website?: string | null;
    eventsLink?: string | null;
    subscribeLink?: string | null;
    totalDonated?: number | null;
    disabled: boolean;
    totalEvents?: number | null;
    addrLineOne: string;
    addrLineTwo: string;
    abn: string;
    priority?: number | null;
    city: string;
    state: string;
    postcode: string;
    country: string;
    contactName: string;
    phoneNumber: string;
    signatureImage?: string | null;
    charityNumber?: string | null;
    currency: string;
    officialName?: string | null;
    assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
    assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
    assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
  };
  guests: Array<{
    __typename?: 'Guest';
    id: string;
    rsvpd?: boolean | null;
    checkinExemption?: number | null;
    checkedIn: boolean;
    entryCode?: string | null;
    userID?: string | null;
    mobileNumber?: string | null;
    email?: string | null;
    source?: string | null;
    name?: string | null;
    isCancelled: boolean;
    user?: {
      __typename?: 'User';
      id: string;
      givenName: string;
      surname: string;
      email: string;
      profilePhoto?: string | null;
      reliability?: number | null;
      createdDate?: number | null;
      mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
    } | null;
  }>;
};

export type GetEventsByOrganizationQueryVariables = Exact<{
  organizationID: Scalars['ID'];
  adminID: Scalars['ID'];
}>;

export type GetEventsByOrganizationQuery = {
  __typename?: 'Query';
  eventsByOrganizationID: Array<{
    __typename?: 'Event';
    id: string;
    createdDateTime?: number | null;
    title: string;
    description: string;
    type?: string | null;
    date: number;
    startTime: number;
    endDate: number;
    endTime: number;
    rsvpDate: number;
    checkInTime: number;
    cost?: number | null;
    isHostCheckedIn: boolean;
    pledgeAmount: number;
    entryCode?: string | null;
    isCancelled: boolean;
    eventOrganizer: {
      __typename?: 'User';
      id: string;
      givenName: string;
      surname: string;
      email: string;
      profilePhoto?: string | null;
      reliability?: number | null;
      createdDate?: number | null;
      mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
    };
    location: {
      __typename?: 'Location';
      id: string;
      latitude: string;
      longitude: string;
      name: string;
      address?: string | null;
      timezoneID?: string | null;
      suburb?: string | null;
    };
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      description?: string | null;
      iconLogo: string;
      logo?: string | null;
      backgroundImage?: string | null;
      website?: string | null;
      eventsLink?: string | null;
      subscribeLink?: string | null;
      totalDonated?: number | null;
      disabled: boolean;
      totalEvents?: number | null;
      addrLineOne: string;
      addrLineTwo: string;
      abn: string;
      priority?: number | null;
      city: string;
      state: string;
      postcode: string;
      country: string;
      contactName: string;
      phoneNumber: string;
      signatureImage?: string | null;
      charityNumber?: string | null;
      currency: string;
      officialName?: string | null;
      assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
    };
    guests: Array<{
      __typename?: 'Guest';
      id: string;
      rsvpd?: boolean | null;
      checkinExemption?: number | null;
      checkedIn: boolean;
      entryCode?: string | null;
      userID?: string | null;
      mobileNumber?: string | null;
      email?: string | null;
      source?: string | null;
      name?: string | null;
      isCancelled: boolean;
      user?: {
        __typename?: 'User';
        id: string;
        givenName: string;
        surname: string;
        email: string;
        profilePhoto?: string | null;
        reliability?: number | null;
        createdDate?: number | null;
        mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
      } | null;
    }>;
  }>;
};

export type GetPaginatedEventsQueryVariables = Exact<{
  adminID: Scalars['String'];
  pageNo: Scalars['Int'];
  pageSize: Scalars['Int'];
  tableFilter?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  filters?: InputMaybe<FilterInput>;
}>;

export type GetPaginatedEventsQuery = {
  __typename?: 'Query';
  paginatedEvents: {
    __typename?: 'PaginatedEventsResponse';
    totalPages: number;
    events?: Array<{
      __typename?: 'Event';
      id: string;
      createdDateTime?: number | null;
      title: string;
      description: string;
      type?: string | null;
      date: number;
      startTime: number;
      endDate: number;
      endTime: number;
      rsvpDate: number;
      checkInTime: number;
      cost?: number | null;
      isHostCheckedIn: boolean;
      pledgeAmount: number;
      entryCode?: string | null;
      isCancelled: boolean;
      eventOrganizer: {
        __typename?: 'User';
        id: string;
        givenName: string;
        surname: string;
        email: string;
        profilePhoto?: string | null;
        reliability?: number | null;
        createdDate?: number | null;
        mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
      };
      location: {
        __typename?: 'Location';
        id: string;
        latitude: string;
        longitude: string;
        name: string;
        address?: string | null;
        timezoneID?: string | null;
        suburb?: string | null;
      };
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        description?: string | null;
        iconLogo: string;
        logo?: string | null;
        backgroundImage?: string | null;
        website?: string | null;
        eventsLink?: string | null;
        subscribeLink?: string | null;
        totalDonated?: number | null;
        disabled: boolean;
        totalEvents?: number | null;
        addrLineOne: string;
        addrLineTwo: string;
        abn: string;
        priority?: number | null;
        city: string;
        state: string;
        postcode: string;
        country: string;
        contactName: string;
        phoneNumber: string;
        signatureImage?: string | null;
        charityNumber?: string | null;
        currency: string;
        officialName?: string | null;
        assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
        assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
        assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      };
      guests: Array<{
        __typename?: 'Guest';
        id: string;
        rsvpd?: boolean | null;
        checkinExemption?: number | null;
        checkedIn: boolean;
        entryCode?: string | null;
        userID?: string | null;
        mobileNumber?: string | null;
        email?: string | null;
        source?: string | null;
        name?: string | null;
        isCancelled: boolean;
        user?: {
          __typename?: 'User';
          id: string;
          givenName: string;
          surname: string;
          email: string;
          profilePhoto?: string | null;
          reliability?: number | null;
          createdDate?: number | null;
          mobileInformation: {
            __typename?: 'MobileInformation';
            areaCode?: string | null;
            mobileNumber?: string | null;
          };
        } | null;
      }>;
    }> | null;
  };
};

export type EventsForAdminBaseTableQueryVariables = Exact<{
  adminID: Scalars['String'];
}>;

export type EventsForAdminBaseTableQuery = {
  __typename?: 'Query';
  eventsForAdminBaseTable: {
    __typename?: 'EventsAdminBaseResponse';
    events?: Array<{
      __typename?: 'Event';
      id: string;
      createdDateTime?: number | null;
      title: string;
      description: string;
      type?: string | null;
      date: number;
      startTime: number;
      endDate: number;
      endTime: number;
      rsvpDate: number;
      checkInTime: number;
      cost?: number | null;
      isHostCheckedIn: boolean;
      pledgeAmount: number;
      entryCode?: string | null;
      isCancelled: boolean;
      eventOrganizer: {
        __typename?: 'User';
        id: string;
        givenName: string;
        surname: string;
        email: string;
        profilePhoto?: string | null;
        reliability?: number | null;
        createdDate?: number | null;
        mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
      };
      location: {
        __typename?: 'Location';
        id: string;
        latitude: string;
        longitude: string;
        name: string;
        address?: string | null;
        timezoneID?: string | null;
        suburb?: string | null;
      };
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        description?: string | null;
        iconLogo: string;
        logo?: string | null;
        backgroundImage?: string | null;
        website?: string | null;
        eventsLink?: string | null;
        subscribeLink?: string | null;
        totalDonated?: number | null;
        disabled: boolean;
        totalEvents?: number | null;
        addrLineOne: string;
        addrLineTwo: string;
        abn: string;
        priority?: number | null;
        city: string;
        state: string;
        postcode: string;
        country: string;
        contactName: string;
        phoneNumber: string;
        signatureImage?: string | null;
        charityNumber?: string | null;
        currency: string;
        officialName?: string | null;
        assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
        assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
        assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      };
      guests: Array<{
        __typename?: 'Guest';
        id: string;
        rsvpd?: boolean | null;
        checkinExemption?: number | null;
        checkedIn: boolean;
        entryCode?: string | null;
        userID?: string | null;
        mobileNumber?: string | null;
        email?: string | null;
        source?: string | null;
        name?: string | null;
        isCancelled: boolean;
        user?: {
          __typename?: 'User';
          id: string;
          givenName: string;
          surname: string;
          email: string;
          profilePhoto?: string | null;
          reliability?: number | null;
          createdDate?: number | null;
          mobileInformation: {
            __typename?: 'MobileInformation';
            areaCode?: string | null;
            mobileNumber?: string | null;
          };
        } | null;
      }>;
    }> | null;
  };
};

export type GetEventsByUserQueryVariables = Exact<{
  input: UserIdInput;
}>;

export type GetEventsByUserQuery = {
  __typename?: 'Query';
  eventsByUser: {
    __typename?: 'UsersEventResponse';
    events?: Array<{
      __typename?: 'Event';
      id: string;
      createdDateTime?: number | null;
      title: string;
      description: string;
      type?: string | null;
      date: number;
      startTime: number;
      endDate: number;
      endTime: number;
      rsvpDate: number;
      checkInTime: number;
      cost?: number | null;
      isHostCheckedIn: boolean;
      pledgeAmount: number;
      entryCode?: string | null;
      isCancelled: boolean;
      eventOrganizer: {
        __typename?: 'User';
        id: string;
        givenName: string;
        surname: string;
        email: string;
        profilePhoto?: string | null;
        reliability?: number | null;
        createdDate?: number | null;
        mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
      };
      location: {
        __typename?: 'Location';
        id: string;
        latitude: string;
        longitude: string;
        name: string;
        address?: string | null;
        timezoneID?: string | null;
        suburb?: string | null;
      };
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        description?: string | null;
        iconLogo: string;
        logo?: string | null;
        backgroundImage?: string | null;
        website?: string | null;
        eventsLink?: string | null;
        subscribeLink?: string | null;
        totalDonated?: number | null;
        disabled: boolean;
        totalEvents?: number | null;
        addrLineOne: string;
        addrLineTwo: string;
        abn: string;
        priority?: number | null;
        city: string;
        state: string;
        postcode: string;
        country: string;
        contactName: string;
        phoneNumber: string;
        signatureImage?: string | null;
        charityNumber?: string | null;
        currency: string;
        officialName?: string | null;
        assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
        assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
        assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      };
      guests: Array<{
        __typename?: 'Guest';
        id: string;
        rsvpd?: boolean | null;
        checkinExemption?: number | null;
        checkedIn: boolean;
        entryCode?: string | null;
        userID?: string | null;
        mobileNumber?: string | null;
        email?: string | null;
        source?: string | null;
        name?: string | null;
        isCancelled: boolean;
        user?: {
          __typename?: 'User';
          id: string;
          givenName: string;
          surname: string;
          email: string;
          profilePhoto?: string | null;
          reliability?: number | null;
          createdDate?: number | null;
          mobileInformation: {
            __typename?: 'MobileInformation';
            areaCode?: string | null;
            mobileNumber?: string | null;
          };
        } | null;
      }>;
    }> | null;
  };
};

export type GetAllPaymentsQueryVariables = Exact<{
  input: AllPaymentsInput;
  adminID: Scalars['ID'];
}>;

export type GetAllPaymentsQuery = {
  __typename?: 'Query';
  allPayments: {
    __typename?: 'PaymentsResponse';
    payments: Array<{
      __typename?: 'Payment';
      id: string;
      stripeChargeID?: string | null;
      receiptNo?: string | null;
      type: string;
      currency?: string | null;
      amount: number;
      status?: string | null;
      lastTried?: number | null;
      eventID?: string | null;
      cardTransactionFee?: number | null;
      userPaymentDetails?: {
        __typename?: 'PaymentDetails';
        id: string;
        user: {
          __typename?: 'User';
          id: string;
          givenName: string;
          surname: string;
          email: string;
          profilePhoto?: string | null;
          reliability?: number | null;
          createdDate?: number | null;
          mobileInformation: {
            __typename?: 'MobileInformation';
            areaCode?: string | null;
            mobileNumber?: string | null;
          };
        };
      } | null;
      organizationPaymentDetails: {
        __typename?: 'OrganizationPaymentDetail';
        id: string;
        organization: {
          __typename?: 'Organization';
          id: string;
          name: string;
          description?: string | null;
          iconLogo: string;
          logo?: string | null;
          backgroundImage?: string | null;
          website?: string | null;
          eventsLink?: string | null;
          subscribeLink?: string | null;
          totalDonated?: number | null;
          disabled: boolean;
          totalEvents?: number | null;
          addrLineOne: string;
          addrLineTwo: string;
          abn: string;
          priority?: number | null;
          city: string;
          state: string;
          postcode: string;
          country: string;
          contactName: string;
          phoneNumber: string;
          signatureImage?: string | null;
          charityNumber?: string | null;
          currency: string;
          officialName?: string | null;
          assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
          assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
          assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
        };
      };
      event?: {
        __typename?: 'Event';
        id: string;
        createdDateTime?: number | null;
        title: string;
        description: string;
        type?: string | null;
        date: number;
        startTime: number;
        endDate: number;
        endTime: number;
        rsvpDate: number;
        checkInTime: number;
        cost?: number | null;
        isHostCheckedIn: boolean;
        pledgeAmount: number;
        entryCode?: string | null;
        isCancelled: boolean;
        eventOrganizer: {
          __typename?: 'User';
          id: string;
          givenName: string;
          surname: string;
          email: string;
          profilePhoto?: string | null;
          reliability?: number | null;
          createdDate?: number | null;
          mobileInformation: {
            __typename?: 'MobileInformation';
            areaCode?: string | null;
            mobileNumber?: string | null;
          };
        };
        location: {
          __typename?: 'Location';
          id: string;
          latitude: string;
          longitude: string;
          name: string;
          address?: string | null;
          timezoneID?: string | null;
          suburb?: string | null;
        };
        organization: {
          __typename?: 'Organization';
          id: string;
          name: string;
          description?: string | null;
          iconLogo: string;
          logo?: string | null;
          backgroundImage?: string | null;
          website?: string | null;
          eventsLink?: string | null;
          subscribeLink?: string | null;
          totalDonated?: number | null;
          disabled: boolean;
          totalEvents?: number | null;
          addrLineOne: string;
          addrLineTwo: string;
          abn: string;
          priority?: number | null;
          city: string;
          state: string;
          postcode: string;
          country: string;
          contactName: string;
          phoneNumber: string;
          signatureImage?: string | null;
          charityNumber?: string | null;
          currency: string;
          officialName?: string | null;
          assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
          assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
          assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
        };
        guests: Array<{
          __typename?: 'Guest';
          id: string;
          rsvpd?: boolean | null;
          checkinExemption?: number | null;
          checkedIn: boolean;
          entryCode?: string | null;
          userID?: string | null;
          mobileNumber?: string | null;
          email?: string | null;
          source?: string | null;
          name?: string | null;
          isCancelled: boolean;
          user?: {
            __typename?: 'User';
            id: string;
            givenName: string;
            surname: string;
            email: string;
            profilePhoto?: string | null;
            reliability?: number | null;
            createdDate?: number | null;
            mobileInformation: {
              __typename?: 'MobileInformation';
              areaCode?: string | null;
              mobileNumber?: string | null;
            };
          } | null;
        }>;
      } | null;
    }>;
  };
};

export type OrganizationDataFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  description?: string | null;
  iconLogo: string;
  logo?: string | null;
  backgroundImage?: string | null;
  website?: string | null;
  eventsLink?: string | null;
  subscribeLink?: string | null;
  totalDonated?: number | null;
  disabled: boolean;
  totalEvents?: number | null;
  addrLineOne: string;
  addrLineTwo: string;
  abn: string;
  priority?: number | null;
  city: string;
  state: string;
  postcode: string;
  country: string;
  contactName: string;
  phoneNumber: string;
  signatureImage?: string | null;
  charityNumber?: string | null;
  currency: string;
  officialName?: string | null;
  assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
  assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
  assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
};

export type OrganizationQueryViewDataFragment = {
  __typename?: 'OrganizationQueryView';
  id: string;
  name: string;
  description?: string | null;
  iconLogo: string;
  logo?: string | null;
  backgroundImage?: string | null;
  website?: string | null;
  eventsLink?: string | null;
  subscribeLink?: string | null;
  totalDonated?: number | null;
  disabled: boolean;
  totalEvents?: number | null;
  addrLineOne: string;
  addrLineTwo: string;
  abn: string;
  priority?: number | null;
  city: string;
  state: string;
  postcode: string;
  country: string;
  contactName: string;
  phoneNumber: string;
  signatureImage?: string | null;
  charityNumber?: string | null;
  currency: string;
  officialName?: string | null;
  paymentDetailsID?: string | null;
  stripeAccountID?: string | null;
  assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
  assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
  assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
};

export type GetOrganizationsIgnoringDisabledQueryVariables = Exact<{
  adminID: Scalars['ID'];
}>;

export type GetOrganizationsIgnoringDisabledQuery = {
  __typename?: 'Query';
  organizationsIgnoringDisabled: {
    __typename?: 'OrganizationsQueryViewResponse';
    organizations?: Array<{
      __typename?: 'OrganizationQueryView';
      id: string;
      name: string;
      description?: string | null;
      iconLogo: string;
      logo?: string | null;
      backgroundImage?: string | null;
      website?: string | null;
      eventsLink?: string | null;
      subscribeLink?: string | null;
      totalDonated?: number | null;
      disabled: boolean;
      totalEvents?: number | null;
      addrLineOne: string;
      addrLineTwo: string;
      abn: string;
      priority?: number | null;
      city: string;
      state: string;
      postcode: string;
      country: string;
      contactName: string;
      phoneNumber: string;
      signatureImage?: string | null;
      charityNumber?: string | null;
      currency: string;
      officialName?: string | null;
      paymentDetailsID?: string | null;
      stripeAccountID?: string | null;
      assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
    }> | null;
  };
};

export type GetOrganizationIgnoringDisabledQueryVariables = Exact<{
  adminID: Scalars['ID'];
  organizationID: Scalars['ID'];
}>;

export type GetOrganizationIgnoringDisabledQuery = {
  __typename?: 'Query';
  organizationIgnoringDisabled: {
    __typename?: 'OrganizationQueryViewResponse';
    organization?: {
      __typename?: 'OrganizationQueryView';
      id: string;
      name: string;
      description?: string | null;
      iconLogo: string;
      logo?: string | null;
      backgroundImage?: string | null;
      website?: string | null;
      eventsLink?: string | null;
      subscribeLink?: string | null;
      totalDonated?: number | null;
      disabled: boolean;
      totalEvents?: number | null;
      addrLineOne: string;
      addrLineTwo: string;
      abn: string;
      priority?: number | null;
      city: string;
      state: string;
      postcode: string;
      country: string;
      contactName: string;
      phoneNumber: string;
      signatureImage?: string | null;
      charityNumber?: string | null;
      currency: string;
      officialName?: string | null;
      paymentDetailsID?: string | null;
      stripeAccountID?: string | null;
      assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
    } | null;
  };
};

export type GetOrganizationStripeLinkQueryVariables = Exact<{
  organizationID: Scalars['String'];
}>;

export type GetOrganizationStripeLinkQuery = {
  __typename?: 'Query';
  stripeLoginLink: { __typename?: 'StripeLoginLinkResponse'; link: string };
};

export type CreateOrganizationMutationVariables = Exact<{
  input: NewOrganizationInput;
}>;

export type CreateOrganizationMutation = {
  __typename?: 'Mutation';
  newOrganization: {
    __typename?: 'OrganizationResponse';
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      description?: string | null;
      iconLogo: string;
      logo?: string | null;
      backgroundImage?: string | null;
      website?: string | null;
      eventsLink?: string | null;
      subscribeLink?: string | null;
      totalDonated?: number | null;
      disabled: boolean;
      totalEvents?: number | null;
      addrLineOne: string;
      addrLineTwo: string;
      abn: string;
      priority?: number | null;
      city: string;
      state: string;
      postcode: string;
      country: string;
      contactName: string;
      phoneNumber: string;
      signatureImage?: string | null;
      charityNumber?: string | null;
      currency: string;
      officialName?: string | null;
      assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
    } | null;
  };
};

export type EditOrganizationMutationVariables = Exact<{
  input: EditOrganizationInput;
}>;

export type EditOrganizationMutation = {
  __typename?: 'Mutation';
  editOrganization?: {
    __typename?: 'OrganizationQueryView';
    id: string;
    name: string;
    description?: string | null;
    iconLogo: string;
    logo?: string | null;
    backgroundImage?: string | null;
    website?: string | null;
    eventsLink?: string | null;
    subscribeLink?: string | null;
    totalDonated?: number | null;
    disabled: boolean;
    totalEvents?: number | null;
    addrLineOne: string;
    addrLineTwo: string;
    abn: string;
    priority?: number | null;
    city: string;
    state: string;
    postcode: string;
    country: string;
    contactName: string;
    phoneNumber: string;
    signatureImage?: string | null;
    charityNumber?: string | null;
    currency: string;
    officialName?: string | null;
    paymentDetailsID?: string | null;
    stripeAccountID?: string | null;
    assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
    assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
    assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
  } | null;
};

export type PaymentDataFragment = {
  __typename?: 'Payment';
  id: string;
  stripeChargeID?: string | null;
  receiptNo?: string | null;
  type: string;
  currency?: string | null;
  amount: number;
  status?: string | null;
  lastTried?: number | null;
  eventID?: string | null;
  cardTransactionFee?: number | null;
  userPaymentDetails?: {
    __typename?: 'PaymentDetails';
    id: string;
    user: {
      __typename?: 'User';
      id: string;
      givenName: string;
      surname: string;
      email: string;
      profilePhoto?: string | null;
      reliability?: number | null;
      createdDate?: number | null;
      mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
    };
  } | null;
  organizationPaymentDetails: {
    __typename?: 'OrganizationPaymentDetail';
    id: string;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      description?: string | null;
      iconLogo: string;
      logo?: string | null;
      backgroundImage?: string | null;
      website?: string | null;
      eventsLink?: string | null;
      subscribeLink?: string | null;
      totalDonated?: number | null;
      disabled: boolean;
      totalEvents?: number | null;
      addrLineOne: string;
      addrLineTwo: string;
      abn: string;
      priority?: number | null;
      city: string;
      state: string;
      postcode: string;
      country: string;
      contactName: string;
      phoneNumber: string;
      signatureImage?: string | null;
      charityNumber?: string | null;
      currency: string;
      officialName?: string | null;
      assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
    };
  };
  event?: {
    __typename?: 'Event';
    id: string;
    createdDateTime?: number | null;
    title: string;
    description: string;
    type?: string | null;
    date: number;
    startTime: number;
    endDate: number;
    endTime: number;
    rsvpDate: number;
    checkInTime: number;
    cost?: number | null;
    isHostCheckedIn: boolean;
    pledgeAmount: number;
    entryCode?: string | null;
    isCancelled: boolean;
    eventOrganizer: {
      __typename?: 'User';
      id: string;
      givenName: string;
      surname: string;
      email: string;
      profilePhoto?: string | null;
      reliability?: number | null;
      createdDate?: number | null;
      mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
    };
    location: {
      __typename?: 'Location';
      id: string;
      latitude: string;
      longitude: string;
      name: string;
      address?: string | null;
      timezoneID?: string | null;
      suburb?: string | null;
    };
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      description?: string | null;
      iconLogo: string;
      logo?: string | null;
      backgroundImage?: string | null;
      website?: string | null;
      eventsLink?: string | null;
      subscribeLink?: string | null;
      totalDonated?: number | null;
      disabled: boolean;
      totalEvents?: number | null;
      addrLineOne: string;
      addrLineTwo: string;
      abn: string;
      priority?: number | null;
      city: string;
      state: string;
      postcode: string;
      country: string;
      contactName: string;
      phoneNumber: string;
      signatureImage?: string | null;
      charityNumber?: string | null;
      currency: string;
      officialName?: string | null;
      assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
    };
    guests: Array<{
      __typename?: 'Guest';
      id: string;
      rsvpd?: boolean | null;
      checkinExemption?: number | null;
      checkedIn: boolean;
      entryCode?: string | null;
      userID?: string | null;
      mobileNumber?: string | null;
      email?: string | null;
      source?: string | null;
      name?: string | null;
      isCancelled: boolean;
      user?: {
        __typename?: 'User';
        id: string;
        givenName: string;
        surname: string;
        email: string;
        profilePhoto?: string | null;
        reliability?: number | null;
        createdDate?: number | null;
        mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
      } | null;
    }>;
  } | null;
};

export type DonationHistoryPaymentDataFragment = {
  __typename?: 'DonationHistoryPayment';
  id: string;
  type: string;
  amount: number;
  status?: string | null;
  lastTried?: number | null;
  eventID?: string | null;
  organizationPaymentDetails: {
    __typename?: 'OrganizationPaymentDetail';
    id: string;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      description?: string | null;
      iconLogo: string;
      logo?: string | null;
      backgroundImage?: string | null;
      website?: string | null;
      eventsLink?: string | null;
      subscribeLink?: string | null;
      totalDonated?: number | null;
      disabled: boolean;
      totalEvents?: number | null;
      addrLineOne: string;
      addrLineTwo: string;
      abn: string;
      priority?: number | null;
      city: string;
      state: string;
      postcode: string;
      country: string;
      contactName: string;
      phoneNumber: string;
      signatureImage?: string | null;
      charityNumber?: string | null;
      currency: string;
      officialName?: string | null;
      assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
      assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
    };
  };
};

export type GetPaymentHistoryQueryVariables = Exact<{
  input: UserIdInput;
}>;

export type GetPaymentHistoryQuery = {
  __typename?: 'Query';
  donationHistory: {
    __typename?: 'DonationHistoryResponse';
    totalDonated: number;
    payments: Array<{
      __typename?: 'DonationHistoryPayment';
      id: string;
      type: string;
      amount: number;
      status?: string | null;
      lastTried?: number | null;
      eventID?: string | null;
      organizationPaymentDetails: {
        __typename?: 'OrganizationPaymentDetail';
        id: string;
        organization: {
          __typename?: 'Organization';
          id: string;
          name: string;
          description?: string | null;
          iconLogo: string;
          logo?: string | null;
          backgroundImage?: string | null;
          website?: string | null;
          eventsLink?: string | null;
          subscribeLink?: string | null;
          totalDonated?: number | null;
          disabled: boolean;
          totalEvents?: number | null;
          addrLineOne: string;
          addrLineTwo: string;
          abn: string;
          priority?: number | null;
          city: string;
          state: string;
          postcode: string;
          country: string;
          contactName: string;
          phoneNumber: string;
          signatureImage?: string | null;
          charityNumber?: string | null;
          currency: string;
          officialName?: string | null;
          assetPairOne?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
          assetPairTwo?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
          assetPairThree?: { __typename?: 'Asset'; website: string; title: string; image: string } | null;
        };
      };
    }>;
  };
};

export type ReportedDataFragment = {
  __typename?: 'Reported';
  id: string;
  reportedReason?: ReportedReason | null;
  content?: string | null;
  dateCreated: number;
  isResolved: boolean;
  reporter: {
    __typename?: 'User';
    id: string;
    givenName: string;
    surname: string;
    email: string;
    profilePhoto?: string | null;
    reliability?: number | null;
    createdDate?: number | null;
    mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
  };
  reported: {
    __typename?: 'User';
    id: string;
    givenName: string;
    surname: string;
    email: string;
    profilePhoto?: string | null;
    reliability?: number | null;
    createdDate?: number | null;
    mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
  };
};

export type GetAllReportedContentQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllReportedContentQuery = {
  __typename?: 'Query';
  allReportedContent: {
    __typename?: 'ReportedContentResponse';
    reportedContents?: Array<{
      __typename?: 'Reported';
      id: string;
      reportedReason?: ReportedReason | null;
      content?: string | null;
      dateCreated: number;
      isResolved: boolean;
      reporter: {
        __typename?: 'User';
        id: string;
        givenName: string;
        surname: string;
        email: string;
        profilePhoto?: string | null;
        reliability?: number | null;
        createdDate?: number | null;
        mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
      };
      reported: {
        __typename?: 'User';
        id: string;
        givenName: string;
        surname: string;
        email: string;
        profilePhoto?: string | null;
        reliability?: number | null;
        createdDate?: number | null;
        mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
      };
    }> | null;
  };
};

export type MarkAsResolvedMutationVariables = Exact<{
  input: MarkResolvedInput;
}>;

export type MarkAsResolvedMutation = {
  __typename?: 'Mutation';
  markAsResolved: {
    __typename?: 'ReportedContentResponse';
    reportedContent?: {
      __typename?: 'Reported';
      id: string;
      reportedReason?: ReportedReason | null;
      content?: string | null;
      dateCreated: number;
      isResolved: boolean;
      reporter: {
        __typename?: 'User';
        id: string;
        givenName: string;
        surname: string;
        email: string;
        profilePhoto?: string | null;
        reliability?: number | null;
        createdDate?: number | null;
        mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
      };
      reported: {
        __typename?: 'User';
        id: string;
        givenName: string;
        surname: string;
        email: string;
        profilePhoto?: string | null;
        reliability?: number | null;
        createdDate?: number | null;
        mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
      };
    } | null;
  };
};

export type SendEmailReplyMutationVariables = Exact<{
  input: EmailReplyInput;
}>;

export type SendEmailReplyMutation = {
  __typename?: 'Mutation';
  sendEmailReply: { __typename?: 'ReportedContentResponse'; success: boolean };
};

export type GetTopEventTypesQueryVariables = Exact<{
  fromDate: Scalars['Float'];
}>;

export type GetTopEventTypesQuery = {
  __typename?: 'Query';
  topEventTypes: {
    __typename?: 'TopEventsResponse';
    types: Array<{ __typename?: 'TopEventsCount'; type: string; count: number }>;
  };
};

export type GetTopDonationTypesQueryVariables = Exact<{
  fromDate: Scalars['Float'];
}>;

export type GetTopDonationTypesQuery = {
  __typename?: 'Query';
  topDonationTypes: {
    __typename?: 'TopDonationsResponse';
    donationTypes: Array<{ __typename?: 'TopDonationsCount'; type: string; count: number }>;
  };
};

export type GetTopCharitiesByDonationQueryVariables = Exact<{
  fromDate: Scalars['Float'];
}>;

export type GetTopCharitiesByDonationQuery = {
  __typename?: 'Query';
  topCharitiesByDonationAmount: {
    __typename?: 'TopOrgDonationResponse';
    orgsTotalDonated?: Array<{ __typename?: 'TopOrgDonationCount'; name: string; donated: number }> | null;
  };
};

export type GetDonateAnywayFrequenciesQueryVariables = Exact<{
  fromDate: Scalars['Float'];
}>;

export type GetDonateAnywayFrequenciesQuery = {
  __typename?: 'Query';
  donateAnywayFrequencies: { __typename?: 'DonateAnywayFreqResponse'; keptPledge: number; donatedAnyway: number };
};

export type GetTopLocationsByStateQueryVariables = Exact<{
  fromDate: Scalars['Float'];
}>;

export type GetTopLocationsByStateQuery = {
  __typename?: 'Query';
  topLocationsByState: {
    __typename?: 'TopLocationStateResponse';
    locations?: Array<{ __typename?: 'TopLocationState'; state: string; count: number }> | null;
  };
};

export type GetTopLocationsBySuburbQueryVariables = Exact<{
  fromDate: Scalars['Float'];
}>;

export type GetTopLocationsBySuburbQuery = {
  __typename?: 'Query';
  topLocationsBySuburb: {
    __typename?: 'TopLocationSuburbResponse';
    locations?: Array<{ __typename?: 'TopLocationSuburb'; suburb: string; count: number }> | null;
  };
};

export type GetDonationsByMonthQueryVariables = Exact<{ [key: string]: never }>;

export type GetDonationsByMonthQuery = {
  __typename?: 'Query';
  donationsByMonth: {
    __typename?: 'DonationsByMonthResponse';
    donationsByMonth: Array<{ __typename?: 'MonthlyDonations'; Month: string; Amount: string }>;
  };
};

export type GetDonationsByAgeQueryVariables = Exact<{
  fromDate: Scalars['Float'];
}>;

export type GetDonationsByAgeQuery = {
  __typename?: 'Query';
  donationsByAge: {
    __typename?: 'DonationsByAgeResponse';
    donationsByAgeBracket?: Array<{
      __typename?: 'AgeBracketDonation';
      AgeBracket: string;
      TotalDonated: number;
    }> | null;
  };
};

export type UserDataFragment = {
  __typename?: 'User';
  id: string;
  givenName: string;
  surname: string;
  email: string;
  profilePhoto?: string | null;
  reliability?: number | null;
  createdDate?: number | null;
  mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
};

export type PaginatedUserDataFragment = {
  __typename?: 'PaginatedUser';
  id: string;
  givenName: string;
  surname: string;
  email: string;
  profilePhoto?: string | null;
  reliability: number;
  mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
};

export type GetPaginatedUsersQueryVariables = Exact<{
  adminID: Scalars['String'];
  pageNo: Scalars['Int'];
  pageSize: Scalars['Int'];
  filter?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
}>;

export type GetPaginatedUsersQuery = {
  __typename?: 'Query';
  paginatedUsers: {
    __typename?: 'PaginatedUsersResponse';
    totalPages: number;
    users: Array<{
      __typename?: 'PaginatedUser';
      userTotalDonated: number;
      totalEvents: number;
      eventsUserHasBeenAGuestOf: number;
      eventsUserHasCreated: number;
      createdDate?: number | null;
      id: string;
      givenName: string;
      surname: string;
      email: string;
      profilePhoto?: string | null;
      reliability: number;
      mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
    }>;
  };
};

export type DownloadUsersQueryVariables = Exact<{
  adminID: Scalars['ID'];
}>;

export type DownloadUsersQuery = {
  __typename?: 'Query';
  userDownloadHubspot: Array<{
    __typename?: 'UserDownloadForHubspot';
    givenName?: string | null;
    surname?: string | null;
    email?: string | null;
    dateOfBirth?: string | null;
    marketingEmails: boolean;
    mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
  }>;
};

export type GetUserQueryVariables = Exact<{
  input: UserForAdminInput;
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  userForAdmin: {
    __typename?: 'UserForAdminResponse';
    user?: {
      __typename?: 'PaginatedUser';
      userTotalDonated: number;
      totalEvents: number;
      eventsUserHasBeenAGuestOf: number;
      eventsUserHasCreated: number;
      id: string;
      givenName: string;
      surname: string;
      email: string;
      profilePhoto?: string | null;
      reliability: number;
      mobileInformation: { __typename?: 'MobileInformation'; areaCode?: string | null; mobileNumber?: string | null };
    } | null;
  };
};

export const AdminDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Admin' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePhoto' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminDataFragment, unknown>;
export const OrganizationQueryViewDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationQueryViewData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationQueryView' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconLogo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundImage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assetPairOne' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assetPairTwo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assetPairThree' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventsLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscribeLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalDonated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addrLineOne' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addrLineTwo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signatureImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'charityNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officialName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentDetailsID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeAccountID' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationQueryViewDataFragment, unknown>;
export const UserDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'givenName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mobileInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'areaCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mobileNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePhoto' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reliability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserDataFragment, unknown>;
export const OrganizationDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconLogo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundImage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assetPairOne' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assetPairTwo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assetPairThree' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventsLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscribeLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalDonated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalEvents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addrLineOne' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addrLineTwo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signatureImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'charityNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officialName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationDataFragment, unknown>;
export const LocationDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LocationData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Location' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezoneID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suburb' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocationDataFragment, unknown>;
export const GuestDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GuestData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Guest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserData' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rsvpd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkinExemption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkedIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobileNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCancelled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GuestDataFragment, unknown>;
export const EventDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EventData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Event' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventOrganizer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserData' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LocationData' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rsvpDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkInTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHostCheckedIn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationData' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pledgeAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCancelled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'guests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GuestData' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventDataFragment, unknown>;
export const PaymentDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Payment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPaymentDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserData' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationPaymentDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationData' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeChargeID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiptNo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTried' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardTransactionFee' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'event' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EventData' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaymentDataFragment, unknown>;
export const DonationHistoryPaymentDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DonationHistoryPaymentData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DonationHistoryPayment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationPaymentDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationData' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTried' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventID' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DonationHistoryPaymentDataFragment, unknown>;
export const ReportedDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportedData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Reported' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportedReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reporter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserData' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reported' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserData' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dateCreated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isResolved' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportedDataFragment, unknown>;
export const PaginatedUserDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaginatedUserData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginatedUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'givenName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mobileInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'areaCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mobileNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePhoto' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reliability' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaginatedUserDataFragment, unknown>;
export const GetAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'adminIDInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'admin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'admin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdminData' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AdminDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAdminQuery, GetAdminQueryVariables>;
export const GetAdminsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAdmins' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'adminIDInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allAdmins' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'admins' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdminData' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AdminDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAdminsQuery, GetAdminsQueryVariables>;
export const CreateAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdminInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newAdmin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'admin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdminData' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AdminDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateAdminMutation, CreateAdminMutationVariables>;
export const EditAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdminInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editAdminProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'admin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdminData' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
    ...AdminDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EditAdminMutation, EditAdminMutationVariables>;
export const GetTotalUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTotalUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countTotalUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalUsers' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTotalUsersQuery, GetTotalUsersQueryVariables>;
export const GetTotalEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTotalEvents' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countTotalEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalEvents' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTotalEventsQuery, GetTotalEventsQueryVariables>;
export const GetTotalDonationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTotalDonations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalDonatedSmyleeWide' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'smyleeWideDonations' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTotalDonationsQuery, GetTotalDonationsQueryVariables>;
export const GetTotalSmyleeRevenueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTotalSmyleeRevenue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'smyleeRevenue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'adminID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'smyleeRevenue' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTotalSmyleeRevenueQuery, GetTotalSmyleeRevenueQueryVariables>;
export const GetDonationsThisWeekDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDonationsThisWeek' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'donationsThisWeek' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'donationsMadeThisWeek' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lastTried' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDonationsThisWeekQuery, GetDonationsThisWeekQueryVariables>;
export const GetUsersThisWeekDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUsersThisWeek' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersThisWeek' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'usersCreatedThisWeekDates' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUsersThisWeekQuery, GetUsersThisWeekQueryVariables>;
export const GetEventsCreatedFromDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEventsCreatedFromDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventsCreatedFromDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'eventsDates' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEventsCreatedFromDateQuery, GetEventsCreatedFromDateQueryVariables>;
export const GetEventsHeldFromDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEventsHeldFromDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventsHeldFromDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'eventsDates' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEventsHeldFromDateQuery, GetEventsHeldFromDateQueryVariables>;
export const GetEventsCancelledFromDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEventsCancelledFromDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventsCancelledFromDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'eventsDates' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEventsCancelledFromDateQuery, GetEventsCancelledFromDateQueryVariables>;
export const GetEventsByOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEventsByOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventsByOrganizationID' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'adminID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EventData' } }],
            },
          },
        ],
      },
    },
    ...EventDataFragmentDoc.definitions,
    ...UserDataFragmentDoc.definitions,
    ...LocationDataFragmentDoc.definitions,
    ...OrganizationDataFragmentDoc.definitions,
    ...GuestDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetEventsByOrganizationQuery, GetEventsByOrganizationQueryVariables>;
export const GetPaginatedEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaginatedEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageNo' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tableFilter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortField' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortOrder' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortOrder' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paginatedEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'adminID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageNo' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageNo' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tableFilter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tableFilter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortField' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortField' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortOrder' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortOrder' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'events' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EventData' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
              ],
            },
          },
        ],
      },
    },
    ...EventDataFragmentDoc.definitions,
    ...UserDataFragmentDoc.definitions,
    ...LocationDataFragmentDoc.definitions,
    ...OrganizationDataFragmentDoc.definitions,
    ...GuestDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPaginatedEventsQuery, GetPaginatedEventsQueryVariables>;
export const EventsForAdminBaseTableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EventsForAdminBaseTable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventsForAdminBaseTable' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'adminID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'events' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EventData' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...EventDataFragmentDoc.definitions,
    ...UserDataFragmentDoc.definitions,
    ...LocationDataFragmentDoc.definitions,
    ...OrganizationDataFragmentDoc.definitions,
    ...GuestDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EventsForAdminBaseTableQuery, EventsForAdminBaseTableQueryVariables>;
export const GetEventsByUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEventsByUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'userIDInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventsByUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'events' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EventData' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...EventDataFragmentDoc.definitions,
    ...UserDataFragmentDoc.definitions,
    ...LocationDataFragmentDoc.definitions,
    ...OrganizationDataFragmentDoc.definitions,
    ...GuestDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetEventsByUserQuery, GetEventsByUserQueryVariables>;
export const GetAllPaymentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllPayments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AllPaymentsInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPayments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'adminID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentData' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PaymentDataFragmentDoc.definitions,
    ...UserDataFragmentDoc.definitions,
    ...OrganizationDataFragmentDoc.definitions,
    ...EventDataFragmentDoc.definitions,
    ...LocationDataFragmentDoc.definitions,
    ...GuestDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAllPaymentsQuery, GetAllPaymentsQueryVariables>;
export const GetOrganizationsIgnoringDisabledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrganizationsIgnoringDisabled' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationsIgnoringDisabled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'adminID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationQueryViewData' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...OrganizationQueryViewDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetOrganizationsIgnoringDisabledQuery, GetOrganizationsIgnoringDisabledQueryVariables>;
export const GetOrganizationIgnoringDisabledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrganizationIgnoringDisabled' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationIgnoringDisabled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'adminID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationQueryViewData' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...OrganizationQueryViewDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetOrganizationIgnoringDisabledQuery, GetOrganizationIgnoringDisabledQueryVariables>;
export const GetOrganizationStripeLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrganizationStripeLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeLoginLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'link' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationStripeLinkQuery, GetOrganizationStripeLinkQueryVariables>;
export const CreateOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'newOrganizationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationData' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...OrganizationDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const EditOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'editOrganizationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationQueryViewData' } }],
            },
          },
        ],
      },
    },
    ...OrganizationQueryViewDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EditOrganizationMutation, EditOrganizationMutationVariables>;
export const GetPaymentHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaymentHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'userIDInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'donationHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalDonated' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DonationHistoryPaymentData' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DonationHistoryPaymentDataFragmentDoc.definitions,
    ...OrganizationDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPaymentHistoryQuery, GetPaymentHistoryQueryVariables>;
export const GetAllReportedContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllReportedContent' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allReportedContent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportedContents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportedData' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ReportedDataFragmentDoc.definitions,
    ...UserDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAllReportedContentQuery, GetAllReportedContentQueryVariables>;
export const MarkAsResolvedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markAsResolved' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarkResolvedInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markAsResolved' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportedContent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportedData' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ReportedDataFragmentDoc.definitions,
    ...UserDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<MarkAsResolvedMutation, MarkAsResolvedMutationVariables>;
export const SendEmailReplyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendEmailReply' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EmailReplyInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendEmailReply' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendEmailReplyMutation, SendEmailReplyMutationVariables>;
export const GetTopEventTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopEventTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topEventTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'types' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTopEventTypesQuery, GetTopEventTypesQueryVariables>;
export const GetTopDonationTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopDonationTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topDonationTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'donationTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTopDonationTypesQuery, GetTopDonationTypesQueryVariables>;
export const GetTopCharitiesByDonationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopCharitiesByDonation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topCharitiesByDonationAmount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orgsTotalDonated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'donated' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTopCharitiesByDonationQuery, GetTopCharitiesByDonationQueryVariables>;
export const GetDonateAnywayFrequenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDonateAnywayFrequencies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'donateAnywayFrequencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'keptPledge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'donatedAnyway' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDonateAnywayFrequenciesQuery, GetDonateAnywayFrequenciesQueryVariables>;
export const GetTopLocationsByStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopLocationsByState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topLocationsByState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'locations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTopLocationsByStateQuery, GetTopLocationsByStateQueryVariables>;
export const GetTopLocationsBySuburbDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopLocationsBySuburb' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topLocationsBySuburb' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'locations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'suburb' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTopLocationsBySuburbQuery, GetTopLocationsBySuburbQueryVariables>;
export const GetDonationsByMonthDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDonationsByMonth' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'donationsByMonth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'donationsByMonth' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'Month' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'Amount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDonationsByMonthQuery, GetDonationsByMonthQueryVariables>;
export const GetDonationsByAgeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDonationsByAge' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'donationsByAge' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'donationsByAgeBracket' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'AgeBracket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'TotalDonated' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDonationsByAgeQuery, GetDonationsByAgeQueryVariables>;
export const GetPaginatedUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaginatedUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageNo' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchQuery' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortField' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortOrder' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortOrder' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paginatedUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'adminID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageNo' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageNo' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchQuery' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchQuery' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortField' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortField' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortOrder' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortOrder' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaginatedUserData' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userTotalDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalEvents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsUserHasBeenAGuestOf' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsUserHasCreated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
              ],
            },
          },
        ],
      },
    },
    ...PaginatedUserDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPaginatedUsersQuery, GetPaginatedUsersQueryVariables>;
export const DownloadUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'downloadUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userDownloadHubspot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'adminID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'adminID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'givenName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mobileInformation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'areaCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mobileNumber' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'marketingEmails' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DownloadUsersQuery, DownloadUsersQueryVariables>;
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserForAdminInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userForAdmin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaginatedUserData' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userTotalDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalEvents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsUserHasBeenAGuestOf' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsUserHasCreated' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PaginatedUserDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
