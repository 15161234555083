import React from 'react';

import { PageLayout } from '../../../components';
import {
  DashboardContainer,
  DonationAmountContainer,
  DonationByStateContainer,
  DonationFrequencyContainer,
  DonationTypesContainer,
  EventByAgeContainer,
  EventSuburbsContainer,
  EventTypesContainer,
  TopCharitiesContainer,
} from './Styles';
import TopEventTypes from './TopEventTypes';
import TopDonationTypes from './TopDonationTypes';
import TopCharities from './TopCharities';
import DonationFrequency from './DonationFrequency';
import TopLocationsState from './TopLocationsState';
import TopLocationsSuburb from './TopLocationSuburb';
import DonationAmountMonthly from './DonationAmountMonthly';
import DonationsByAge from './DonationsByAge';

import useStrings from '../../../hooks/useStrings';

const Statistics: React.FC = () => {
  const [{ Statistics: strings }] = useStrings();
  return (
    <PageLayout loading={false} pageTitle={strings.pageTitle}>
      <DashboardContainer>
        <EventTypesContainer>
          <TopEventTypes />
        </EventTypesContainer>
        <DonationTypesContainer>
          <TopDonationTypes />
        </DonationTypesContainer>
        <EventSuburbsContainer>
          <TopLocationsSuburb />
        </EventSuburbsContainer>
        <DonationAmountContainer>
          <DonationAmountMonthly />
        </DonationAmountContainer>
        <DonationFrequencyContainer>
          <DonationFrequency />
        </DonationFrequencyContainer>
        <DonationByStateContainer>
          <TopLocationsState />
        </DonationByStateContainer>
        <TopCharitiesContainer>
          <TopCharities />
        </TopCharitiesContainer>
        <EventByAgeContainer>
          <DonationsByAge />
        </EventByAgeContainer>
      </DashboardContainer>
    </PageLayout>
  );
};

export default Statistics;
