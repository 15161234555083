import styled from 'styled-components/macro';

export const Header = styled.div`
  grid-area: header;
  background-color: ${({ theme }) => theme.palette.white};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: ${({ theme }) => theme.borderStyles.tertiary};
`;

export const PageName = styled.div`
  padding: 0.4rem 0 0.5rem 1rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: calc(${({ theme }) => theme.fontScale.L}rem * 1.1);
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.palette.title};
`;

export const InfoText = styled.p`
  margin-left: 10px;
`;

export const ProfileWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: auto;
  margin-right: 1em;
`;

export const ProfileImage = styled.img`
  height: 40px;
  width: 40px;
  margin-left: 20px;
  border-radius: 100%;
`;
