/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import {
  HeadingContainer, StatsGraphHeading, NoResultsHeading, LoadingSpinner,
} from '../Styles';
import DateFilterDropdown from '../DateFilterDropdown';
import RadialGraph from '../RadialGraph';

import useStrings from '../../../../hooks/useStrings';
import { useGetDonateAnywayFrequencies } from '../../../../lib/graphql/queries';
import { fromDate } from '../../../../lib/Utils';

const DonationFrequency: React.FC<DonationFrequencyProps> = () => {
  const [{ Statistics: strings }] = useStrings();

  const [showDateFilter, setShowDateFilter] = useState(false);
  const [filterSelected, setFilterSelected] = useState<'week' | 'month' | 'year'>('week');

  const { data: donationFreqData, loading } = useGetDonateAnywayFrequencies({
    variables: { fromDate: fromDate(filterSelected) },
  });
  const { donatedAnyway = 0, keptPledge = 0 } = donationFreqData?.donateAnywayFrequencies || { };
  const data = [
    {
      name: 'donatedAnyway', value: donatedAnyway, fill: '#FD7B62', background: '#FCF0EE',
    },
    {
      name: 'keptPledge', value: (keptPledge + donatedAnyway), fill: '#FFFFFF', background: '#FFFFFF',
    },
  ];

  const percentage = Math.floor((donatedAnyway / (donatedAnyway + keptPledge)) * 100);

  return (
    <>
      <HeadingContainer>
        <StatsGraphHeading>{strings.donationFreq}</StatsGraphHeading>
        <DateFilterDropdown
          onToggleDropdown={() => setShowDateFilter(!showDateFilter)}
          isDropdownShown={showDateFilter}
          onClickFilter={setFilterSelected}
          filterSelected={filterSelected}
        />
      </HeadingContainer>
      {loading
        ? <LoadingSpinner />
        : (donatedAnyway === 0 && keptPledge === 0)
          ? <NoResultsHeading>{strings.noResults}</NoResultsHeading>
          : <RadialGraph data={data} percentage={donatedAnyway === 0 ? 0 : percentage} />}
    </>
  );
};

interface DonationFrequencyProps { }

export default DonationFrequency;
