import React, { ReactNode, useEffect } from 'react';
import {
  Column, SortingRule, usePagination, useTable, useSortBy,
} from 'react-table';

import {
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  PaginationWrap,
  PaginationButton,
  PaginationSeparator,
  Sort,
  SortImg,
} from './Styles';
import { SortArrowDown, SortArrowUp } from '../../assets/Icons';

export type sortByType = SortingRule<Record<string, unknown>>[];

const Table: React.FC<TableProps> = ({
  columns,
  data,
  paginated = false,
  pageSize = 10,
  fetchData = () => { },
  initalSortBy = [],
  pageCount = 1,
  manualPagination = false,
  manualSortBy = false,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount: PageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0, pageSize, sortBy: initalSortBy,
      },
      pageCount,
      manualPagination,
      manualSortBy,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetFilters: false,
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy });
  }, [fetchData, pageIndex, pageSize, sortBy]);

  const renderMiddlePagination = (): ReactNode => {
    if (PageCount === 3 && pageIndex !== 1) {
      return (
        <PaginationButton type="button" onClick={() => gotoPage(1)}>
          2
        </PaginationButton>
      );
    }

    const LeftSide = pageIndex > 1 && pageIndex + 1 < PageCount && (
      <>
        {pageIndex > 2 && (
          <PaginationButton type="button" onClick={() => gotoPage(pageIndex - 2)}>
            {pageIndex - 1}
          </PaginationButton>
        )}
        <PaginationButton type="button" onClick={() => gotoPage(pageIndex - 1)}>
          {pageIndex}
        </PaginationButton>
      </>
    );
    const Middle = pageIndex > 0 && pageIndex + 1 < PageCount ? (
      <PaginationButton active type="button">
        {pageIndex + 1}
      </PaginationButton>
    ) : null;
    const RightSide = pageIndex > 0 && pageIndex + 2 < PageCount && (
      <>
        <PaginationButton type="button" onClick={() => gotoPage(pageIndex + 1)}>
          {pageIndex + 2}
        </PaginationButton>
        {pageIndex + 3 < PageCount && (
          <PaginationButton type="button" onClick={() => gotoPage(pageIndex + 2)}>
            {pageIndex + 3}
          </PaginationButton>
        )}
      </>
    );
    return (
      <>
        {pageIndex > 3 && <PaginationSeparator>&hellip;</PaginationSeparator>}
        {LeftSide}
        {Middle}
        {RightSide}
        {pageIndex + 3 < PageCount && <PaginationSeparator>&hellip;</PaginationSeparator>}
      </>
    );
  };

  const SortAsc = <SortImg src={SortArrowDown} />;
  const SortDesc = <SortImg src={SortArrowUp} />;
  const SortNil = <Sort />;

  return (
    <>
      <TableContainer {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeaderCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {column.isSorted ? (column.isSortedDesc ? SortAsc : SortDesc) : SortNil}
                </TableHeaderCell>
              ))}
            </TableHeaderRow>
          ))}
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
      <PaginationWrap paginated={paginated}>
        <PaginationButton type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </PaginationButton>
        <PaginationButton active={pageIndex === 0} type="button" onClick={() => gotoPage(0)}>
          1
        </PaginationButton>
        {PageCount > 1 && (
          <>
            {renderMiddlePagination()}
            <PaginationButton
              active={pageIndex === PageCount - 1}
              type="button"
              onClick={() => gotoPage(PageCount - 1)}
            >
              {PageCount}
            </PaginationButton>
          </>
        )}
        <PaginationButton type="button" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </PaginationButton>
      </PaginationWrap>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface TableProps<D extends Record<string, any> = Record<string, any>> {
  columns: Column<D>[];
  data: D[];
  paginated?: boolean;
  pageSize?: number;
  fetchData?: (pageData: { pageIndex: number; pageSize: number; sortBy: sortByType }) => void;
  initalSortBy?: { id: string; desc?: boolean }[];
  pageCount?: number;
  manualPagination?: boolean;
  manualSortBy?: boolean;
}

export default Table;

// eslint-disable-file @typescript-eslint/restrict-plus-operands
