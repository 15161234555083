import styled from 'styled-components/macro';

export const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 2rem 3rem;
  place-items: stretch stretch;
  place-content: center center;
  width: calc(100% - 1rem);
  height: 100%;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledDashboardCard = styled.div`
  width: calc(100% - 3rem);
  height: calc(100% - 3.5rem);
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 0.36rem;
  border: ${({ theme }) => theme.borderStyles.secondary};
  padding: 1.5rem 2rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  color:  ${({ theme }) => theme.palette.black};
  display: flex;
  flex-direction: column;
  place-self: stretch stretch;
  min-width: unset;
  justify-content: space-between;
`;

export const EventTypesContainer = styled(StyledDashboardCard)`
  grid-area: 1 / 1 / 2 / 2;
`;

export const DonationTypesContainer = styled(StyledDashboardCard)`
  grid-area: 1 / 2 / 2 / 3;
`;

export const EventSuburbsContainer = styled(StyledDashboardCard)`
  grid-area: 1 / 3 / 2 / 4;
`;

export const DonationAmountContainer = styled(StyledDashboardCard)`
  grid-area: 2 / 1 / 3 / 3;
`;

export const DonationFrequencyContainer = styled(StyledDashboardCard)`
  grid-area: 2 / 3 / 3 / 4;
`;

export const DonationByStateContainer = styled(StyledDashboardCard)`
  grid-area: 3 / 1 / 4 / 2;
`;

export const TopCharitiesContainer = styled(StyledDashboardCard)`
  grid-area: 3 / 2 / 4 / 3;
`;

export const EventByAgeContainer = styled(StyledDashboardCard)`
  grid-area: 3 / 3 / 4 / 4;
`;

export const StatsGraphHeading = styled.h2`
  font-size: ${({ theme }) => theme.fontScale.M}rem; 
  font-weight: normal;
`;

export const NoResultsHeading = styled.p`
  align-self: center;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

export const LegendContainer = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  position: absolute;
  margin-top: 125px;
  margin-right: 15px;
  text-align: center;
`;

export const Percentage = styled.p`
  font-weight: 600;
  font-size: 2.5rem;
  margin-bottom: 0.3rem;
  color: ${({ theme }) => theme.palette.header};
`;

export const PercentSubheading = styled.p`
  color: ${({ theme }) => theme.palette.subheader};
  font-weight: bold;
`;

export const TooltipContainer = styled.div`
  padding: 0.4rem 0.8rem;
  background-color: white;
  border-radius: 4px;
  border: ${({ theme }) => theme.borderStyles.secondary};
`;

export const TooltipValue = styled.p`
  color: ${({ theme }) => theme.palette.primary};
  font-weight: 600;
`;

export const LoadingSpinner = styled.div`
  border: 16px solid ${({ theme }) => theme.palette.bgPrimary};
  border-top: 16px solid ${({ theme }) => theme.palette.primary};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 1em;
  animation: spin 2s linear infinite;
  align-self: center;

  @keyframes spin {
    0% { 
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
