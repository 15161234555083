import styled from 'styled-components/macro';

const StyledContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 0.3rem;
  min-height: fit-content;
  width: auto;
  margin: 1rem;
  border: ${({ theme }) => theme.borderStyles.secondary};
  align-items: stretch;
  align-content: stretch;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: relative;
  min-width: min-content;
`;

export default StyledContentWrapper;
