/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import {
  HeadingContainer, StatsGraphHeading, NoResultsHeading, LoadingSpinner,
} from '../Styles';
import DateFilterDropdown from '../DateFilterDropdown';
import PieChartGraph from '../PieChart';

import useStrings from '../../../../hooks/useStrings';
import { useGetTopCharitiesByDonation } from '../../../../lib/graphql/queries';
import { fromDate } from '../../../../lib/Utils';

const TopCharities: React.FC<TopCharitiesProps> = () => {
  const [{ Statistics: strings }] = useStrings();

  const [showDateFilter, setShowDateFilter] = useState(false);
  const [filterSelected, setFilterSelected] = useState<'week' | 'month' | 'year'>('week');

  const { data: topCharitiesData, loading } = useGetTopCharitiesByDonation({
    variables: { fromDate: fromDate(filterSelected) },
  });
  const topDonationTypes = topCharitiesData?.topCharitiesByDonationAmount
    .orgsTotalDonated?.map((orgsTotal) => (
      { name: orgsTotal.name, value: orgsTotal.donated }
    )) || [];

  return (
    <>
      <HeadingContainer>
        <StatsGraphHeading>{strings.topCharities}</StatsGraphHeading>
        <DateFilterDropdown
          onToggleDropdown={() => setShowDateFilter(!showDateFilter)}
          isDropdownShown={showDateFilter}
          onClickFilter={setFilterSelected}
          filterSelected={filterSelected}
        />
      </HeadingContainer>
      {loading
        ? <LoadingSpinner />
        : topDonationTypes.length === 0
          ? <NoResultsHeading>{strings.noResults}</NoResultsHeading>
          : <PieChartGraph data={topDonationTypes} />}
    </>
  );
};

interface TopCharitiesProps { }

export default TopCharities;
