import styled from 'styled-components/macro';

export const Heading = styled.h2`
  display: block;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-transform: uppercase;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.XS}rem;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
  width: auto;
  color: ${({ theme }) => theme.palette.header};

`;

export const Subtext = styled.p`
  text-align: center;
  width: 50%;
  align-self: center;
  line-height: 20px;
`;

export const Subheading = styled.h3`
  margin-top: ${({ theme }) => theme.spacing.S}rem;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const LogoImageContainer = styled.img`
  height: 7rem;
  width: 7em;
  background-color: #D8D8D8;
  border-radius: 100px;
  border: ${({ theme }) => theme.borderStyles.primary};
  margin: ${({ theme }) => theme.spacing.XXS}rem 0;
`;

export const AddLogoButton = styled.button`
  height: 2.5rem;
  width: 28%;
  background: none;
  border: ${({ theme }) => theme.borderStyles.primary};
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.bold};  
  cursor: pointer;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-self: center;
  justify-content: space-between;
`;

export const CityInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
  width: 55%;
`;

export const StateInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
  width: 20%;
`;

export const PostcodeInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
  width: 20%;
`;

export const CheckBoxWrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const PromotedUnticked = styled.div`
  cursor: pointer;
  height: 35px;
  width: 35px;
  border: ${({ theme }) => theme.borderStyles.primary};
  border-radius: 4px;
  margin-right: ${({ theme }) => theme.spacing.XXS}rem
`;

export const PromotedTicked = styled.img`
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing.XXS}rem;
  height: 25px;
  width: 25px;
  background-color: ${({ theme }) => theme.palette.primary};
  border-radius: 4px;
  padding: 6px;
`;

export const PromotedLabel = styled.p`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontScale.S}rem;
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const EditPriorityDetails = styled.button`
  height: 2.5rem;
  width: 45%;
  background: none;
  border: ${({ theme }) => theme.borderStyles.primary};
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.bold};  
  cursor: pointer;
`;

export const CheckBoxRow = styled.div`
  flex-direction: row;
  display: flex;
  margin: ${({ theme }) => theme.spacing.XS}rem 0 ${({ theme }) => theme.spacing.S}rem;
  align-items: center;
  justify-content: space-between;
`;

export const CountrySelect = styled.select`
  width: 100%;
  border-radius: 4px;
  height: ${({ theme }) => theme.spacing.S * 0.85}rem;
  color: ${({ theme }) => theme.palette.label};
  padding: 5px 35px 5px 5px;
  font-family: ${({ theme }) => theme.fontFamily.body};
  outline: none;
  border: ${({ theme }) => theme.borderStyles.primary};
`;

export const CountryOption = styled.option``;

export const SignatureInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const SignatureImage = styled.img<{ hasImage?: boolean }>`
  height: ${({ hasImage }) => (hasImage ? 'auto' : '145px')};
  width: 65%;
  max-width: 65%;
  border-radius: 4px;
  background-color: #D8D8D8;
  border: ${({ theme }) => theme.borderStyles.primary};
`;
