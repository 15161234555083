import styled from 'styled-components/macro';

interface ResolvedComplaintProps {
  resolved?: boolean;
}

export const ComplaintContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.XS}rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70vh;
`;

export const MainContent = styled.div`
  position: relative;
  min-height: 55vh;
`;

export const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.XS}rem
`;

export const PhotoDetailsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileImage = styled.img`
  height: 85px;
  width: 85px;
  border-radius: 100px;
  cursor: pointer;
`;

export const DetailsContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const Name = styled.h4`
  font-weight: normal;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.palette.header};
  margin-bottom: 0.4rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const ComplaintTypeLabel = styled.p`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.subheader};
  display: inline;
`;

export const HighlightText = styled(ComplaintTypeLabel)`
  color: ${({ theme }) => theme.palette.primary};
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Date = styled.p`
  background-color: ${({ theme }) => theme.palette.title};
  color: ${({ theme }) => theme.palette.white};
  border-radius: 4px;
  padding: 0.4rem 0.6rem;
  text-transform: uppercase;
  font-size: 0.9rem;
  align-self: flex-start;
  white-space: no-wrap;
`;

export const MessageContentLabel = styled.h4`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.subheader};
  font-weight: normal;
  margin-bottom: ${({ theme }) => theme.spacing.XXS / 1.5}rem
`;

export const MessageContent = styled.p<ResolvedComplaintProps>`
  font-size: 0.95rem;
  color: ${({ theme }) => theme.palette.label};
  margin-bottom: ${({ theme }) => theme.spacing.XS}rem;
  line-height: 1.5;
  max-height: ${({ resolved, theme }) => (resolved ? '24em' : `${theme.spacing.M}rem`)};
  overflow: scroll;
`;

export const ReplyTextArea = styled.textarea`
  width: 100%;
  border: none;
  border-top: 2px solid ${({ theme }) => theme.palette.bgPrimary};
  outline: none;
  resize: none;
  font-family: ${({ theme }) => theme.fontFamily.body};
  padding: ${({ theme }) => theme.spacing.XS}rem;
  margin: 0 -${({ theme }) => theme.spacing.XS}rem;
  white-space: pre-line;
  height: 16vh;
`;

export const ButtonContainer = styled.div<ResolvedComplaintProps>`
  width: calc(100% + ${({ theme }) => theme.spacing.XS * 2}rem);
  margin-left: -${({ theme }) => theme.spacing.XS}rem;
  display: flex;
  flex-direction: column;
  border-top: ${({ resolved, theme }) => (resolved ? 'none' : `2px solid ${theme.palette.bgPrimary}`)};
  padding: ${({ theme }) => theme.spacing.XXS}rem 0;
  align-items: center;
  z-index: 2;
  background-color: ${({ theme }) => theme.palette.bgSecondary};
`;

export const InnerButtonContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.bgSecondary};
`;
