import React from 'react';
import {
  ResponsiveContainer, RadialBarChart, RadialBar,
} from 'recharts';

import useStrings from '../../../../hooks/useStrings';
import { LegendContainer, Percentage, PercentSubheading } from '../Styles';

const PieChartGraph: React.FC<PieChartGraphProps> = ({ data, percentage }) => {
  const [{ Statistics: strings }] = useStrings();
  const percentString = `${percentage}%`;

  return (
    <>
      <ResponsiveContainer height="100%" width="100%">
        <RadialBarChart
          style={{
            marginLeft: -18,
            marginBottom: -115,
            marginTop: 10,
          }}
          data={data}
          innerRadius={110}
          outerRadius={350}
          barSize={60}
          startAngle={180}
          endAngle={0}
          cx={150}
          cy={145}
        >
          <RadialBar dataKey="value" style={{ height: 200 }} background={{ fill: '#FCF0EE' }} />
        </RadialBarChart>
      </ResponsiveContainer>
      <LegendContainer>
        <Percentage>{percentString}</Percentage>
        <PercentSubheading>{strings.donateAnyway}</PercentSubheading>
      </LegendContainer>
    </>
  );
};

interface PieChartGraphProps {
  data: { name: string; value: number }[];
  percentage: number;
}

export default PieChartGraph;
