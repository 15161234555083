import styled, { keyframes } from 'styled-components/macro';

// ------------------------------ OVERLAY

const fadeToBlack = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
  position: fixed;
  animation: ${fadeToBlack} 0.3s ease-in-out;
  overflow: scroll;
`;

// ------------------------------ MODAL CONTAINER
export interface ModalStyleProps {
  rounded?: boolean;
}

export const Container = styled.div<ModalStyleProps>`
  position: relative;
  margin: 5% auto;
  min-width: 330px;
  width: 30vw;
  border-radius: ${({ rounded }) => (rounded ? '40px' : '4px')};
  height: fit-content;
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0 2px 18px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  padding:  ${({ theme }) => theme.spacing.XS}rem ${({ theme }) => theme.spacing.M}rem ${({ theme }) => theme.spacing.S}rem;
  
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent; /* make scrollbar transparent i.e. hide */
  }
`;

// ------------------------------ MODAL HEADER

export interface HeaderProps {
  hasHeader?: boolean;
}

export const Header = styled.div<HeaderProps>`
  display: ${({ hasHeader }) => (hasHeader ? 'block' : 'none')};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontScale.L}rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  width: auto;
  color: ${({ theme }) => theme.palette.header};
`;

// ------------------------------ MODAL SUBHEADER

export interface SubHeaderProps {
  hasSubHeader?: boolean;
}

export const SubHeader = styled(Header) <SubHeaderProps>`
  font-size: calc(${({ theme }) => theme.fontScale.M}rem * 1.1);
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: calc(${({ theme }) => theme.fontScale.M}rem * 1.7);
  text-transform: none;
  padding: ${({ theme }) => theme.spacing.XXS}rem ${({ theme }) => theme.spacing.XXS}rem 2.75rem;
  width: auto;
  display: ${({ hasSubHeader }) => (hasSubHeader ? 'block' : 'none')};
  margin: 0 auto ${({ theme }) => theme.spacing.XS}px;
  color: ${({ theme }) => theme.palette.body};
`;

// ------------------------------ CLOSE ICON
export const StyledCloseIcon = styled.img`
  margin: 0 -3rem 0 auto;
  &:hover {
    cursor: pointer;
  }
`;
