import { DefaultTheme } from 'styled-components/macro';

import {
  defDevice, defFontWeight, defFontScale, defSpacing, ffFontFamily, ffPalette, defBorderStyles,
} from '../core';

const appTheme: DefaultTheme = {
  palette: ffPalette,
  fontFamily: ffFontFamily,
  fontScale: defFontScale,
  fontWeight: defFontWeight,
  spacing: defSpacing,
  device: defDevice,
  borderStyles: defBorderStyles,
};

export default appTheme;
