import React, { useMemo, useState } from 'react';
import moment from 'moment';

import {
  Modal, Button, InputWrapper, Label, DatePicker, Field, TextInputField, Icon,
} from '../../../../components';
import ButtonContainer from './Styles';
import { DollarFieldIcon } from '../../../../assets/Icons';

import useStrings from '../../../../hooks/useStrings';

const EventsFilterModal: React.FC<EventsFilterModalProps> = ({
  onClose, onFilter, onClearFilters,
}) => {
  const [{ EventsFilterModal: strings }] = useStrings();

  const [dateFromSelected, setdateFromSelected] = useState<Date | Date[] | undefined>(undefined);
  const [dateToSelected, setdateToSelected] = useState<Date | Date[] | undefined>(undefined);
  const [lowerDonation, setLowerDonation] = useState<number | undefined>(undefined);
  const [upperDonation, setUpperDonation] = useState<number | undefined>(undefined);

  const applyButtonDisabled = useMemo(() => {
    if (dateFromSelected && dateToSelected) {
      return ((!!lowerDonation && !!upperDonation) || (lowerDonation === 0 && !!upperDonation))
        ? (lowerDonation > upperDonation) : false;
    }
    return (!!lowerDonation && !!upperDonation) || (lowerDonation === 0 && !!upperDonation)
      ? (lowerDonation > upperDonation) : true;
  }, [dateFromSelected, dateToSelected, lowerDonation, upperDonation]);

  return (
    <Modal
      closeModal={onClose}
      header={strings.heading}
    >
      <InputWrapper>
        <Label>{strings.dateFrom}</Label>
        <DatePicker
          onChange={setdateFromSelected}
          value={dateFromSelected}
        />
      </InputWrapper>
      <InputWrapper>
        <Label>{strings.dateTo}</Label>
        <DatePicker
          onChange={setdateToSelected}
          value={dateToSelected}
        />
      </InputWrapper>
      <InputWrapper>
        <Label>{strings.donationRange}</Label>
        <Field>
          <Icon src={DollarFieldIcon} />
          <TextInputField
            hasIcon
            onChange={(e) => setLowerDonation(parseInt(e.target.value, 10))}
            placeholder={strings.lowerAmount}
            type="number"
          />
        </Field>
        <Field>
          <Icon src={DollarFieldIcon} />
          <TextInputField
            hasIcon
            onChange={(e) => setUpperDonation(parseInt(e.target.value, 10))}
            placeholder={strings.upperRange}
            type="number"
          />
        </Field>
      </InputWrapper>
      <ButtonContainer>
        <Button
          buttonText={strings.clear}
          variant="outlined"
          onClick={onClearFilters}
          width="48%"
        />
        <Button
          buttonText={strings.apply}
          variant="primary"
          onClick={() => onFilter({
            dateFrom: moment((dateFromSelected || '').toString()).valueOf(),
            dateTo: moment((dateToSelected || '').toString()).valueOf(),
            lowerDonation: ((lowerDonation || lowerDonation === 0)
              ? lowerDonation * 100
              : undefined
            ),
            upperDonation: (upperDonation ? upperDonation * 100 : undefined),
          })}
          width="48%"
          disabled={applyButtonDisabled}
        />
      </ButtonContainer>
    </Modal>
  );
};

export interface EventsFilterModalProps {
  onClose: () => void;
  onFilter: (queries: {
    dateFrom?: number; dateTo?: number; lowerDonation?: number; upperDonation?: number;
  }) => void;
  onClearFilters: () => void;
}

export default EventsFilterModal;
