/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

const documents = {
    "\nfragment AdminData on Admin {\n  id\n  firstName\n  surname\n  email\n  profilePhoto\n}\n": types.AdminDataFragmentDoc,
    "\n  query GetAdmin($input: adminIDInput!) {\n    admin(input: $input) {\n      admin {\n        ...AdminData\n      }\n    }\n  }\n": types.GetAdminDocument,
    "\n  query GetAdmins($input: adminIDInput!) {\n    allAdmins(input: $input) {\n      admins {\n        ...AdminData\n      }\n    }\n  }\n": types.GetAdminsDocument,
    "\n  mutation CreateAdmin($input: AdminInput!) {\n    newAdmin(input: $input) {\n      admin {\n        ...AdminData\n      }\n    }\n  }\n": types.CreateAdminDocument,
    "\n  mutation EditAdmin($input: AdminInput!) {\n    editAdminProfile(input: $input) {\n      admin {\n        ...AdminData\n      }\n      success\n    }\n  }\n": types.EditAdminDocument,
    "\n  query GetTotalUsers {\n    countTotalUsers {\n      totalUsers\n    }\n  }\n": types.GetTotalUsersDocument,
    "\n  query GetTotalEvents {\n    countTotalEvents {\n      totalEvents\n    }\n  }\n": types.GetTotalEventsDocument,
    "\n  query GetTotalDonations {\n    totalDonatedSmyleeWide {\n      smyleeWideDonations\n    }\n  }\n": types.GetTotalDonationsDocument,
    "\n  query GetTotalSmyleeRevenue($adminID: ID!) {\n    smyleeRevenue(adminID: $adminID) {\n      smyleeRevenue\n    }\n  }\n": types.GetTotalSmyleeRevenueDocument,
    "\n  query GetDonationsThisWeek($date: String!) {\n    donationsThisWeek(date: $date) {\n      donationsMadeThisWeek {\n        lastTried\n        amount\n      }\n    }\n  }\n": types.GetDonationsThisWeekDocument,
    "\n  query GetUsersThisWeek($date: String!) {\n    usersThisWeek(date: $date) {\n      usersCreatedThisWeekDates\n    }\n  }\n": types.GetUsersThisWeekDocument,
    "\n  query GetEventsCreatedFromDate($date: String!) {\n    eventsCreatedFromDate(date: $date) {\n      eventsDates\n    }\n  }\n": types.GetEventsCreatedFromDateDocument,
    "\n  query GetEventsHeldFromDate($date: String!) {\n    eventsHeldFromDate(date: $date) {\n      eventsDates\n    }\n  }\n": types.GetEventsHeldFromDateDocument,
    "\n  query GetEventsCancelledFromDate($date: String!) {\n    eventsCancelledFromDate(date: $date) {\n      eventsDates\n    }\n  }\n": types.GetEventsCancelledFromDateDocument,
    "\nfragment GuestData on Guest {\n  id\n  user { ...UserData }\n  rsvpd\n  checkinExemption\n  checkedIn\n  entryCode\n  userID\n  mobileNumber\n  email\n  source\n  name\n  isCancelled\n}\n": types.GuestDataFragmentDoc,
    "\nfragment LocationData on Location {\n  id\n  latitude\n  longitude\n  name\n  address\n  timezoneID\n  suburb\n}\n": types.LocationDataFragmentDoc,
    "\nfragment EventData on Event {\n  id\n  createdDateTime\n  title\n  eventOrganizer { ...UserData }\n  description\n  location { ...LocationData }\n  type\n  date\n  startTime\n  endDate\n  endTime\n  rsvpDate\n  checkInTime\n  cost\n  isHostCheckedIn\n  organization { ...OrganizationData }\n  pledgeAmount\n  entryCode\n  isCancelled\n  guests { ...GuestData }\n}\n": types.EventDataFragmentDoc,
    "\n  query GetEventsByOrganization($organizationID: ID!, $adminID: ID!) {\n    eventsByOrganizationID(organizationID: $organizationID, adminID: $adminID) {\n      ...EventData\n    }\n  }\n": types.GetEventsByOrganizationDocument,
    "\n  query GetPaginatedEvents($adminID: String!, $pageNo: Int!, $pageSize: Int!, $tableFilter: String, $sortField: String, $sortOrder: SortOrder, $filters: FilterInput) {\n    paginatedEvents(adminID: $adminID, pageNo: $pageNo, pageSize: $pageSize, tableFilter: $tableFilter, sortField: $sortField, sortOrder: $sortOrder, filters: $filters) {\n      events {\n        ...EventData\n      }\n      totalPages\n    }\n  }\n": types.GetPaginatedEventsDocument,
    "\n  query EventsForAdminBaseTable($adminID: String!) {\n    eventsForAdminBaseTable(adminID: $adminID) {\n      events {\n        ...EventData\n      }\n    }\n  }\n": types.EventsForAdminBaseTableDocument,
    "\n  query GetEventsByUser($input: userIDInput!) {\n    eventsByUser(input: $input) {\n      events { ...EventData }\n    }\n  }\n": types.GetEventsByUserDocument,
    "\n  query GetAllPayments($input: AllPaymentsInput!, $adminID: ID!) {\n    allPayments(input: $input, adminID: $adminID) {\n      payments {\n        ...PaymentData\n      }\n    }\n  }\n": types.GetAllPaymentsDocument,
    "\nfragment OrganizationData on Organization {\n  id\n  name\n  description\n  iconLogo\n  logo\n  backgroundImage\n  assetPairOne {\n    website\n    title\n    image\n  }\n  assetPairTwo {\n    website\n    title\n    image\n  }\n  assetPairThree {\n    website\n    title\n    image\n  }\n  website\n  eventsLink\n  subscribeLink\n  totalDonated\n  disabled\n  totalEvents\n  addrLineOne\n  addrLineTwo\n  abn\n  priority\n  city\n  state\n  postcode\n  country\n  contactName\n  phoneNumber\n  signatureImage\n  charityNumber\n  currency\n  officialName\n}\n": types.OrganizationDataFragmentDoc,
    "\nfragment OrganizationQueryViewData on OrganizationQueryView {\n  id\n  name\n  description\n  iconLogo\n  logo\n  backgroundImage\n  assetPairOne {\n    website\n    title\n    image\n  }\n  assetPairTwo {\n    website\n    title\n    image\n  }\n  assetPairThree {\n    website\n    title\n    image\n  }\n  website\n  eventsLink\n  subscribeLink\n  totalDonated\n  disabled\n  totalEvents\n  addrLineOne\n  addrLineTwo\n  abn\n  priority\n  city\n  state\n  postcode\n  country\n  contactName\n  phoneNumber\n  signatureImage\n  charityNumber\n  currency\n  officialName\n  paymentDetailsID\n  stripeAccountID\n}\n": types.OrganizationQueryViewDataFragmentDoc,
    "\n  query GetOrganizationsIgnoringDisabled($adminID: ID!) {\n    organizationsIgnoringDisabled(adminID: $adminID) {\n      organizations {\n        ...OrganizationQueryViewData\n      }\n    }\n  }\n": types.GetOrganizationsIgnoringDisabledDocument,
    "\n  query GetOrganizationIgnoringDisabled($adminID: ID!, $organizationID: ID!) {\n    organizationIgnoringDisabled(adminID: $adminID, organizationID: $organizationID) {\n      organization {\n        ...OrganizationQueryViewData\n      }\n    }\n  }\n": types.GetOrganizationIgnoringDisabledDocument,
    "\nquery GetOrganizationStripeLink($organizationID: String!) {\n  stripeLoginLink(organizationID: $organizationID) {\n    link\n  }\n}\n": types.GetOrganizationStripeLinkDocument,
    "\n  mutation CreateOrganization($input: newOrganizationInput!) {\n    newOrganization(input: $input) {\n      organization {\n        ...OrganizationData\n      }\n    }\n  }\n": types.CreateOrganizationDocument,
    "\n  mutation EditOrganization($input: editOrganizationInput!) {\n    editOrganization(input: $input) {\n      ...OrganizationQueryViewData\n    }\n  }\n": types.EditOrganizationDocument,
    "\nfragment PaymentData on Payment {\n  id\n  userPaymentDetails {\n    id\n    user { ...UserData }\n  }\n  organizationPaymentDetails {\n    id\n    organization { ...OrganizationData }\n  }\n  stripeChargeID\n  receiptNo\n  type\n  currency\n  amount\n  status\n  lastTried\n  eventID\n  cardTransactionFee\n  event { ...EventData }\n}\n": types.PaymentDataFragmentDoc,
    "\nfragment DonationHistoryPaymentData on DonationHistoryPayment {\n  id\n  organizationPaymentDetails {\n    id\n    organization { ...OrganizationData }\n  }\n  type\n  amount\n  status\n  lastTried\n  eventID\n}\n": types.DonationHistoryPaymentDataFragmentDoc,
    "\n  query GetPaymentHistory($input: userIDInput!) {\n    donationHistory(input: $input) {\n      totalDonated\n      payments {\n        ...DonationHistoryPaymentData\n      }\n    }\n  }\n": types.GetPaymentHistoryDocument,
    "\nfragment ReportedData on Reported {\n  id\n  reportedReason\n  content\n  reporter { ...UserData }\n  reported { ...UserData }\n  dateCreated\n  isResolved\n}\n": types.ReportedDataFragmentDoc,
    "\n  query GetAllReportedContent {\n    allReportedContent {\n      reportedContents {\n        ...ReportedData\n      }\n    }\n  }\n": types.GetAllReportedContentDocument,
    "\n  mutation markAsResolved($input: MarkResolvedInput!) {\n    markAsResolved(input: $input) {\n      reportedContent {\n        ...ReportedData\n      }\n    }\n  }\n": types.MarkAsResolvedDocument,
    "\n  mutation sendEmailReply($input: EmailReplyInput!) {\n    sendEmailReply(input: $input) {\n      success\n    }\n  }\n": types.SendEmailReplyDocument,
    "\n  query GetTopEventTypes($fromDate: Float!) {\n    topEventTypes(fromDate: $fromDate) {\n      types {\n        type\n        count\n      }\n    }\n  }\n": types.GetTopEventTypesDocument,
    "\n  query GetTopDonationTypes($fromDate: Float!) {\n    topDonationTypes(fromDate: $fromDate) {\n      donationTypes {\n        type\n        count\n      }\n    }\n  }\n": types.GetTopDonationTypesDocument,
    "\n  query GetTopCharitiesByDonation($fromDate: Float!) {\n    topCharitiesByDonationAmount(fromDate: $fromDate) {\n      orgsTotalDonated {\n        name\n        donated\n      }\n    }\n  }\n": types.GetTopCharitiesByDonationDocument,
    "\n  query GetDonateAnywayFrequencies($fromDate: Float!) {\n    donateAnywayFrequencies(fromDate: $fromDate) {\n      keptPledge\n      donatedAnyway\n    }\n  }\n": types.GetDonateAnywayFrequenciesDocument,
    "\n  query GetTopLocationsByState($fromDate: Float!) {\n    topLocationsByState(fromDate: $fromDate) {\n      locations {\n        state\n        count\n      }\n    }\n  }\n": types.GetTopLocationsByStateDocument,
    "\n  query GetTopLocationsBySuburb($fromDate: Float!) {\n    topLocationsBySuburb(fromDate: $fromDate) {\n      locations {\n        suburb\n        count\n      }\n    }\n  }\n": types.GetTopLocationsBySuburbDocument,
    "\n  query GetDonationsByMonth {\n    donationsByMonth {\n      donationsByMonth {\n        Month\n        Amount\n      }\n    }\n  }\n": types.GetDonationsByMonthDocument,
    "\n  query GetDonationsByAge($fromDate: Float!) {\n    donationsByAge(fromDate: $fromDate ) {\n      donationsByAgeBracket {\n        AgeBracket\n        TotalDonated\n      }\n    }\n  }\n": types.GetDonationsByAgeDocument,
    "\nfragment UserData on User {\n  id\n  givenName\n  surname\n  email\n  mobileInformation {\n    areaCode\n    mobileNumber\n  }\n  profilePhoto\n  reliability\n  createdDate\n}\n": types.UserDataFragmentDoc,
    "\nfragment PaginatedUserData on PaginatedUser {\n  id\n  givenName\n  surname\n  email\n  mobileInformation {\n    areaCode\n    mobileNumber\n  }\n  profilePhoto\n  reliability\n}\n": types.PaginatedUserDataFragmentDoc,
    "\n  query GetPaginatedUsers($adminID: String!, $pageNo: Int!, $pageSize: Int!, $filter: String, $searchQuery: String, $sortField: String, $sortOrder: SortOrder) {\n    paginatedUsers(adminID: $adminID, pageNo: $pageNo, pageSize: $pageSize, filter: $filter, searchQuery: $searchQuery, sortField: $sortField, sortOrder: $sortOrder) {\n      users {\n        ...PaginatedUserData\n        userTotalDonated\n        totalEvents\n        eventsUserHasBeenAGuestOf\n        eventsUserHasCreated\n        createdDate\n      }\n      totalPages\n    }\n  }\n": types.GetPaginatedUsersDocument,
    "\n  query downloadUsers($adminID: ID!) {\n    userDownloadHubspot(adminID: $adminID) {\n      givenName\n      surname\n      email\n      dateOfBirth\n      mobileInformation {\n        areaCode\n        mobileNumber\n      }\n      marketingEmails\n    }\n  }\n": types.DownloadUsersDocument,
    "\n  query GetUser($input: UserForAdminInput!) {\n    userForAdmin(input: $input) {\n      user {\n        ...PaginatedUserData\n        userTotalDonated\n        totalEvents\n        eventsUserHasBeenAGuestOf\n        eventsUserHasCreated\n      }\n    }\n  }\n": types.GetUserDocument,
};

export function graphql(source: "\nfragment AdminData on Admin {\n  id\n  firstName\n  surname\n  email\n  profilePhoto\n}\n"): (typeof documents)["\nfragment AdminData on Admin {\n  id\n  firstName\n  surname\n  email\n  profilePhoto\n}\n"];
export function graphql(source: "\n  query GetAdmin($input: adminIDInput!) {\n    admin(input: $input) {\n      admin {\n        ...AdminData\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAdmin($input: adminIDInput!) {\n    admin(input: $input) {\n      admin {\n        ...AdminData\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetAdmins($input: adminIDInput!) {\n    allAdmins(input: $input) {\n      admins {\n        ...AdminData\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAdmins($input: adminIDInput!) {\n    allAdmins(input: $input) {\n      admins {\n        ...AdminData\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation CreateAdmin($input: AdminInput!) {\n    newAdmin(input: $input) {\n      admin {\n        ...AdminData\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAdmin($input: AdminInput!) {\n    newAdmin(input: $input) {\n      admin {\n        ...AdminData\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation EditAdmin($input: AdminInput!) {\n    editAdminProfile(input: $input) {\n      admin {\n        ...AdminData\n      }\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation EditAdmin($input: AdminInput!) {\n    editAdminProfile(input: $input) {\n      admin {\n        ...AdminData\n      }\n      success\n    }\n  }\n"];
export function graphql(source: "\n  query GetTotalUsers {\n    countTotalUsers {\n      totalUsers\n    }\n  }\n"): (typeof documents)["\n  query GetTotalUsers {\n    countTotalUsers {\n      totalUsers\n    }\n  }\n"];
export function graphql(source: "\n  query GetTotalEvents {\n    countTotalEvents {\n      totalEvents\n    }\n  }\n"): (typeof documents)["\n  query GetTotalEvents {\n    countTotalEvents {\n      totalEvents\n    }\n  }\n"];
export function graphql(source: "\n  query GetTotalDonations {\n    totalDonatedSmyleeWide {\n      smyleeWideDonations\n    }\n  }\n"): (typeof documents)["\n  query GetTotalDonations {\n    totalDonatedSmyleeWide {\n      smyleeWideDonations\n    }\n  }\n"];
export function graphql(source: "\n  query GetTotalSmyleeRevenue($adminID: ID!) {\n    smyleeRevenue(adminID: $adminID) {\n      smyleeRevenue\n    }\n  }\n"): (typeof documents)["\n  query GetTotalSmyleeRevenue($adminID: ID!) {\n    smyleeRevenue(adminID: $adminID) {\n      smyleeRevenue\n    }\n  }\n"];
export function graphql(source: "\n  query GetDonationsThisWeek($date: String!) {\n    donationsThisWeek(date: $date) {\n      donationsMadeThisWeek {\n        lastTried\n        amount\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetDonationsThisWeek($date: String!) {\n    donationsThisWeek(date: $date) {\n      donationsMadeThisWeek {\n        lastTried\n        amount\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetUsersThisWeek($date: String!) {\n    usersThisWeek(date: $date) {\n      usersCreatedThisWeekDates\n    }\n  }\n"): (typeof documents)["\n  query GetUsersThisWeek($date: String!) {\n    usersThisWeek(date: $date) {\n      usersCreatedThisWeekDates\n    }\n  }\n"];
export function graphql(source: "\n  query GetEventsCreatedFromDate($date: String!) {\n    eventsCreatedFromDate(date: $date) {\n      eventsDates\n    }\n  }\n"): (typeof documents)["\n  query GetEventsCreatedFromDate($date: String!) {\n    eventsCreatedFromDate(date: $date) {\n      eventsDates\n    }\n  }\n"];
export function graphql(source: "\n  query GetEventsHeldFromDate($date: String!) {\n    eventsHeldFromDate(date: $date) {\n      eventsDates\n    }\n  }\n"): (typeof documents)["\n  query GetEventsHeldFromDate($date: String!) {\n    eventsHeldFromDate(date: $date) {\n      eventsDates\n    }\n  }\n"];
export function graphql(source: "\n  query GetEventsCancelledFromDate($date: String!) {\n    eventsCancelledFromDate(date: $date) {\n      eventsDates\n    }\n  }\n"): (typeof documents)["\n  query GetEventsCancelledFromDate($date: String!) {\n    eventsCancelledFromDate(date: $date) {\n      eventsDates\n    }\n  }\n"];
export function graphql(source: "\nfragment GuestData on Guest {\n  id\n  user { ...UserData }\n  rsvpd\n  checkinExemption\n  checkedIn\n  entryCode\n  userID\n  mobileNumber\n  email\n  source\n  name\n  isCancelled\n}\n"): (typeof documents)["\nfragment GuestData on Guest {\n  id\n  user { ...UserData }\n  rsvpd\n  checkinExemption\n  checkedIn\n  entryCode\n  userID\n  mobileNumber\n  email\n  source\n  name\n  isCancelled\n}\n"];
export function graphql(source: "\nfragment LocationData on Location {\n  id\n  latitude\n  longitude\n  name\n  address\n  timezoneID\n  suburb\n}\n"): (typeof documents)["\nfragment LocationData on Location {\n  id\n  latitude\n  longitude\n  name\n  address\n  timezoneID\n  suburb\n}\n"];
export function graphql(source: "\nfragment EventData on Event {\n  id\n  createdDateTime\n  title\n  eventOrganizer { ...UserData }\n  description\n  location { ...LocationData }\n  type\n  date\n  startTime\n  endDate\n  endTime\n  rsvpDate\n  checkInTime\n  cost\n  isHostCheckedIn\n  organization { ...OrganizationData }\n  pledgeAmount\n  entryCode\n  isCancelled\n  guests { ...GuestData }\n}\n"): (typeof documents)["\nfragment EventData on Event {\n  id\n  createdDateTime\n  title\n  eventOrganizer { ...UserData }\n  description\n  location { ...LocationData }\n  type\n  date\n  startTime\n  endDate\n  endTime\n  rsvpDate\n  checkInTime\n  cost\n  isHostCheckedIn\n  organization { ...OrganizationData }\n  pledgeAmount\n  entryCode\n  isCancelled\n  guests { ...GuestData }\n}\n"];
export function graphql(source: "\n  query GetEventsByOrganization($organizationID: ID!, $adminID: ID!) {\n    eventsByOrganizationID(organizationID: $organizationID, adminID: $adminID) {\n      ...EventData\n    }\n  }\n"): (typeof documents)["\n  query GetEventsByOrganization($organizationID: ID!, $adminID: ID!) {\n    eventsByOrganizationID(organizationID: $organizationID, adminID: $adminID) {\n      ...EventData\n    }\n  }\n"];
export function graphql(source: "\n  query GetPaginatedEvents($adminID: String!, $pageNo: Int!, $pageSize: Int!, $tableFilter: String, $sortField: String, $sortOrder: SortOrder, $filters: FilterInput) {\n    paginatedEvents(adminID: $adminID, pageNo: $pageNo, pageSize: $pageSize, tableFilter: $tableFilter, sortField: $sortField, sortOrder: $sortOrder, filters: $filters) {\n      events {\n        ...EventData\n      }\n      totalPages\n    }\n  }\n"): (typeof documents)["\n  query GetPaginatedEvents($adminID: String!, $pageNo: Int!, $pageSize: Int!, $tableFilter: String, $sortField: String, $sortOrder: SortOrder, $filters: FilterInput) {\n    paginatedEvents(adminID: $adminID, pageNo: $pageNo, pageSize: $pageSize, tableFilter: $tableFilter, sortField: $sortField, sortOrder: $sortOrder, filters: $filters) {\n      events {\n        ...EventData\n      }\n      totalPages\n    }\n  }\n"];
export function graphql(source: "\n  query EventsForAdminBaseTable($adminID: String!) {\n    eventsForAdminBaseTable(adminID: $adminID) {\n      events {\n        ...EventData\n      }\n    }\n  }\n"): (typeof documents)["\n  query EventsForAdminBaseTable($adminID: String!) {\n    eventsForAdminBaseTable(adminID: $adminID) {\n      events {\n        ...EventData\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetEventsByUser($input: userIDInput!) {\n    eventsByUser(input: $input) {\n      events { ...EventData }\n    }\n  }\n"): (typeof documents)["\n  query GetEventsByUser($input: userIDInput!) {\n    eventsByUser(input: $input) {\n      events { ...EventData }\n    }\n  }\n"];
export function graphql(source: "\n  query GetAllPayments($input: AllPaymentsInput!, $adminID: ID!) {\n    allPayments(input: $input, adminID: $adminID) {\n      payments {\n        ...PaymentData\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAllPayments($input: AllPaymentsInput!, $adminID: ID!) {\n    allPayments(input: $input, adminID: $adminID) {\n      payments {\n        ...PaymentData\n      }\n    }\n  }\n"];
export function graphql(source: "\nfragment OrganizationData on Organization {\n  id\n  name\n  description\n  iconLogo\n  logo\n  backgroundImage\n  assetPairOne {\n    website\n    title\n    image\n  }\n  assetPairTwo {\n    website\n    title\n    image\n  }\n  assetPairThree {\n    website\n    title\n    image\n  }\n  website\n  eventsLink\n  subscribeLink\n  totalDonated\n  disabled\n  totalEvents\n  addrLineOne\n  addrLineTwo\n  abn\n  priority\n  city\n  state\n  postcode\n  country\n  contactName\n  phoneNumber\n  signatureImage\n  charityNumber\n  currency\n  officialName\n}\n"): (typeof documents)["\nfragment OrganizationData on Organization {\n  id\n  name\n  description\n  iconLogo\n  logo\n  backgroundImage\n  assetPairOne {\n    website\n    title\n    image\n  }\n  assetPairTwo {\n    website\n    title\n    image\n  }\n  assetPairThree {\n    website\n    title\n    image\n  }\n  website\n  eventsLink\n  subscribeLink\n  totalDonated\n  disabled\n  totalEvents\n  addrLineOne\n  addrLineTwo\n  abn\n  priority\n  city\n  state\n  postcode\n  country\n  contactName\n  phoneNumber\n  signatureImage\n  charityNumber\n  currency\n  officialName\n}\n"];
export function graphql(source: "\nfragment OrganizationQueryViewData on OrganizationQueryView {\n  id\n  name\n  description\n  iconLogo\n  logo\n  backgroundImage\n  assetPairOne {\n    website\n    title\n    image\n  }\n  assetPairTwo {\n    website\n    title\n    image\n  }\n  assetPairThree {\n    website\n    title\n    image\n  }\n  website\n  eventsLink\n  subscribeLink\n  totalDonated\n  disabled\n  totalEvents\n  addrLineOne\n  addrLineTwo\n  abn\n  priority\n  city\n  state\n  postcode\n  country\n  contactName\n  phoneNumber\n  signatureImage\n  charityNumber\n  currency\n  officialName\n  paymentDetailsID\n  stripeAccountID\n}\n"): (typeof documents)["\nfragment OrganizationQueryViewData on OrganizationQueryView {\n  id\n  name\n  description\n  iconLogo\n  logo\n  backgroundImage\n  assetPairOne {\n    website\n    title\n    image\n  }\n  assetPairTwo {\n    website\n    title\n    image\n  }\n  assetPairThree {\n    website\n    title\n    image\n  }\n  website\n  eventsLink\n  subscribeLink\n  totalDonated\n  disabled\n  totalEvents\n  addrLineOne\n  addrLineTwo\n  abn\n  priority\n  city\n  state\n  postcode\n  country\n  contactName\n  phoneNumber\n  signatureImage\n  charityNumber\n  currency\n  officialName\n  paymentDetailsID\n  stripeAccountID\n}\n"];
export function graphql(source: "\n  query GetOrganizationsIgnoringDisabled($adminID: ID!) {\n    organizationsIgnoringDisabled(adminID: $adminID) {\n      organizations {\n        ...OrganizationQueryViewData\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetOrganizationsIgnoringDisabled($adminID: ID!) {\n    organizationsIgnoringDisabled(adminID: $adminID) {\n      organizations {\n        ...OrganizationQueryViewData\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetOrganizationIgnoringDisabled($adminID: ID!, $organizationID: ID!) {\n    organizationIgnoringDisabled(adminID: $adminID, organizationID: $organizationID) {\n      organization {\n        ...OrganizationQueryViewData\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetOrganizationIgnoringDisabled($adminID: ID!, $organizationID: ID!) {\n    organizationIgnoringDisabled(adminID: $adminID, organizationID: $organizationID) {\n      organization {\n        ...OrganizationQueryViewData\n      }\n    }\n  }\n"];
export function graphql(source: "\nquery GetOrganizationStripeLink($organizationID: String!) {\n  stripeLoginLink(organizationID: $organizationID) {\n    link\n  }\n}\n"): (typeof documents)["\nquery GetOrganizationStripeLink($organizationID: String!) {\n  stripeLoginLink(organizationID: $organizationID) {\n    link\n  }\n}\n"];
export function graphql(source: "\n  mutation CreateOrganization($input: newOrganizationInput!) {\n    newOrganization(input: $input) {\n      organization {\n        ...OrganizationData\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateOrganization($input: newOrganizationInput!) {\n    newOrganization(input: $input) {\n      organization {\n        ...OrganizationData\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation EditOrganization($input: editOrganizationInput!) {\n    editOrganization(input: $input) {\n      ...OrganizationQueryViewData\n    }\n  }\n"): (typeof documents)["\n  mutation EditOrganization($input: editOrganizationInput!) {\n    editOrganization(input: $input) {\n      ...OrganizationQueryViewData\n    }\n  }\n"];
export function graphql(source: "\nfragment PaymentData on Payment {\n  id\n  userPaymentDetails {\n    id\n    user { ...UserData }\n  }\n  organizationPaymentDetails {\n    id\n    organization { ...OrganizationData }\n  }\n  stripeChargeID\n  receiptNo\n  type\n  currency\n  amount\n  status\n  lastTried\n  eventID\n  cardTransactionFee\n  event { ...EventData }\n}\n"): (typeof documents)["\nfragment PaymentData on Payment {\n  id\n  userPaymentDetails {\n    id\n    user { ...UserData }\n  }\n  organizationPaymentDetails {\n    id\n    organization { ...OrganizationData }\n  }\n  stripeChargeID\n  receiptNo\n  type\n  currency\n  amount\n  status\n  lastTried\n  eventID\n  cardTransactionFee\n  event { ...EventData }\n}\n"];
export function graphql(source: "\nfragment DonationHistoryPaymentData on DonationHistoryPayment {\n  id\n  organizationPaymentDetails {\n    id\n    organization { ...OrganizationData }\n  }\n  type\n  amount\n  status\n  lastTried\n  eventID\n}\n"): (typeof documents)["\nfragment DonationHistoryPaymentData on DonationHistoryPayment {\n  id\n  organizationPaymentDetails {\n    id\n    organization { ...OrganizationData }\n  }\n  type\n  amount\n  status\n  lastTried\n  eventID\n}\n"];
export function graphql(source: "\n  query GetPaymentHistory($input: userIDInput!) {\n    donationHistory(input: $input) {\n      totalDonated\n      payments {\n        ...DonationHistoryPaymentData\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPaymentHistory($input: userIDInput!) {\n    donationHistory(input: $input) {\n      totalDonated\n      payments {\n        ...DonationHistoryPaymentData\n      }\n    }\n  }\n"];
export function graphql(source: "\nfragment ReportedData on Reported {\n  id\n  reportedReason\n  content\n  reporter { ...UserData }\n  reported { ...UserData }\n  dateCreated\n  isResolved\n}\n"): (typeof documents)["\nfragment ReportedData on Reported {\n  id\n  reportedReason\n  content\n  reporter { ...UserData }\n  reported { ...UserData }\n  dateCreated\n  isResolved\n}\n"];
export function graphql(source: "\n  query GetAllReportedContent {\n    allReportedContent {\n      reportedContents {\n        ...ReportedData\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAllReportedContent {\n    allReportedContent {\n      reportedContents {\n        ...ReportedData\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation markAsResolved($input: MarkResolvedInput!) {\n    markAsResolved(input: $input) {\n      reportedContent {\n        ...ReportedData\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation markAsResolved($input: MarkResolvedInput!) {\n    markAsResolved(input: $input) {\n      reportedContent {\n        ...ReportedData\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation sendEmailReply($input: EmailReplyInput!) {\n    sendEmailReply(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation sendEmailReply($input: EmailReplyInput!) {\n    sendEmailReply(input: $input) {\n      success\n    }\n  }\n"];
export function graphql(source: "\n  query GetTopEventTypes($fromDate: Float!) {\n    topEventTypes(fromDate: $fromDate) {\n      types {\n        type\n        count\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTopEventTypes($fromDate: Float!) {\n    topEventTypes(fromDate: $fromDate) {\n      types {\n        type\n        count\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetTopDonationTypes($fromDate: Float!) {\n    topDonationTypes(fromDate: $fromDate) {\n      donationTypes {\n        type\n        count\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTopDonationTypes($fromDate: Float!) {\n    topDonationTypes(fromDate: $fromDate) {\n      donationTypes {\n        type\n        count\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetTopCharitiesByDonation($fromDate: Float!) {\n    topCharitiesByDonationAmount(fromDate: $fromDate) {\n      orgsTotalDonated {\n        name\n        donated\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTopCharitiesByDonation($fromDate: Float!) {\n    topCharitiesByDonationAmount(fromDate: $fromDate) {\n      orgsTotalDonated {\n        name\n        donated\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetDonateAnywayFrequencies($fromDate: Float!) {\n    donateAnywayFrequencies(fromDate: $fromDate) {\n      keptPledge\n      donatedAnyway\n    }\n  }\n"): (typeof documents)["\n  query GetDonateAnywayFrequencies($fromDate: Float!) {\n    donateAnywayFrequencies(fromDate: $fromDate) {\n      keptPledge\n      donatedAnyway\n    }\n  }\n"];
export function graphql(source: "\n  query GetTopLocationsByState($fromDate: Float!) {\n    topLocationsByState(fromDate: $fromDate) {\n      locations {\n        state\n        count\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTopLocationsByState($fromDate: Float!) {\n    topLocationsByState(fromDate: $fromDate) {\n      locations {\n        state\n        count\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetTopLocationsBySuburb($fromDate: Float!) {\n    topLocationsBySuburb(fromDate: $fromDate) {\n      locations {\n        suburb\n        count\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTopLocationsBySuburb($fromDate: Float!) {\n    topLocationsBySuburb(fromDate: $fromDate) {\n      locations {\n        suburb\n        count\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetDonationsByMonth {\n    donationsByMonth {\n      donationsByMonth {\n        Month\n        Amount\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetDonationsByMonth {\n    donationsByMonth {\n      donationsByMonth {\n        Month\n        Amount\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetDonationsByAge($fromDate: Float!) {\n    donationsByAge(fromDate: $fromDate ) {\n      donationsByAgeBracket {\n        AgeBracket\n        TotalDonated\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetDonationsByAge($fromDate: Float!) {\n    donationsByAge(fromDate: $fromDate ) {\n      donationsByAgeBracket {\n        AgeBracket\n        TotalDonated\n      }\n    }\n  }\n"];
export function graphql(source: "\nfragment UserData on User {\n  id\n  givenName\n  surname\n  email\n  mobileInformation {\n    areaCode\n    mobileNumber\n  }\n  profilePhoto\n  reliability\n  createdDate\n}\n"): (typeof documents)["\nfragment UserData on User {\n  id\n  givenName\n  surname\n  email\n  mobileInformation {\n    areaCode\n    mobileNumber\n  }\n  profilePhoto\n  reliability\n  createdDate\n}\n"];
export function graphql(source: "\nfragment PaginatedUserData on PaginatedUser {\n  id\n  givenName\n  surname\n  email\n  mobileInformation {\n    areaCode\n    mobileNumber\n  }\n  profilePhoto\n  reliability\n}\n"): (typeof documents)["\nfragment PaginatedUserData on PaginatedUser {\n  id\n  givenName\n  surname\n  email\n  mobileInformation {\n    areaCode\n    mobileNumber\n  }\n  profilePhoto\n  reliability\n}\n"];
export function graphql(source: "\n  query GetPaginatedUsers($adminID: String!, $pageNo: Int!, $pageSize: Int!, $filter: String, $searchQuery: String, $sortField: String, $sortOrder: SortOrder) {\n    paginatedUsers(adminID: $adminID, pageNo: $pageNo, pageSize: $pageSize, filter: $filter, searchQuery: $searchQuery, sortField: $sortField, sortOrder: $sortOrder) {\n      users {\n        ...PaginatedUserData\n        userTotalDonated\n        totalEvents\n        eventsUserHasBeenAGuestOf\n        eventsUserHasCreated\n        createdDate\n      }\n      totalPages\n    }\n  }\n"): (typeof documents)["\n  query GetPaginatedUsers($adminID: String!, $pageNo: Int!, $pageSize: Int!, $filter: String, $searchQuery: String, $sortField: String, $sortOrder: SortOrder) {\n    paginatedUsers(adminID: $adminID, pageNo: $pageNo, pageSize: $pageSize, filter: $filter, searchQuery: $searchQuery, sortField: $sortField, sortOrder: $sortOrder) {\n      users {\n        ...PaginatedUserData\n        userTotalDonated\n        totalEvents\n        eventsUserHasBeenAGuestOf\n        eventsUserHasCreated\n        createdDate\n      }\n      totalPages\n    }\n  }\n"];
export function graphql(source: "\n  query downloadUsers($adminID: ID!) {\n    userDownloadHubspot(adminID: $adminID) {\n      givenName\n      surname\n      email\n      dateOfBirth\n      mobileInformation {\n        areaCode\n        mobileNumber\n      }\n      marketingEmails\n    }\n  }\n"): (typeof documents)["\n  query downloadUsers($adminID: ID!) {\n    userDownloadHubspot(adminID: $adminID) {\n      givenName\n      surname\n      email\n      dateOfBirth\n      mobileInformation {\n        areaCode\n        mobileNumber\n      }\n      marketingEmails\n    }\n  }\n"];
export function graphql(source: "\n  query GetUser($input: UserForAdminInput!) {\n    userForAdmin(input: $input) {\n      user {\n        ...PaginatedUserData\n        userTotalDonated\n        totalEvents\n        eventsUserHasBeenAGuestOf\n        eventsUserHasCreated\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUser($input: UserForAdminInput!) {\n    userForAdmin(input: $input) {\n      user {\n        ...PaginatedUserData\n        userTotalDonated\n        totalEvents\n        eventsUserHasBeenAGuestOf\n        eventsUserHasCreated\n      }\n    }\n  }\n"];

export function graphql(source: string): unknown;
export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;