import React from 'react';
import { RouteComponentProps } from 'react-router';

import { BackgroundImage, Wrapper, Logo } from './Styles';
import { Logo as SmyleeLogo } from '../../assets/Images';
import { Button } from '../../components';

import useStrings from '../../hooks/useStrings';

const Home: React.FC<HomeProps> = ({ history }) => {
  const [{ Home: strings }] = useStrings();

  return (
    <BackgroundImage>
      <Wrapper>
        <Logo src={SmyleeLogo} />
        <Button
          buttonText={strings.login}
          onClick={() => history.push('/admin')}
          textTransform="uppercase"
          variant="login"
          fit="stretch"
          margin="1rem 0"
          type="button"
        />
      </Wrapper>
    </BackgroundImage>
  );
};

export interface HomeProps extends RouteComponentProps {}

export default Home;
