import React, { useMemo } from 'react';
import moment from 'moment';
import {
  LineChart, Line, XAxis, YAxis, ResponsiveContainer,
} from 'recharts';

import useStrings from '../../../../hooks/useStrings';
import { useGetDonationsThisWeek } from '../../../../lib/graphql/queries';
import { getDashboardGraphDates } from '../../../../lib/Utils';

import { GraphHeading, HeadingContainer, DonationsCount } from '../Styles';

const mondayOfThisWeek = moment().startOf('isoWeek').valueOf();

const DonationsThisWeekGraph: React.FC<DonationsThisWeekGraphProps> = () => {
  const [{ Dashboard: strings }] = useStrings();

  const { data: donationsData } = useGetDonationsThisWeek({
    variables: { date: mondayOfThisWeek.toString() },
  });
  const donations = useMemo(() => (
    donationsData?.donationsThisWeek.donationsMadeThisWeek || []
  ), [donationsData?.donationsThisWeek.donationsMadeThisWeek]);

  const donationsThisWeekData = useMemo(() => (day: string) => donations
    .filter(({ lastTried }) => moment(lastTried).format('YYYY-MM-DD') === day)
    .map(({ amount }) => amount)
    .reduce((prev, curr) => (prev + curr), 0), [donations]);

  const donationsForTable = useMemo(() => (
    getDashboardGraphDates(moment(mondayOfThisWeek).format()).map((day) => ({
      day: moment(day).format('D MMM'),
      count: donationsThisWeekData(day) / 100,
    }))), [donationsThisWeekData]);

  const renderCreditPacksPurchasedGraph = () => (
    <ResponsiveContainer width="100%" height="60%">
      <LineChart
        data={donationsForTable}
        style={{ marginLeft: -30 }}
      >
        <XAxis
          dataKey="day"
          axisLine={false}
          tickLine={false}
          tick={{
            fontSize: 10, dy: 5,
          }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 10, dx: -5 }}
          tickFormatter={(tick) => `$${tick}`}
        />
        <Line type="monotone" dataKey="count" stroke="#6ABED1" dot={false} strokeWidth={3} />
      </LineChart>
    </ResponsiveContainer>
  );

  const totalCreditsPurchasedThisWeek = useMemo(() => donationsForTable
    .flatMap((row) => row.count)
    .reduce((prev, curr) => (prev + curr), 0)
    .toLocaleString(), [donationsForTable]);

  return (
    <>
      <HeadingContainer>
        <GraphHeading>{strings.donationsHeading}</GraphHeading>
      </HeadingContainer>
      <DonationsCount>{`$${totalCreditsPurchasedThisWeek}`}</DonationsCount>
      {renderCreditPacksPurchasedGraph()}
    </>
  );
};

interface DonationsThisWeekGraphProps { }

export default DonationsThisWeekGraph;
