import moment from 'moment';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Cell, Column } from 'react-table';

import {
  ContentHeader, Table, ViewProfile, ViewAllLink,
} from '../../../../components';

import useStrings from '../../../../hooks/useStrings';
import { getUserId } from '../../../../lib/Authentication';
import { SortOrder } from '../../../../lib/graphql/generated/graphql';
import { useGetPaginatedUsers } from '../../../../lib/graphql/queries';
import { User } from '../../../../lib/types';
import { displayDonation } from '../../../../lib/Utils';

const NewUsersTable: React.FC<NewUsersTableProps> = () => {
  const [{ Dashboard: strings }] = useStrings();

  const history = useHistory();
  const previousPath = history.location.pathname;

  const [getPaginatedUsers, { data: paginatedUsersData }] = useGetPaginatedUsers();
  const users = paginatedUsersData?.paginatedUsers.users || [];

  const fetchData = useCallback(
    ({ pageIndex }: { pageIndex: number; }) => {
      getPaginatedUsers({
        variables: {
          adminID: getUserId(),
          filter: 'all',
          pageNo: pageIndex,
          pageSize: 5,
          sortField: 'user_admin.createdDate',
          sortOrder: SortOrder.Asc,
        },
      });
    },
    [getPaginatedUsers],
  );

  const columns: Column<Partial<User>>[] = [
    {
      Header: strings.tableHeadings.createdDate,
      id: 'user_admin.createdDate',
      accessor: (originalRow) => originalRow.createdDate,
      Cell: ({ row }: Cell<User>) => (
        <p>{moment(row.original.createdDate).format('DD/MM/YYYY')}</p>
      ),
    },
    {
      Header: strings.tableHeadings.firstName,
      id: 'givenName',
      accessor: (originalRow) => originalRow.givenName,
    },
    {
      Header: strings.tableHeadings.lastName,
      id: 'surname',
      accessor: (originalRow) => originalRow.surname,
    },
    {
      Header: strings.tableHeadings.email,
      id: 'email',
      accessor: (originalRow) => originalRow.email,
    },
    {
      Header: strings.tableHeadings.events,
      id: 'events',
      accessor: (originalRow) => originalRow.totalEvents,
    },
    {
      Header: strings.tableHeadings.donations,
      id: 'totalDonated',
      accessor: (originalRow) => displayDonation(originalRow.userTotalDonated || 0),
    },
    {
      Header: strings.tableHeadings.rating,
      id: 'reliabilityRating',
      accessor: (originalRow) => `${originalRow.reliability}%`,
    },
    {
      Header: strings.tableHeadings.action,
      id: 'viewProfile',
      Cell: ({ row }: Cell<User>) => (
        <ViewProfile onClick={() => history.push(`/admin/user/${row.original.id}`, { previousPath })}>
          {strings.tableHeadings.viewProfile}
        </ViewProfile>
      ),
    },
  ];

  return (
    <>
      <ContentHeader contentTitle={strings.newUsersTableHeading}>
        <ViewAllLink to="/admin/users">{strings.viewAll}</ViewAllLink>
      </ContentHeader>
      <Table
        columns={columns}
        data={users}
        fetchData={fetchData}
      />
    </>
  );
};

interface NewUsersTableProps { }

export default NewUsersTable;
