import {
  AdminDataFragment,
  DonationHistoryPaymentDataFragment,
  EventDataFragment,
  GetPaginatedUsersQuery,
  GuestDataFragment,
  LocationDataFragment,
  OrganizationDataFragment,
  ReportedDataFragment,
} from '../graphql/generated/graphql';

export type Admin = AdminDataFragment;
export type User = GetPaginatedUsersQuery['paginatedUsers']['users'][number];
export type Organization = OrganizationDataFragment;
export type Location = LocationDataFragment;
export type Guest = GuestDataFragment;

export type Event = EventDataFragment;

export type Payment = DonationHistoryPaymentDataFragment;
export type ReportedContent = ReportedDataFragment;

export enum reminder {
  host,
  guest,
}

export type EventsFilterString = '' | 'all' | 'upcoming' | 'previous';
export type UsersFilterString = 'all' | 'active' | 'inactive';

export enum DonationType {
  DONATED_ANYWAY = 'donation',
  MISSED_CHECK_IN = 'guest_failed_to_show',
  HOST_CANCELLED_EVENT = 'event_cancelled',
  GUEST_CANCELLED_ATTENDANCE = 'guest_cancelled_attendance',
}
