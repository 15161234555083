import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Column, Cell } from 'react-table';
import moment from 'moment';

import {
  PageLayout, ContentWrapper, ContentHeader, Button,
  Table, ViewAllLink, ViewProfile, SearchBar,
} from '../../../components';
import { sortByType } from '../../../components/Table/Table';
import { SubLabel, DownloadLink } from './Styles';

import useStrings from '../../../hooks/useStrings';
import { getUserId } from '../../../lib/Authentication';
// import { SUBSET_SIZE } from '../../../lib/Constants';
import { useGetPaginatedUsers, useGetDownloadUsers } from '../../../lib/graphql/queries';
import { User, UsersFilterString } from '../../../lib/types';
import { displayDonation } from '../../../lib/Utils';
import { SortOrder, UserDownloadForHubspot } from '../../../lib/graphql/generated/graphql';

const Users: React.FC = () => {
  const [{ Users: strings }] = useStrings();

  const [exportData, setExportData] = useState<UserDownloadForHubspot[]>([]);
  const [noData, setNoData] = useState(false);

  const [getUsersExport, { loading: usersExportLoading }] = useGetDownloadUsers({
    onCompleted: (data) => {
      const dataToSet = data.userDownloadHubspot;
      setExportData(dataToSet);
      if (dataToSet.length === 0) setNoData(true);
    },
  });

  const headings = [
    { label: 'Given Name', key: 'givenName' },
    { label: 'Surname', key: 'surname' },
    { label: 'Mobile Area Code', key: 'mobileInformation.areaCode' },
    { label: 'Mobile Number', key: 'mobileInformation.mobileNumber' },
    { label: 'Email', key: 'email' },
    { label: 'Date of Birth', key: 'dateOfBirth' },
    { label: 'Marketing Emails', key: 'marketingEmails' },
  ];

  const history = useHistory();
  const previousPath = history.location.pathname;
  const { state }: { state: { displayHeader?: string; searchQuery?: string } } = useLocation();

  const [viewingAll, setViewingAll] = useState(!!state?.displayHeader);
  const [displayHeader, setDisplayHeader] = useState(state?.displayHeader || '');

  const [searchQuery, setSearchQuery] = useState(state?.searchQuery || undefined);

  const [maxPage, setMaxPage] = useState(1);

  const [getPaginatedUsers, { data: paginatedUsersData }] = useGetPaginatedUsers();
  const users = paginatedUsersData?.paginatedUsers.users || [];

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy }: {
      pageIndex: number; pageSize: number; sortBy: sortByType;
    }) => {
      let filter: UsersFilterString = 'all';
      switch (displayHeader) {
        case strings.activeUsers:
          filter = 'active';
          break;
        case strings.inactiveUsers:
          filter = 'inactive';
          break;
        default:
          filter = 'all';
          break;
      }
      getPaginatedUsers({
        variables: {
          adminID: getUserId(),
          filter,
          pageNo: pageIndex,
          pageSize,
          searchQuery,
          sortField: (sortBy.length > 0 ? sortBy[0].id : 'user_admin.createdDate'),
          // eslint-disable-next-line no-nested-ternary
          sortOrder: (sortBy.length > 0 ? (sortBy[0].desc ? SortOrder.Desc : SortOrder.Asc) : SortOrder.Desc),
        },
      });
    },
    [getPaginatedUsers, strings, displayHeader, searchQuery],
  );

  useEffect(() => {
    if (paginatedUsersData) setMaxPage(paginatedUsersData.paginatedUsers.totalPages);
  }, [paginatedUsersData]);

  const onViewAll = (set: string) => {
    switch (set) {
      case 'all':
        setDisplayHeader(strings.allUsers);
        break;
      case 'active':
        setDisplayHeader(strings.activeUsers);
        break;
      case 'inactive':
        setDisplayHeader(strings.inactiveUsers);
        break;
      default:
        break;
    }
    setViewingAll(true);
  };

  const columns: Column<Partial<User>>[] = [
    {
      Header: strings.tableHeadings.createdDate,
      id: 'user_admin.createdDate',
      accessor: (originalRow) => originalRow.createdDate,
      Cell: ({ row }: Cell<User>) => (
        <p>{moment(row.original.createdDate).format('DD/MM/YYYY')}</p>
      ),
    },
    {
      Header: strings.tableHeadings.firstName,
      id: 'user_admin.givenName',
      accessor: (originalRow) => originalRow.givenName,
    },
    {
      Header: strings.tableHeadings.lastName,
      id: 'user_admin.surname',
      accessor: (originalRow) => originalRow.surname,
    },
    {
      Header: strings.tableHeadings.email,
      id: 'user_admin.email',
      accessor: (originalRow) => originalRow.email,
    },
    {
      Header: strings.tableHeadings.events,
      id: 'user_admin.totalEvents',
      accessor: (originalRow) => originalRow.totalEvents,
    },
    {
      Header: strings.tableHeadings.donations,
      id: 'user_admin.userTotalDonated',
      accessor: (originalRow) => displayDonation(originalRow.userTotalDonated || 0),
    },
    {
      Header: strings.tableHeadings.rating,
      id: 'user_admin.reliability',
      accessor: (originalRow) => `${originalRow.reliability}%`,
    },
    {
      Header: strings.tableHeadings.action,
      id: 'viewProfile',
      Cell: ({ row }: Cell<User>) => (
        <ViewProfile onClick={() => history.push(`/admin/user/${row.original.id}`, { displayHeader, previousPath, searchQuery })}>
          {strings.viewProfile}
        </ViewProfile>
      ),
    },
  ];

  return (
    <PageLayout loading={false} pageTitle={strings.pageTitle}>
      {viewingAll ? (
        <ContentWrapper>
          <ContentHeader
            contentTitle={displayHeader}
            hasBackButton
            clickFunction={() => {
              setViewingAll(false);
              setDisplayHeader('');
            }}
          >
            <SearchBar handleSearch={setSearchQuery} searchQuery={searchQuery} />
            {/* <Button
              buttonText={strings.filter}
              // TODO: filtering
              onClick={() => { }}
              variant="primary"
              alignBottom
              disabled
            /> */}
          </ContentHeader>
          <Table
            data={users.filter((user) => user.givenName !== 'Smylee' && user.surname !== 'User')}
            paginated
            columns={columns}
            fetchData={fetchData}
            pageCount={maxPage}
            manualPagination
            manualSortBy
          />
        </ContentWrapper>
      ) : (
        <>
          {exportData.length === 0 ? (
            <>
              <Button
                buttonText={strings.export}
                variant="secondary"
                onClick={() => getUsersExport({
                  variables: {
                    adminID: getUserId(),
                  },
                })}
                disabled={usersExportLoading}
                alignRight
              />
              {noData && <SubLabel>{strings.noData}</SubLabel>}
            </>
          ) : (
            <DownloadLink
              data={exportData}
              headers={headings}
              filename="usersForHubSpot.csv"
            >
              {strings.download}
            </DownloadLink>
          )}
          <ContentWrapper>
            <ContentHeader contentTitle={strings.allUsers}>
              <ViewAllLink to="#" onClick={() => onViewAll('all')}>{strings.viewAll}</ViewAllLink>
            </ContentHeader>
            <Table
              columns={columns}
              data={users}
              fetchData={fetchData}
            />
          </ContentWrapper>
          {/* <ContentWrapper>
            <ContentHeader contentTitle={strings.activeUsers}>
            <ViewAllLink to="#" onClick={() => onViewAll('active')}>{strings.viewAll}</ViewAllLink>
            </ContentHeader>
            <Table
              columns={columns}
              data={users.slice(0, SUBSET_SIZE)} // TODO: filtered
              fetchData={fetchData}
            />
          </ContentWrapper> */}
          {/* <ContentWrapper>
            <ContentHeader contentTitle={strings.inactiveUsers}> */}
          {/* TODO: Inactive field not yet implemented */}
          {/* <ViewAllLink to="#" onClick={() => onViewAll('inactive')}>
                {strings.viewAll}
              </ViewAllLink> */}
          {/* </ContentHeader>
            <Table
              columns={columns}
              data={[]} // TODO: replace mock data. Inactive field not yet implemented
              fetchData={fetchData}
            />
            </ContentWrapper> */}
        </>
      )}
    </PageLayout>
  );
};
export default Users;
