import { graphql } from '../generated';
import { createUseMutation, createUseQuery } from './helpers';

export const REPORTED_CONTENT_DATA_FRAGMENT = graphql(`
fragment ReportedData on Reported {
  id
  reportedReason
  content
  reporter { ...UserData }
  reported { ...UserData }
  dateCreated
  isResolved
}
`);

export const GET_REPORTED_CONTENT = graphql(`
  query GetAllReportedContent {
    allReportedContent {
      reportedContents {
        ...ReportedData
      }
    }
  }
`);
export const useGetAllReportedContent = createUseQuery(GET_REPORTED_CONTENT);

export const MARK_RESOLVED = graphql(`
  mutation markAsResolved($input: MarkResolvedInput!) {
    markAsResolved(input: $input) {
      reportedContent {
        ...ReportedData
      }
    }
  }
`);
export const useMarkAsResolved = createUseMutation(MARK_RESOLVED);

export const SEND_EMAIL_REPLY = graphql(`
  mutation sendEmailReply($input: EmailReplyInput!) {
    sendEmailReply(input: $input) {
      success
    }
  }
`);
export const useSendEmailReply = createUseMutation(SEND_EMAIL_REPLY);
