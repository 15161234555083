import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const ProfileWidgetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 860px;
  align-items: center;
`;

export const BackIcon = styled.img`
  cursor: pointer;
  height: 25px;
  margin: auto 2.5% auto 1.5%;
`;

export const CharityLogo = styled.img`
  height: 90px;
  width: 90px;
  padding: 0.5em 0;
`;

export const CharityDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0 auto 1%;
  padding: 0.5em;
`;

export const PrimaryDetail = styled.h2`
  color: ${({ theme }) => theme.palette.header};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontScale.L}rem;
  white-space: nowrap;
`;

export const SecondaryDetail = styled.p`
  color: ${({ theme }) => theme.palette.subheader};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  margin-top: 0.4rem;
`;

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0 auto 10%;
  padding: 0.5em;
`;

export const StatsHeading = styled.h3`
  color: ${({ theme }) => theme.palette.header};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  white-space: nowrap;
  margin-bottom: 1.2rem;
`;

export const StatsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StatColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
`;

export const StatValue = styled.p`
  color: ${({ theme }) => theme.palette.header};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontScale.L}rem;
  white-space: nowrap;
  margin-bottom: 0.1rem;
`;

export const StatLabel = styled.p`
  color: ${({ theme }) => theme.palette.subheader};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  margin-top: 0.4rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 3% auto auto;
  width: fit-content;
`;

export const StripeLinkMessage = styled.div`
  color: ${({ theme }) => theme.palette.header};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  margin: 1rem 0;
`;

export const StripeLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-family: ${({ theme }) => theme.fontFamily.body};
`;
