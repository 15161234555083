import { BorderStyles } from '../../lib/types/StyleTypes';

const defBorderStyles: BorderStyles = {
  primary: '1px solid #979797',
  secondary: '1px solid #E9E9E9',
  tertiary: '1px solid #FD7B62',
  error: '1px solid #BE2D2D',
  disabled: '',
};

export default defBorderStyles;
