import React from 'react';

import { PageLayout } from '../../../components';
import {
  DashboardContainer, Count, CountLabel,
  TotalUsersContainer, UserIcon,
  TotalEventsContainer, CalendarIcon,
  TotalDonationsContainer, DollarIcon,
  UsersGraphContainer,
  ActivityContainer,
  DonationsGraphContainer,
  NewUsersTableContainer,
  TotalsGridContainer,
  TotalRevenueContainer, RevenueDollarIcon,
} from './Styles';
import { DollarDashboard, UserDashboard, CalendarDashboard } from '../../../assets/Icons';
import NewUsersGraph from './NewUsersGraph';
import DonationsThisWeekGraph from './DonationsThisWeekGraph';
import NewUsersTable from './NewUsersTable';
import ActivityThisWeek from './ActivityThisWeek';

import useStrings from '../../../hooks/useStrings';
import {
  useGetTotalUsers,
  useGetTotalEvents,
  useGetTotalDonations,
  useGetTotalSmyleeRevenue,
} from '../../../lib/graphql/queries';
import { displayDonation } from '../../../lib/Utils';
import { getAuthUser } from '../../../lib/Authentication';

const Dashboard: React.FC = () => {
  const [{ Dashboard: strings }] = useStrings();

  const { data: totalUserData } = useGetTotalUsers();
  const { data: totalEventData } = useGetTotalEvents();
  const { data: totalDonationsData } = useGetTotalDonations();
  const { data: totalRevenueData } = useGetTotalSmyleeRevenue({ variables: { adminID: getAuthUser()?.uid || '' } });
  const totalDonation = totalDonationsData?.totalDonatedSmyleeWide.smyleeWideDonations;

  return (
    <PageLayout loading={false} pageTitle={strings.pageTitle}>
      <DashboardContainer>
        <TotalsGridContainer>
          <TotalUsersContainer>
            <UserIcon src={UserDashboard} />
            <div>
              <Count>{totalUserData?.countTotalUsers.totalUsers || 0}</Count>
              <CountLabel>{strings.totalUsers}</CountLabel>
            </div>
          </TotalUsersContainer>
          <TotalEventsContainer>
            <CalendarIcon src={CalendarDashboard} />
            <div>
              <Count>{totalEventData?.countTotalEvents.totalEvents || 0}</Count>
              <CountLabel>{strings.totalEvents}</CountLabel>
            </div>
          </TotalEventsContainer>
          <TotalDonationsContainer>
            <DollarIcon src={DollarDashboard} />
            <div>
              <Count>{displayDonation(totalDonation || 0)}</Count>
              <CountLabel>{strings.totalDonations}</CountLabel>
            </div>
          </TotalDonationsContainer>
          <TotalRevenueContainer>
            <RevenueDollarIcon src={DollarDashboard} />
            <div>
              <Count>{displayDonation(totalRevenueData?.smyleeRevenue.smyleeRevenue || 0)}</Count>
              <CountLabel>{strings.totalRevenue}</CountLabel>
            </div>
          </TotalRevenueContainer>
        </TotalsGridContainer>
        <UsersGraphContainer>
          <NewUsersGraph />
        </UsersGraphContainer>
        <ActivityContainer>
          <ActivityThisWeek />
        </ActivityContainer>
        <DonationsGraphContainer>
          <DonationsThisWeekGraph />
        </DonationsGraphContainer>
        {/* TODO: to be implemented when users has been done */}
        <NewUsersTableContainer>
          <NewUsersTable />
        </NewUsersTableContainer>
      </DashboardContainer>
    </PageLayout>
  );
};

export default Dashboard;
