import React from 'react';
import {
  ResponsiveContainer, AreaChart, XAxis, YAxis, Area, Tooltip, CartesianGrid, TooltipProps,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { TooltipContainer, TooltipValue } from '../Styles';

const LineGraph: React.FC<LineGraphProps> = ({ data }) => {
  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContainer>
          <TooltipValue>{`$${payload[0].value}`}</TooltipValue>
        </TooltipContainer>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height="80%">
      <AreaChart data={data} style={{ marginLeft: -30 }}>
        <defs>
          <linearGradient id="value" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FD7B62" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#FD7B62" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="9" vertical={false} stroke="#E7E7E7" />
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={{
            fontSize: 10, dy: 5,
          }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 10, dx: -5 }}
          tickFormatter={(tick) => `$${tick}`}
        />
        <Area type="monotone" dataKey="value" stroke="#FD7B62" dot={false} strokeWidth={3} fill="url(#value)" />
        <Tooltip content={<CustomTooltip />} cursor={{ stroke: '#FD7B62', strokeDasharray: '5' }} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

interface LineGraphProps {
  data: { name: string; value: number }[]
}

export default LineGraph;
