/* eslint-disable no-nested-ternary */
import React from 'react';

import {
  HeadingContainer, StatsGraphHeading, NoResultsHeading, LoadingSpinner,
} from '../Styles';
import LineGraph from '../LineGraph';

import useStrings from '../../../../hooks/useStrings';
import { useGetDonationsByMonth } from '../../../../lib/graphql/queries';

const DonationAmountMonthly: React.FC<DonationAmountMonthlyProps> = () => {
  const [{ Statistics: strings }] = useStrings();

  const { data: donationFreqData, loading } = useGetDonationsByMonth();
  const monthlyDonations = donationFreqData?.donationsByMonth.donationsByMonth
    .map((monthlyDonation) => ({
      name: monthlyDonation.Month,
      value: +monthlyDonation.Amount / 100,
    })) || [];

  return (
    <>
      <HeadingContainer>
        <StatsGraphHeading>{strings.donationAmount}</StatsGraphHeading>
      </HeadingContainer>
      {loading
        ? <LoadingSpinner />
        : monthlyDonations.length === 0
          ? <NoResultsHeading>{strings.noResults}</NoResultsHeading>
          : <LineGraph data={monthlyDonations} />}
    </>
  );
};

interface DonationAmountMonthlyProps { }

export default DonationAmountMonthly;
