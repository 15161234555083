import React from 'react';
import {
  ButtonIcon,
  ButtonText,
  ButtonStyled,
  StyledButtonProps,
  TextProps,
  IconProps,
} from './Styles';

const Button: React.FC<ButtonProps> = ({
  buttonText,
  icMargin,
  icHeight,
  icWidth,
  hasIcon,
  iconSrc,
  txtMargin,
  children,
  type = 'button',
  ...props
}) => (
  <ButtonStyled {...props} type={type}>
    <ButtonIcon
      hasIcon={hasIcon}
      icMargin={icMargin}
      icWidth={icWidth}
      icHeight={icHeight}
      src={iconSrc}
    />
    <ButtonText txtMargin={txtMargin}>
      {buttonText}
      {children}
    </ButtonText>
  </ButtonStyled>
);

export interface ButtonProps extends StyledButtonProps, IconProps, TextProps {
  buttonText?: string;
  onClick?: () => void;
  hasIcon?: boolean;
  iconSrc?: string;
  type?: 'submit' | 'button' | 'reset';
}

export default Button;
