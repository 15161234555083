import styled from 'styled-components/macro';
import { PlaceholderProfile } from '../../../assets/Images';

const StyledDashboardCard = styled.div`
  width: calc(100% - 3rem);
  height: calc(100% - 3rem);
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 0.36rem;
  border: ${({ theme }) => theme.borderStyles.secondary};
  padding: 1.5rem 2rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  color: ${({ theme }) => theme.palette.header};
  display: flex;
`;

export const SettingsDashboard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 1fr 3fr;
  grid-template-areas: 
    "admins addAdmin"
    "logout addAdmin"
    "changePassword updateDetails";   
  grid-gap: 2rem 3rem;
  place-items: stretch stretch;
  place-content: center center;
  width: calc(100% - 1rem);
  height: auto;
`;

export const SectionHeading = styled.h3`
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  margin-bottom: 1.5rem;
`;

export const ProfilePicture = styled.img`
  height: 108px;
  width: 108px;
  border-radius: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const ProfilePicturePlaceholder = styled.div`
  height: 108px;
  width: 108px;
  background: center / contain no-repeat url(${PlaceholderProfile});
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const ShowPasswordContainer = styled.div`
  position: relative;
`;

export const ShowPasswordText = styled.p`
  position: absolute;
  margin-left: -53px;
  margin-top: -7px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  color: ${({ theme }) => theme.palette.label};
  opacity: 0.5;
  line-height: 16px;
  &:hover {
    cursor: pointer;
  }
`;

export const AdminsWrapper = styled(StyledDashboardCard)`
  grid-area: admins;
`;

export const AddAdminWrapper = styled(StyledDashboardCard)`
  grid-area: addAdmin;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface InputContainerProps {
  fullWidth?: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '65%')};
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
`;

export const NameInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LogoutWrapper = styled(StyledDashboardCard)`
  grid-area: logout;
`;

export const LoggedIn = styled.div`
  font-size: ${({ theme }) => theme.fontScale.XXS}rem;
  line-height: ${({ theme }) => theme.fontScale.L}rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-top: auto;
`;

export const UserName = styled.div`
  margin-bottom: auto;
  font-size: ${({ theme }) => theme.fontScale.L}rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const LogoutText = styled.div`
  width: 50%;
  flex-direction: column;
  height: auto;
  margin: auto auto auto 0;
  justify-content: center;
  align-items: flex-start;
  display: flex;
`;

export const ChangePasswordWrapper = styled(StyledDashboardCard)`
  grid-area: changePassword;
`;

export const UpdateDetailsWrapper = styled(StyledDashboardCard)`
  grid-area: updateDetails;
`;

export const Gap = styled.div`
  width: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

export const ListContainer = styled.div`
  width: 100%;
  height: 11rem;
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  margin-top: -${({ theme }) => theme.spacing.M}rem;
  align-items: flex-start;
`;

export const AdminCard = styled.div`
  float: left;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 13px 0;
`;

export const ListProfilePicture = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 100%;
`;

export const ListProfilePlaceholder = styled.div`
  height: 32px;
  width: 32px;
  background: center / contain no-repeat url(${PlaceholderProfile});
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
`;

export const Name = styled.p`
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  color: ${({ theme }) => theme.palette.header};
  margin-bottom: 0.2rem;
`;

export const Email = styled.p`
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  color: ${({ theme }) => theme.palette.subheader};
`;

export const HiddenFileInput = styled.input`
  display: none;
`;
