import firebase from 'firebase/app';
import 'firebase/auth';

import FirebaseConfig from './firebase-config.json';

// Firebase Initialize
if (!firebase.apps.length) firebase.initializeApp(FirebaseConfig);

// App Authentication Helpers

export const isSignedIn = (): boolean => (
  !!firebase.auth().currentUser && !firebase.auth().currentUser?.isAnonymous
);

export const getAuthUser = (): firebase.User | null => firebase.auth().currentUser;

export const getUserId = (): string => firebase.auth().currentUser?.uid || '';

/** Auth Change Listener */
export const onAuthChange = (callback: (a: firebase.User | null) => void): firebase.Unsubscribe => (
  firebase.auth().onAuthStateChanged(callback)
);

/** Sign up with Username and Password */
export const signUp = (email: string, password: string): Promise<firebase.auth.UserCredential> => (
  firebase.auth()
    .createUserWithEmailAndPassword(email, password)
);

/** Sign In Anonymously */
export const signInAnon = async (): Promise<firebase.auth.UserCredential> => (
  firebase.auth()
    .setPersistence(firebase.auth.Auth.Persistence.NONE)
    .then(() => firebase.auth().signInAnonymously())
);

/** Sign in with Username and Password */
export const signIn = (email: string, password: string): Promise<firebase.auth.UserCredential> => (
  firebase.auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => firebase.auth().signInWithEmailAndPassword(email, password))
);

/** Sign in with Facebook */
export const signInWithFacebook = (socialToken: string): Promise<firebase.auth.UserCredential> => (
  firebase.auth()
    .signInWithCredential(firebase.auth.FacebookAuthProvider.credential(socialToken))
);

/** Sign in with Facebook */
export const signInWithGoogle = (socialToken: string): Promise<firebase.auth.UserCredential> => (
  firebase.auth()
    .signInWithCredential(firebase.auth.GoogleAuthProvider.credential(socialToken))
);

/** Sign out */
export const signOut = (): Promise<void> => firebase.auth().signOut();

/** Reset Password */
export const resetPassword = (email: string): Promise<void> => (
  firebase.auth().sendPasswordResetEmail(email)
);

// /** Reauthenticate User */
const reAuthenticate = (password: string) => {
  const authUser = getAuthUser();

  return (authUser && authUser.email)
    ? authUser.reauthenticateWithCredential(
      firebase.auth.EmailAuthProvider.credential(authUser.email, password),
    )
    : undefined;
};

// /** Change Password */
export const changePassword = (
  oldPassword: string, newPassword: string,
): Promise<void | undefined> | undefined => (
  reAuthenticate(oldPassword)?.then((userCredentials) => (
    userCredentials?.user?.updatePassword(newPassword)))
);

/** Update user email */
export const updateUserEmail = (
  newEmail: string, password: string,
): Promise<void | undefined> | undefined => (
  reAuthenticate(password)?.then((userCreds) => (
    userCreds?.user?.updateEmail(newEmail)))
);

export default {
  isSignedIn,
  getAuthUser,
  getUserId,
  onAuthChange,
  signUp,
  signInAnon,
  signInWithFacebook,
  signInWithGoogle,
  signOut,
  resetPassword,
  updateUserEmail,
};
