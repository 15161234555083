import { graphql } from '../generated';
import { createUseLazyQuery } from './helpers';

export const GET_EXPORT_PAYMENT_DATA = graphql(`
  query GetAllPayments($input: AllPaymentsInput!, $adminID: ID!) {
    allPayments(input: $input, adminID: $adminID) {
      payments {
        ...PaymentData
      }
    }
  }
`);
export const useGetPaymentExports = createUseLazyQuery(GET_EXPORT_PAYMENT_DATA);
