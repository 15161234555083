import React, { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import moment from 'moment';

import {
  ContentWrapper, ContentHeader, Table, PageLayout, EventStatus,
  // Button,
} from '../../../../components';
import {
  ProfileWidgetWrapper,
  BackIcon,
  UserProfilePhoto,
  UserDetailContainer,
  PrimaryDetail,
  SecondaryDetail,
  StatsContainer,
  StatsHeading,
  StatsWrapper,
  StatColumn,
  StatValue,
  StatLabel,
  // ButtonWrapper,
} from './Styles';
import { BackIcon as BackIconImage } from '../../../../assets/Icons';

import useStrings from '../../../../hooks/useStrings';
import { useGetUser, useGetEventsByUser, useGetPaymentHistory } from '../../../../lib/graphql/queries';
import { Event, Guest } from '../../../../lib/types';
import {
  displayDonation, displayEventID, hasAnyGuestRSVPResponded, getTimeInLocationTimezone,
} from '../../../../lib/Utils';
import { getUserId } from '../../../../lib/Authentication';

const UserView: React.FC<UserViewProps> = () => {
  const [{ UserProfile: strings }] = useStrings();

  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const { state }: {
    state?: { previousPath?: string; displayHeader?: string; searchQuery?: string };
  } = useLocation();

  const adminID = getUserId();

  const { data: userData, loading: userLoading } = useGetUser({
    variables: { input: { id, adminID } },
    skip: !adminID,
  });
  const { user } = userData?.userForAdmin || {};

  const { data: eventsData, loading: eventsLoading } = useGetEventsByUser({
    variables: { input: { id } },
  });
  const events = eventsData?.eventsByUser.events || [];

  const { data: donationData, loading: donationsLoading } = useGetPaymentHistory({
    variables: { input: { id } },
  });
  const usersPayments = useMemo(() => (
    donationData?.donationHistory.payments || []
  ), [donationData?.donationHistory.payments]);

  const displayEventStatus = useMemo(() => (
    guests: Guest[], paymentType?: string,
    isCancelledEvent?: boolean, checkInTime?: number, eventStartTime?: number,
  ) => {
    if (paymentType === 'guest_failed_to_show') {
      return <EventStatus variant={paymentType}>{strings.eventStatus.pledgeTaken}</EventStatus>;
    }
    if (paymentType === 'donation') {
      return <EventStatus variant={paymentType}>{strings.eventStatus.donatedAnyway}</EventStatus>;
    }
    if (paymentType === 'event_cancelled' || isCancelledEvent) {
      return <EventStatus variant={paymentType || 'event_cancelled'}>{strings.eventStatus.eventCancelled}</EventStatus>;
    }
    if (paymentType === 'guest_cancelled_attendance') {
      return (
        <EventStatus variant={paymentType}>
          {strings.eventStatus.cancelledAttendance}
        </EventStatus>
      );
    }
    if (moment(eventStartTime).isAfter(moment())) {
      return <EventStatus variant="waiting">{strings.eventStatus.waiting}</EventStatus>;
    }
    if (!hasAnyGuestRSVPResponded(guests)) {
      return <EventStatus variant="didnt_proceed">{strings.eventStatus.didntProceed}</EventStatus>;
    }
    if (moment(checkInTime).isBefore(moment()) && !paymentType) {
      return <EventStatus variant="no_donation">{strings.eventStatus.noDonation}</EventStatus>;
    }

    return '';
  }, [strings]);

  const columns: Column<Partial<Event>>[] = useMemo(() => [
    {
      Header: strings.eventsTable.eventID,
      id: 'id',
      accessor: (row) => displayEventID(row.id || ''),
    },
    {
      Header: strings.eventsTable.eventTitle,
      id: 'title',
      accessor: (row) => (
        row.title && row.title?.length > 24
          ? `${row.title.slice(0, 24)}...`
          : row.title),
    },
    {
      Header: strings.eventsTable.eventType,
      id: 'type',
      accessor: (row) => row.type,
    },
    {
      Header: strings.eventsTable.location,
      id: 'locationName',
      accessor: (row) => (
        row.location?.name && row.location?.name.length > 24
          ? `${row.location?.name.slice(0, 24)}...`
          : row.location?.name),
    },
    {
      Header: strings.eventsTable.startDate,
      id: 'date',
      accessor: (row) => row.date,
      Cell: ({ row }: Cell<Event>) => (
        <p>
          {getTimeInLocationTimezone(row.original.location.timezoneID || 'Australia/Melbourne', row.original.date).format('DD/MM/YYYY z')}
        </p>
      ),
    },
    {
      Header: strings.eventsTable.endDate,
      id: 'endDate',
      accessor: (row) => row.endDate,
      Cell: ({ row }: Cell<Event>) => (
        <p>
          {getTimeInLocationTimezone(row.original.location.timezoneID || 'Australia/Melbourne', row.original.endDate).format('DD/MM/YYYY z')}
        </p>
      ),
    },
    {
      Header: strings.eventsTable.startTime,
      id: 'startTime',
      accessor: (row) => moment(row.startTime).format('HH:mm'),
      Cell: ({ row }: Cell<Event>) => (
        <p>
          {getTimeInLocationTimezone(row.original.location.timezoneID || 'Australia/Melbourne', row.original.startTime).format('HH:mm z')}
        </p>
      ),
    },
    {
      Header: strings.eventsTable.endTime,
      id: 'endTime',
      accessor: (row) => moment(row.endTime).format('HH:mm'),
      Cell: ({ row }: Cell<Event>) => (
        <p>
          {getTimeInLocationTimezone(row.original.location.timezoneID || 'Australia/Melbourne', row.original.endTime).format('HH:mm z')}
        </p>
      ),
    },
    {
      Header: strings.eventsTable.donation,
      id: 'pledgeAmount',
      accessor: (row) => displayDonation(row.pledgeAmount || 0),
    },
    {
      Header: strings.eventsTable.status,
      id: 'status',
      Cell: ({ row }: Cell<Event>) => (
        displayEventStatus(
          row.original.guests || [],
          usersPayments.find((payment) => payment.eventID === row.original.id)?.type,
          row.original.isCancelled,
          row.original.checkInTime,
          row.original.startTime,
        )
      ),
    },
  ], [strings, usersPayments, displayEventStatus]);

  const usersMobileInfo = `${user?.mobileInformation.areaCode} ${user?.mobileInformation.mobileNumber}` || '';

  return (
    <PageLayout
      pageTitle={strings.pageHeading}
      loading={userLoading || eventsLoading || donationsLoading}
    >
      <ContentWrapper>
        <ProfileWidgetWrapper>
          <BackIcon
            src={BackIconImage}
            onClick={() => history.push(`${state?.previousPath || '/admin/users'}`, {
              displayHeader: state?.displayHeader,
              searchQuery: state?.searchQuery,
            })}
          />
          <UserProfilePhoto src={user?.profilePhoto || undefined} />
          <UserDetailContainer>
            <PrimaryDetail>{`${user?.givenName} ${user?.surname}`}</PrimaryDetail>
            <SecondaryDetail>{user?.email}</SecondaryDetail>
            <SecondaryDetail>{usersMobileInfo}</SecondaryDetail>
          </UserDetailContainer>
          <StatsContainer>
            <StatsHeading>{strings.stats}</StatsHeading>
            <StatsWrapper>
              <StatColumn>
                <StatValue>{`${user?.reliability}%`}</StatValue>
                <StatLabel>{strings.rating}</StatLabel>
              </StatColumn>
              <StatColumn>
                <StatValue>{user?.totalEvents}</StatValue>
                <StatLabel>{strings.events}</StatLabel>
              </StatColumn>
              <StatColumn>
                <StatValue>{displayDonation(user?.userTotalDonated || 0)}</StatValue>
                <StatLabel>{strings.donations}</StatLabel>
              </StatColumn>
            </StatsWrapper>
          </StatsContainer>
          {/* TODO: suspend users */}
          {/* <ButtonWrapper>
            <Button
              buttonText={user?.disabled ? strings.activateUser : strings.deactivateUser}
              variant="outlined"
              fit="stretch"
              disabled
            />
            <Button
              buttonText={strings.delete}
              variant="outlined"
              fit="stretch"
              disabled
            />
          </ButtonWrapper> */}
        </ProfileWidgetWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <ContentHeader contentTitle={strings.eventsTable.heading} />
        <Table
          data={events}
          columns={columns}
          paginated
        />
      </ContentWrapper>
    </PageLayout>
  );
};

export interface UserViewProps { }

export default UserView;
