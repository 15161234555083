import React from 'react';

import { PageLayout, Button } from '../../../components';
import {
  AddAdminWrapper,
  AdminsWrapper,
  ChangePasswordWrapper,
  LogoutWrapper, LogoutText, LoggedIn, UserName,
  UpdateDetailsWrapper,
  SettingsDashboard,
} from './Styles';
import AddAdmin from './AddAdmin';
import ChangePassword from './ChangePassword';
import ChangeDetails from './ChangeDetails';
import Admins from './Admins';

import useStrings from '../../../hooks/useStrings';
import { signOut, getUserId } from '../../../lib/Authentication';
import { useGetAdmin } from '../../../lib/graphql/queries';

const Settings: React.FC = () => {
  const [{ Settings: strings }] = useStrings();
  const authID = getUserId();

  const { data } = useGetAdmin({ variables: { input: { id: authID } } });
  const admin = data?.admin.admin;

  return (
    <PageLayout loading={false} pageTitle={strings.pageTitle}>
      <SettingsDashboard>
        <AdminsWrapper>
          <Admins />
        </AdminsWrapper>
        <AddAdminWrapper>
          <AddAdmin />
        </AddAdminWrapper>
        <LogoutWrapper>
          <LogoutText>
            <LoggedIn>{strings.logOut.loggedInAs}</LoggedIn>
            <UserName>{`${admin?.firstName.charAt(0) || ''} ${admin?.surname || ''}`}</UserName>
          </LogoutText>
          <Button buttonText={strings.logOut.logoutButton} onClick={signOut} variant="outlined" width="50%" alignCenter />
        </LogoutWrapper>
        <ChangePasswordWrapper>
          <ChangePassword />
        </ChangePasswordWrapper>
        <UpdateDetailsWrapper>
          <ChangeDetails />
        </UpdateDetailsWrapper>
      </SettingsDashboard>
    </PageLayout>
  );
};

export default Settings;
