import { graphql } from '../generated';
import { createUseLazyQuery, createUseQuery } from './helpers';

export const USER_DATA_FRAGMENT = graphql(`
fragment UserData on User {
  id
  givenName
  surname
  email
  mobileInformation {
    areaCode
    mobileNumber
  }
  profilePhoto
  reliability
  createdDate
}
`);

export const PAGINATED_USER_DATA_FRAGMENT = graphql(`
fragment PaginatedUserData on PaginatedUser {
  id
  givenName
  surname
  email
  mobileInformation {
    areaCode
    mobileNumber
  }
  profilePhoto
  reliability
}
`);

export const GET_PAGINATED_USERS = graphql(`
  query GetPaginatedUsers($adminID: String!, $pageNo: Int!, $pageSize: Int!, $filter: String, $searchQuery: String, $sortField: String, $sortOrder: SortOrder) {
    paginatedUsers(adminID: $adminID, pageNo: $pageNo, pageSize: $pageSize, filter: $filter, searchQuery: $searchQuery, sortField: $sortField, sortOrder: $sortOrder) {
      users {
        ...PaginatedUserData
        userTotalDonated
        totalEvents
        eventsUserHasBeenAGuestOf
        eventsUserHasCreated
        createdDate
      }
      totalPages
    }
  }
`);
export const useGetPaginatedUsers = createUseLazyQuery(GET_PAGINATED_USERS);

export const GET_USERS_DOWNLOAD_ADMIN = graphql(`
  query downloadUsers($adminID: ID!) {
    userDownloadHubspot(adminID: $adminID) {
      givenName
      surname
      email
      dateOfBirth
      mobileInformation {
        areaCode
        mobileNumber
      }
      marketingEmails
    }
  }
`);
export const useGetDownloadUsers = createUseLazyQuery(GET_USERS_DOWNLOAD_ADMIN);

export const GET_USER = graphql(`
  query GetUser($input: UserForAdminInput!) {
    userForAdmin(input: $input) {
      user {
        ...PaginatedUserData
        userTotalDonated
        totalEvents
        eventsUserHasBeenAGuestOf
        eventsUserHasCreated
      }
    }
  }
`);
export const useGetUser = createUseQuery(GET_USER);
