import * as CSS from 'csstype';
import styled, { css } from 'styled-components/macro';

// ------------------------------------ Reusable Button

// -------------- Button Props
export interface StyledButtonProps {
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  border?: string;
  color?: string;
  bg?: string;
  variant?: Variant; // primary | secondary | outlined | pagination
  shape?: Shape; // circle | square | rect
  fit?: Fit; // fill | contain | stretch
  corner?: Corner; // sharp | rounded
  disabled?: boolean;
  size?: string;
  fontWeight?: CSS.FontWeightProperty;
  fontSize?: CSS.FontSizeProperty<number>;
  textTransform?: string;
  alignLeft?: boolean;
  alignRight?: boolean;
  alignTop?: boolean;
  alignBottom?: boolean;
  alignCenter?: boolean;
}

// -------------- Button Icon Props
export interface IconProps {
  icWidth?: string;
  icHeight?: string;
  icMargin?: string;
  hasIcon?: boolean;
}

// -------------- Button Text Props
export interface TextProps {
  txtMargin?: string;
}

// -------------- Icon Component
export const ButtonIcon = styled.img<IconProps>`
  height: ${({ icHeight }) => (icHeight || '75%')};
  margin: ${({ hasIcon }) => (hasIcon ? '38%' : '0')};
  width: ${({ icWidth }) => (icWidth || 'auto')};
  object-fit: contain;
  visibility: ${({ hasIcon }) => (hasIcon ? 'visible' : 'hidden')};
`;

// -------------- Text Component
export const ButtonText = styled.div<TextProps>`
  margin: ${({ txtMargin }) => (txtMargin || 'auto')};
  text-align: center;
`;

// ---------------------------- Defined types for Button Component

// ---------------------------------- Fit
export const fits = ['fill', 'contain', 'stretch'] as const;
export type Fit = typeof fits[number];

const fill = css`
  width: auto;
  height: 100%;
`;

const contain = css`
  width: fit-content;
`;

const stretch = css`
  width: 100%;
`;

// ---------------------------------- Shape
export const shapes = ['rect', 'circle', 'square'] as const;
export type Shape = typeof shapes[number];

const square = css<StyledButtonProps>`
  max-width: ${({ size }) => (size || '')};
  max-height: ${({ size }) => (size || '')};
  width: ${({ size }) => (size || '')};
  height: ${({ size }) => (size || '')};
  min-width: ${({ size }) => (size || '')};
  min-height: ${({ size }) => (size || '')};
`;

const rect = css`
  height: 3rem;
  width: 100%;
`;

const circle = css`
  border-radius: 50%;
`;

// ---------------------------------- Corners
export const corners = ['sharp', 'rounded'] as const;
export type Corner = typeof corners[number];

const sharp = css`
  border-radius: 0;
`;

const rounded = css`
  border-radius: 0.3rem;
`;

// ---------------------------------- Variants
export const variants = [
  'primary',
  'secondary',
  'pagination',
  'modal',
  'remove',
  'outlined',
  'login',
] as const;
export type Variant = typeof variants[number];

// -------------- PRIMARY --> Orange bg | white text
const primary = css`
  background-color: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.modalButtonText};
  
  &:hover {
    transform: scale(1.02);
    filter: contrast(110%);
  }
    
  &:active {
    transform: scale(0.95);
  }
`;

// -------------- OUTLINED --> White bg | orange border & dark grey text
const secondary = css`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  background-color: ${({ theme }) => theme.palette.white};
  border: ${({ theme }) => theme.borderStyles.tertiary};
  color: ${({ theme }) => theme.palette.title};
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }
    
  &:hover {
    background: ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.white};
    border-color: ${({ theme }) => theme.palette.primary};
    transform: scale(1.02);
  }
`;

// -------------- PAGINATION
const pagination = css`
  background-color: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.grey60};
    
  &:active {
    background-color: ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.white};
  }
  
  &:hover {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) => theme.palette.primary};
    border: none;
  }
  
  &:hover ${ButtonIcon} {
    filter: brightness(150);
  }
`;

// -------------- MODAL - Confirm
const modal = css`
  background-color: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.modalButtonText};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  width: 100%;
  margin: 0 0 1rem 0;
  text-transform: uppercase;
  border: ${({ theme }) => theme.borderStyles.secondary};
  height: 42px;
    
    &:hover {
      transform: scale(1.01);
      filter: contrast(115%);
    }
    
    &:active {
      transform: scale(0.96);
    }
`;

// -------------- Modal - Delete
const remove = css`
  background-color: ${({ theme }) => theme.palette.error};
  color: ${({ theme }) => theme.palette.white};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  width: 100%;
  margin: 0;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  border: ${({ theme }) => theme.borderStyles.error};
  height: 42px;

    &:hover {
      transform: scale(1.01);
      filter: contrast(115%);
    }
    
    &:active {
      transform: scale(0.96);
    }
`;

// -------------- OUTLINED --> White bg | dark grey border & text
const outlined = css`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  background-color: ${({ theme }) => theme.palette.white};
  border: ${({ theme }) => theme.borderStyles.primary};
  color: ${({ theme }) => theme.palette.title};
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }
    
  &:hover {
    background: ${({ theme }) => theme.palette.title};
    color: ${({ theme }) => theme.palette.white};
    border-color: ${({ theme }) => theme.palette.title};
    transform: scale(1.02);
  }
`;

// -------------- LOGIN
const login = css`
  background-color: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.white};
  padding: 0.6rem 6rem;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};

    &:active {
      transform: scale(0.95);
    }
  
    &:hover {
      color: ${({ theme }) => theme.palette.primary};
      background-color: ${({ theme }) => theme.palette.white};
    }
`;

// ------------------------------------ Button Component
export const ButtonStyled = styled.button<StyledButtonProps>`
  width: ${({ width }) => (width || 'fit-content')};
  height: ${({ height }) => (height || '38px')};
  padding: ${({ padding }) => (padding || '0rem 1.5rem')};
  margin: ${({ alignCenter }) => (alignCenter ? 'auto' : '0.5rem 0')};
  background-color: ${({ theme }) => theme.palette.primary};
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  outline: none;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.25rem;
  white-space: nowrap;
  font-size: ${({ fontSize, theme }) => (fontSize || theme.fontScale.S)}rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ fontWeight, theme }) => (fontWeight || theme.fontWeight.semibold)};    
  margin-bottom: ${({ alignTop }) => (alignTop ? 'auto' : '')};
  margin-top: ${({ alignBottom }) => (alignBottom ? 'auto' : '')};
  margin-left: ${({ alignRight }) => (alignRight ? 'auto' : '')};
  margin-right: ${({ alignLeft }) => (alignLeft ? 'auto' : '')};
  transition:all .5s ease-in-out;
  box-sizing: border-box;
  min-width: 150px;

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  ${({ variant }) => variant === 'primary' && primary};
  ${({ variant }) => variant === 'secondary' && secondary};
  ${({ variant }) => variant === 'pagination' && pagination};
  ${({ variant }) => variant === 'modal' && modal};
  ${({ variant }) => variant === 'remove' && remove};
  ${({ variant }) => variant === 'outlined' && outlined};
  ${({ variant }) => variant === 'login' && login};


  ${({ fit }) => fit === 'contain' && contain};
  ${({ fit }) => fit === 'fill' && fill};
  ${({ fit }) => fit === 'stretch' && stretch};

  ${({ corner }) => corner === 'sharp' && sharp};
  ${({ corner }) => corner === 'rounded' && rounded};

  ${({ shape }) => shape === 'circle' && circle};
  ${({ shape }) => shape === 'rect' && rect};
  ${({ shape }) => shape === 'square' && square};
`;
