import styled from 'styled-components/macro';

const StyledEllipsis = styled.img`
  cursor: pointer;
  height: 1rem;
  width: auto;
  padding-right: ${({ theme }) => theme.spacing.L}px;
`;

export default StyledEllipsis;
