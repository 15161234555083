import React, {
  ChangeEvent, useMemo, useRef, useState,
} from 'react';

import {
  Modal,
  MultilineTextInputField,
  TextInputField,
  InputWrapper,
  Label,
  Field,
  Icon,
  // Error,
  Button,
} from '../../../../components';
import {
  Subheading,
  LogoWrapper,
  LogoImageContainer,
  AddLogoButton,
  HiddenFileInput,
  UploadButton,
  CauseUploadButton,
  CauseExpandBox,
  ExpandButton,
  CauseHeading,
  CauseContainer,
} from './Styles';
import {
  DescriptionFieldIcon, HeartFieldIcon, ImageFieldIcon, LinkFieldIcon, PlusIcon, MinusIcon,
} from '../../../../assets/Icons';

import useStrings from '../../../../hooks/useStrings';
import { uploadMedia } from '../../../../lib/Media';
import { PromotedCharityDetails } from '../CharityModal/CharityModal';

const PriorityCharityModal: React.FC<PriorityCharityModalProps> = ({
  hideModal, promotedDetails, changePromotedDetails, changeIsPromoted, isEdit,
}) => {
  const [{ PriorityCharityModal: strings }] = useStrings();

  const [showCause1Dropdown, setShowCause1Dropdown] = useState(false);
  const [showCause2Dropdown, setShowCause2Dropdown] = useState(false);
  const [showCause3Dropdown, setShowCause3Dropdown] = useState(false);

  const [horizontalLogo, setHorizontalLogo] = useState(promotedDetails?.horizontalLogo || '');
  const [description, setDescription] = useState(promotedDetails?.description || '');
  const [bgImage, setBgImage] = useState(promotedDetails?.bgImage || '');
  const [cause1, setCause1] = useState<{
    cause1Name?: string; cause1Link?: string; cause1Image?: string
  }>({
    cause1Name: promotedDetails?.cause1?.cause1Name || '',
    cause1Link: promotedDetails?.cause1?.cause1Link || '',
    cause1Image: promotedDetails?.cause1?.cause1Image || '',
  });
  const [cause2, setCause2] = useState<{
    cause2Name?: string; cause2Link?: string; cause2Image?: string
  }>({
    cause2Name: promotedDetails?.cause2?.cause2Name || '',
    cause2Link: promotedDetails?.cause2?.cause2Link || '',
    cause2Image: promotedDetails?.cause2?.cause2Image || '',
  });
  const [cause3, setCause3] = useState<{
    cause3Name?: string; cause3Link?: string; cause3Image?: string
  }>({
    cause3Name: promotedDetails?.cause3?.cause3Name || '',
    cause3Link: promotedDetails?.cause3?.cause3Link || '',
    cause3Image: promotedDetails?.cause3?.cause3Image || '',
  });

  const hiddenFileInputRefLogo = useRef<HTMLInputElement>(null);
  const hiddenFileInputRefBgImage = useRef<HTMLInputElement>(null);
  const hiddenFileInputRefCause1 = useRef<HTMLInputElement>(null);
  const hiddenFileInputRefCause2 = useRef<HTMLInputElement>(null);
  const hiddenFileInputRefCause3 = useRef<HTMLInputElement>(null);

  const handleClick = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref && ref.current) {
      ref.current.click();
    }
  };

  const handleChangeImage = async (
    event: ChangeEvent<HTMLInputElement>,
    imageType: 'logo' | 'cause1Image' | 'cause2Image' | 'cause3Image' | 'bgImage',
  ) => {
    if (event?.target?.files && event?.target?.files?.length > 0) {
      const imageFile = event.target.files[0];
      const imageURL = await uploadMedia(imageFile);

      if (imageURL) {
        switch (imageType) {
          case 'bgImage':
            setBgImage(imageURL);
            break;
          case 'logo':
            setHorizontalLogo(imageURL);
            break;
          case 'cause1Image':
            setCause1({ ...cause1, cause1Image: imageURL });
            break;
          case 'cause2Image':
            setCause2({ ...cause2, cause2Image: imageURL });
            break;
          case 'cause3Image':
            setCause3({ ...cause3, cause3Image: imageURL });
            break;
          default:
            break;
        }
      }
    }
  };

  const handleSavePromo = () => {
    changePromotedDetails({
      bgImage,
      description,
      horizontalLogo,
      cause1,
      cause2,
      cause3,
    });
    hideModal();
  };

  const saveDisabled = useMemo(() => horizontalLogo === '' || description === '' || bgImage === ''
    || cause1.cause1Name === '' || cause1.cause1Image === '' || cause1.cause1Link === ''
    || cause2.cause2Name === '' || cause2.cause2Image === '' || cause2.cause2Link === ''
    || cause3.cause3Name === '' || cause3.cause3Image === '' || cause3.cause3Link === '',
  [horizontalLogo, description, bgImage, cause1, cause2, cause3]);

  return (
    <Modal
      showCloseButton={!isEdit}
      closeModal={() => {
        hideModal();
        changeIsPromoted();
      }}
    >
      <Subheading>{strings.subheading}</Subheading>
      <LogoWrapper>
        <LogoImageContainer src={horizontalLogo || undefined} />
        <HiddenFileInput
          ref={hiddenFileInputRefLogo}
          multiple={false}
          accept="image/*"
          type="file"
          onChange={(e) => handleChangeImage(e, 'logo')}
        />
        <AddLogoButton onClick={() => handleClick(hiddenFileInputRefLogo)}>
          {strings.addLogo}
        </AddLogoButton>
      </LogoWrapper>

      {/* DESCRIPTION */}
      <InputWrapper>
        <Label>{strings.description}</Label>
        <Field isMultiline>
          <Icon src={DescriptionFieldIcon} isMultiline />
          <MultilineTextInputField
            id="description"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Field>
      </InputWrapper>

      {/* BACKGROUND IMAGE */}
      <InputWrapper>
        <Label>{strings.backgroundImage}</Label>
        <Field>
          <Icon src={ImageFieldIcon} />
          <TextInputField
            id="backgroundImage"
            name="backgroundImage"
            type="text"
            value={bgImage}
            readOnly
            hasIcon
          />
          <HiddenFileInput
            ref={hiddenFileInputRefBgImage}
            multiple={false}
            accept="image/*"
            type="file"
            onChange={(e) => handleChangeImage(e, 'bgImage')}
          />
          <UploadButton onClick={() => handleClick(hiddenFileInputRefBgImage)}>
            {strings.upload}
          </UploadButton>
        </Field>
      </InputWrapper>

      {/* CAUSE 1 */}
      <CauseExpandBox>
        <CauseHeading>{strings.cause1}</CauseHeading>
        <ExpandButton
          onClick={() => setShowCause1Dropdown(!showCause1Dropdown)}
          src={showCause1Dropdown ? MinusIcon : PlusIcon}
        />
      </CauseExpandBox>
      {showCause1Dropdown && (
        <CauseContainer>
          <InputWrapper>
            <Label>{strings.cause}</Label>
            <Field>
              <Icon src={HeartFieldIcon} />
              <TextInputField
                id="cause1Name"
                name="cause1Name"
                type="text"
                value={cause1?.cause1Name}
                onChange={(e) => setCause1({ ...cause1, cause1Name: e.target.value })}
                hasIcon
              />
            </Field>
          </InputWrapper>

          <InputWrapper>
            <Label>{strings.link}</Label>
            <Field>
              <Icon src={LinkFieldIcon} />
              <TextInputField
                id="cause1Link"
                name="cause1Link"
                type="text"
                value={cause1?.cause1Link}
                onChange={(e) => setCause1({ ...cause1, cause1Link: e.target.value })}
                hasIcon
              />
            </Field>
          </InputWrapper>

          <InputWrapper>
            <Label>{strings.image}</Label>
            <Field>
              <Icon src={ImageFieldIcon} />
              <TextInputField
                id="cause1Image"
                name="cause1Image"
                type="text"
                value={cause1?.cause1Image}
                readOnly
                hasIcon
              />
              <HiddenFileInput
                ref={hiddenFileInputRefCause1}
                multiple={false}
                accept="image/*"
                type="file"
                onChange={(e) => handleChangeImage(e, 'cause1Image')}
              />
              <CauseUploadButton onClick={() => handleClick(hiddenFileInputRefCause1)}>
                {strings.upload}
              </CauseUploadButton>
            </Field>
          </InputWrapper>
        </CauseContainer>
      )}

      {/* CAUSE 2 */}
      <CauseExpandBox>
        <CauseHeading>{strings.cause2}</CauseHeading>
        <ExpandButton
          onClick={() => setShowCause2Dropdown(!showCause2Dropdown)}
          src={showCause2Dropdown ? MinusIcon : PlusIcon}
        />
      </CauseExpandBox>
      {showCause2Dropdown && (
        <CauseContainer>
          <InputWrapper>
            <Label>{strings.cause}</Label>
            <Field>
              <Icon src={HeartFieldIcon} />
              <TextInputField
                id="cause2Name"
                name="cause2Name"
                type="text"
                value={cause2?.cause2Name}
                onChange={(e) => setCause2({ ...cause2, cause2Name: e.target.value })}
                hasIcon
              />
            </Field>
          </InputWrapper>

          <InputWrapper>
            <Label>{strings.link}</Label>
            <Field>
              <Icon src={LinkFieldIcon} />
              <TextInputField
                id="cause2Link"
                name="cause2Link"
                type="text"
                value={cause2?.cause2Link}
                onChange={(e) => setCause2({ ...cause2, cause2Link: e.target.value })}
                hasIcon
              />
            </Field>
          </InputWrapper>

          <InputWrapper>
            <Label>{strings.image}</Label>
            <Field>
              <Icon src={ImageFieldIcon} />
              <TextInputField
                id="cause2Image"
                name="cause2Image"
                type="text"
                value={cause2?.cause2Image}
                readOnly
                hasIcon
              />
              <HiddenFileInput
                ref={hiddenFileInputRefCause2}
                multiple={false}
                accept="image/*"
                type="file"
                onChange={(e) => handleChangeImage(e, 'cause2Image')}
              />
              <CauseUploadButton onClick={() => handleClick(hiddenFileInputRefCause2)}>
                {strings.upload}
              </CauseUploadButton>
            </Field>
          </InputWrapper>
        </CauseContainer>
      )}

      {/* CAUSE 3 */}
      <CauseExpandBox>
        <CauseHeading>{strings.cause3}</CauseHeading>
        <ExpandButton
          onClick={() => setShowCause3Dropdown(!showCause3Dropdown)}
          src={showCause3Dropdown ? MinusIcon : PlusIcon}
        />
      </CauseExpandBox>
      {showCause3Dropdown && (
        <CauseContainer>
          <InputWrapper>
            <Label>{strings.cause}</Label>
            <Field>
              <Icon src={HeartFieldIcon} />
              <TextInputField
                id="cause3Name"
                name="cause3Name"
                type="text"
                value={cause3?.cause3Name}
                onChange={(e) => setCause3({ ...cause3, cause3Name: e.target.value })}
                hasIcon
              />
            </Field>
          </InputWrapper>

          <InputWrapper>
            <Label>{strings.link}</Label>
            <Field>
              <Icon src={LinkFieldIcon} />
              <TextInputField
                id="cause3Link"
                name="cause3Link"
                type="text"
                value={cause3?.cause3Link}
                onChange={(e) => setCause3({ ...cause3, cause3Link: e.target.value })}
                hasIcon
              />
            </Field>
          </InputWrapper>

          <InputWrapper>
            <Label>{strings.image}</Label>
            <Field>
              <Icon src={ImageFieldIcon} />
              <TextInputField
                id="cause3Image"
                name="cause3Image"
                type="text"
                value={cause3?.cause3Image}
                readOnly
                hasIcon
              />
              <HiddenFileInput
                ref={hiddenFileInputRefCause3}
                multiple={false}
                accept="image/*"
                type="file"
                onChange={(e) => handleChangeImage(e, 'cause3Image')}
              />
              <CauseUploadButton onClick={() => handleClick(hiddenFileInputRefCause3)}>
                {strings.upload}
              </CauseUploadButton>
            </Field>
          </InputWrapper>
        </CauseContainer>
      )}
      <Button buttonText={strings.save} variant="modal" onClick={handleSavePromo} disabled={saveDisabled} />
    </Modal>
  );
};

interface PriorityCharityModalProps {
  hideModal: () => void;
  changeIsPromoted: () => void;
  promotedDetails?: PromotedCharityDetails | null;
  changePromotedDetails: (details: PromotedCharityDetails) => void;
  isEdit: boolean;
}

export default PriorityCharityModal;
