import styled from 'styled-components/macro';

export const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  grid-template-rows: 1fr 3fr 4fr;
  grid-gap: 2rem 3rem;
  place-items: stretch stretch;
  place-content: center center;
  width: calc(100% - 1rem);
  height: auto;
`;

export const TotalsGridContainer = styled.div`
  display: grid;
  grid-area: 1 / 1 / 2 / 4;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 2rem 3rem;
  place-items: stretch stretch;
  place-content: center center;
`;

const StyledDashboardCard = styled.div`
  width: calc(100% - 3rem);
  height: calc(100% - 3rem);
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 0.36rem;
  border: ${({ theme }) => theme.borderStyles.secondary};
  padding: 1.5rem 2rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  color:  ${({ theme }) => theme.palette.black};
  display: flex;
`;

const DashboardIcon = styled.img`
  margin: -2rem 2rem -2rem -2rem;
  padding: 1.8rem 2rem;
  border-radius: 0.36rem;
  height: 70%;
`;

export const UserIcon = styled(DashboardIcon)`
  background-color: #EE7661;
`;

export const CalendarIcon = styled(DashboardIcon)`
  background-color: #570228;
`;

export const DollarIcon = styled(DashboardIcon)`
  background-color: #68B6C6;
`;

export const RevenueDollarIcon = styled(DashboardIcon)`
  background-color: #727B8E;
`;

export const Count = styled.p`
  color: ${(({ theme }) => theme.palette.white)};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${(({ theme }) => theme.spacing.XXS / 3)}rem;
`;

export const CountLabel = styled.p`
  color: ${(({ theme }) => theme.palette.white)};
  font-size: ${({ theme }) => theme.fontScale.M}rem;
  font-weight: lighter;
`;

export const TotalUsersContainer = styled(StyledDashboardCard)`
  grid-area: 1 / 1 / 2 / 2;
  place-self: stretch stretch;
  min-width: unset;
  background-color: ${({ theme }) => theme.palette.primary};
  align-items: center;
`;

export const TotalEventsContainer = styled(StyledDashboardCard)`
  grid-area: 1 / 2 / 2 / 3;
  place-self: stretch stretch;
  min-width: unset;
  background-color: ${({ theme }) => theme.palette.secondary};
  align-items: center;
`;

export const TotalDonationsContainer = styled(StyledDashboardCard)`
  grid-area: 1 / 3 / 2 / 4;
  place-self: stretch stretch;
  min-width: unset;
  background-color: ${({ theme }) => theme.palette.tertiary};
  align-items: center;
`;

export const TotalRevenueContainer = styled(StyledDashboardCard)`
  grid-area: 1 / 4 / 2 / 5;
  place-self: stretch stretch;
  min-width: unset;
  background-color: ${({ theme }) => theme.palette.tableHeader};
  align-items: center;
`;

export const UsersGraphContainer = styled(StyledDashboardCard)`
  grid-area: 2 / 1 / 3 / 2;
  place-self: stretch stretch;
  min-width: unset;
  flex-direction: column;
`;

export const NewUsersCount = styled.p`
  color: ${(({ theme }) => theme.palette.primary)};
  margin: ${(({ theme }) => theme.spacing.XXS)}rem 0 ${(({ theme }) => theme.spacing.XS)}rem 0;
  font-size: ${({ theme }) => theme.fontScale.XL}rem;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const GraphHeading = styled.p`
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform : uppercase;
  color: ${(({ theme }) => theme.palette.header)};
`;

export const ActivityContainer = styled(StyledDashboardCard)`
  grid-area: 2 / 2 / 3 / 3;
  place-self: stretch stretch;
  min-width: unset;
  flex-direction: column;
  justify-content: space-between;
`;

export const DonationsGraphContainer = styled(StyledDashboardCard)`
  grid-area: 2 / 3 / 3 / 4;
  place-self: stretch stretch;
  min-width: unset;
  flex-direction: column;
`;

export const DonationsCount = styled.p`
  color: ${(({ theme }) => theme.palette.tertiary)};
  margin: ${(({ theme }) => theme.spacing.XXS)}rem 0 ${(({ theme }) => theme.spacing.XS)}rem 0;
  font-size: ${({ theme }) => theme.fontScale.XL}rem;
`;

export const NewUsersTableContainer = styled(StyledDashboardCard)`
  grid-area: 3 / 1 / 4 / 4;
  place-self: stretch stretch;
  min-width: unset;
  flex-direction: column;
`;

export const BarGraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
`;

export const LabelBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
`;

export const Label = styled.p`
  color: ${(({ theme }) => theme.palette.subHeader)};
`;

export const BarContainer = styled.div`
  border-radius: 4px;
  background: ${(({ theme }) => theme.palette.bgPrimary)};
  width: 95%;
  margin-top: ${(({ theme }) => theme.spacing.XXS / 3)}rem;
`;

interface BarProps {
  percentage: number;
}

export const Bar = styled.div<BarProps>`
  background: ${(({ theme }) => theme.palette.primary)};
  width: ${(({ percentage }) => percentage)}%;
  max-width: 100%;
  height: 6px;
  border-radius: 4px;
`;

export const Total = styled.p`
  color: ${(({ theme }) => theme.palette.subHeader)};
`;
