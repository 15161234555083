import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';

import { Button, Modal } from '../../../../components';
import {
  ComplaintContainer,
  MainContent,
  ProfileHeader,
  PhotoDetailsContainer,
  ProfileImage,
  DetailsContainer,
  Name,
  ComplaintTypeLabel,
  HighlightText,
  Date,
  MessageContentLabel,
  MessageContent,
  ReplyTextArea,
  ButtonContainer,
  InnerButtonContainer,
} from './Styles';

import useStrings from '../../../../hooks/useStrings';
import { ReportedContent } from '../../../../lib/types';
import { displayReportReason } from '../Complaints';
import { useMarkAsResolved, useSendEmailReply } from '../../../../lib/graphql/queries';
import { GET_REPORTED_CONTENT } from '../../../../lib/graphql/queries/reportedContent';

const ComplaintView: React.FC<ComplaintViewProps> = ({ complaint, onMarkedResolved }) => {
  const [{ Complaints: strings }] = useStrings();

  const history = useHistory();

  const [messageReply, setMessageReply] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [sendEmailReply, { loading: sendingEmail }] = useSendEmailReply({
    onCompleted: () => setShowSuccessModal(true),
  });

  const handleMessageReply = async (recipient: 'reporter' | 'reported') => {
    const messageWithWhiteSpace = messageReply.split('\n').map((para) => (`${para}<br>`)).join('');
    await sendEmailReply({
      variables: {
        input: {
          message: messageWithWhiteSpace,
          recipientID: (recipient === 'reporter' ? complaint.reporter.id : complaint.reported.id),
        },
      },
    });
    setMessageReply('');
  };

  const [markAsResolved, { loading: markingResolved }] = useMarkAsResolved({
    update(cache, { data: resolvedReportedContentData }) {
      const resolvedReportedContent = resolvedReportedContentData?.markAsResolved.reportedContent;

      const allComplaintsData = cache
        .readQuery({
          query: GET_REPORTED_CONTENT,
        });
      const allComplaints = allComplaintsData?.allReportedContent.reportedContents || [];

      if (resolvedReportedContent && complaint) {
        cache.writeQuery({
          query: GET_REPORTED_CONTENT,
          data: {
            allReportedContent: {
              reportedContents: allComplaints.map((reportedContent) => (
                reportedContent.id === resolvedReportedContent.id
                  ? { ...reportedContent, ...resolvedReportedContent }
                  : reportedContent)),
            },
          },
        });
      }
    },
    onCompleted: () => onMarkedResolved(),
  });

  const reportedName = `${complaint.reported.givenName} ${complaint.reported.surname}`;
  const reportedBy = `${complaint.reporter.givenName} ${complaint.reporter.surname}`;

  return (
    <ComplaintContainer>
      {showSuccessModal && (
        <Modal
          rounded={false}
          closeModal={() => setShowSuccessModal(false)}
          header={strings.successHeading}
          subHeader={strings.successSubheading}
        />
      )}
      <MainContent>
        <ProfileHeader>
          <PhotoDetailsContainer>
            <ProfileImage src={complaint.reported.profilePhoto || ''} alt="Customer Profile" onClick={() => history.push(`/admin/user/${complaint.reported.id}`)} />
            <DetailsContainer>
              <Name onClick={() => history.push(`/admin/user/${complaint.reported.id}`)}>{reportedName}</Name>
              <ComplaintTypeLabel>
                {strings.reportedBy}
                {' '}
              </ComplaintTypeLabel>
              <HighlightText onClick={() => history.push(`/admin/user/${complaint.reporter.id}`)}>
                {reportedBy}
              </HighlightText>
            </DetailsContainer>
          </PhotoDetailsContainer>
          <Date>{moment(complaint.dateCreated).format('MMM DD')}</Date>
        </ProfileHeader>
        <MessageContentLabel>{strings.reason}</MessageContentLabel>
        <MessageContent>
          {displayReportReason(complaint.reportedReason, strings.reasons)}
        </MessageContent>
        {complaint.isResolved ? <div /> : (
          <ReplyTextArea
            placeholder={strings.placeholder}
            onChange={(e) => setMessageReply(e.target.value)}
            value={messageReply}
          />
        )}
      </MainContent>
      {complaint.isResolved ? (
        <ButtonContainer resolved>
          <Button buttonText={strings.resolved} disabled variant="outlined" width="90%" />
        </ButtonContainer>
      )
        : (
          <ButtonContainer>
            <InnerButtonContainer>
              <Button
                variant="primary"
                buttonText={strings.sendMessage.replace('%', reportedName)}
                width="49%"
                disabled={messageReply.length === 0 || sendingEmail}
                onClick={() => handleMessageReply('reported')}
              />
              <Button
                variant="primary"
                buttonText={strings.sendMessage.replace('%', reportedBy)}
                width="49%"
                disabled={messageReply.length === 0 || sendingEmail}
                onClick={() => handleMessageReply('reporter')}
              />
            </InnerButtonContainer>
            <Button
              buttonText={strings.markResolved}
              variant="outlined"
              width="90%"
              disabled={markingResolved}
              onClick={() => markAsResolved({ variables: { input: { id: complaint.id } } })}
            />
            {/* TODO: suspend users */}
            {/* <Button
              buttonText={strings.suspendUser}
              variant="primary"
              width="49%"
              disabled
            /> */}
          </ButtonContainer>
        )}
    </ComplaintContainer>
  );
};

export interface ComplaintViewProps {
  complaint: ReportedContent;
  onMarkedResolved: () => void;
}

export default ComplaintView;
