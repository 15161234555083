import React, { useState, useRef, ChangeEvent } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';

import {
  SectionHeading, FormWrapper, InputContainer, ShowPasswordText, ShowPasswordContainer,
  NameInputWrapper, Gap, ProfilePicture, ProfilePicturePlaceholder, ImageContainer, HiddenFileInput,
} from '../Styles';
import {
  Form, InputWrapper, Label, Field, TextInputField, Error, Button, Modal,
} from '../../../../components';

import useStrings from '../../../../hooks/useStrings';
import { Admin } from '../../../../lib/types';
import { signUp } from '../../../../lib/Authentication';
import { useCreateAdmin } from '../../../../lib/graphql/queries';
import { logError } from '../../../../lib/Analytics';
import { uploadMedia } from '../../../../lib/Media';

const AddAdmin: React.FC = () => {
  const [{ Settings: strings }] = useStrings();
  const history = useHistory();

  const hiddenFileInputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [password, setPassword] = useState('');
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [createNewAdmin] = useCreateAdmin();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: '',
      surname: '',
      email: '',
      profilePhoto: undefined,
    },
    onSubmit: async ({
      firstName,
      surname,
      email,
      profilePhoto, // TODO
    }) => {
      setLoading(true);
      try {
        const userDetails = await signUp(email, password);
        if (userDetails.user) {
          const id = userDetails.user.uid;

          await createNewAdmin({
            variables: {
              input: {
                email, surname, firstName, id, profilePhoto,
              },
            },
          });
        }
        await setFieldValue('firstName', '');
        await setFieldValue('surname', '');
        await setFieldValue('email', '');
        await setFieldValue('profilePhoto', undefined);
        setPassword('');
        setLoading(false);
        setShowSuccessModal(true);
      } catch (err) {
        setShowErrorModal(true);
        setLoading(false);
        logError(err);
      }
    },
    validateOnChange: false,
    validate: ({
      firstName,
      surname,
      email,
    }) => {
      const newErrors: Partial<Record<keyof Admin, string>> = {};

      if (!firstName) newErrors.firstName = strings.addAdmin.firstNameError;
      if (!surname) newErrors.surname = strings.addAdmin.lastNameError;
      if (!email) newErrors.email = strings.addAdmin.emailError;

      return newErrors;
    },
  });

  const handleClick = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref && ref.current) {
      ref.current.click();
    }
  };

  const handleChangeImage = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event?.target?.files?.length > 0) {
      const imageFile = event.target.files[0];
      const imageURL = await uploadMedia(imageFile);

      if (imageURL) await setFieldValue('profilePhoto', imageURL);
    }
  };

  const buttonDisabled = password === '' || values.firstName === '' || values.surname === '' || values.email === '' || loading;

  return (
    <Form onSubmit={handleSubmit}>
      {showErrorModal && (
        <Modal
          closeModal={() => setShowErrorModal(false)}
          header={strings.FailureModal.heading}
          subHeader={strings.FailureModal.subheading}
          hasSubHeader
          rounded={false}
        >
          <Button
            onClick={() => setShowErrorModal(false)}
            buttonText={strings.FailureModal.buttonText}
            variant="modal"
          />
        </Modal>
      )}
      {showSuccessModal && (
        <Modal
          closeModal={() => {
            setShowSuccessModal(false);
            history.push('/admin/settings');
          }}
          header={strings.SuccessModal.heading}
          subHeader={strings.addAdmin.successMessage}
          hasSubHeader
          rounded={false}
        >
          <Button
            onClick={() => {
              setShowSuccessModal(false);
              history.push('/admin/settings');
            }}
            buttonText={strings.SuccessModal.buttonText}
            variant="modal"
          />
        </Modal>
      )}
      <SectionHeading>{strings.addAdmin.heading}</SectionHeading>
      <FormWrapper>
        <InputContainer>
          <NameInputWrapper>
            <InputWrapper>
              <Label error={!!errors.firstName} htmlFor="firstName">{strings.addAdmin.firstNameLabel}</Label>
              <Field>
                <TextInputField
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                  error={!!errors.firstName}
                />
              </Field>
              <Error>{errors.firstName}</Error>
            </InputWrapper>
            <Gap />
            <InputWrapper>
              <Label error={!!errors.surname} htmlFor="surname">{strings.addAdmin.lastNameLabel}</Label>
              <Field>
                <TextInputField
                  id="surname"
                  name="surname"
                  type="text"
                  value={values.surname}
                  onChange={handleChange}
                  error={!!errors.surname}
                />
              </Field>
              <Error>{errors.surname}</Error>
            </InputWrapper>
          </NameInputWrapper>
          <InputWrapper>
            <Label error={!!errors.email} htmlFor="email">{strings.addAdmin.emailLabel}</Label>
            <Field>
              <TextInputField
                id="email"
                name="email"
                type="text"
                value={values.email}
                onChange={handleChange}
                error={!!errors.email}
              />
            </Field>
            <Error>{errors.email}</Error>
          </InputWrapper>
          <InputWrapper>
            <Label error={isPasswordError} htmlFor="password">{strings.addAdmin.passwordLabel}</Label>
            <Field>
              <TextInputField
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (e.target.value === '') {
                    setIsPasswordError(true);
                  } else {
                    setIsPasswordError(false);
                  }
                }}
                error={isPasswordError}
              />
              <ShowPasswordContainer>
                <ShowPasswordText onClick={() => setShowPassword(!showPassword)}>
                  {strings.addAdmin.show}
                </ShowPasswordText>
              </ShowPasswordContainer>
            </Field>
            {isPasswordError && <Error>{strings.addAdmin.passwordError}</Error>}
          </InputWrapper>
        </InputContainer>
        <ImageContainer>
          {values.profilePhoto
            ? <ProfilePicture src={values.profilePhoto} />
            : <ProfilePicturePlaceholder />}
          <HiddenFileInput
            ref={hiddenFileInputRef}
            multiple={false}
            accept="image/*"
            type="file"
            onChange={handleChangeImage}
          />
          <Button buttonText={strings.addAdmin.addImageButtonText} variant="outlined" onClick={() => handleClick(hiddenFileInputRef)} />
        </ImageContainer>
      </FormWrapper>
      <Button
        buttonText={strings.addAdmin.submitButtonText}
        variant="modal"
        onClick={handleSubmit}
        disabled={buttonDisabled}
      />
    </Form>
  );
};

export default AddAdmin;
