import React, { useMemo } from 'react';
import {
  LineChart, Line, XAxis, YAxis, ResponsiveContainer,
} from 'recharts';
import moment from 'moment';

import useStrings from '../../../../hooks/useStrings';
import { useGetUsersThisWeek } from '../../../../lib/graphql/queries';
import { getDashboardGraphDates } from '../../../../lib/Utils';
import { GraphHeading, HeadingContainer, NewUsersCount } from '../Styles';
import { ViewAllLink } from '../../../../components';

const mondayOfThisWeek = moment().startOf('isoWeek').valueOf();

const NewUsersGraph: React.FC<NewUsersGraphProps> = () => {
  const [{ Dashboard: strings }] = useStrings();

  const { data: usersThisWeekData } = useGetUsersThisWeek({
    variables: { date: mondayOfThisWeek.toString() },
  });

  const newUsersThisWeekDates = useMemo(() => (
    usersThisWeekData?.usersThisWeek.usersCreatedThisWeekDates || []
  ), [usersThisWeekData?.usersThisWeek.usersCreatedThisWeekDates]);

  const newCustomerDataCounts = useMemo(() => (
    getDashboardGraphDates(moment(mondayOfThisWeek).format()).map((day) => ({
      day,
      count: newUsersThisWeekDates.filter((customerCreatedDate) => moment(customerCreatedDate).format('YYYY-MM-DD') === day).length,
    }))), [newUsersThisWeekDates]);

  const renderNewCustomerLineChart = () => (
    <ResponsiveContainer width="100%" height="60%">
      <LineChart
        data={newCustomerDataCounts}
        style={{ marginLeft: -30 }}
      >
        <XAxis
          dataKey="day"
          axisLine={false}
          tickLine={false}
          tick={{
            fontSize: 10, textDecoration: 'uppercase', dy: 5,
          }}
          tickFormatter={(tick) => `${moment(tick).format('ddd')}`}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 10, textDecoration: 'uppercase', dx: -5 }}
          allowDecimals={false}
        />
        <Line type="monotone" dataKey="count" stroke="#FD7B62" dot={false} strokeWidth={3} />
      </LineChart>
    </ResponsiveContainer>
  );

  return (
    <>
      <HeadingContainer>
        <GraphHeading>{strings.newUsersHeading}</GraphHeading>
        <ViewAllLink to="/admin/users">{strings.viewAll}</ViewAllLink>
      </HeadingContainer>
      <NewUsersCount>{newUsersThisWeekDates.length.toLocaleString()}</NewUsersCount>
      {renderNewCustomerLineChart()}
    </>
  );
};

interface NewUsersGraphProps { }

export default NewUsersGraph;
