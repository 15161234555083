import {
  LazyQueryHookOptions,
  MutationHookOptions,
  QueryHookOptions,
  TypedDocumentNode,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useCallback } from 'react';

/**
 * Wraps a GraphQL mutation to create a hook that only requires the vars to be provided.
 */
export function createUseMutation<Result, Vars>(
  mutation: TypedDocumentNode<Result, Vars>,
  defaultOptions?: Partial<MutationHookOptions<Result, Vars>>,
) {
  return defaultOptions
    ? (options?: MutationHookOptions<Result, Vars>) => useMutation(mutation, { ...defaultOptions, ...options })
    : (options?: MutationHookOptions<Result, Vars>) => useMutation(mutation, options);
}

/**
 * Wraps a GraphQL query to create a hook that only requires the vars to be provided.
 */
export function createUseQuery<Result, Vars>(
  query: TypedDocumentNode<Result, Vars>,
  defaultOptions?: Partial<QueryHookOptions<Result, Vars>>,
) {
  return defaultOptions
    ? (options?: QueryHookOptions<Result, Vars>) => useQuery(query, { ...defaultOptions, ...options })
    : (options?: QueryHookOptions<Result, Vars>) => useQuery(query, options);
}

/**
 * Wraps a GraphQL lazy query to create a hook that only requires the vars to be provided.
 */
export function createUseLazyQuery<Result, Vars>(
  query: TypedDocumentNode<Result, Vars>,
  defaultOptions?: Partial<LazyQueryHookOptions<Result, Vars>>,
) {
  return defaultOptions
    ? (options?: LazyQueryHookOptions<Result, Vars>) => useLazyQuery(query, { ...defaultOptions, ...options })
    : (options?: LazyQueryHookOptions<Result, Vars>) => useLazyQuery(query, options);
}

type WriteQueryCallback<Result, Vars> = Vars extends Record<string, never>
  ? (data: Result) => void
  : (data: Result, variables: Vars) => void;

/**
 * Returns a hook that will call writeQuery to update the Apollo cache.
 */
export function createUseWriteQuery<Result, Vars>(query: TypedDocumentNode<Result, Vars>) {
  return () => {
    const client = useApolloClient();
    return useCallback(
      (data: Result, variables?: Vars) => {
        client.writeQuery({
          query,
          variables,
          data,
        });
      },
      [client],
    ) as WriteQueryCallback<Result, Vars>;
  };
}
