import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const ViewAllLink = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-family: ${({ theme }) => theme.fontFamily.body};
  margin: 0 0 0 auto;
`;

export const ViewProfile = styled.p`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary};
  font-size: ${({ theme }) => theme.fontScale.S};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-family: ${({ theme }) => theme.fontFamily.body};
  line-height: 18px;
  cursor: pointer;
  text-align: left;
  vertical-align: middle;
  padding-left: 0;
  margin-left: 0;
`;
