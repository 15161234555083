import { FontFamily, FontScale, FontWeight } from '../../lib/types/StyleTypes';

export const ffFontFamily: FontFamily = {
  heading: 'Open Sans, sans-serif',
  body: 'Open Sans, sans-serif',
};

// What scale adapts to the font-size relationship (golden ratio, minor third, perfect fourth etc.)
export const defFontScale: FontScale = {
  XXS: 0.569,
  XS: 0.686,
  S: 0.829,
  M: 1,
  L: 1.4,
  XL: 2,
  XXL: 2.8,
};

// Font Weight
export const defFontWeight: FontWeight = {
  light: 300,
  regular: 400,
  semibold: 600,
  bold: 700,
  black: 800,
};
