import styled, { css } from 'styled-components/macro';

export interface StatusTextProps {
  variant?: string;
}

const failedToShow = css`
  color: ${({ theme }) => theme.palette.status03};
`;

const donationAnyway = css`
  color: ${({ theme }) => theme.palette.status03};
`;

const eventCancelled = css`
  color: ${({ theme }) => theme.palette.status02};
`;

const cancelledAttendance = css`
  color: ${({ theme }) => theme.palette.status03};
`;

const waiting = css`
  color: ${({ theme }) => theme.palette.status01};
`;

const didntProceed = css`
  color: ${({ theme }) => theme.palette.status02};
`;

const noDonation = css`
  color: ${({ theme }) => theme.palette.status02};
`;

const proceeded = css`
  color: ${({ theme }) => theme.palette.status04};
`;

const StatusText = styled.p<StatusTextProps>`
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontScale.S};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-family: ${({ theme }) => theme.fontFamily.body};
  line-height: 18px;

  ${({ variant }) => variant === 'guest_failed_to_show' && failedToShow};
  ${({ variant }) => variant === 'donation' && donationAnyway};
  ${({ variant }) => variant === 'event_cancelled' && eventCancelled};
  ${({ variant }) => variant === 'guest_cancelled_attendance' && cancelledAttendance};
  ${({ variant }) => variant === 'waiting' && waiting};
  ${({ variant }) => variant === 'didnt_proceed' && didntProceed};
  ${({ variant }) => variant === 'no_donation' && noDonation};
  ${({ variant }) => variant === 'proceeded' && proceeded};
`;

export default StatusText;
