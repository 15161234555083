import { Palette } from '../../lib/types/StyleTypes';

const ffPalette: Palette = {

  /** ------ BRAND ------ */

  primary: '#FD7B62',
  secondary: '#6F0137',
  tertiary: '#6ABED1',

  /** ------ UI ------ */

  // Text
  title: '#3E3F42',
  header: '#1E1418',
  subheader: '#8D8D8D',
  label: '#1E1418',

  modalHeader: '#1E1E1E',
  modalSubheader: '#232119',
  modalButtonText: '#FFFFFF',

  /** ------ LAYOUT ------ */

  // Background
  bgPrimary: '#F1F3F6',
  bgSecondary: '#FFFFFF',

  // Navigation Menu
  navBackground: '#6F0137',
  navBackgroundActive: '#5E0431',
  navText: '#B17E98',
  navTextActive: '#FFFFFF',

  // Default Table
  tableHeader: '#788195',
  tableHeaderBg: '#F1F3F6',
  tableBody: '#979797',
  tableBodyBg: '#FFFFFF',

  // Status
  status01: '#FF9076',
  status02: '#FB4141',
  status03: '#58C337',
  status04: '#5FD4E3',

  /** ------ SEMANTIC ------ */
  error: '#BE2D2D',
  white: '#FFFFFF',
  grey: '#E5E5E5',
};

export default ffPalette;
