import styled from 'styled-components/macro';
import { CSVLink } from 'react-csv';

export const SubLabel = styled.label`
  margin-bottom: ${({ theme }) => theme.spacing.XS / 8}rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  color: ${({ theme }) => theme.palette.body};
  line-height: 19px;
`;

export const DownloadLink = styled(CSVLink)`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  background-color: ${({ theme }) => theme.palette.white};
  border: ${({ theme }) => theme.borderStyles.tertiary};
  color: ${({ theme }) => theme.palette.title};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  width: fit-content;
  margin: 0 1rem 0.5rem 0;
  text-decoration: none;
  height: 38px;
  padding: 0rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  outline: none;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.25rem;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  transition:all .5s ease-in-out;
  box-sizing: border-box;
  margin-left: auto;

  &:active {
    transform: scale(0.95);
  }
    
  &:hover {
    background: ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.white};
    border-color: ${({ theme }) => theme.palette.primary};
    transform: scale(1.02);
  }
`;
