import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import { getUserId } from '../../lib/Authentication';
import { useGetAdmin } from '../../lib/graphql/queries';

import LoadingScreen from '../LoadingScreen';

const AuthRoute: React.FC<AuthRouteProps> = ({
  path,
  basePath = '/',
  children,
}) => {
  const isLoggedIn = useAuth();
  const authID = getUserId();

  const { data, loading, called } = useGetAdmin({
    variables: { input: { id: authID } },
    // get data only when user logged in
    skip: !isLoggedIn && !!authID,
  });

  // Any auth route requires authentication
  if (!isLoggedIn) return <Redirect to={basePath} />;
  // If we don't have Admin data yet, load.
  if (loading || !called) return <LoadingScreen />;
  // If we couldn't get admin data
  if (!data?.admin.admin) return <Redirect to={basePath} />;
  // Finally allow access to the desired route
  return <Route path={path} render={() => children} />;
};

export interface AuthRouteProps extends RouteProps {
  basePath?: string;
}

export default AuthRoute;
