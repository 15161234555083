import React from 'react';

import useStrings from '../../../hooks/useStrings';

import {
  UsersNav,
  CharitiesNav,
  ComplaintsNav,
  DashboardNav,
  EventsNav,
  SettingsNav,
  StatisticsNav,
} from '../../../assets/Icons';
import {
  SideNavListItem,
  SideNavIcon,
  SideNavLink,
  SideNavWrapper,
  NavProps,
} from './Styles';

const SideNav: React.FC = () => {
  const [{ PageHeadings: strings }] = useStrings();

  const linkOptions = [
    {
      to: '/admin/dashboard',
      text: strings.dashboard,
      icon: DashboardNav,
    },
    {
      to: '/admin/users',
      text: strings.users,
      icon: UsersNav,
    },
    {
      to: '/admin/charities',
      text: strings.charities,
      icon: CharitiesNav,
    },
    {
      to: '/admin/events',
      text: strings.events,
      icon: EventsNav,
    },
    {
      to: '/admin/statistics',
      text: strings.statistics,
      icon: StatisticsNav,
    },
    {
      to: '/admin/complaints',
      text: strings.complaints,
      icon: ComplaintsNav,
    },
    {
      to: '/admin/settings',
      text: strings.settings,
      icon: SettingsNav,
    },
  ];

  return (
    <SideNavWrapper>
      {linkOptions.map((link) => (
        <SideNavListItem key={link.to} to={link.to}>
          <SideNavIcon src={link.icon} />
          <SideNavLink>{link.text}</SideNavLink>
        </SideNavListItem>
      ))}
    </SideNavWrapper>
  );
};

export interface SideNavProps extends NavProps { }

export default SideNav;
