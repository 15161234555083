import { graphql } from '../generated';
import { createUseMutation, createUseQuery } from './helpers';

export const ADMIN_DATA_FRAGMENT = graphql(`
fragment AdminData on Admin {
  id
  firstName
  surname
  email
  profilePhoto
}
`);

export const GET_ADMIN = graphql(`
  query GetAdmin($input: adminIDInput!) {
    admin(input: $input) {
      admin {
        ...AdminData
      }
    }
  }
`);
export const useGetAdmin = createUseQuery(GET_ADMIN);

export const GET_ADMINS = graphql(`
  query GetAdmins($input: adminIDInput!) {
    allAdmins(input: $input) {
      admins {
        ...AdminData
      }
    }
  }
`);
export const useGetAdmins = createUseQuery(GET_ADMINS);

export const CREATE_ADMIN = graphql(`
  mutation CreateAdmin($input: AdminInput!) {
    newAdmin(input: $input) {
      admin {
        ...AdminData
      }
    }
  }
`);
export const useCreateAdmin = createUseMutation(CREATE_ADMIN);

export const EDIT_ADMIN = graphql(`
  mutation EditAdmin($input: AdminInput!) {
    editAdminProfile(input: $input) {
      admin {
        ...AdminData
      }
      success
    }
  }
`);
export const useEditAdmin = createUseMutation(EDIT_ADMIN);
