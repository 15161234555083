import React from 'react';

import { EllipsisIcon } from '../../assets/Icons';
import StyledEllipsis from './Styles';

const Ellipsis: React.FC<EllipsisProps> = ({ onClick }) => (
  <StyledEllipsis
    src={EllipsisIcon}
    alt="Context Menu"
    onClick={onClick}
  />
);

export interface EllipsisProps {
  onClick?: () => void;
}

export default Ellipsis;
