import styled from 'styled-components/macro';

export const ComplaintsHeading = styled.h1`
  margin: ${({ theme }) => theme.spacing.XS}rem ${({ theme }) => theme.spacing.XS}rem;
  font-size: 1.2rem;
`;

export const ComplaintList = styled.div`
  background-color: ${({ theme }) => theme.palette.bgSecondary};
  border-radius: 4px;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent; /* make scrollbar transparent i.e. hide */
  }
`;

export const ListHeadingContainer = styled.div`
  padding: 1.5rem 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ListHeading = styled.h2`
  font-weight: normal;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.palette.header};
`;

export const PageContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 97%;
  margin: 0 ${({ theme }) => theme.spacing.XS}rem;
`;

export const ComplaintViewContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.bgSecondary};
  width: 49%;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
  height: 75vh;
  border-radius: 4px;
`;

export const ComplaintListContainer = styled.div`
  width: 49%;
  max-height: 75vh;
  overflow: scroll;
  border-radius: 4px;
`;

interface ListItemProps {
  selected?: boolean;
}

export const ListItem = styled.div<ListItemProps>`
  padding: ${({ theme }) => theme.spacing.XXS}rem 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.palette.bgPrimary};
  box-shadow: ${({ selected }) => (selected ? '0px 0px 4px 1px rgba(120,120,120,0.45)' : '0')};
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 4px 1px rgba(120,120,120,0.45);
  }

  &:focus {
    outline: 0;
    outline: none;
  }
`;

export const Date = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 1.6rem
`;

export const Details = styled.p`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.subheader};
  line-height: 1.5;
`;

export const Name = styled.p`
  color: ${({ theme }) => theme.palette.header};
  font-size: 0.95rem;
  margin-bottom: 0.5rem;
`;

export const Reason = styled.p`
  color: ${({ theme }) => theme.palette.header};
  font-size: 0.95rem;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SeeAllButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.palette.subHeader};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.body};

  &:hover {
    text-decoration: underline;
  }
`;

export const DateMonth = styled.p`
  color: ${({ theme }) => theme.palette.subheader};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  margin-bottom: 0.4rem;
`;

export const DateNumber = styled.p`
  color: ${({ theme }) => theme.palette.subheader};
  font-size: 1.1rem;
`;
