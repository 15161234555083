import styled from 'styled-components/macro';

export const TableContainer = styled.table`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.S}% 0 0 0;
  color: ${({ theme }) => theme.palette.tableHeader};
  background-color: transparent;
  text-align: left;
  margin: 0;
  border-collapse: collapse;
  min-width: fit-content;
  table-layout: auto;

  thead {
    th {
      padding-left: ${({ theme }) => theme.spacing.XL}px;
      
      &:first-child {
        width: fit-content;
      } 
    
      &:last-child {
        width: 20px;
      }
    }
  }
  tbody {
    tr {
      td {
        &:first-child {
            width: fit-content;
        }
        
        &:last-child {
          text-align: right;
        }
      }
    }
  }
`;

export const TableHeader = styled.thead`
  background-color: ${({ theme }) => theme.palette.tableHeaderBg};
  color: ${({ theme }) => theme.palette.tableHeader};
`;

export const TableHeaderRow = styled.tr`
  width: 100%;
  height: fit-content;
  border: none;
  background-color: transparent;
  color: inherit;
`;

export const TableHeaderCell = styled.th`
  width: fit-content;
  height: fit-content;
  text-align: left;
  color: ${({ theme }) => theme.palette.tableHeader};
  background-color: ${({ theme }) => theme.palette.tableHeaderBg};
  padding: ${({ theme }) => theme.spacing.XXL}px 0 ${({ theme }) => theme.spacing.XL}px ${({ theme }) => theme.spacing.XL}px; 
  font-size: calc(${({ theme }) => theme.fontScale.XS}rem * 1.05);
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  white-space: nowrap;
  vertical-align: middle;
  margin: auto 0;
  min-width: fit-content;
`;

export const TableBody = styled.tbody`
  width: 100%;
  min-width: 100%;
  color: ${({ theme }) => theme.palette.tableBody};
  background-color: ${({ theme }) => theme.palette.tableBodyBg};
`;

export const TableRow = styled.tr`
  width: 100%;
  height: 40px;
  border-bottom: ${({ theme }) => theme.borderStyles.secondary};
  background-color: ${({ theme }) => theme.palette.tableRowBg};
  color: ${({ theme }) => (theme.palette.tableRow)};

  &:nth-child(2) {
    border-top: ${({ theme }) => theme.borderStyles.tertiary};
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent; /* make scrollbar transparent i.e. hide */
  }
`;
export const TableCell = styled.td`
  height: fit-content};
  text-align: left;
  color: ${({ theme }) => theme.palette.tableBody};
  background-color: transparent;
  padding: 0 0 0 ${({ theme }) => theme.spacing.XL}px; 
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  white-space: nowrap;
  vertical-align: middle;
  margin: auto 0;
  min-width: fit-content;
  width: fit-content;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent; /* make scrollbar transparent i.e. hide */
  }
`;

interface PaginationWrapProps {
  paginated?: boolean;
}

export const PaginationWrap = styled.div<PaginationWrapProps>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  visibility: ${({ paginated }) => (paginated ? 'visible' : 'hidden')};
  height:  ${({ paginated }) => (paginated ? '60px' : '0')};
  width: 100%;
`;

interface PaginationButtonProps {
  active?: boolean;
  disabled?: boolean;
}

export const PaginationButton = styled.button<PaginationButtonProps>`
  min-width: 28px;
  height: 28px;
  margin-left: ${({ theme }) => theme.spacing.M}px;
  margin-right: ${({ theme }) => theme.spacing.M}px;
  background-color: ${({ theme, active }) => (active ? theme.palette.primary : theme.palette.white)};
  border-radius: 4px;
  border: ${({ theme, active }) => (active ? 'none' : theme.borderStyles.secondary)};
  color: ${({ theme, active }) => (active ? theme.palette.white : '')};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontScale.S}rem;
  text-align: center;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  
  &:focus {
    outline: 0;
  }
`;
export const PaginationSeparator = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.body};
`;

export const SortImg = styled.img`
  width: 7px;
  height: auto;
  object-fit: contain;
  margin: 0 0 1px 4px;
`;

export const Sort = styled.span`
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
