import firebase from 'firebase/app';
import 'firebase/storage';
import { v4 as uuid } from 'uuid';

// Functions relating to storing / deleting media from firebase storage

export type ProgressCallback = (progress: number, uploaded: number, total: number) => void;

export const uploadMedia = async (
  media: File,
  onProgress?: ProgressCallback,
  storagePath = 'organisation/',
  fileName = null,
): Promise<string | null> => {
  const ext = media.name.split('.').pop()?.toLowerCase();

  const uploadTask = firebase
    .storage()
    .ref()
    .child(`${storagePath}/${fileName || uuid()}.${ext}`)
    .put(media);

  uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, ({ bytesTransferred, totalBytes }) => {
    const progress = (bytesTransferred / totalBytes) * 100;
    if (onProgress) onProgress(progress, bytesTransferred, totalBytes);
  });
  const finalSnapshot = await uploadTask;
  return finalSnapshot.ref.getDownloadURL();
};

export const deleteMedia = async (url: string): Promise<null> => {
  await firebase
    .storage()
    .refFromURL(url)
    .delete();
  return null;
};

export default { uploadMedia, deleteMedia };
