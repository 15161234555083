import { Device } from '../../lib/types/StyleTypes';

const defDevice: Device = {
  minDevice: '@media (min-width: 320px)',
  mobile: '@media (min-width: 370px)',
  tablet: '@media (min-width: 768px)',
  laptop: '@media (min-width: 1366px)',
  desktop: '@media (min-width: 1440px)',
  maxDevice: '@media (min-width: 1920px)',
};

export default defDevice;
