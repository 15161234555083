import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { PhoneNumberUtil } from 'google-libphonenumber';
import * as lookup from 'country-code-lookup';
import { Guest, DonationType } from './types';

/** Format Donation from total cents to $XX.XX */
export const displayDonation = (cents: number): string => `$${(cents / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

/** Format EventID as #Sxxxx */
export const displayEventID = (id: string): string => `#S${id.slice(0, 4).toUpperCase()}`;
// TODO: check how these should be displayed?

/** Create an array of dates from given start date */
export const getDashboardGraphDates = (start: string): string[] => Array(7).fill(null)
  .map((day, i) => moment(start).add(i, 'days').format('YYYY-MM-DD'));

/** Check if event has any RSVP responses */
export const hasAnyGuestRSVPResponded = (guests: Guest[]): boolean => (
  guests.some(({ rsvpd }) => rsvpd)
);

/** Display donation type in readable format */
export const displayDonationType = (type: DonationType | string | null | undefined): string => {
  let donationType = '';
  switch (type) {
    case DonationType.DONATED_ANYWAY:
      donationType = 'Donated Anyway';
      break;
    case DonationType.MISSED_CHECK_IN:
      donationType = 'Missed Check-in';
      break;
    case DonationType.HOST_CANCELLED_EVENT:
      donationType = 'Event Cancelled';
      break;
    case DonationType.GUEST_CANCELLED_ATTENDANCE:
      donationType = 'Guest Cancelled Attendance';
      break;
    default:
      break;
  }
  return donationType;
};

/** Calculate valueOf date for statistics filter */
export const fromDate = (filter: 'week' | 'month' | 'year'): number => {
  let date = 0;
  switch (filter) {
    case 'week':
      date = moment().startOf('week').add(1, 'day').valueOf();
      break;
    case 'month':
      date = moment().startOf('month').valueOf();
      break;
    case 'year':
      date = moment().startOf('year').valueOf();
      break;
    default:
      date = moment().startOf('week').add(1, 'day').valueOf();
      break;
  }
  return date;
};

/** Convert event times to event location timezone */
export const getTimeInLocationTimezone = (
  timezoneID: string, time: number,
): momentTimezone.Moment => {
  const locationZone = momentTimezone.tz.zone(timezoneID);
  const offset = locationZone && locationZone?.parse(time);
  const utcTime = momentTimezone(time).utcOffset(offset || 0);
  const actualTime = utcTime.tz(timezoneID);
  return actualTime;
};

/** Display country from mobile number used */
const phoneUtil = PhoneNumberUtil.getInstance();

export const getCountryFromPhoneNumber = (
  areaCode: string | null | undefined,
  phoneNumber: string | null | undefined,
): string | undefined => {
  if (!areaCode || !phoneNumber) {
    return undefined;
  }

  const formatted = '+'.concat(areaCode).concat(phoneNumber);
  try {
    const number = phoneUtil.parseAndKeepRawInput(formatted, 'US');
    const countrycode = phoneUtil.getRegionCodeForNumber(number);
    const lookupCountry = countrycode ? lookup.byInternet(countrycode) : undefined;
    return lookupCountry?.country;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Invalid phone number:', formatted);
    return undefined;
  }
};

export default ({
  displayDonation,
  displayEventID,
  hasAnyGuestRSVPResponded,
});
