import styled from 'styled-components/macro';

const StyledScrollableTable = styled.div`
  overflow-x: auto;

  @media(min-width: 1310px) {
    max-width: 76vw;
  }

  @media(min-width: 1410px) {
    max-width: 78vw;
  }

  @media(min-width: 1510px) {
    max-width: 80vw;
  }

  @media(min-width: 1610px) {
    max-width: 82vw;
  }
`;

export default StyledScrollableTable;
