import { GuestDataFragment, UserDataFragment } from '../../../lib/graphql/generated/graphql';
import { getCountryFromPhoneNumber, getTimeInLocationTimezone } from '../../../lib/Utils';

export function getCountryStr(user: UserDataFragment | null | undefined) {
  if (user == null) {
    return '';
  }

  const country = getCountryFromPhoneNumber(
    user.mobileInformation.areaCode,
    user.mobileInformation.mobileNumber,
  );

  return country || '';
}

export function getEventTime(time: number | null | undefined, timezoneId: string | null | undefined) {
  if (!time) {
    return null;
  }
  return getTimeInLocationTimezone(timezoneId || 'Australia/Melbourne', time);
}

export function formatEventTime(time: number | null | undefined, timezoneId: string | null | undefined) {
  return getEventTime(time, timezoneId)?.format('DD/MM/YY') || '';
}

export function getGuestFirstName(guest: GuestDataFragment) {
  return guest.name ?? guest.user?.givenName ?? 'Unknown Guest First Name';
}

export function getGuestSurname(guest: GuestDataFragment) {
  return guest.user?.surname ?? '';
}

export function getGuestEmail(guest: GuestDataFragment) {
  return guest.email ?? guest.user?.email ?? '';
}

export function getGuestRsvpStatus(guest: GuestDataFragment) : string {
  if (guest.rsvpd == null) {
    return 'Await';
  }
  return guest.rsvpd ? 'Going' : 'Not Going';
}
