import React, { useCallback, useMemo, useState } from 'react';
import { Cell, Column } from 'react-table';
import { useHistory, useLocation } from 'react-router';

import {
  PageLayout,
  ContentWrapper,
  ContentHeader,
  ViewAllLink,
  Table,
  ViewProfile,
  Button,
  InactiveFlag,
  Ellipsis,
} from '../../../components';
import CharityModal from './CharityModal';
import ExportCharityModal from './ExportCharityModal';

import useStrings from '../../../hooks/useStrings';
import { getUserId } from '../../../lib/Authentication';
import { useGetOrganizationsIgnoringDisabled } from '../../../lib/graphql/queries';
import { SUBSET_SIZE } from '../../../lib/Constants';
import { displayDonation } from '../../../lib/Utils';
import { OrganizationQueryViewDataFragment } from '../../../lib/graphql/generated/graphql';

const Charities: React.FC = () => {
  const [{ Charities: strings }] = useStrings();

  const history = useHistory();
  const previousPath = history.location.pathname;
  const { state }: { state: { displayHeader?: string } } = useLocation();

  const [viewingAll, setViewingAll] = useState(!!state?.displayHeader);
  const [displayHeader, setDisplayHeader] = useState<string>(state?.displayHeader || '');

  const [creatingCharity, setCreatingCharity] = useState(false);
  const [editingCharity, setEditingCharity] = useState<OrganizationQueryViewDataFragment | null>(null);
  const [showExportingModal, setShowExportingModal] = useState(false);

  const authID = getUserId();
  const { data, loading } = useGetOrganizationsIgnoringDisabled({
    variables: { adminID: authID },
  });
  const allCharities = useMemo(() => data?.organizationsIgnoringDisabled.organizations || [],
    [data?.organizationsIgnoringDisabled.organizations]);

  const activeCharities = useMemo(() => allCharities
    .filter((charity) => !charity.disabled), [allCharities]);

  const inactiveCharities = useMemo(() => allCharities
    .filter((charity) => charity.disabled), [allCharities]);

  const filteredCharityData = useCallback(() => {
    if (displayHeader === strings.allCharities) {
      return allCharities;
    }
    if (displayHeader === strings.activeCharities) {
      return activeCharities;
    }
    if (displayHeader === strings.inactiveCharities) {
      return inactiveCharities;
    }
    return allCharities;
  }, [displayHeader, strings, allCharities, activeCharities, inactiveCharities]);

  const onViewAll = (set: string) => {
    switch (set) {
      case 'all':
        setDisplayHeader(strings.allCharities);
        break;
      case 'active':
        setDisplayHeader(strings.activeCharities);
        break;
      case 'inactive':
        setDisplayHeader(strings.inactiveCharities);
        break;
      default:
        break;
    }
    setViewingAll(true);
  };

  const handleBack = () => {
    setViewingAll(false);
    setDisplayHeader('');
  };

  const columns: Column<Partial<OrganizationQueryViewDataFragment>>[] = useMemo(() => [
    {
      Header: '',
      id: 'charityStatus',
      Cell: ({ row }: Cell<OrganizationQueryViewDataFragment>) => (
        row.original.disabled ? <InactiveFlag /> : <div />
      ),
    },
    {
      Header: strings.tableHeadings.charityName,
      id: 'charityName',
      accessor: (originalRow) => originalRow.name,
    },
    // TODO: implement
    // {
    //   Header: strings.tableHeadings.category,
    //   id: 'category',
    // },
    {
      Header: strings.tableHeadings.nominated,
      accessor: (originalRow) => originalRow.totalEvents,
      id: 'nominated',
    },
    {
      Header: strings.tableHeadings.donations,
      accessor: (originalRow) => displayDonation(originalRow.totalDonated || 0),
      id: 'donations',
    },
    {
      Header: strings.tableHeadings.action,
      id: 'viewProfile',
      Cell: ({ row }: Cell<OrganizationQueryViewDataFragment>) => (
        <ViewProfile onClick={() => history.push(`/admin/charity/${row.original.id}`, { displayHeader, previousPath })}>
          {strings.viewProfile}
        </ViewProfile>
      ),
    },
    {
      Header: '',
      id: 'modifyCharity',
      Cell: ({ row }: Cell<OrganizationQueryViewDataFragment>) => (
        <Ellipsis onClick={() => setEditingCharity(row.original)} />
      ),
    },
  ], [strings, displayHeader, history, previousPath]);

  return (
    <PageLayout loading={loading} pageTitle={strings.pageTitle}>
      {creatingCharity && <CharityModal closeModal={() => setCreatingCharity(false)} />}
      {editingCharity && (
        <CharityModal
          closeModal={() => setEditingCharity(null)}
          initialData={editingCharity}
          isEdit
        />
      )}
      {showExportingModal && <ExportCharityModal closeModal={() => setShowExportingModal(false)} />}
      {viewingAll ? (
        <ContentWrapper>
          <ContentHeader
            contentTitle={displayHeader}
            hasBackButton
            clickFunction={handleBack}
          >
            <Button
              buttonText={strings.export}
              onClick={() => setShowExportingModal(true)}
              variant="secondary"
              alignRight
            />
            <Button
              buttonText={strings.addNew}
              onClick={() => setCreatingCharity(true)}
              variant="primary"
              alignRight
            />
          </ContentHeader>
          <Table
            columns={columns}
            data={filteredCharityData()}
            paginated
          />
        </ContentWrapper>
      ) : (
        <>
          {/* ALL CHARITIES */}
          <ContentWrapper>
            <ContentHeader
              contentTitle={strings.allCharities}
              legendText={strings.inactiveCharities}
            >
              <ViewAllLink to="#" onClick={() => onViewAll('all')}>{strings.viewAll}</ViewAllLink>
            </ContentHeader>
            <Table
              data={allCharities.slice(0, SUBSET_SIZE)}
              columns={columns}
            />
          </ContentWrapper>

          {/* ACTIVE CHARITIES */}
          <ContentWrapper>
            <ContentHeader contentTitle={strings.activeCharities}>
              <ViewAllLink to="#" onClick={() => onViewAll('active')}>{strings.viewAll}</ViewAllLink>
            </ContentHeader>
            <Table
              data={activeCharities.slice(0, SUBSET_SIZE)}
              columns={columns}
            />
          </ContentWrapper>

          {/* INACTIVE CHARITIES */}
          <ContentWrapper>
            <ContentHeader contentTitle={strings.inactiveCharities}>
              <ViewAllLink to="#" onClick={() => onViewAll('inactive')}>{strings.viewAll}</ViewAllLink>
            </ContentHeader>
            <Table
              data={inactiveCharities.slice(0, SUBSET_SIZE)}
              columns={columns}
            />
          </ContentWrapper>
        </>
      )}
    </PageLayout>
  );
};

export default Charities;
