import { graphql } from '../generated';
import { createUseQuery } from './helpers';

export const GET_TOTAL_USER_COUNT = graphql(`
  query GetTotalUsers {
    countTotalUsers {
      totalUsers
    }
  }
`);
export const useGetTotalUsers = createUseQuery(GET_TOTAL_USER_COUNT);

export const GET_TOTAL_EVENTS_COUNT = graphql(`
  query GetTotalEvents {
    countTotalEvents {
      totalEvents
    }
  }
`);
export const useGetTotalEvents = createUseQuery(GET_TOTAL_EVENTS_COUNT);

export const GET_TOTAL_DONATIONS = graphql(`
  query GetTotalDonations {
    totalDonatedSmyleeWide {
      smyleeWideDonations
    }
  }
`);
export const useGetTotalDonations = createUseQuery(GET_TOTAL_DONATIONS);

export const GET_TOTAL_SMYLEE_REVENUE = graphql(`
  query GetTotalSmyleeRevenue($adminID: ID!) {
    smyleeRevenue(adminID: $adminID) {
      smyleeRevenue
    }
  }
`);
export const useGetTotalSmyleeRevenue = createUseQuery(GET_TOTAL_SMYLEE_REVENUE);

export const DONATIONS_THIS_WEEK = graphql(`
  query GetDonationsThisWeek($date: String!) {
    donationsThisWeek(date: $date) {
      donationsMadeThisWeek {
        lastTried
        amount
      }
    }
  }
`);
export const useGetDonationsThisWeek = createUseQuery(DONATIONS_THIS_WEEK);

export const USERS_THIS_WEEK = graphql(`
  query GetUsersThisWeek($date: String!) {
    usersThisWeek(date: $date) {
      usersCreatedThisWeekDates
    }
  }
`);
export const useGetUsersThisWeek = createUseQuery(USERS_THIS_WEEK);

export const EVENTS_CREATED_THIS_WEEK = graphql(`
  query GetEventsCreatedFromDate($date: String!) {
    eventsCreatedFromDate(date: $date) {
      eventsDates
    }
  }
`);
export const useGetEventsCreatedFromDate = createUseQuery(EVENTS_CREATED_THIS_WEEK);

export const EVENTS_HELD_THIS_WEEK = graphql(`
  query GetEventsHeldFromDate($date: String!) {
    eventsHeldFromDate(date: $date) {
      eventsDates
    }
  }
`);
export const useGetEventsHeldFromDate = createUseQuery(EVENTS_HELD_THIS_WEEK);

export const EVENTS_CANCELLED_THIS_WEEK = graphql(`
  query GetEventsCancelledFromDate($date: String!) {
    eventsCancelledFromDate(date: $date) {
      eventsDates
    }
  }
`);
export const useGetEventsCancelledFromDate = createUseQuery(EVENTS_CANCELLED_THIS_WEEK);
