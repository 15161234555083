import React, { useEffect, useState } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components/macro';
import { ApolloProvider } from '@apollo/client';
import { createBrowserHistory } from 'history';
import {
  Router, Switch, Route, Redirect,
} from 'react-router-dom';

import appTheme from './style/themes/appTheme';
import { reset } from './style/themes/reset';
import { apolloInit, Client } from './lib/Apollo';
import { onAuthChange } from './lib/Authentication';
import { AuthProvider } from './hooks/useAuth';
import { setPage, logUserData } from './lib/Analytics';

import { LoadingScreen } from './components';

import Admin from './routes/Admin';
import Home from './pages/Home';
import ForgotPassword from './pages/ForgotPassword';

const GlobalStyle = createGlobalStyle`
  ${reset}
`;

const history = createBrowserHistory();

const App: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [apolloClient, setApolloClient] = useState<Client | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const client = apolloInit();
    setApolloClient(client);
    const unSubHistory = history.listen((location) => setPage(location.pathname));
    const unSubAuth = onAuthChange((user) => {
      if (user) {
        logUserData({ id: user.uid, email: user.email || '' });
      }
      setLoggedIn(!!user && !user.isAnonymous);
      setLoading(false);
    });

    return () => { unSubHistory(); unSubAuth(); };
  }, []);

  return (
    <ThemeProvider theme={appTheme}>
      <GlobalStyle />
      <div className="App">
        {(loading || !apolloClient ? <LoadingScreen /> : (
          <ApolloProvider client={apolloClient}>
            <AuthProvider value={loggedIn}>
              <Router history={history}>
                <Switch>
                  {/* Restricted Admin Routes */}
                  <Route path="/admin" component={Admin} />
                  {/* Home Page */}
                  <Route path="/home" component={Home} />
                  {/** Forgot password */}
                  <Route path="/forgot-password" component={ForgotPassword} />
                  {/* Catch all */}
                  <Route path="/">
                    <Redirect to="/home" />
                  </Route>
                </Switch>
              </Router>
            </AuthProvider>
          </ApolloProvider>
        ))}
      </div>
    </ThemeProvider>
  );
};

export default App;
