import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';

import {
  BackgroundImage,
  Wrapper,
  SmyleeLogo,
  Heading,
  Form,
  ShowPasswordText,
  ForgotPasswordLink,
} from './Styles';
import { Logo } from '../../assets/Images';
import { Lock, CloseEnvelope } from '../../assets/Icons';
import {
  Field, Icon, Label, InputWrapper, TextInputField, Button, Error,
} from '../../components';

import useStrings from '../../hooks/useStrings';
import { signIn } from '../../lib/Authentication';
import { logError } from '../../lib/Analytics';

type LoginFormValues = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const [{ Login: strings }] = useStrings();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setErrors,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async ({ email, password }: LoginFormValues) => {
      setLoading(true);
      try {
        await signIn(email, password);
        history.replace('/admin/dashboard');
      } catch (err) {
        logError(err);
        setLoading(false);
        switch (err.code) {
          case 'auth/user-not-found':
            setErrors({ email: strings.emailDoesNotExist });
            break;
          case 'auth/wrong-password':
            setErrors({ password: strings.wrongPassword });
            break;
          default:
            setErrors({ email: strings.defaultError, password: '' });
            break;
        }
      }
    },
  });

  return (
    <BackgroundImage>
      <Wrapper>
        <SmyleeLogo src={Logo} />
        <Heading>{strings.heading}</Heading>
        <Form onSubmit={handleSubmit}>
          <InputWrapper>
            <Label error={!!errors.email} htmlFor="email">{strings.emailLabel}</Label>
            <Field>
              <Icon src={CloseEnvelope} />
              <TextInputField
                id="email"
                name="email"
                type="text"
                value={values.email}
                onChange={handleChange}
                error={!!errors.email}
                hasIcon
              />
            </Field>
            <Error>{errors.email}</Error>
          </InputWrapper>
          <InputWrapper>
            <Label error={!!errors.password} htmlFor="password">{strings.passwordLabel}</Label>
            <Field>
              <Icon src={Lock} />
              <TextInputField
                id="password"
                name="password"
                type={passwordVisible ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange}
                hasIcon
              />
              <ShowPasswordText onClick={() => setPasswordVisible(!passwordVisible)}>
                {strings.show}
              </ShowPasswordText>
            </Field>
            <Error>{errors.password}</Error>
          </InputWrapper>
          <Button variant="modal" disabled={loading} type="submit">
            {loading ? strings.loadingButtonText : strings.buttonText}
          </Button>
        </Form>
        <ForgotPasswordLink to="/forgot-password">
          {strings.forgotPassword}
        </ForgotPasswordLink>
      </Wrapper>
    </BackgroundImage>
  );
};

export default Login;
