import styled from 'styled-components/macro';

export const Subheading = styled.h3`
  margin-top: ${({ theme }) => theme.spacing.S}rem;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const LogoImageContainer = styled.img`
  height: 7rem;
  width: 66%;
  background-color: #D8D8D8;
  border-radius: 20px;
  border: ${({ theme }) => theme.borderStyles.primary};;
`;

export const AddLogoButton = styled.button`
  height: 2.5rem;
  width: 30%;
  background: none;
  border: ${({ theme }) => theme.borderStyles.primary};
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.bold};  
  cursor: pointer;
`;

export const CauseDropdownButtonContainer = styled.div``;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const UploadButton = styled.button`
  height: 30px;
  padding: 0rem 2rem;
  margin: 0.5rem 0;
  background-color: ${({ theme }) => theme.palette.white};
  border: ${({ theme }) => theme.borderStyles.primary};
  cursor: pointer;
  color: ${({ theme }) => theme.palette.title};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  outline: none;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.25rem;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontScale.XS}rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.bold};  
  transition: all .5s ease-in-out;
  box-sizing: border-box;
  position: absolute;
  right: 15.5%;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &:active {
    transform: scale(0.95);
  }
    
  &:hover {
    background: ${({ theme }) => theme.palette.title};
    color: ${({ theme }) => theme.palette.white};
    border-color: ${({ theme }) => theme.palette.title};
    transform: scale(1.02);
  }
`;

export const CauseUploadButton = styled(UploadButton)`
  right: 19%;
`;

export const CauseExpandBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: ${({ theme }) => theme.spacing.S * 0.85}rem;
  padding: 0rem 1.3rem;
  background-color: black;
  border-radius: 4px;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
`;

export const ExpandButton = styled.img`
  cursor: pointer;
`;

export const CauseHeading = styled.h4`
  color: ${({ theme }) => theme.palette.white};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontScale.S}rem;
`;

export const CauseContainer = styled.div`
  border: ${({ theme }) => theme.borderStyles.primary};
  border-radius: 4px;
  padding: 1.5rem ${({ theme }) => theme.spacing.XXS}rem 0.2rem ${({ theme }) => theme.spacing.XXS}rem;
  margin-bottom: ${({ theme }) => theme.spacing.XXS}rem;
`;
